import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";


const Exclusive = ({ slug, lang }) => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const employee_type = localStorage.getItem("employee_type");
  const [hasExclusive, setHasExclusive] = useState(true);
  useEffect(() => {
    if (role == "affiliate") {
      AxiosController.get(
        `api/${slug}/${lang}/exclusive/get-exclusive`,        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {
          setHasExclusive(true)
        })
        .catch((e) => {
          setHasExclusive(false)
        });
    }
  }, []);
  return (
    <>
      {hasExclusive ? (
        <li>

          <NavLink to={`/${slug}/${lang}/panel/products/${role == 'affiliate' || employee_type == 'confirmation employee' ? 'prodcuts-exclusive' : 'exclusive-products'}`}>
            <p className="d-inline ">{_("panel.ExclusiveProduct")}</p>
          </NavLink>
        </li>
      ) : (
        ""
      )}
    </>
  );
};

export default Exclusive;
