import "./Product.css";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Dropdown, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { Breadcrumb } from "antd";
import IconsGen from "../../components/icons_generator/IconsGen";
import AxiosController from "../../components/authentication/AxiosController";
import Loader from "../../components/loader/Loader";
import ProductsSlider from "../../components/home/ProductsSlider";
import ProductSwipper from "../../components/home/ProductSwipper";
import easyOrders from "../../imgs/easy-icon.png";
import CreateProduct from "../../components/panel/outside integrations/requests classes/CreateProduct";
import VariantsSelector from "./VariantsSelector";
import { UserContext } from "../../components/contexts/UserContext";import { Helmet } from "react-helmet";

const Product = () => {
  const { slug, lang, id } = useParams();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const { userData } = useContext(UserContext);

  const [loading, handleLoader] = useState(false);
  const role = localStorage.getItem("role") ?? "";
  const [product, handleProduct] = useState({});
  const [api, contextHolder] = notification.useNotification();

  const [sameCatProducts, handleSameCatProducts] = useState([]);
  const [productImgs, handleProductImgs] = useState([]);
  const [resData, setResData] = useState([]);
  const [productVariants, handleProductVariants] = useState([]);
  const [selectedVariant, handleSelectedVariant] = useState("");
  const [hasKey2, setHasKey] = useState(false);
  const [key, setKey] = useState([]);
  const [altName, handleAltName] = useState("");
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token") ?? "";
  const Navigate = useNavigate();

  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const exportToEasyOrders = (secretKey) => {
    handleLoader(true);
    axios
      .create({
        headers: {
          Accept: "application/json",
        },
      })
      .post(
        `https://api.easy-orders.net/api/v1/external-apps/products`,
        new CreateProduct(resData, slug),
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Api-Key": secretKey,
          },
        }
      )
      .then((res) => {
        openNotification(
          lang == "ar" ? "topRight" : "topLeft",
          _("panel.productAddedToEasyOrders")
        );
        handleLoader(false);
      })
      .catch(() => {});
  };

  const hasKey = () => {
    AxiosController.get(`api/${slug}/${lang}/get-secrets/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        type: "easy_order",
      },
    })
      .then((res) => {
        if (res.data) setHasKey(true);
        setKey(res.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (token) {
      window.scrollTo(0, 0);
      handleLoader(true);
      AxiosController.get(`api/${slug}/${lang}/get-product/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          exclusive: urlParams.get("exclusive"),
        },
      })
        .then((res) => {
          setResData(res.data);
          handleProduct(res.data.product);
          handleSameCatProducts(res.data.same_category);
          handleAltName(res.data.alt_name);
          handleProductImgs(res.data.product_imgs);
          if (res.data.product_variants.length > 0) {
            handleProductVariants(res.data.product_variants);
            handleSelectedVariant(res.data.product_variants[0]);
          } else {
            Navigate(`/${slug}/${lang}/`);
          }
          handleLoader(false);
        })
        .catch((e) => {
          handleLoader(false);
          Navigate(`/${slug}/${lang}/`);
        });
    } else {
      Navigate(`/ar/auth/login`);
    }
    hasKey();
  }, [id, slug, lang]);
  const openNotification = (placement, status) => {
    api.success({
      style: { direction: lang == "ar" ? "rtl" : "ltr" },
      description: `${status}!`,
      placement,
    });
  };

  const addToCart = () => {
    AxiosController.post(
      `api/${slug}/${lang}/add-to-cart/${id}/${selectedVariant.id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        document.getElementById("ecomboCart").click();
      })
      .catch((e) => {});
  };
  const addToFav = () => {
    AxiosController.post(
      `api/${slug}/${lang}/add-to-fav/${id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        openNotification(
          lang == "ar" ? "topRight" : "topLeft",
          res.data.success.fav_added
        );
      })
      .catch((e) => {
        Navigate(`/${slug}/${lang}/product/${id}`);
      });
  };

  return (
    <Container dir={lang === "en" ? "rtl" : "ltr"}>
      {contextHolder}
      <div> <Helmet>
            <title>{product.title}</title>
            <meta name="description" content={product.description} />
        </Helmet></div>
      <Breadcrumb
        style={{ direction: box_head_dir }}
        separator=">"
        className="p-4 px-0 fs-5"
        itemRender={(route, _, routes) => {
          const last = routes.indexOf(route) === routes.length - 1;
          return last ? (
            <Link style={{ color: "#eea536" }}>{route.title}</Link>
          ) : (
            <Link to={route.href} style={{ color: "#57c2c5" }}>
              {route.title}
            </Link>
          );
        }}
        items={[
          {
            title: _("panel.main"),
            href: `/${slug}/${lang}`,
          },
          {
            title: _("panel.products"),
            href: `/${slug}/${lang}/panel/products`,
          },
          {
            title:
              lang == "en"
                ? sameCatProducts[0]
                  ? sameCatProducts[0]["arabic_name"]
                  : ""
                : sameCatProducts[0]
                ? sameCatProducts[0]["arabic_name"]
                : "",
            href: `/${slug}/${lang}/category/${product.category_id}`,
          },
        ]}
      />

      <div
        className="product-whole-container d-flex flex-column"
        style={{ position: "relative" }}
      >
        <Loader active={loading} />
        <div className="product-container row my-3 gap-2">
          <div
            className="product-info col-12 px-4 col-md-5 flex-grow-1"
            style={{ height: "fit-content" }}
          >
            <div
              className="d-flex flex-column"
              style={{ height: "100%" }}
              dir={lang === "en" ? "ltr" : "rtl"}
            >
              <div className="top d-flex justify-content-between my-3 align-items-center">
                <h1>{product.title}</h1>
                <div
                  className={` ${product.is_fav === "true" ? true : false}`}
                  onClick={(e) => {
                    addToFav(product.id);
                    e.target.parentNode.classList.toggle("active");
                  }}
                >
                  <IconsGen
                    icon_name={"heartCart"}
                    active={product.is_fav === "true" ? true : false}
                  />
                </div>
              </div>
              <div className="head d-flex justify-content-between flex-column flex-lg-row gap-1">
                <div
                  className="product-number d-flex col-6"
                  style={{ flexGrow: 1 }}
                >
                  <p className="product-label">{_("home.productcode")}</p>
                  <p className="product-value"> : {product.id}</p>
                </div>

                <div
                  className="product-code d-flex col-6 justify-content-start gap-1"
                  style={{ flexGrow: 1 }}
                >
                  <p className="product-label">{_("home.productsku")}</p>
                  <p className="product-value">
                    {" "}
                    : {selectedVariant.sku ? selectedVariant.sku : ""}
                  </p>
                </div>
              </div>

              <div className="props price d-flex justify-content-between">
                <p className="pricing-label flex-grow-1">
                  {_("home.costprice")}
                </p>
                <p
                  className="pricing-value flex-grow-1"
                  style={{ paddingRight: "10px" }}
                >
                  {userData?.level_data && userData?.level_data?.commission ? (
                    <>
                      <del className="text-secondary">{product.price}</del>
                    </>
                  ) : (
                    product.price
                  )}{" "}
                  {altName}
                </p>
              </div>
              {userData?.level_data && userData?.level_data?.commission  ? (
                <div className="props price d-flex justify-content-between ecombo-primary">
                  <p className="pricing-label flex-grow-1">
                    {_("home.levelPrice")}
                  </p>
                  <p
                    className="pricing-value flex-grow-1 "
                    style={{ paddingRight: "10px" }}
                  >
                    {product.price - userData?.level_data?.commission} {altName}
                  </p>
                </div>
              ) : (
                ""
              )}

              {productVariants.length > 1 ? (
                <VariantsSelector
                  variants={productVariants}
                  selectedVariant={selectedVariant}
                  setSelectedVariant={handleSelectedVariant}
                />
              ) : (
                ""
              )}
              <div
                className="product-description"
                dangerouslySetInnerHTML={{ __html: product.description }}
                dir={box_head_dir}
              ></div>
              <div className="buttons d-flex flex-column gap-2 my-5 justify-content-start">
                {["AFFILIATE", "ADMIN", "EMPLOYEE", "FULFILLMENT"].includes(
                  role.toUpperCase()
                ) ? (
                  <div className="top d-flex gap-4">
                    <button
                      className="btn"
                      style={{ flexGrow: 1, background: "rgb(243, 168, 55)" }}
                      onClick={() => {
                        Navigate(
                          `/${slug}/${lang}/order-now/${id}/${selectedVariant.id}`
                        );
                      }}
                    >
                      {_("home.ordernow")}
                    </button>
                    <button
                      onClick={addToCart}
                      className="btn"
                      style={{
                        flexGrow: 1,
                        background: "rgb(89, 198, 201)",
                        color: "white",
                      }}
                    >
                      {_("home.addtocart")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="button d-flex flex-column gap-2 justify-content-start"
                  style={{}}
                >
                  <Link
                    to={product.google_drive_link}
                    className="flex-grow-1"
                    target={"_blank"}
                  >
                    <button
                      className="btn d-flex flex-row-reverse gap-2 justify-content-center align-items-center"
                      style={{ width: "100%", border: "1px solid " }}
                    >
                      {_("home.productfiles")}{" "}
                      <IconsGen icon_name={"googleDrive"} />
                    </button>
                  </Link>
                </div>

                {key.length ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="w-100"
                      style={{
                        backgroundColor: "transparent",
                        color: "rgba(23, 43, 77, 1)",
                      }}
                    >
                      {_("panel.retrieveAtEasyOrders")}
                      <img src={easyOrders} width={"20px"} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                      {key.map((key) => {
                        return (
                          <Dropdown.Item
                            onClick={() => exportToEasyOrders(key.secretKey)}
                          >
                            {key.secretKey}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <ProductSwipper productImgs={productImgs} />
        </div>
      </div>
      <div className="d-block d-md-none">
        <div className="buttons d-flex flex-column gap-2 my-5 justify-content-start">
          {["AFFILIATE", "EMPLOYEE", "ADMIN"].includes(role.toUpperCase()) ? (
            <div className="top d-flex gap-4">
              <button
                onClick={addToCart}
                className="btn"
                style={{ flexGrow: 1 }}
              >
                {_("home.addtocart")}
              </button>
            </div>
          ) : (
            ""
          )}
          <div className="bottom" style={{}}>
            <Link to={product.google_drive_link} target={"_blank"}>
              <button
                className="btn d-flex flex-row-reverse gap-2 justify-content-center align-items-center"
                style={{ width: "100%" }}
              >
                {_("home.productfiles")} <IconsGen icon_name={"googleDrive"} />
              </button>
            </Link>
          </div>
        </div>
      </div>
      {sameCatProducts.length > 0 ? (
        <ProductsSlider row={sameCatProducts} altName={altName} />
      ) : (
        ""
      )}
    </Container>
  );
};

export default Product;
