import React, { useEffect, useRef, useState } from "react"; 
import logo from "../../../../../imgs/Logo_Ecombo 1.png"; 
import { useTranslation } from "react-i18next";
import AxiosController from "../../../../authentication/AxiosController";
import { useParams } from "react-router-dom";
import { downloadPDF } from "./print";
import { FaFilePdf } from "react-icons/fa6";

const Manifest = ({ selectedOrders }) => {
  const tableRef = useRef(null);
  const [_] = useTranslation("global");
  const [selected, handleselected] = useState([]);
  const { slug, lang } = useParams();
  const [loading, handleLoader] = useState(false);
  const token = localStorage.getItem("token");
 
  const orderIdsQueryString = selectedOrders.map((order) => `order_ids[]=${order.id}`).join("&");

  const get_selected_manifest = () => {
    AxiosController.get(
      `api/${slug}/${lang}/orders/manifest?${orderIdsQueryString}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleselected(res.data); 
        if (res && res.data && res.data.Content) {
        } else {
          console.log("Content is empty or undefined");
        }

        handleLoader(false);
      })
      .catch((e) => {handleLoader(false);});
  };
  useEffect(() => {
    get_selected_manifest();
  }, [orderIdsQueryString]); 
  return (
    <>
     
        <button 
          onClick={()=>downloadPDF(selected ,_("panel.total") , _("panel.orderContent") , _("panel.courierNumber") , _("panel.totalorders2"))}
          className="btn  text-nowrap"
          style={{ border: "1px solid #172B4D", color: "#172B4D" }}
        >
          <FaFilePdf  className="fs-3 px-1 text-danger"/>
          {_("panel.Manifest")}

        </button>
 

      {/* <div className="row fw-bold" ref={tableRef}>
        <h1 className="text-center ecombo-secondary"> Manifest </h1>
        <div className="d-flex justify-content-end">
          <img
            src={logo}
            alt="logo"
            className="m-4"
            style={{ height: "65px", width: "200px" }}
          />
        </div>
        <hr />
        <table className="table-responsive   table table-borderless">
          <thead>
            <tr>
              <th>#</th>
              <th>{_("panel.total")}</th>
              <th>{_("panel.orderContent")}</th>
              <th>{_("panel.courierNumber")}</th>
            </tr>
          </thead>
          <tbody>
            {selected.map((ele, index) => (
              <tr key={ele["Ref No"]}>
                <td>{index + 1}</td>
                <td>{ele["COD"]}</td>
                <td>
                  <span className="fw-bold">#{ele["Ref No"]}</span> <br />
                  <span>
                    {ele["Content"] &&
                      ele["Content"].split("\n").map((line, i) => (
                        <p key={i}>
                          {line}
                          <br />
                        </p>
                      ))}
                  </span>
                </td>
                <td>{ele["Courier Track"]}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr style={{ borderTop: "3px solid black" }}>
              <td> </td>
              <td> </td>
              <td>{_("panel.totalorders2")}</td>
              <td>{selected.length}</td>
            </tr>
          </tfoot>
        </table>
        <hr />
        <div className="row" style={{ marginTop: "100px" }} dir="ltr">
          <div className="col-6 gy-4 d-flex justify-content-between">
            <p className="fw-bold"> send By : </p>
            <p> __________________________________________________</p>
          </div>
          <div className="col-6 gy-4 d-flex justify-content-between">
            <p className="fw-bold">Received By :</p>
            <p> __________________________________________________</p>
          </div>
        </div>
        <div className="row" dir="ltr">
          <div className="col-6 gy-4 d-flex justify-content-between">
            <p className="fw-bold"> Date : </p>
            <p> __________________________________________________</p>
          </div>
          <div className="col-6 gy-4 d-flex justify-content-between">
            <p className="fw-bold">Date :</p>
            <p> __________________________________________________</p>
          </div>
        </div>
        <div className="row" dir="ltr">
          <div className="col-6 gy-4 d-flex justify-content-between">
            <p className="fw-bold"> Signature : </p>
            <p> __________________________________________________</p>
          </div>
          <div className="col-6 gy-4 d-flex justify-content-between">
            <p className="fw-bold">Signature :</p>
            <p> __________________________________________________</p>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Manifest;
