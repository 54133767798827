import React, { useEffect, useState } from "react";
import logo from "../../imgs/Logo_Ecombo 1.png";
import { Form, Button, Alert } from "react-bootstrap";
import {
  Link,
  useParams,
  useNavigate,
  NavLink,
  useLocation,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSelector from "../languages/LangSelector";
import AxiosController from "../authentication/AxiosController";
import Loader from "../loader/Loader";
import { Helmet } from "react-helmet";

const NewPassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [authErr, setAuthErr] = useState("");
  const token = localStorage.getItem("token");
  const [resetToken, setResetToken] = useState("");
  const [_] = useTranslation("global");
  const { lang } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  if (token) {
    navigate(`/ae/${lang}`);
  }
  const loginAction = async () => {
    setLoader(true);
    AxiosController.post(`api/${lang}/auth/reset-password/confirm`, {
      token: resetToken,
      password,
    })
      .then((res) => {
        navigate(`/${lang}/auth/login`);
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    setResetToken(urlParams.get("token"));
  });
  return (
    <div
      className="   inputs-data d-flex align-items-center px-5 justify-content-center flex-grow-1 position-relative overflow-hidden"
      style={{ backgroundColor: "white" }}
    ><Helmet>
    <title>  {_("panel.NewPassword")}</title> 
 </Helmet>
      <Loader active={loader} />
      <div className="data-entry-container" style={{ width: "100%" }}>
        <div className="d-flex justify-content-between flex-column flex-md-row-reverse">
          <NavLink to={`/${lang}/auth/login`}>
            <img src={logo} width="230px" alt="" />
          </NavLink>
          <LangSelector lang={lang} />
        </div>
        <div className="header d-flex justify-content-end">
          <h3 style={{ color: "rgba(243, 168, 55, 1)", fontSize: "1.6rem" }}>
            {_("registeration.welcoming")}
          </h3>
        </div>
        <Form
          className="d-flex flex-column gap-2"
          dir={lang === "ar" ? "rtl" : "ltr"}
          onSubmit={(e) => {
            e.preventDefault();
            loginAction();
          }}
        >
          <Form.Group className="d-flex flex-column gap-2">
            <Form.Label>{_("registeration.enter_password")}</Form.Label>

            <Form.Control
              type="password"
              className="p-2"
              placeholder={`${_("registeration.enter_password")}`}
              name="user_password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordErr ? <Alert variant="danger">{passwordErr}</Alert> : ""}
            {authErr ? <Alert variant="danger">{authErr}</Alert> : ""}
          </Form.Group>
          <input
            className="btn"
            style={{
              color: "white",
              backgroundColor: "rgba(89, 198, 201, 1)",
              border: "none",
            }}
            type="submit"
            value={_("registeration.changePassword")}
          />
        </Form>
      </div>
    </div>
  );
};

export default NewPassword;
