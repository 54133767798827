import React from "react";
import { useTranslation } from "react-i18next";

const TableLabels = () => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const roles_labels = {
    employee: [
      _("panel.size"),
    ],
    admin: [
      _("panel.size"),
    ],
    seller: [
      _("panel.size"),
    ],
  };

  return (
    <tr>
      {roles_labels[role].map((label) => {
        return <th>{label}</th>;
      })}
    </tr>
  );
};

export default TableLabels;
