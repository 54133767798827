import React, { useEffect, useState } from "react";
import WarehousesTableRow from "./WarehousesTableRow";
import Loader from "../../../loader/Loader";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import { useTranslation } from "react-i18next";
import { Alert, Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
const get_warehouses = (
  slug,
  lang,
  token,
  handleWarehouses,
  handleLoader,
  handleNewWarehouseErr
) => {
  AxiosController.get(`apiv2/${slug}/${lang}/sizes/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleWarehouses(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};
const createWarehouse = (
  slug,
  lang,
  token,
  newWarehouseName,
  handleLoader,
  handleWarehouses,
  setShow,
  handleNewWarehouseErr
) => {
  handleLoader(true);
  AxiosController.post(
    `apiv2/${slug}/${lang}/sizes/`,
    { name:newWarehouseName },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      get_warehouses(slug, lang, token, handleWarehouses, handleLoader);
      setShow(false);
    })
    .catch((e) => {
      handleNewWarehouseErr(
        e.response.data.errors ? e.response.data.errors.name[0] : ""
      );
      handleLoader(e.response.data.errors ? false : true);
    });
};

const Sizes = () => {
  const { slug, lang } = useParams();

  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [warehouses, handleWarehouses] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [newWarehouseName, handleNewWarehouseName] = useState("");
  const [show, setShow] = useState(false);
  const [newWarehouseErr, handleNewWarehouseErr] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  useEffect(() => {
    get_warehouses(
      slug,
      lang,
      token,
      handleWarehouses,
      handleLoader,
      handleNewWarehouseErr
    );
  }, [slug, lang]);

  return (
    <div
      className="position-relative tables-container"
      style={{ minHeight: "100px", width: "100%" }}
    > <Helmet>
    <title>{_("panel.sizes")}</title> 
 </Helmet>
      <Button
        variant="primary"
        onClick={handleShow}
        style={{ background: "#59C6C9", color: "white", border: "none" }}
      >
        {_("panel.add")}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">
            {_("panel.sizes")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
          {label_input(
            _("panel.size"),
            newWarehouseName,
            handleNewWarehouseName
          )}
          {newWarehouseErr ? (
            <Alert variant="danger">{newWarehouseErr}</Alert>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button
            variant="primary"
            onClick={() => {
              handleNewWarehouseName("");
              createWarehouse(
                slug,
                lang,
                token,
                newWarehouseName,
                handleLoader,
                handleWarehouses,
                setShow,
                handleNewWarehouseErr
              );
            }}
          >
            {_("panel.save")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Loader active={loading} />
      {/* {warehouses.map((warehouse) => {
        return <WarehouseTableRow warehouseData={warehouse} />;
      })} */}
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.sizes")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {warehouses ? (
            <WarehousesTableRow
              payments_list={warehouses}
              handleLoader={handleLoader}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
const label_input = (label, newWarehouseName, handleNewWarehouseName) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
      />
    </div>
  );
};
export default Sizes;
