import React, { useState } from "react";
import IconsGen from "../../../icons_generator/IconsGen";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dateFormat from "dateformat";
import LevelsAddAndUpdate from "../../../modals/LevelsAddAndUpdate"; 
import Form from "react-bootstrap/Form";
import AxiosController from "../../../authentication/AxiosController";

const LevelsTable = ({ data, fetchLevels }) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [show, setShow] = useState(false);
  const [showImageEdit, setShowImageEdit] = useState(false);
  const [id, setid] = useState(null);
  const [editData, setEditData] = useState(null);
  const statues = ["Active", "Inactive"];
  const [loading, handleLoader] = useState(false);

  const StatusColors = {
    Active: "rgb(28, 152, 47)",
    Inactive: "rgb(255, 0, 0)",
  };

  const change_level_status = (levelId) => {
  

    AxiosController.put(
      `api/${slug}/${lang}/level/switch-activtion-level/${levelId}`, 
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false);
        fetchLevels();
      })
      .catch((e) => {
        console.error(e);
        handleLoader(false);
      });
  };

  return (
    <div
      style={{ borderRadius: "40px" }}
      className={`table-responsive border my-3 d-flex ${lang === "en" ? "flex-row" : "flex-row-reversed"} align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th>
              <div className="mt-4">
                <input
                  className="form-check-input"
                  style={{
                    border: "1px solid rgba(243, 168, 55, 1)",
                    transform: "translate(-0%, -50%)",
                  }}
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
              </div>
            </th>
            <th>{_("panel.date")}</th>
            <th>{_("panel.image")}</th>
            <th>{_("panel.affiliatesCommission")}</th>
            <th>{_("panel.affiliatesLevel")}</th>
            <th>{_("panel.target")}</th>
            <th>{_("panel.status")}</th>
            <th>{_("panel.updateImage")}</th>
            <th>{_("panel.updateLevel")}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((ele) => (
            <tr key={ele.id}>
              <td>
                <div>
                  <input
                    className="form-check-input"
                    style={{
                      border: "1px solid rgba(243, 168, 55, 1)",
                      transform: "translate(-0%, -50%)",
                    }}
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </div>
              </td>
              <td>
                {dateFormat(ele["created_at"], "dd/mm/yyyy ")} <br />
                {dateFormat(ele["created_at"], " h:MM TT")}
              </td>
              <td>
                <img src={ele["image_url"]} width={"69px"} className="rounded" alt="Level" />
              </td>
              <td>{ele["commission"]}</td>
              <td>{ele["level"]}</td>
              <td>{ele["target"]}</td>
              <td>
                <Form.Select
                  style={{
                    border: "1px solid " + StatusColors[ele["is_active"] == "1" ? statues[0] : statues[1]],
                    color: StatusColors[ele["is_active"] == "1" ? statues[0] : statues[1]],
                  }}
                  onChange={() => {
                    handleLoader(true);
                    change_level_status(ele["id"]);
                  }}
                >
                  <option selected={true} value={ele["is_active"] == "1" ? statues[0] : ""}>
                    {ele["is_active"] == "1" ? _(`panel.${statues[0]}`) : _(`panel.${statues[1]}`)}
                  </option>
                  {statues.map((status, index) => {
                    if (status !== (ele["is_active"] == "1" ? statues[0] : statues[1])) {
                      return (
                        <option key={index} value={status}>
                          {_(`panel.${status}`)}
                        </option>
                      );
                    }
                  })}
                </Form.Select>
              </td>
              <td>
                <div
                  onClick={() => {
                    setShowImageEdit(true);
                    setid(ele["id"]);
                    setEditData(ele);
                  }}
                >
                  <IconsGen icon_name={"enter"} />
                </div>
              </td>
              <td
                onClick={() => {
                  setShow(true);
                  setid(ele["id"]);
                  setEditData(ele);
                }}
              >
                <IconsGen icon_name={"enter"} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <LevelsAddAndUpdate
        show={show}
        setShow={setShow}
        showImageEdit={showImageEdit}
        setShowImageEdit={setShowImageEdit}
        update={true}
        id={id}
        data={editData}
        fetchLevels={fetchLevels}
      />
    </div>
  );
};

export default LevelsTable;
