import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Modal, Pagination } from "react-bootstrap";
import PaymentRequestsTableRow from "./PaymentRequestsTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import * as XLSX from "xlsx";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Offers = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [id, setId] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  const handleClose = () => {
    setShow(false);
    setId(null);
  };
  const handleShow = () => setShow(true);

  const get_offers = () => {
    AxiosController.get(`apiv2/${slug}/${lang}/offers`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleLoader(false);
        setData(res.data);
        setTitle("");
        setThumbnail("");
      })
      .catch((e) => {});
  };

  const handleSubmit = async (e) => {
    handleLoader(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    if (id) {

      formData.append("id", id);
    }
    formData.append("thumbnail", thumbnail ?? null);
    AxiosController.post(
      `apiv2/${slug}/${lang}/offers/create-offer/`,
      formData,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false);
        get_offers();
        setTitle('')
        setThumbnail('');
        handleClose();
        setErrors({});
        setId(null)
      })
      .catch((e) => {
        handleLoader(false);
        setErrors(e.response.data);

      });
  };
  useEffect(() => {
    handleLoader(true);
    get_offers();
  }, [token, slug, lang, handleLoader]);
  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    >
      <Helmet>
        <title>{_("panel.offers2")}</title>
      </Helmet>
      <Loader active={loading} />

      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.offers2")}
          </h3>
          <Button
            variant="primary"
            onClick={handleShow}
            style={{ background: "#59C6C9", color: "white", border: "none" }}
          >
            {_("panel.add")}
          </Button>
        </div>
        {/* Data */}
        <div className="footer d-flex flex-column flex-grow-1">
          {data ? (
            <PaymentRequestsTableRow
              payments_list={data}
              handleLoader={handleLoader}
              get_offers={get_offers}
              setTitle={setTitle}
              setId={setId}
              setThumbnail={setThumbnail}
              handleShow={handleShow}
              
            />
          ) : (
            ""
          )}
        </div>
      </div>
      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{_("panel.offers2")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>{_("panel.OfferTitle")}</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              {errors?.title ? (
                <div class="alert alert-danger mt-1" role="alert">
                  {errors?.title[0]}
                </div>
              ) : (
                ""
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{_("panel.image")}</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setThumbnail(e.target.files[0])}
                required
              />
              {errors?.thumbnail ? (
                <div class="alert alert-danger mt-1" role="alert">
                  {errors?.thumbnail[0]}
                </div>
              ) : (
                ""
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {_("panel.close")}
          </Button>
          <Button onClick={handleSubmit}>{_("panel.save")}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Offers;
