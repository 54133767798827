import { memo, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import GroupList from "../grouplist/GroupList";

function ViewModal({
  showModal,
  setShowViewModal,
  modalHeader,
  modalBody, 
  errorLinkMessages,
  successLinkMessages,
  setUnupdatedOrders,
  data,
}) { 
  const [show, setShow] = useState(showModal);
  const [_] = useTranslation("global"); 
  const handleClose = () => {
    setUnupdatedOrders([])
    setShowViewModal(false);
    setShow(false);
  }; 
  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{_(`panel.${modalHeader}`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
         <div className="mt-4 ">{data  && 
           data.map((msg, index) => (
              <p key={index}>{msg} </p>
            ))    
          }   
          </div>  
          {/* <GroupList list={data} /> */}
          
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "red" }} onClick={handleClose}>
            {_(`panel.close`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default memo(ViewModal);
