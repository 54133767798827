import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen";
  
const Orders = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const ecombo_affiliate = localStorage.getItem("ecombo_affiliate");
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/panel/sellings/")) {
      setIsSubNavVisible(true);
    } else {
      setIsSubNavVisible(false);
    }
  }, [location.pathname]);
  const handleClick = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };
  return (
    <div className="links-container d-flex flex-column">
            
      <div className="d-flex flex-column">
        <div
          className={`feature_link ${
            lang === "en" ? "feature_link_en" : ""
          } d-flex flex-row-reverse align-items-center gap-2 py-3 
           ${isSubNavVisible ? "active-link" : ""}`}
          dir={direction} 
          onClick={handleClick}
        >
          <IconsGen icon_name={"orders"} />
          <p>{_("panel.sellings")}</p>
        </div>
        {isSubNavVisible && (
          <ul
            className={`${
              isSubNavVisible ? "d-flex" : "d-none"
            } sub-nav  flex-column gap-2 px-5 pt-3`}
          >
            <li>
              <NavLink to={`/${slug}/${lang}/panel/sellings/orders`}>
                <p className="d-inline ">{_("panel.orders")}</p>
              </NavLink>
            </li>
            {role == "admin" || employee_type == "operation employee" ? (
              <li>
                <NavLink to={`/${slug}/${lang}/panel/sellings/shipping-excel`}>
                  <p className="d-inline ">{_("panel.shippingSheet")}</p>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {role == "admin" || employee_type == "operation employee" ? (
              <>            <li>
                <NavLink to={`/${slug}/${lang}/panel/sellings/cancels`}>
                  <p className="d-inline ">{_("panel.Cancels")}</p>
                </NavLink>
              </li>
               <li>
               <NavLink to={`/${slug}/${lang}/panel/sellings/download-manifest`}>
                 <p className="d-inline ">{_("panel.downloadManifest")}</p>
               </NavLink>
             </li>
              </>
  
            ) : (
              ""
            )}
            {role == "admin" ||
            role == "seller" ||
            employee_type == "operation employee" ? (
              <li>
                <NavLink to={`/${slug}/${lang}/panel/sellings/pickup-report`}>
                  <p className="d-inline ">{_("panel.pickupReport")}</p>
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {role == "admin" ||
            employee_type == "operation employee" ||
            employee_type == "confirmation employee" ? (<>     
             <li>
                <NavLink
                  to={`/${slug}/${lang}/panel/sellings/postponed-orders?postponed=1`}
                >
                  <p className="d-inline ">{_("panel.posponedOrders")}</p>
                </NavLink>
              </li>
               <li>
               <NavLink
                 to={`/${slug}/${lang}/panel/sellings/about-shipping`}
               >
                 <p className="d-inline ">{_("panel.aboutShipping")}</p>
               </NavLink>
             </li></>
        
            ) : (
              ""
            )}
            {role === "affiliate" && (
              <li>
                <NavLink to={`/${slug}/${lang}/panel/sellings/orders-bulk`}>
                  <p className="d-inline ">{_("panel.ordersBulk")}</p>
                </NavLink>
              </li>
            )}

            {role === "fulfillment" && (
              <li>
                <NavLink
                  to={`/${slug}/${lang}/panel/sellings/orders-bulk-fulfillment`}
                >
                  <p className="d-inline ">{_("panel.ordersBulk")}</p>
                </NavLink>
              </li>
            )}
            {role === "fulfillment" || role === 'admin' ? (
              <li>
                <NavLink
                  to={`/${slug}/${lang}/panel/sellings/orders-settlements`}
                >
                  <p className="d-inline ">{_("panel.ordersSettlements")}</p>
                </NavLink>
              </li>
            ) : ''}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Orders;
