import React, { useEffect, useState } from "react";
import StatusGenerator from "../../../globals/StatusGenerator";
import StatusColors from "../../../globals/StatusColors";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";

const statuesLabels = {
  Canceled: [
    "Affiliate asked for cancelation",
    "Canceled by customer",
    "Canceled by affiliate",
    "Duplicate",
    "Invalid Number",
    "Canceled after 3 attempts",
    "Canceled by followup",
    "Customer evasion",
  ],
  'Not Confirmed': [
    "Affiliate asked for cancelation",
    "Canceled by customer",
    "Canceled by affiliate",
    "Duplicate",
    "Invalid Number",
    "Canceled after 3 attempts",
    "Canceled by followup",
    "Customer evasion",
  ],
  'Pending Cancel' : [
    "Customer evasion",
    "Customer refused to receive"
  ],
  "Call Attempt": ["Call attempt 1", "Call attempt 2", "Call attempt 3"],
  Waiting: ["Delivery Attempt 1", "Delayed By Customer"],
};
const change_order_status = (
  slug,
  lang,
  token,
  orderId,
  status,
  currentStatusHandler,
  handleLoader,
  handleStatusChangeErr
) => {
  AxiosController.put(
    `api/${slug}/${lang}/orders/change-label/${orderId}`,
    { label: status },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      currentStatusHandler(status);
      handleLoader(false);
    })
    .catch((e) => {
      handleStatusChangeErr(true);
      handleLoader(false);
    });
};
const TableSelectLabels = ({
  currentStatus,
  orderId,
  handleLoader,
  currentLabel,
}) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");

  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  const [orderStatus, handleOrderStatus] = useState("");
  const [statusChangeErr, handleStatusChangeErr] = useState(false);

  const [_] = useTranslation("global");
  const statues = StatusGenerator(role, employee_type);

  useEffect(() => {
    handleOrderStatus(currentStatus);
    handleStatusChangeErr(false);
  }, [currentStatus]);
  return (
    <div className="w-100">
      <Form.Select
        style={{
          border: "2px solid " + "rgb(255, 0, 0)",
          color: "rgb(255, 0, 0)",
          width: "fit-content",
        }}
        onChange={(e) => {
          handleLoader(true);
          change_order_status(
            slug,
            lang,
            token,
            orderId,
            e.target.value,
            handleOrderStatus,
            handleLoader,
            handleStatusChangeErr
          );
        }}
        className="w-100"
      >
        <option selected={true} value={currentStatus}>
          {_(`panel.${currentLabel ?? "notexists"}`)}
        </option>
        {statuesLabels[currentStatus]
          ? statuesLabels[currentStatus].map((status) => {
              if (status !== currentStatus) {
                return (
                  <option selected={false} value={status}>
                    {_(`panel.${status}`)}
                  </option>
                );
              }
            })
          : ""}
      </Form.Select>
      {statusChangeErr ? <p>{_("panel.statusChangeErr")}</p> : ""}
    </div>
  );
};

export default TableSelectLabels;
