import React, { useContext, useEffect, useRef, useState } from "react";
import "./Profile.css";
import AxiosController from "../../authentication/AxiosController";
import { useParams } from "react-router-dom";
import IconsGen from "../../icons_generator/IconsGen";
import Loader from "../../loader/Loader";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { notification } from "antd";
import ChangePasswordModel from "../../modals/ChangePasswordModel";
import { UserContext } from "../../contexts/UserContext";
import { Helmet } from "react-helmet";

const Profile = () => {
  const { slug, lang } = useParams();

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [userData, handleUserData] = useState({});
  const [refData, handleRefData] = useState({});
  const [userFullname, handleUserFullname] = useState("");
  const [userEmail, handleUserEmail] = useState("");
  const [userNumber, handleUserNumber] = useState("");
  const [userAge, handleUserAge] = useState("");
  const [userCountry, handleUserCountry] = useState("");
  const [userImg, handleUserImg] = useState("");
  const [userRole, handleUserRole] = useState("");
  const [loader, handleLoader] = useState(false);
  const [countries, setCountries] = useState([]);
  const [countriesErr, setCountriesErr] = useState();
  const [selectedCountry, setSelectedCountry] = useState({});
  const { userData2 } = useContext(UserContext);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, status) => {
    api.success({
      style: { direction: lang == "ar" ? "rtl" : "ltr" },
      description: `${status}!`,
      placement,
    });
  };

  const inputRef = useRef(null);

  const change_info = () => {
    AxiosController.put(
      `api/${slug}/${lang}/user/update`,
      {
        first_name: userFullname.split(" ")[0],

        last_name: userFullname.split(" ").slice(1).join(" "),

        phone_number: userNumber,
        email: userEmail,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then(() => {
        getUserData();
      })
      .catch(() => {});
  };

  const [_] = useTranslation("global");
  const copyToClipboard = () => {
    inputRef.current.select();
    document.execCommand("copy");
    openNotification(lang == "ar" ? "topRight" : "topLeft", _("panel.copied"));
  };
  const getUserData = () => {
    handleLoader(true);
    AxiosController.get(`api/${lang}/auth/userdata`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleUserData(res.data);
        handleUserFullname(
          res.data["first_name"] + " " + res.data["last_name"]
        );
        handleUserEmail(res.data["email"]);
        handleUserNumber(res.data["phone_number"]);
        handleUserImg(res.data["photo"]);
        handleLoader(false);
        handleUserRole(res.data["role"]);
      })
      .catch(() => {});

    AxiosController.get(`api/${slug}/${lang}/user/ref-data`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleRefData(res.data);
      })
      .catch(() => {});
  };
  useEffect(() => {
    getUserData();
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, [slug, lang, token]);
  const [show, setShow] = useState(false);
  return (
    <div
      className="profile-container d-flex flex-column px-4"
      style={{ position: "relative", flexGrow: 3 }}
    >
      <Helmet>
        <title>{_("panel.myprofile")}</title>
      </Helmet>
      <ChangePasswordModel show={show} setShow={setShow} />
      {contextHolder}
      <Loader active={loader} />
      <div
        className="ticket py-2 px-2"
        style={{ position: "absolute", left: "5%", zIndex: "9" }}
      >
        <p className="pt-1">{_("panel." + userRole)}</p>
      </div>

      <div className="header py-4">
        <div
          className={`upper d-flex flex-column  flex-lg-row ${
            lang === "en" ? "flex-row-reverse" : "flex-row"
          } gap-3`}
        >
          <div className="img-section col-10 col-lg-6 align-self-center">
            <img
              src={userImg}
              style={{
                maxWidth: "100%",
                width: "90%",
                borderRadius: " 7px 7px 0px 0px",
              }}
              alt=""
            />{" "}
            {role === "affiliate" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgba(243, 168, 55, 0.8)",
                  height: "40px",
                  maxWidth: "100%",
                  width: "90%",
                  color: "white",
                  borderRadius: "0px 0px 30px 30px",
                }}
              >
                {" "}
                {userData?.level}
              </div>
            )}
            {userData?.level_data?.image_url ? (
              <div className="img-container" style={{ position: "relative" }}>
                <div
                  className="upload p-2"
                  style={{
                    position: "absolute",
                    backgroundColor: "#2c3e50",
                    borderRadius: "50%",
                    left: "-2%",
                    bottom: "-5%",
                  }}
                >
                  <img src={userData?.level_data?.image_url} width={"50px"} />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="user-info col-12 col-lg-6 align-self-center">
            <div className="info-group d-flex  flex-column">
              <div className="info-group d-flex gap-2">
                <span className="user-key">
                  <p>{_("panel.name")} : </p>
                </span>
                <span className="user-value">
                  <p>{userFullname}</p>
                </span>
              </div>
              <div className="info-group d-flex gap-2">
                <span className="user-key">
                  <p>{_("panel.accountnumber")} : </p>
                </span>
                <span className="user-value">
                  <p>{userData["id"]}</p>
                </span>
              </div>
            </div>
            <div className="info-group d-flex gap-2">
              <span className="user-key">
                <p>{_("panel.number")} :</p>
              </span>
              <span className="user-value">
                <p> {userNumber}</p>
              </span>
            </div>
            <div className="info-group d-flex gap-2">
              <span className="user-key">
                <p>{_("panel.email")} :</p>
              </span>
              <span className="user-value">
                <p>{userEmail}</p>
              </span>
            </div>
            <div className="info-group d-flex gap-2 my-3">
              <span className="user-key align-self-center">
                <p>{_("panel.referal")}:</p>
              </span>
              <span
                className="user-value d-felx"
                style={{ position: "relative" }}
              >
                <img
                  src="./icons/people.svg"
                  width="20px"
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "13px",
                    transform: "translate(0, -50%)",
                  }}
                  alt=""
                />
                <button className="btn mx-2" onClick={copyToClipboard}>
                  رابط الاحالة
                </button>
                <input
                  type="text"
                  className="form-control px-5"
                  style={{ width: "100%", height: "100%" }}
                  ref={inputRef}
                  id="exampleInputEmail1"
                  placeholder="الحسابات المشار إليها"
                  aria-describedby="emailHelp"
                  value={`https://ecombo.co/${lang}/auth/register/marketer?userId=${userData["id"]}`}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="lower row gap-2 mt-4 px-3">
          <div
            className="box d-flex orangify flex-column col-4 col-lg-2 py-3"
            style={{ textAlign: "center" }}
          >
            <div className="number">
              <p>
                {refData.total_accounts
                  ? refData.total_accounts.total_accounts
                  : 0}
              </p>
            </div>
            <h2>{_("panel.totalaccounts")}</h2>
          </div>
          <div
            className="box d-flex orangify flex-column col-4 col-lg-2 py-3"
            style={{ textAlign: "center" }}
          >
            <div className="number">
              <p>
                {refData.total_orders ? refData.total_orders.orders_number : 0}
              </p>
            </div>
            <h2>{_("panel.totalorders")}</h2>
          </div>
          <div
            className="box d-flex flex-column col-4 col-lg-2 py-3"
            style={{ textAlign: "center" }}
          >
            <div className="number">
              <p>
                {refData.total_orders_delivered
                  ? refData.total_orders_delivered.orders_number
                  : 0}
              </p>
            </div>
            <h2>{_("panel.deliveredorder")}</h2>
          </div>
          <div
            className="box d-flex flex-column col-4 col-lg-2 py-3"
            style={{ textAlign: "center" }}
          >
            <div className="number">
              <p>
                {refData.total_orders_delivered
                  ? refData.total_orders_delivered.total_earnings?.toFixed(2) ||
                    0
                  : 0}
              </p>
            </div>
            <h2>{_("panel.profits")}</h2>
          </div>
        </div>
      </div>

      <button className="btn my-3" onClick={() => setShow(true)}>
        {_("panel.changepassword")}
      </button>
      <hr />
      <div className="footer">
        <div className="header px-3">
          <h2>معلومات الحساب</h2>
        </div>
        <div
          className="inputs-container"
          dir={`${lang === "en" ? "ltr" : "rtl"}`}
          style={{ textAlign: lang === "en" ? "left" : "right" }}
        >
          <div className="inputs-group d-flex flex-column flex-lg-row  gap-5 px-3 my-4">
            <div className="d-flex flex-column">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.name")}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                value={userFullname}
                aria-describedby="emailHelp"
                onChange={(e) => handleUserFullname(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.email")}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                value={userEmail}
                aria-describedby="emailHelp"
                onChange={(e) => handleUserEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="inputs-group d-flex flex-column flex-lg-row  gap-5 px-3 my-4">
            <div className="d-flex flex-column">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.number")}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                value={userNumber}
                aria-describedby="emailHelp"
                onChange={(e) => handleUserNumber(e.target.value)}
              />
            </div>
            <Form.Group className="d-flex flex-column">
              <Form.Label>{_("registeration.country")}</Form.Label>
              <Select
                options={countries}
                value={selectedCountry}
                onChange={(selectedOption) =>
                  setSelectedCountry(selectedOption)
                }
                name="country"
                className="h-100 flex-grow-1"
              />
            </Form.Group>
          </div>
          <div
            className={`inputs-group d-flex flex-column ${
              lang === "en" ? "flex-md-row" : "flex-md-row"
            } gap-5 px-3 my-4`}
          >
            <button
              className="btn flex-grow-1"
              style={{ color: "white" }}
              onClick={() => {
                change_info();
              }}
            >
              {_("panel.save")}
            </button>
            {/* <button className="btn flex-grow-1">{_("panel.cancel")}</button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
