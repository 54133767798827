import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../authentication/AxiosController";
import Loader from "../loader/Loader";
import { Form, Button,  Modal } from "react-bootstrap";
import Select from "react-select";
import { notifyError ,notifySuccess} from "../toastify/toastify";
import { useFormik } from "formik";
import { createEmployee } from "../../utils/validation";


const EmployeeAdd = ({ show, setShow , fetchPaymentRequests  }) => {

    const { t } = useTranslation("global");
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const { slug, lang } = useParams();
  
    useEffect(() => {
      fetch("https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code")
        .then((response) => response.json())
        .then((data) => {
          setCountries(data.countries);
        });
    }, []);
  
    const formik = useFormik({
      initialValues: {
        first_name: "",
        last_name: "",
        email: "",
        birthdate: "",
        sex: "",
        country: "",  
        country_ISO: "",
        employee_type: "",
        password: "",
        password_confirmation: "",
      },
      validationSchema: createEmployee,
      onSubmit: (values) => {
        setLoading(true);
  
        AxiosController.post(
          `api/${slug}/${lang}/employee/register`,
          values,   { headers: { Authorization: `Bearer ${token}` }, } )
          .then((res) => {
            notifySuccess(t("panel.successMsg"));
            formik.resetForm();
            handleClose()
            setLoading(false);
            fetchPaymentRequests()
          })
          .catch((e) => {
            setLoading(false);
  
            if (e.response && e.response.data && typeof e.response.data.error === "object") {
              Object.entries(e.response.data.error).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                  value.forEach((err) => {
                    notifyError(`${key}: ${err}`);
                  });
                } else {
                  notifyError(`${key}: ${value}`);
                }
              });
            }
          });
      },
    });

    const handleClose = () => {
      formik.resetForm(); // Reset the form
      setShow(false); // Close the modal
    };
    

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="flex-grow-1">
                {t("panel.addEmployee")}

        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
      <Loader active={loading} />
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div className="add-header" dir={lang === "en" ? "ltr" : "rtl"}>
        
        </div>
<div   dir={lang === "en" ? "ltr" : "rtl"}>    <form  onSubmit={formik.handleSubmit} >
          {labelInput(t("panel.first_name"), "first_name", formik)}
          {labelInput(t("panel.last_name"), "last_name", formik)}
          {labelInput(t("panel.email"), "email", formik)}

          <div className="mb-3">
            <label htmlFor="birthdate" className="form-label">
              {t("panel.birth")}
            </label>
            <input
              type="date"
              id="birthdate"
              name="birthdate"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.birthdate}
              className="form-control col-4"
            />
            {formik.touched.birthdate && formik.errors.birthdate ? (
              <div className="text-danger">{formik.errors.birthdate}</div>
            ) : null}
          </div>

          <Form.Group className="d-flex gap-5">
            <Form.Check
              type="radio"
              label={t("registeration.male")}
              name="sex"
              value="male"
              checked={formik.values.sex === "male"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Check
              type="radio"
              label={t("registeration.female")}
              name="sex"
              value="female"
              checked={formik.values.sex === "female"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>

          <div className="py-2">
            <select
              className="form-control"
              id="employeeType"
              name="employee_type"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.employee_type}
            >
              <option value="" disabled>
                {t("panel.employee")}
              </option>
              <option value="financials employee">
                {t("panel.financials employee")}
              </option>
              <option value="confirmation employee">
                {t("panel.confirmation employee")}
              </option>
              <option value="operation employee">
                {t("panel.operation employee")}
              </option>
              <option value="warehouse employee">
                {t("panel.warehouse employee")}
              </option>
              <option value="products employee">
                {t("panel.products employee")}
              </option>
            </select>
            {formik.touched.employee_type && formik.errors.employee_type ? (
              <div className="text-danger">{formik.errors.employee_type}</div>
            ) : null}
          </div>

          <Form.Group className="d-flex flex-column">
  <Form.Label>{t("registeration.country")}</Form.Label>
  <Select
    options={countries}
    value={countries.find(
      (country) => country.value === formik.values.country_ISO
    )}
    onChange={(selectedOption) => {
      formik.setFieldValue("country", selectedOption.label);
      formik.setFieldValue("country_ISO", selectedOption.value);
    }}
    onBlur={formik.handleBlur}
    name="country_ISO"
  />
  {formik.touched.country_ISO && formik.errors.country_ISO ? (
    <div className="text-danger">{formik.errors.country_ISO}</div>
  ) : null}
</Form.Group>


          {labelInput(
            t("registeration.enter_password"),
            "password",
            formik,
            "password"
          )}
          {labelInput(
            t("panel.confirmPassword"),
            "password_confirmation",
            formik,
            "password"
          )}

          
        </form></div>
    
      </div> 
      </Modal.Body>
      <Modal.Footer className="border-0">
  <Button 
    variant="primary"      type="submit"       style={{ border:"none",  backgroundColor: "rgba(89, 198, 201, 1)" }}
       disabled={!formik.isValid || !formik.dirty || loading}
    onClick={formik.handleSubmit}  
  >  {t("panel.create")}
  </Button>
  <Button variant="danger" onClick={handleClose}>
    {t("panel.cancel")}
  </Button>
</Modal.Footer>

    </Modal>
  );
};
const labelInput = (label, name, formik, type = "text") => (
  <div className="mb-3">
    <input
      placeholder={label}
      type={type}
      className="form-control py-2 mt-4 mb-4"
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    />
    {formik.touched[name] && formik.errors[name] ? (  
      <div className="text-danger">{formik.errors[name]}</div>  ) : null}
  </div>
);

export default EmployeeAdd;