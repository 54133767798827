import React from "react";
import { Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Transaction from "./Transaction";
import Invoice from "./Invoice";
const TransactionTabs = () => {

  const [_] = useTranslation("global");

  return (
    <Tabs defaultValue="gallery" className="w-100">
      <Tabs.List grow>
        <Tabs.Tab value="gallery" className="fs-5" color="rgb(89, 198, 201)">
          {_("panel.payment_request")}
        </Tabs.Tab>
        <Tabs.Tab value="messages" className="fs-5" color="rgb(89, 198, 201)">
          {_("panel.invoice")}
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="gallery">
        <Transaction />
      </Tabs.Panel>

      <Tabs.Panel value="messages">
        <Invoice />
      </Tabs.Panel>
    </Tabs>
  );
};

export default TransactionTabs;

