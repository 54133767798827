import StatusColors  from '../../components/globals/StatusColors';
export default class PieChartTemplate {
    constructor(data, column, resolve = true) {
      let resolvedColors = data.map((value) => StatusColors(value['status']));
      this.titles = data.map((value) => value[column]);
      this.datasets = [{
        data: data.map((value) => value['stats']),
        backgroundColor:  resolve ? resolvedColors : [
          "rgb(89, 198, 201)",
          "rgb(243, 168, 55)",
          "rgb(113, 1, 255)",
      ],
        borderWidth: 0
      }];
    }

  }