import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, Button, Form, Modal, Pagination } from "react-bootstrap";
import SuppliersTableRow from "./SuppliersTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import { notifyError } from "../../../toastify/toastify";
import { Helmet } from "react-helmet";
const get_warehouses = (
  slug,
  lang,
  token,
  handleWarehouses,
  courierId,
  page,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/accounts/`, {
    headers: { Authorization:` Bearer ${token}` },
  })
    .then((res) => {
      handleWarehouses(res.data); 
      handleLoader(false);
    })
    .catch((e) => {});
};

const get_shippingcosts = (
  slug,
  lang,
  token,
  shippingcostsHandler,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/get-shippingcost/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      shippingcostsHandler(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const createWarehouse = (
  slug,
  lang,
  token,
  courierId,
  govId,
  cost, 
  affiliatesBalance,
  sellersBalance,
  systemBalance,
  fulfilllment,
  extra_handling,
  handleLoader,
  setShow,
  handleSuppliers
) => {
  handleLoader(true);
  AxiosController.post(
    `api/${slug}/${lang}/accounts/create`,
    { name: cost, affiliates_balance : affiliatesBalance , sellers_balance:sellersBalance,system_balance:systemBalance , fulfillment_balance : fulfilllment },
    {
      headers: { Authorization:` Bearer ${token}` },
    }
  )
    .then((res) => {
      get_warehouses(
        slug,
        lang,
        token,
        handleSuppliers,
        courierId,
        1,
        handleLoader
      );
      setShow(false);
    })
    .catch((e) => {
      if (e.response && e.response.data && typeof e.response.data.error === 'object') { 
        Object.entries(e.response.data.error).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((err) => {
              notifyError(`${key}: ${err}`);
            });
          } else {
            notifyError(`${key}: ${value}`);
          }
        });
        
      }       // handleNewCourierNameErr(
      //   e.response.data.errors ? e.response.data.errors.name[0] : ""
      // );
      // handleCourierTypeErr(
      //   e.response.data.errors ? e.response.data.errors.type[0] : ""
      // );
      handleLoader(false);
    });
};

const Balances = () => {
  const { slug, lang, courierId } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [suppliers, handleSuppliers] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [cost, setCost] = useState("");
  const [affiliatesBalance, setAffiliatesBalance] = useState(0);
  const [sellersBalance, setSellersBalance] = useState(0);
  const [systemBalance, setSystemBalance] = useState(0); 
  const [fulfilllment, setFulfilllment] = useState(0); 
  const [extraHandling, setExtraHandling] = useState(0);
  const [show, setShow] = useState(false);
  const [shippingCosts, setShippingCosts] = useState([]);
  const [govId, setGovId] = useState();
  const [page, handlePage] = useState(urlParams.get("page") || 1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };
  useEffect(() => {
    handleLoader(true);
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    handlePage(nextPage);
    get_shippingcosts(slug, lang, token, setShippingCosts, handleLoader);
    get_warehouses(
      slug,
      lang,
      token,
      handleSuppliers,
      courierId,
      nextPage,
      handleLoader
    );
  }, [slug, lang, location.search]); 
  return (
    <div
      className="position-relative tables-container"
      style={{ minHeight: "100px", width: "100%" }}
    ><Helmet>
    <title>{_("panel.balances")}</title> 
 </Helmet>
      <Button
        variant="primary"
        onClick={() => {
          setCost("");
          setAffiliatesBalance(0);
          setSellersBalance(0);
          setSystemBalance(0);
          setFulfilllment(0)
          setExtraHandling(0);
          setGovId(0);
          handleShow(true);
        }}
        style={{ background: "#59C6C9", color: "white", border: "none" }}
      >
        {_("panel.add")}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">{_("panel.add")}</Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
          {label_input(_("panel.thebank"), cost, setCost)} 
          {label_input(_("panel.affiliatesBalance"), affiliatesBalance, setAffiliatesBalance)}
      {label_input(_("panel.sellersBalance"), sellersBalance, setSellersBalance)}
      {label_input(_("panel.systemBalance"), systemBalance, setSystemBalance)}
      {label_input(_("panel.fulfillmentBalance"), fulfilllment, setFulfilllment)}
          {/* {newCourierNameErr ? (
            <Alert variant="danger">{newCourierNameErr}</Alert>
          ) : (
            ""
          )} */}
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button
            variant="primary"
            onClick={() => {
              createWarehouse(
                slug,
                lang,
                token,
                courierId,
                govId,
                cost, 
                affiliatesBalance,
                sellersBalance,
                systemBalance,
                fulfilllment,
                extraHandling,
                handleLoader,
                setShow,
                handleSuppliers
              );
            }}
          >
            {_("panel.save")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.balances")}{" "}
            <span style={{ color: "rgb(243, 168, 55)" }}>
              {suppliers.data
                ? suppliers.data.length
                  ? suppliers.data[0].name
                  : ""
                : ""}
            </span>
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {suppliers ? <SuppliersTableRow payments_list={suppliers} /> : ""}
        </div>
      </div>
    </div>
  );
};
const label_input = (label, newWarehouseName, handleNewWarehouseName) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
      />
    </div>
  );
};
export default Balances;