import React, {   useState } from "react";
import { useTranslation } from "react-i18next";
import IconsGen from "../../../icons_generator/IconsGen";
import AxiosController from "../../../authentication/AxiosController";
import {   useParams } from "react-router-dom"; 
import TableLabels from "./TableLabels"; 
import "primereact/resources/themes/lara-light-cyan/theme.css"; 
import ConfirmModal from "../../../modals/ConfirmModal";
import { notifyError, notifySuccess } from "../../../toastify/toastify";

const SuppliersTableRow = ({ payments_list, alt_name, handleLoader ,get_warehouses }) => {
 
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token"); 
  const [exclusiveIdToDelete, setExclusiveIdToDelete] = useState(null); 
  const [showConfirmModal, setShowConfirmModal] = useState(false);
 

  const handleCloseModal = () => {
    setShowConfirmModal(false);
    setExclusiveIdToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (!exclusiveIdToDelete) return;

    AxiosController.delete(
      `api/${slug}/${lang}/exclusive/delete-exclusive/${exclusiveIdToDelete}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        notifySuccess(_(  "panel.delete_successful"));
        get_warehouses()
        handleCloseModal();
      })
      .catch((e) => {
        notifyError('Delete failed ');
        handleCloseModal();
      });
  };

  const handleShowModal = (exclusive_id) => {
    setShowConfirmModal(true);
    setExclusiveIdToDelete(exclusive_id)
  };
 
 
 
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
 <ConfirmModal
            showModal={showConfirmModal}
            setShowModal={setShowConfirmModal}
            action={handleConfirmDelete}
            modalBody={`deleteSure`}
            modalHeader="deleteProduct"
            confirmButton="confirm"
          /> 
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");
            return (
              <tr className="">
                <td key={order.id}>
                  <div
                    className="form-check mt-3 d-flex justify-content-center align-items-center"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </td>
                <td scope="row" className="align-middle">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td className="align-middle">
                  {order["first_name"]} {order["last_name"]}<br />#{order["id"]}
                </td>
                <td className="align-middle">
                  {order["title"]} <br /> <img width={'70px'} className="rounded-3" src={order["thumbnail"]}/><br />#{order["p_id"]}
                </td>
                <td
                  className="align-middle px-3"
                  onClick={() => handleShowModal(order["exclusive_id"])}
                >
                  {<IconsGen icon_name={"delete"} />}
                </td>
              </tr>
            );
          })}
          
        </tbody>
      </table>
    </div>
  );
};

export default SuppliersTableRow;
