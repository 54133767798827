import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function ConfirmModal({
  showModal,
  setShowModal,
  action,
  modalHeader,
  modalBody,
  confirmButton,
}) { 
  const [_] = useTranslation("global");
  const handleClose = () => {
    setShowModal(false); 
  };
  const handleAction = () => {
    action();
    handleClose();
  }; 
  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{_(`panel.${modalHeader}`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{_(`panel.${modalBody}`)}</Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "red" }} onClick={handleClose}>
            {_(`panel.close`)}
          </Button>
          <Button variant="primary" onClick={handleAction}>
            {_(`panel.${confirmButton}`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmModal;
