import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import "./Cart.css";

import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CartItem from "./CartItem";
import CartStat from "./CartStat";
import AxiosController from "../../authentication/AxiosController";
import SearchComponent from "./SearchComponent";
import Loader from "../../loader/Loader";

const add_ticket = (
  slug,
  lang,
  token,
  order_id,
  newTicket,
  ticketAdd,
  handleSuccess,
  handleLoader
) => {
  handleLoader(true);
  AxiosController.post(
    `api/${slug}/${lang}/add-ticket-order/${order_id}`,
    { ticket: newTicket },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      handleSuccess(ticketAdd);
      handleLoader(false);
    })
    .catch((e) => {});
};

const Cart = ({ orderCart, alt_name, order_id, shippingCost, canEdit , status }) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [cart, handleCart] = useState([]);
  const [_] = useTranslation("global");
  const [isAddproduct, handleIsAddProduct] = useState(false);
  const [loading, handleLoader] = useState(false);
  const role = localStorage.getItem("role");

  useEffect(() => {
    handleCart(orderCart ? orderCart : []);
  }, [orderCart]);
  const applyCartChanges = () => {
    handleLoader(true);
    AxiosController.put(
      `api/${slug}/${lang}/update-order-details/${order_id}`,
      cart,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false); 
      })
      .catch((e) => {});
  };
  
  return (
    <Container
      className="add-product-container my-3 p-0"
      style={{ position: "relative" }}
    >
      <Loader active={loading} />
      <div className="add-product-container d-flex flex-column gap-2 my-3">
        <div
          className={`d-flex gap-2 flex-column-reverse ${
            lang === "en" ? "flex-lg-row-reverse" : "flex-lg-row"
          }`}
        >
          <div
            className="order-right-container d-flex flex-column gap-3"
            style={{ flexGrow: 3 }}
          >
            {cart.length
              ? cart.map((item) => {
                  return (
                    <CartItem
                      quantity={item.quantity  }
                      originalPrice={item.total_base_commissions }
                      marketerPrice={item.total_commissions}
                      sellerPrice={item.seller_commission}
                      sysPrice={item.sys_commission}
                      altName={alt_name}
                      thumbnail={item.thumbnail}
                      cartId={item.id}
                      productId={item.p_id  }
                      productTitle={item.p_title }
                      sku={item.sku}
                      size={item.size}
                      color={item.color}
                      orderId={item.order_id}
                      cart={cart}
                      handleCart={handleCart}
                      status={item.status}
                      canEdit={canEdit}
                    />
                  );
                })
              : ""}

            {status && status == "Pending"   &&
                <div className="d-flex flex-column flex-md-row gap-3">
                  <Button
                    className="fs-5 px-5 flex-grow-1"
                    style={{
                      backgroundColor: "rgb(89, 198, 201)",
                      border: "none",
                      borderRadius: "7px",
                    }}
                    onClick={() => {
                      applyCartChanges();
                    }}
                  >
                    {_("panel.save")}
                  </Button>
                  <Button
                    className="fs-5 px-5 flex-grow-1"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "rgb(89, 198, 201)",
                      border: "1px solid rgb(89, 198, 201)",
                      borderRadius: "7px",
                    }}
                    onClick={() => {
                      handleIsAddProduct(!isAddproduct);
                    }}
                  >
                    {_("panel.addProduct")}
                  </Button>
                </div> 
                }
            {isAddproduct ? (
              <SearchComponent cart={cart} handleCart={handleCart} />
            ) : (
              ""
            )}
          </div>
 
          <div
            className="h-100  d-flex flex-column gap-2"
            style={{ flexGrow: "10" }}
            // style={{}}
          >
       
              <CartStat cart={cart} shippingCost={shippingCost} />
            
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Cart;
