import React from "react";
import PreviewStats from "../preview_stats/PreviewStats";

const ControlPanelCallCenter = () => {
  return (
    <div>
      <div className="stats-container d-flex flex-column gap-2">
        <PreviewStats />
      </div>
    </div>
  );
};

export default ControlPanelCallCenter;
