import React from "react";
import { useTranslation } from "react-i18next";

const TableLabels = () => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const roles_labels = {
    employee: [
      _("panel.OfferTitle"),
      _("panel.image"),
      _("panel.status"),

    ],
    admin: [
      _("panel.OfferTitle"),
      _("panel.image"),
      _("panel.image"),
      _("panel.totalorders"),
      _("panel.deliveredorder"),

    ],
    affiliate: [
      _("panel.OfferTitle"),
      _("panel.image"),
      _("panel.status"),
    ],
  };

  return (
    <tr>
      {roles_labels[role].map((label, index) => {
        return <th>{label}</th>;
      })}
    </tr>
  );
};

export default TableLabels;
