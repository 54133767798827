import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";

const change_order_status = (
  slug,
  lang,
  token,
  orderId,
  status,
  currentStatusHandler,
  handleLoader,
  handleStatusChangeErr
) => {
  AxiosController.put(
    `api/${slug}/${lang}/change-request-status/${orderId}`,
    { status },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      currentStatusHandler(status);
      handleLoader(false);
    })
    .catch((e) => {
      handleStatusChangeErr(true);
      handleLoader(false);
    });
};

const TableSelectStatus = ({ currentStatus, orderId, handleLoader }) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");

  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  const [orderStatus, handleOrderStatus] = useState("");
  const [statusChangeErr, handleStatusChangeErr] = useState(false);

  const [_] = useTranslation("global");
  const statues = ["Approved", "Rejected"];
  const StatusColors = {
    Approved: "#74D990",
    Rejected: "#FC8383",
    Pending: "#C2C2C2",
  };
  const textColors = {
    Approved: "#74D990",
    Rejected: "#FC8383",
    Pending: "#8F8F8F",
  };

  useEffect(() => {
    handleOrderStatus(currentStatus);
    handleStatusChangeErr(false);
  }, [currentStatus]);
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Form.Select
        style={{
          border: "2.5px solid " + StatusColors[orderStatus],
          backgroundColor: StatusColors[orderStatus],
          color: StatusColors[textColors],
          fontWeight: "bold",
          width: "fit-content",
        }}
        onChange={(e) => {
          handleLoader(true);
          change_order_status(
            slug,
            lang,
            token,
            orderId,
            e.target.value,
            handleOrderStatus,
            handleLoader,
            handleStatusChangeErr
          );
        }}
      >
        <option selected={true} value={currentStatus}>
          {_(`panel.${orderStatus}`)}
        </option>
      </Form.Select>
      {statusChangeErr ? <p>{_("panel.statusChangeErr")}</p> : ""}
    </div>
  );
};

export default TableSelectStatus;
