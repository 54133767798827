import React from 'react'
import { useTranslation } from 'react-i18next'
import network from '../../imgs/network.png';

const RightSideChangePasswordVerify = () => {
    const [_] = useTranslation('global');

    return (
        <div className="  cover-left d-flex flex-column gap-2 align-items-end px-5 d-none d-md-flex" style={{ flexGrow: 3, color: 'white' }}>
            <img src={network} width="400px" alt="" style={{ position: 'absolute', left: 0, bottom: 0 }} />
            <h1 className="px-5" style={{ fontWeight: 400, fontSize: '4rem' }}>{_("registeration.changePasswordVerify")}</h1>
            <h1 className="px-5" style={{ fontWeight: 400, fontSize: '4rem' }}>{_("registeration.changePasswordVerify2")}</h1>
            <h1 className="px-5" style={{ fontWeight: 400, fontSize: '4rem' }}>{_("registeration.changePasswordVerify3")}</h1>
            <h2 className="px-5" style={{ fontWeight: 100 }}>{_("registeration.registerSlug")}</h2>
        </div>
    )
}

export default RightSideChangePasswordVerify
