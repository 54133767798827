import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import StatisticsSearchComponent from "./search components/StatisticsSearchComponent";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";

const StagnantProducts = () => {

  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [allData, handleAllData] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [search, handleSearch] = useState(false); 
  const [days, setDays] = useState("");
  const [productId, setProductId] = useState("");  


  useEffect(() => { 
    setDays(urlParams.get("days") || "");
    setProductId(urlParams.get("product_id") || ""); 
  }, [urlParams]);

  const get_stagnant_products = () => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/reports/stagnant-products`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {  
        days: days,
        product_id: productId,
      },
    })
      .then((res) => {
        handleLoader(false);

        handleAllData(res?.data);
      })
      .catch(() => {
        handleLoader(false);
      });
  };

  useEffect(() => {
    get_stagnant_products();
  }, [  days, productId ]);

  // const totalQuantity = allData.reduce((acc, item) => acc + Number(item.total_quantity),0);
  // const totalShippedCount =   allData.reduce((acc, item) => acc + item.shipped_count,0);
  // const totalDeliveredCashDeliveryCount =   allData.reduce((acc, item) => acc + Number(item.delivered_cash_delivery_count),0);
  // const totalSysCommission =   allData.reduce((acc, item) => acc + item.total_sys_commission,0);
  // const totalSellerCommission =   allData.reduce((acc, item) => acc + item.total_seller_commission,0);
  // const totalMarketerCommission =   allData.reduce((acc, item) => acc + item.total_marketer_commission,0 );


  

  return (
    <div className="flex-grow-1 stats-component"> <Helmet>
    <title>{_("panel.stagnantProducts")}</title> 
 </Helmet>
      <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <StatisticsSearchComponent type={"stagnantProducts"} /> : ""}

      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.stagnantProducts")}
          </h3>
        </div>
        <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
         <Table  >
      <thead>
      <tr className="text-center">

          <th>{_("panel.products")}</th>
          <th>{_("panel.total_delivered")}</th>
          <th>{_("panel.totalorders")}</th> 
          <th>{_("panel.Soldquantity")}</th> 
          <th>{_("panel.left")}</th> 
          <th>{_("panel.totalAmount")}</th> 
        </tr>
      </thead>
      <tbody>
        {allData?.length > 0 && allData.map((item) => (
          <tr className="text-center" key={item?.id}>
            <td><span className="fw-bold">{item?.id} </span> <br/>{item?.title}</td>
            <td>{item?.delivered_orders}</td>
            <td>{item?.total_orders}</td>
            <td>{item?.total_quantity}</td>
            <td>{item?.left_stock}</td>
            <td>{item?.total_stock}</td>
          </tr>
        ))}
      </tbody>
    </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StagnantProducts;
