import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const TableLabels = ({
  totalCurrentStock,
  totalLeftValue,
  courierQ,
  courierV,
  totalPrice,
  totalLeftByPage,
  totalPiecePriceByPage,
  totalCourierQuantityByPage,
  totalCourierValueByPage ,
  totalLeftValueByPage
}) => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  const roles_labels = {
    employee: [
      _("panel.date"),
      _("panel.title"),
      _("sku"),
      _("panel.left"),
      _("panel.courier_quantity"),
      _("panel.courier_value"),
    ],
    admin: [
      _("panel.date"),
      _("panel.title"),
      _("sku"),
      _("panel.left"),
      _("panel.leftValue"),
      _("panel.اجمالي ربحيه التجار"),
  
      _("panel.courier_quantity"),
      _("panel.courier_value"),
    ],
    seller: [
      _("panel.date"),
      _("panel.title"),
      _("sku"),
      _("panel.left"),
      _("panel.price"),
      _("panel.courier_quantity"),
    ],
    fulfillment: [
      _("panel.date"),
      _("panel.title"),
      _("sku"),
      _("panel.left"),
      _("panel.price"),
      _("panel.courier_quantity"),
    ],
  };
  const labels = {
    left: totalCurrentStock,
    leftValue: totalLeftValue,
    courier_quantity: courierQ,
    courier_value: courierV,
    price:totalPrice
  };
  useEffect(() => {}, [totalCurrentStock, totalLeftValue, courierQ, courierV]);

  return (
    <tr>
      <th>
        <div
          className="form-check mt-3 d-flex justify-content-center"
          style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
        >
          <input
            className="form-check-input"
            style={{
              border: "1px solid rgba(243, 168, 55, 1)",
              transform: "translate(-0%, -50%)",
            }}
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
        </div>
      </th>
      {roles_labels[role].map((label, index) => {
        let labelValue;
        let labelPage;
        if (index == 3) {
          labelValue = labels.left   ; 
          labelPage = totalLeftByPage  ||'0';
        }
        if (index == 4) {
          labelValue = labels.leftValue;
          labelPage = totalLeftValueByPage ||"0"
        }
        if (index == 5) {
          labelValue = labels.price;
          labelPage=  totalPiecePriceByPage ||'0'
        }
        if (index == 6) {
          labelValue = labels.courier_quantity;
          labelPage=   totalCourierQuantityByPage ||'0'
        }
        if (index == 7) {
          labelValue = labels.courier_value;
          labelPage=totalCourierValueByPage ||'0'
        }
        return (
          <th>
           {`${labelPage && role == 'admin' ? `(${labelPage.toLocaleString()})` : ""}`}  <br/>  {`${labelValue && role == 'admin' ? `t (${labelValue.toLocaleString()})` : ""}`}    <br/>  {label}
          </th>
        );
      })}
    </tr>
  );
};

export default TableLabels;
