export default class Product {
  constructor(response, slug) {
    this.response = response;
    this.name = response.product.title;
    this.slug = String(response.product.id);
    this.price = response.product.price;
    this.sale_price = 0;
    this.description = response.product.description;
    this.thumb = response.product.thumbnail;
    this.images = response.product_imgs.map((val) => val.img_url);
    this.quantity = 0;
    this.track_stock = false;
    this.disable_orders_for_no_stock = false;
    this.buy_now_text = "اضغط هنا للشراء";
    this.is_reviews_enabled = true;
    this.fake_visitors_min = 20;
    this.fake_visitors_max = 70;
    this.fake_timer_hours = 1;
    this.is_quantity_hidden = false;
    this.is_header_hidden = false;
    this.is_free_shipping = false;
    this.taager_code =
      response.product_variants.length >= 1
        ? String(response.product_variants[0].id)
        : 0;
    this.drop_shipping_provider = "Ecombo";
    if (response.product_variants.length > 1) {
      this.createVariations();
      this.createVariants();
    }
  }
  createVariations() {
    this.variations = [];
    if (this.response.product_variants[0].color) {
      this.variations.push({
        id: "521a0ab9-12e0-4536-b458-47006108c39e",
        name: "اللون",
        product_id: String(this.response.product.id),
        type: "color",
        props: this.response.product_variants.map((variant, index) => {
          return {
            id: String(index),
            name: variant.color,
            variation_id: "521a0ab9-12e0-4536-b458-47006108c39e",
            value: variant.color,
          };
        }),
      });
    }
    if (this.response.product_variants[0].size) {
      this.variations.push({
        id: "fa9ba052-6140-43f0-92ba-f206a22b764b",
        name: "المقاس",
        product_id: String(this.response.product.id),
        type: "dropdown",
        props: this.response.product_variants.map((variant, index) => {
          return {
            id: String(index),
            name: variant.size,
            variation_id: "fa9ba052-6140-43f0-92ba-f206a22b764b",
            value: variant.size,
          };
        }),
      });
    }
  }
  createVariants() {
    this.variants = [];
    this.response.product_variants.map((variant, index) => {
      let props = [];
      if (variant.color) {
        props.push({
          variation: "اللون",
          variation_prop: variant.color,
        });
      }
      if (variant.size) {
        props.push({
          variation: "المقاس",
          variation_prop: variant.size,
        });
      }
      this.variants.push({
        id: String(variant.id),
        price: variant.price,
        sale_price: 0,
        quantity: 0,
        taager_code: String(variant.id), // your variant id
        variation_props: props,
      });
    });
  }
}
