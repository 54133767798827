import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import IconsGen from "../../../icons_generator/IconsGen";
import TableLabels from "./TableLabels";
import AxiosController from "../../../authentication/AxiosController";
import { FaCaretDown } from "react-icons/fa";

const ProductsStockTableRow = ({
  payments_list,
  alt_name,
  labels,
  handleLoader,
  productsStockLog,
}) => {
  const { slug, lang } = useParams();
  const navigate = useNavigate();
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const [totalCurrentStock, setTotalCurrentStock] = useState(0);
  const [totalLeftValue, setTotalLeftValue] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [courierQ, setCourierQ] = useState(0);
  const [courierV, setCourierV] = useState(0);
  const token = localStorage.getItem("token");

  const [skus, setSkus] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const [loading, setLoading] = useState({});

  const get_sku = (id) => {
    setLoading((prev) => ({ ...prev, [id]: true }));
    AxiosController.get(
      `api/${slug}/${lang}/get-product-variants-stocks/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => { 
        
        setSkus((prev) => ({ ...prev, [id]: res.data }));
        setLoading((prev) => ({ ...prev, [id]: false }));
        setShowDropdown((prev) => ({ ...prev, [id]: true }));
      })
      .catch((e) => {
        setLoading((prev) => ({ ...prev, [id]: false }));
        console.error(e);
      });
  }; 

  const handleClick = (id) => {
    if (!showDropdown[id]) {
      get_sku(id);
    } else {
      setShowDropdown((prev) => ({ ...prev, [id]: false }));
    }
  };

  useEffect(() => {
    payments_list.map((order) => {
      let leftValue = productsStockLog.reduce((prev, stock_log) => {
        if (stock_log.product_id === order["product_id"]) {
          return (
            parseFloat(stock_log.piece_price) * parseInt(stock_log.left) + prev
          );
        } else {
          return prev;
        }
      }, 0);
    });
  }, [payments_list, productsStockLog]);

  useEffect(() => {
    setTotalCurrentStock(labels?.total_left);
    setCourierQ(labels?.courier_quantity);
    setTotalPrice(labels?.total_price);
    setCourierV(labels?.courier_value);
    setTotalLeftValue(labels?.total_value);
  }, [labels]);
  const totalLeftByPage =  payments_list
  .map((order) => order["left"])  
  .reduce((acc, left) => acc + left, 0);


  
const totalLeftValueByPage = payments_list.reduce((acc, order) => {
  const leftValue = productsStockLog.reduce((prev, stock_log) => {
    if (stock_log.product_id === order["product_id"]) {
      return prev + parseFloat(stock_log.piece_price) * parseInt(stock_log.left);
    } else {
      return prev;
    }
  }, 0);
  return acc + leftValue;
}, 0);


  const totalPiecePriceByPage =  payments_list
  .map((order) => order["piece_price"])  
  .reduce((acc, piecePrice) =>acc + Number (piecePrice), 0);


  const totalCourierQuantityByPage =  payments_list
  .map((order) => order["courier_quantity"])  
  .reduce((acc, courierQuantity) => acc +Number( courierQuantity), 0);

  const totalCourierValueByPage =  payments_list
  .map((order) => order["courier_value"])  
  .reduce((acc, courierValue) => acc + Number(courierValue), 0);
  
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless text-nowrap">
        <thead>
          <TableLabels
            totalCurrentStock={totalCurrentStock}
            totalLeftValue={totalLeftValue}
            totalPrice={totalPrice}
            courierQ={courierQ}
            courierV={courierV}
            totalLeftByPage={totalLeftByPage}
            totalPiecePriceByPage={totalPiecePriceByPage}
            totalCourierQuantityByPage={totalCourierQuantityByPage}
            totalCourierValueByPage={totalCourierValueByPage}
            totalLeftValueByPage={totalLeftValueByPage}
          />
        </thead>
        <tbody> 
          {payments_list.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");
            let leftValue = productsStockLog.reduce((prev, stock_log) => {
              if (stock_log.product_id === order["product_id"]) {
                return (
                  parseFloat(stock_log.piece_price) *
                    parseInt(stock_log.left) +
                  prev
                );
              } else {
                return prev;
              }
            }, 0);

            return (
              <tr key={order["id"]}>
                <td>
                  <div
                    className="form-check mt-3 d-flex justify-content-center"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </td>
                <td>
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td>
                  <img
                    src={order["thumbnail"]}
                    width={"69px"}
                    className="rounded"
                  />
                  <br />
                  {order["title"].slice(0, 15)}... <br />{" "}
                  <span className="fw-bold">{order["product_id"]} </span> #
                  <br />
                  {order["sku"]}
                </td>               <td
  onClick={() => handleClick(order["product_id"])}
  style={{ position: "relative", cursor: "pointer" }}
>
  {loading[order["product_id"]] ? (
    <span>Loading...</span>
  ) : (
    <>
    SKU
    <div style={{ display: 'inline-block', marginLeft: '5px' }}>
      <FaCaretDown />
    </div>

    {showDropdown[order["product_id"]] && (
      <div
        style={{
          position: "relative",
          marginTop: "10px",
        }}
      >
 

        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #ccc",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            width: "100%",
            borderRadius: "4px",
            padding: "10px",
          }}
        >
         {skus[order["product_id"]] && (
  <div className="mt-4 mb-4">                 
    <table className="table">
      <thead>
        <tr className="text-center">
          <th scope="col">{_("sku")}</th>
          <th scope="col">{_("panel.size")}</th>
          <th scope="col">{_("panel.color")}</th>
          <th scope="col">{_("panel.left")}</th>
          <th scope="col">{_("panel.totalAmount")}</th>
        </tr>
      </thead>
      <tbody>
        {skus[order["product_id"]]
          .sort((a, b) => b.left - a.left)   
          .map((sku, index) => (
            <tr key={index} className="text-center">
              <th>{sku?.sku}</th>
              <th>{sku?.size || "__"}</th>
              <th>
              {sku?.color || "__"}
               </th>
              <th>{sku?.left}</th>
              <th>{sku?.total}</th>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
)}

        </div>
      </div>
    )}
  </>
  )}
</td>


                <td>{order["left"]}</td>
 
                {role === "admin" && (
                  <td>
                    {/* {productsStockLog.map((stock_log, index) => {
                      if (stock_log.product_id === order["product_id"])
                        return `(${stock_log.left})*${parseFloat(stock_log.piece_price
                        )}`;
                    })}
                    <br /> */}
                    {leftValue || 0}
                  </td>
                )}
                {(role === "admin" || role ==='seller') && (
                  <td>{parseFloat(order["piece_price"]) || 0}</td>
                  )} 
                <td>{order["courier_quantity"] || '0'}</td>
                {(role === "admin" || role === "employee") && (
                  <td>{order["courier_value"] || 0}</td>
                )}
                <td
                  onClick={() =>
                    navigate(
                      `/${slug}/${lang}/panel/warehouse/product-stock/${order["product_id"]}`
                    )
                  }
                >
                  {<IconsGen icon_name={"enter"} />}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsStockTableRow;
