import React from "react";
import { Tabs } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Income from "./Shipping";
import Labels from "./labels/Shipping";
import { Helmet } from "react-helmet";

const ExpenseTabs = () => {

  const [_] = useTranslation("global");

  return (
    <Tabs defaultValue="gallery" className="w-100">
      <Helmet>
    <title>{_("panel.exports")}</title> 
 </Helmet>
      <Tabs.List grow>
        <Tabs.Tab value="gallery" className="fs-5" color="rgb(89, 198, 201)">
          {_("panel.exports")}
        </Tabs.Tab>
        <Tabs.Tab value="messages" className="fs-5" color="rgb(89, 198, 201)">
          {_("panel.labels")}
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="gallery">
        <Income />
      </Tabs.Panel>

      <Tabs.Panel value="messages">
        <Labels />
      </Tabs.Panel>
    </Tabs>
  );
};

export default ExpenseTabs;
