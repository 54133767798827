import React, { useEffect, useState } from "react"; 
import { useTranslation } from "react-i18next"; 
import { downloadPDF } from "./LinkedPrint";

const LinkedManifest = ({ linkManifest }) => {
   const [_] = useTranslation("global"); 
  const [loading, handleLoader] = useState(false);
  const [linked,setLinked ] = useState(linkManifest);
 
  useEffect(() => {
    if (linked && linked.length > 0) {
      handleLoader(false); 
      downloadPDF(linked ,_("panel.total") , _("panel.orderContent") , _("panel.courierNumber") , _("panel.totalorders2"));  
      setLinked([])
    }
  }, [linked]);

  return (
    <>
      {/* <div  className={`table-responsive tables-container  d-flex flex-column position-relative  ${
          lang === "en" ? "flex-row" : ""
        } `}
        style={{marginTop:"100px"}}>
        <h3 className="text-center  bg-secondary bg-gradient text-white pt-3 pb-3 mb-4 rounded ">  الطلبات التي تم ربطها بشركات الشحن</h3>
        <div className="d-flex justify-content-end ">
          <button
            className="btn"
            disabled={!(loading === false)}
            style={{ border: "1px solid #172B4D", color: "#172B4D" }}
          >
            {_("panel.downloadpdf")}
          </button>
        </div>

        <div className="row fw-bold" ref={tableRef}>
          <h1 className="text-center ecombo-secondary"> Manifest </h1>
          <div  className="d-flex justify-content-end " >
            <img
              src={logo}
              alt="logo"
              className="m-4"
              style={{ height: "65px", width: "200px" }}
            />
          </div>
          <hr />
          <table className="table table-borderless" htmlFor="flexCheckDefault">
            <thead>
              <tr>
                <th>#</th>
                <th>{_("panel.total")}</th>

                <th>{_("panel.orderContent")}</th>
                <th>{_("panel.courierNumber")}</th>
              </tr>
            </thead>
            <tbody>
            {(linked.length > 0 || linkManifest.length > 0) &&
  (linked.length > 0 ? linked.map((ele , index) => (
    <tr key={ele["Ref No"]}>
       {index +1 }
      <td scope="row" className="align-middle">
        {ele["COD"]}
      </td>
      <td className="align-middle">
        <span className="fw-bold">#{ele["Ref No"]}</span> <br />
        <span>
          {ele["Content"] &&
            ele["Content"].split("\n").map((line, index) => (
              <p key={index}>
                {line}
                <br />
              </p>
            ))}
        </span>
      </td>
      <td className="align-middle">{ele["Courier Track"]}</td>
    </tr>
  )) : linkManifest.map((ele , index ) => (
    <tr key={ele["Ref No"]}>
       <td scope="row" className="align-middle">
        {index +1 }
      </td>
      <td scope="row" className="align-middle">
        {ele["COD"]}
      </td>
      <td className="align-middle">
        <span className="fw-bold">#{ele["Ref No"]}</span> <br />
        <span>
          {ele["Content"] &&
            ele["Content"].split("\n").map((line, index) => (
              <p key={index}>
                {line}
                <br />
              </p>
            ))}
        </span>
      </td>
      <td className="align-middle">{ele["Courier Track"]}</td>
    </tr>
  )))
}

            </tbody>
            <tfoot >
              <tr style={{borderTop :"3px solid black"}}>
              <td> </td>
                <td> </td>
                <td  >  {_("panel.totalorders2")}   </td>
                <td   >
              {linked.length ||linkManifest.length }
                </td>
               
              </tr>
  
             
            </tfoot>
          </table>
          <hr />
          <div className="row" style={{ marginTop: "100px" }} dir="ltr">
            <div className="col-6 gy-4 d-flex justify-content-between">
              <p className="fw-bold"> send By : </p>
              <p> __________________________________________________</p>
            </div>
            <div className="col-6 gy-4 d-flex justify-content-between">
              <p className="fw-bold">Received By :</p>
              <p> __________________________________________________</p>
            </div>
          </div>
          <div className="row" dir="ltr">
            <div className="col-6 gy-4 d-flex justify-content-between">
              <p className="fw-bold"> Date : </p>
              <p> __________________________________________________</p>
            </div>
            <div className="col-6 gy-4 d-flex justify-content-between">
              <p className="fw-bold">Date :</p>
              <p> __________________________________________________</p>
            </div>
          </div>
          <div className="row" dir="ltr">
            <div className="col-6 gy-4 d-flex justify-content-between">
              <p className="fw-bold"> Signature : </p>
              <p> __________________________________________________</p>
            </div>
            <div className="col-6 gy-4 d-flex justify-content-between">
              <p className="fw-bold">Signature :</p>
              <p> __________________________________________________</p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default LinkedManifest;
