import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../authentication/AxiosController";
import { notifyError, notifySuccess } from "../../toastify/toastify";

const TableSelectLevels = ({ currentLevel, userId ,levels, handleLoader , userRole}) => {
 

  
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token"); 
  const [_] = useTranslation("global");
  const [orderStatus, handleOrderStatus] = useState(currentLevel);
  const [selectedLevelId, setSelectedLevelId] = useState(null);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedLevelId(selectedValue);
    change_level_status(selectedValue);
  }; 
  useEffect(() => {
    handleOrderStatus(currentLevel);
    
  }, [currentLevel]);   



  const change_level_status = (levelId) => {
    handleLoader(true);

    AxiosController.put(
      `api/${slug}/${lang}/level/change-user-level`,
      {
        user_id: userId,
        level_id: levelId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        notifySuccess(_("panel.updateSuccessMsg")); 
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
        if (e.response && e.response.data && e.response.data.errors) {
          const errors = e.response.data.errors;
          for (const key in errors) {
            if (errors[key].length) {
              errors[key].forEach((error) => notifyError(`${key}: ${error}`));
            }
          }
        } else {
          notifyError("An unexpected error occurred");
        }
      });
  }; 


  return (
    <div
      className=""
      style={{
        width: "fit-content",
        minWidth: "100px",
        margin: "0 10px",
      }}
    >
     <Form.Select
        value={selectedLevelId}
        onChange={handleSelectChange}
        disabled={userRole !=="affiliate"}
      >
        <option value={currentLevel}>
          {currentLevel}
        </option>
        {levels.length > 0 && levels.map((ele, index) => {
          if (ele.level !== currentLevel) {
            return (
              <option key={index} value={ele.id}>
                {ele.level}
              </option>
            );
          }
          return null;  
        })}
 

        
      </Form.Select>
    </div>
  );
};

export default TableSelectLevels;
