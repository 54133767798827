import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import TableLabels from "./TableLabels";
import IconsGen from "../../../icons_generator/IconsGen";
const colors = {
  seller: "rgba(89, 198, 201, 1)",
  affiliate: "rgb(243, 168, 55)",
  employee: "rgba(23, 43, 77, 1)",
};
const PaymentRequestsTableRow = ({
  payments_list,
  setSheetOrders
}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");


  useEffect(() => {
  }, [payments_list]);
  return (
    <div
      className={`table-responsive my-3 d-flex border rounded-3 ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels
            stats={[payments_list.length]}
          />
        </thead>
        <tbody>
          {payments_list.map((order, index) => {

            return (
              <tr className="">
                <td className={`align-middle danger `}>
                {order.variants.map((r) => {
                    return (<p>{r.sku}</p>);
                  }, 0)}
                </td>
                <td className={`align-middle danger `}>
                  {order["customer_name"]} <br />
                  {order["phone_1"]}
                </td>
                <td className={`align-middle danger `}>
                  {order["address"]}
                </td> 
                <td className={`align-middle danger `}>
                  {order.variants.reduce((p, r) => {
                    return p + r.price
                  }, 0)}
                </td>
                <td
                  className="align-middle px-3"
                  onClick={() => {
                    setSheetOrders((orders) => {
                      return orders.filter((v, i) => i !== index);
                    })
                  }}
                >
                  {<IconsGen icon_name={"delete"} />}
                </td>

              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentRequestsTableRow;
