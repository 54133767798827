import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import StatisticsSearchComponent from "../admin/search components/StatisticsSearchComponent";

const ConfirmationReport = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [search, handleSearch] = useState(false);
  const [userId, setUserId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const get_confirmation_report = () => {
    setLoading(true);
    AxiosController.get(
      `/apiv2/${slug}/${lang}/reports/confirmation-report`,
      {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: startDate,
          end_date: endDate,
          user_id: userId, 
        },
      }
    )
      .then((res) => {
        setLoading(false);
        setAllData(res?.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    get_confirmation_report();
  }, [ startDate, endDate, userId]);
  useEffect(() => { 
    setUserId(urlParams.get("user_id") || "");
    setStartDate(urlParams.get("start_date") || "");
    setEndDate(urlParams.get("end_date") || "");
  }, [urlParams]);
  return (
    <div className="flex-grow-1 stats-component" dir={box_head_dir}>
       <Helmet>
   <title>{_("panel.confirmationReport")}</title> 
</Helmet>
<Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <StatisticsSearchComponent type={"confirmation"} /> : ""}
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.confirmationReport")}
          </h3>
        </div>

        <div className="row">
          <div
            className="col-lg-12 gy-4"
            style={{ position: "relative", overflowX: "auto" }}
          >
                    <Table
                responsive
                dir={box_head_dir}
                className="reports-table text-nowrap text-center">
              <thead>
                <tr className="text-center">
              
                  <th>{_("home.fullname")}</th>
                  <th>{_("panel.orders")}</th>
                  <th>{_("panel.Confirmed")}</th>
                  <th>{_("panel.deliveredOrders")}</th>
                </tr>
              </thead>
              <tbody>
                {allData &&
                  allData.map((product) => (
                    <tr key={product.confirmation_user} className="text-center">
              
                      <td>
                        <span className="fw-bold">{product.confirmation_user} # </span><br/>
                        {product.confirmation
                          ? `${product.confirmation.first_name} ${product.confirmation.last_name}`
                          : _("panel.noData")}
                      </td>
                      <td>{product.orders_count}</td>
                      <td>{product.confirmed_orders_count}</td>
                      <td>{product.delivered_orders}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationReport;
