import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";

import TableLabels from "./TableLabels";
import IconsGen from "../../../icons_generator/IconsGen";
import { Form } from "react-bootstrap";
import AxiosController from "../../../authentication/AxiosController";
const colors = {
  seller: "rgba(89, 198, 201, 1)",
  affiliate: "rgb(243, 168, 55)",
  employee: "rgba(23, 43, 77, 1)",
};
const PaymentRequestsTableRow = ({
  payments_list,
  alt_name,
  handleLoader,
  get_offers,
  setTitle,
  setThumbnail,
  handleShow,
  setId
}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const changeStatus = (value, id) => {
    handleLoader(true);
    AxiosController.post(
      `apiv2/${slug}/${lang}/offers/change-offer-status/${id}`,
      {
        value: value,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {})
      .catch((e) => {});
  };
  useEffect(() => {}, [payments_list]);
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table
        className="table table-borderless text-nowrap"
        htmlFor="flexCheckDefault"
      >
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order, index) => {
            return (
              <tr className="">
                <td>{order["title"]}</td>
                <td>
                  <img
                    src={order["thumbnail"]}
                    className="img-fluid"
                    width={"90px"}
                  />
                </td>
                <td>
                  <Form.Select
                    className={`${
                      order["active"]
                        ? "ecombo-select-active"
                        : "ecombo-select-inactive"
                    }`}
                    value={order["active"]}
                    onChange={(e) => {
                      changeStatus(e.target.value, order["id"]);
                      get_offers();
                    }}
                  >
                    <option value={1}>{_("panel.Active")}</option>
                    <option value={0}>{_("panel.Inactive")}</option>
                  </Form.Select>
                </td>
                {role == "admin" ? (
                  <>
                    <td>{order['all_orders_details']}</td>
                    <td>{order['succeeded_orders_details']}</td>
                  </>
                ) : (
                  ""
                )}
                <td className="px-3">
                  <NavLink
                    to={`/${slug}/${lang}/panel/products/offer/${order["id"]}`}
                  >
                    {<IconsGen icon_name={"enter"} />}
                  </NavLink>
                </td>
                <td className="px-3" onClick={() => {
                  setTitle(order['title']);
                  setId(order['id']);
                  handleShow()
                }}>

                    {<IconsGen icon_name={"edit"} />}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentRequestsTableRow;
