import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen"; 

const Wallet = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
 
  return (
    <div className="links-container d-flex flex-column">

      <NavLink
        className={`feature_link ${
          lang === "en" ? "feature_link_en" : ""
        } d-flex flex-row-reverse align-items-center gap-2 py-3`}
        dir={direction}
        to={`/${slug}/${lang}/panel/wallet`}
        
      >
        <IconsGen icon_name={"wallet"} />
        <p>{_("panel.wallet")}</p>
      </NavLink>
    </div>
  );
};

export default Wallet;
