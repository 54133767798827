import React, { useEffect, useState } from "react";
import BlueRow from "./BlueRow";
import AxiosController from "../../../authentication/AxiosController";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MarketersStats = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [marketersStats, setMarketersStats] = useState({});
  const [_] = useTranslation("global");

  const [altName, handleAltName] = useState();
  const marketers_stats = () => {
    AxiosController.get(`api/${slug}/${lang}/get-marketers-stats-wallet/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setMarketersStats(res.data);
        handleAltName(res.data.alt_name);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    marketers_stats();
  }, []);
  return (
    <div className="mt-2">
      <BlueRow
        slug={altName}
        values={[
          marketersStats.total_expected
            ? marketersStats.total_expected.toLocaleString()
            : 0,

          marketersStats.total_confirmed
            ? marketersStats.total_confirmed.toLocaleString()
            : 0,
          marketersStats.total_balances
            ? marketersStats.total_balances.toLocaleString()
            : 0,
            marketersStats.total_pending
            ? marketersStats.total_pending.toLocaleString()
            : 0,
        ]}
        image={marketersStats.image}
        titles={[
          _("panel.expectedearnings"),
          _("panel.confirmedearnings"),
          _("panel.withdrawableearnings"),
          _("panel.pendingEarnings"),
        ]}
      />
    </div>
  );
};

export default MarketersStats;
