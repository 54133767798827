import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Link, useParams, useNavigate, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Divider, notification, Space } from "antd";
import LangSelector from "../languages/LangSelector";
import logo from "../../imgs/Logo_Ecombo 1.png";
import AxiosController from "../authentication/AxiosController";
import Loader from "../loader/Loader";
import { Helmet } from "react-helmet";


const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [loader, setLoader] = useState(false);
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  
  const { lang } = useParams();
  const navigate = useNavigate();
  if (token) {
    navigate(`/ae/${lang}`);
  }
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement, status) => {
    api.success({
      style: { direction: lang == "ar" ? "rtl" : "ltr" },
      description: `${status}!`,
      placement,
    });
  };
  const loginAction = async () => {
    setLoader(true);
    AxiosController.post(`api/${lang}/auth/reset-password`, { email })
      .then((res) => {
        openNotification(
          lang == "ar" ? "topRight" : "topLeft",
          _('panel.msgSent')
        );
        navigate(`/${lang}/auth/changepasswordverify`);
         setLoader(false);
      })
      .catch((err) => {                                   
 
        setEmailErr(
          err.response.data.error.email ? err.response.data.error.email[0] : err.response.data.error.auth ? err.response.data.error.auth[0] : ""
        );
        setLoader(false);
      });
  };
  return (
    <div
      className="   inputs-data d-flex align-items-center px-5 justify-content-center flex-grow-1 position-relative overflow-hidden"
      style={{ backgroundColor: "white" }}
    >
      {contextHolder}
      <Helmet>
    <title>{_("registeration.verify")}</title> 
 </Helmet>
      <Loader active={loader} />
      <div className="data-entry-container" style={{ width: "100%" }}>
        <div className="d-flex justify-content-between flex-column flex-md-row-reverse">
          <NavLink to={`/${lang}/auth/login`}>
            <img src={logo} width="230px" alt="" />
          </NavLink>
          <LangSelector lang={lang} />
        </div>
        <div className="header d-flex justify-content-end">
          <h3 style={{ color: "rgba(243, 168, 55, 1)", fontSize: "1.6rem" }}>
            {_("registeration.verify")}
          </h3>
        </div>
        <Form
          className="d-flex flex-column gap-2"
          dir={lang === "ar" ? "rtl" : "ltr"}
          onSubmit={(e) => {
            e.preventDefault();
            loginAction();
          }}
        >
          <Form.Group className="d-flex flex-column gap-2">
            <Form.Label>{_("registeration.email")}</Form.Label>
            <Form.Control
              type="email"
              className="p-2"
              placeholder={`${_("registeration.enter_email")}`}
              name="user_email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailErr ? <Alert variant="danger">{emailErr}</Alert> : ""}
          </Form.Group>
          <input
            className="btn"
            style={{
              color: "white",
              backgroundColor: "rgba(89, 198, 201, 1)",
              border: "none",
            }}
            type="submit"
            value={_("registeration.send")}
          />
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
