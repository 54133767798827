import React from "react";
import { useTranslation } from "react-i18next";

const TableLabels = ({ stats }) => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const roles_labels = {
    employee: [
      _("panel.orderContent"),
      _("panel.customerInfo"),
      _("home.address"),
      _("panel.totalNoShipping"),
    ],
    admin: [
      _("panel.orderContent"),
      _("panel.customerInfo"),
      _("home.address"),
      _("panel.totalNoShipping"),
    ],
    affiliate: [
      _("panel.orderContent"),
      _("panel.customerInfo"),
      _("home.address"),
      _("panel.totalNoShipping"),
    ]
  };

  return (
    <tr>

      {roles_labels[role].map((label, index) => {
        return <th className="text-center">{label}</th>;
      })}
    </tr>
  );
};

export default TableLabels;
