import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../../../loader/Loader";
import AxiosController from "../../../../authentication/AxiosController";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Sellers from "./Sellers";
import Marketers from "./Marketers";
import { Tabs } from "@mantine/core";
import StatisticsSearchComponent from "../search components/StatisticsSearchComponent";

const PaymentRequest = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [seller, setSeller] = useState([]);
  const [marketer, setMarketer] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [search, handleSearch] = useState(false);
  const [userId, setUserId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [state, setState] = useState({
  //   selection: {
  //     startDate: new Date(),
  //     endDate:new Date(),
  //     key: 'selection'
  //   }
  // });
  
  const get_seller = () => {
    setLoading(true);
    AxiosController.get( `/api/${slug}/${lang}/reports/payments`, {
        headers: { Authorization: `Bearer ${token}` },
        params:{
          role:"seller" ,
          start_date: startDate,
          end_date: endDate,
          user_id: userId}})
      .then((res) => {
        setLoading(false);
        setSeller(res?.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const get_marketer = () => {
    setLoading(true);
    AxiosController.get( `/api/${slug}/${lang}/reports/payments`, {
        headers: { Authorization: `Bearer ${token}` },
        params:{
          role:"affiliate",        
          start_date: startDate,
          end_date: endDate,
          user_id: userId
        }})
      .then((res) => {
        setLoading(false);
        setMarketer(res?.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => { 
    get_seller();
    get_marketer()
  }, [ userId, startDate, endDate]);
  useEffect(() => {
    setUserId(urlParams.get("user_id") || "");
    setStartDate(urlParams.get("start_date") || "");
    setEndDate(urlParams.get("end_date") || "");
 
  }, [urlParams]);



  return (
    <div className="flex-grow-1 stats-component" dir={box_head_dir}>
      <Helmet>
        <title>{_("panel.paymentRequest")}</title>
      </Helmet>
      <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <StatisticsSearchComponent type={"payment"} /> : ""}
  
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.paymentRequest")}
          </h3>
        </div>

    <Tabs defaultValue="gallery">
        
 <Tabs.List grow>
          <Tabs.Tab value="gallery" className="fs-5" color="rgb(89, 198, 201)">
            {_("panel.marketers")}
          </Tabs.Tab>
          <Tabs.Tab value="messages" className="fs-5" color="rgb(89, 198, 201)">
            {_("panel.sellers")}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="gallery">
          <Marketers allData={marketer}/>
        </Tabs.Panel>

        <Tabs.Panel value="messages">
          <Sellers allData={seller}/>
        </Tabs.Panel>    
      </Tabs>
      </div>
    </div>
  );
};

export default PaymentRequest;
