import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import { Pagination } from "react-bootstrap";
import Loader from "../../../loader/Loader";
import { useTranslation } from "react-i18next";
import VariantsTableRow from "./VariantsTableRow";

const get_products_stock = (
  slug,
  lang,
  token,
  handleProductsStock,
  page,
  handleLoader
) => {
  AxiosController.get(
    `api/${slug}/${lang}/get-product-variants-stocks/${page}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      handleProductsStock(res.data);
      handleLoader(false);
    })
    .catch((e) => {
    });
};

const VariantsStock = ({ productId }) => {
  const { slug, lang } = useParams();

  const token = localStorage.getItem("token");
  const [productsStock, handleProductsStock] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [_] = useTranslation("global");

  useEffect(() => {
    handleLoader(true);
    get_products_stock(
      slug,
      lang,
      token,
      handleProductsStock,
      productId,
      handleLoader
    );
  }, [slug, lang, productId]);

  return (
    <div
      className="tables-container d-flex flex-column px-2"
      style={{ position: "relative", flexGrow: 3 }}
    >
      <div
        className={`header d-flex ${
          lang === "en" ? "flex-row" : "flex-row"
        } gap-3 align-items-center justify-content-between`}
      >
        <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
          {_("panel.variantsStock")}
        </h3>
      </div>
      <div className="footer d-flex flex-column flex-grow-1">
        {productsStock.length ? (
          <VariantsTableRow
            payments_list={productsStock}
            handleLoader={handleLoader}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default VariantsStock;
