import React, { useEffect } from "react";
import { Image } from "antd";
import { useParams } from "react-router-dom";
import IconsGen from "../../../../icons_generator/IconsGen";
import AxiosController from "../../../../authentication/AxiosController";
import './style.css';
const delete_product_image = (slug, lang, token, product_image_id, fetch) => {
  AxiosController.delete(
    `api/${slug}/${lang}/slider/delete-slider/${product_image_id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      fetch();
    })
    .catch((e) => {});
};
const ProductImgsSlider = ({ productImgs = [], fetch}) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  useEffect(() => {

  }, [])
  return (
    <div  className="d-flex flex-wrap gap-2 flex-column">
      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) =>
            console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        {productImgs.map((img) => {
          return (
            <div className="position-relative antd-container rounded-3 p-1 w-100"  >
              <div
                className="trash position-absolute p-2 rounded-3"
                onClick={() => {
                  delete_product_image(slug, lang, token, img.id, fetch);
                }}
              >
                <IconsGen icon_name={"delete"} />
              </div>
              <Image height={200} src={img.image} style={{minWidth:'100%'}}/>
            </div>
          );
        })}
      </Image.PreviewGroup>
    </div>
  );
};
export default ProductImgsSlider;
