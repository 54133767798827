import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import IconsGen from "../../../../icons_generator/IconsGen";
import * as XLSX from "xlsx";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AxiosController from "../../../../authentication/AxiosController";

const ExcelExport = ({
  selectedOrders,
  orders_products,
  fetchingOrdersHandler,
}) => {
  const token = localStorage.getItem("token");
  const { slug, lang } = useParams();
  const [loading, handleLoader] = useState(false);
  const [_] = useTranslation("global");
  const employee_type = localStorage.getItem("employee_type");
  const role = localStorage.getItem("role");
  const [orders, setOrders] = useState([]); 
  const fileInputRef = useRef(null);
  const Navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
    let mergedData = [];

  const excel_data = useCallback(async () => {
    handleLoader(true);
    const orderIdsQueryString = selectedOrders.map((order) => `order_ids[]=${order.id}`).join("&");
    const url = `api/${slug}/${lang}/orders/orders-excel?${orderIdsQueryString}`;



    try {
      const res = await AxiosController.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrders(res?.data);

      mergedData = selectedOrders.map((selectedOrder) => {
        const excelObj = {
          Date: selectedOrder["created_at_formatted"],
          Track: selectedOrder["track"],
          Customer_Name: selectedOrder["customer_name"],
          Address: "",
          Status: selectedOrder["status"],
          "Net Price": selectedOrder["net_price"],
          "Total Price": selectedOrder["total_price"],
          Commission: selectedOrder["commissions"],
          Phone_Number1: "",
          Phone_Number2: "",
          Country: "",
          Quantity: "",
          Sku: "",
          Cancellation_Price: "",
          Confirmation_Price: "",
          Fulfillment_Price: "",
          Reference_Order: "",
        };

        let pieces = 0;
        let products = "";
        if (orders_products[selectedOrder.id]) {
          orders_products[selectedOrder.id].forEach((item) => {
            pieces += item.quantity;
            products += `${item.product.title}\n`;
          });
        }

        excelObj["Quantity"] = pieces;
        const order = res.data.find((o) => o.id === selectedOrder.id);
        if (order) {
          excelObj["Cancellation_Price"] = order["cancellation_price"];
          excelObj["Confirmation_Price"] = order["confirmation_price"];
          excelObj["Fulfillment_Price"] = order["fulfillment_price"];
          excelObj["Reference_Order"] = order["reference_order"];
          const productDetails = order["details"].map((d) => ({
            sku: d.sku,
            Product: d.Product,
            Quantity: d.Quantity,
          }));
          if (productDetails.length > 0) {
            excelObj.productDetails = productDetails
              .map((pd) => `Name: ${pd.Product}, SKU: ${pd.sku}, Quantity: ${pd.Quantity}`)
              .join("\n");
            excelObj["Sku"] = productDetails[0].sku;
            excelObj["Product"] = productDetails[0].Product;
          }

          const statusLog = order["status_history"].map((d) => ({
            status: d.status,
            StatusDate: d.StatusDate,
          }));
          excelObj.Status_Log = statusLog
            .map((pd) => `Status_Type: ${pd.status}, Status_Date: ${pd.StatusDate}`)
            .join("\n");

          const support_tickets = order["support_tickets"].map((d) => ({
            ticket: d.ticket,
            TicketDate: d.TicketDate,
          }));
          excelObj.Ticket = support_tickets
            .map((pd) => `Ticket: ${pd.ticket}, Ticket_Date: ${pd.TicketDate}`)
            .join("\n");

          excelObj.Phone_Number1 = order["phone_1"];
          excelObj.Phone_Number2 = order["phone_2"];
          excelObj.COD = order["COD"];
          excelObj.Country = order["Gov"];
          excelObj.Address = order["Address"];
        }

        return excelObj;
      });

    } catch (e) {
      console.error(e);
    } finally {
      handleLoader(false);
      handleCreateExcel(mergedData);  
    }
  }, [selectedOrders, slug, lang, token, orders_products]);
 
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };

  const handleFile = (e) => {
    try {
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        changeParam(
          "tracks",
          parsedData.map((item) => item.tracks)
        );
      };
    } catch (e) {}
  };

  const handleCreateExcel = (mergedData) => {
    let worksheet;
    if (employee_type === "operation employee") {
      worksheet = XLSX.utils.json_to_sheet(
        mergedData?.map((data) => ({
          Date: data["Date"],
          "No.": data["Track"],
          Name: data["Customer_Name"],
          "Phone 1": data["Phone_Number1"],
          "Phone 2": data["Phone_Number2"],
          City: data["Country"],
          Address: data["Address"],
          Product: data["Product"],
          Pieces: data["Quantity"],
          Sku: data["Sku"],
          "Total Price ": data["Total Price"],
          Status: data["Status"],
          Commission: data["Commission"],
          Ticket: data["Ticket"],
          "Status Logs": data["Status_Log"],
        }))
      );
    } else if (role === "affiliate") {
      worksheet = XLSX.utils.json_to_sheet(
        mergedData?.map((data) => ({
          Date: data["Date"],
          "No.": data["Track"],
          Name: data["Customer_Name"],
          "Phone 1": data["Phone_Number1"],
          "Phone 2": data["Phone_Number2"],
          City: data["Country"],
          Address: data["Address"],
          Product: data["Product"],
          Pieces: data["Quantity"],
          Sku: data["Sku"],
          "Total Price ": data["Total Price"],
          COD: data["COD"],
          Status: data["Status"],
          Commission: data["Commission"],
          Ticket: data["Ticket"],
          "Status Logs": data["Status_Log"],
        }))
      );
    } else if (role === "fulfillment") {
      worksheet = XLSX.utils.json_to_sheet(
          mergedData?.map((data) => ({
          Date: data["Date"],
          "No.": data["Track"],
          Name: data["Customer_Name"],
          "Phone 1": data["Phone_Number1"],
          "Phone 2": data["Phone_Number2"],
          City: data["Country"],
          Address: data["Address"],
          Product: data["Product"],
          Pieces: data["Quantity"],
          Sku: data["Sku"],
          "Total Price ": data["Total Price"],
          COD: data["COD"],
          Cancellation_Price: data["Cancellation_Price"],
          Confirmation_Price: data["Confirmation_Price"],
          Fulfillment_Price: data["Fulfillment_Price"],
          Reference_Order: data["Reference_Order"],
          Status: data["Status"],
          Commission: data["Commission"],
          Ticket: data["Ticket"],
          "Status Logs": data["Status_Log"],
        }))
      );
    } else {
      worksheet = XLSX.utils.json_to_sheet(selectedOrders);
    }
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Orders.xlsx");
  };

  return (
    <div>
      {employee_type !== "operation employee" ? (
        <button
          className="btn w-100 text-nowrap"
          style={{ border: "1px solid #172B4D", color: "#172B4D" }}
          onClick={excel_data}
          disabled={loading}
        >
          <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
        </button>
      ) : (
        <Dropdown>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFile}
            style={{ display: "none" }}
          />
          <Dropdown.Toggle
            variant="success"
            id="dropdown-basic"
            className="btn btn-success w-100 text-nowrap"
            style={{ border: "1px solid #172B4D", color: "#172B4D" }}
          >
            <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={excel_data}>
              Download Orders
            </Dropdown.Item>
            <Dropdown.Item onClick={handleButtonClick}>
              Import Orders
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default ExcelExport;