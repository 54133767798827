import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import TableLabels from "./TableLabels";
import TableSelectStatus from "./TableSelectStatus";
import IconsGen from "../../../icons_generator/IconsGen";
const SuppliersTableRow = ({ payments_list, settingModal }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");
            return (
              <tr className="">
                <td scope="row" className="align-middle">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td className="align-middle">
                  {Number(order["confirmation_price"]).toLocaleString()}
                </td>
                <td className="align-middle">
                  {Number(order["fulfillment_price"]).toLocaleString()}
                </td>
                <td className="align-middle">
                  {Number(order["cancellation_price"]).toLocaleString()}
                </td>
                <td className="align-middle">
                  {order["user"]["first_name"]} {order["user"]["last_name"]} #
                  {order["user"]["id"]}
                </td>
                <td
                  onClick={() => {
                    settingModal(
                      order["user"]["id"],
                      order["fulfillment_price"],
                      order["cancellation_price"],
                      order["confirmation_price"]
                    );
                  }}
                  className="align-middle"
                >
                  {<IconsGen icon_name={"edit"} />}
                </td>
                <td
                  className="align-middle"
                >
                  <NavLink to={`/${slug}/${lang}/panel/shipping/fulfillment/govs/${order["user"]["id"]}`}>{<IconsGen icon_name={"enter"} />}</NavLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SuppliersTableRow;
