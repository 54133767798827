import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../authentication/AxiosController";

const change_order_status = (
  slug,
  lang,
  token,
  orderId,
  status,
  currentStatusHandler,
  handleLoader,
  handleStatusChangeErr
) => {
  AxiosController.put(
    `api/${slug}/${lang}/users/switch-user-activate/${orderId}`,
    {},
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      currentStatusHandler(status);
      handleLoader(false);
    })
    .catch((e) => {
      handleStatusChangeErr(true);
      handleLoader(false);
    });
};

const TableSelectStatus = ({ currentStatus, orderId, handleLoader }) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");

  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  const [orderStatus, handleOrderStatus] = useState("");
  const [statusChangeErr, handleStatusChangeErr] = useState(false);

  const [_] = useTranslation("global");
  const statues = ["Active", "Inactive"];
  const StatusColors = {
    Active: "rgb(28, 152, 47)",
    Inactive: "rgb(255, 0, 0)",
  };

  useEffect(() => {
    handleOrderStatus(currentStatus);
    handleStatusChangeErr(false);
  }, [currentStatus]);
  return (
    <div
      className=""
      style={{
        width: "fit-content",
        minWidth: "100px",

        margin: "0 10px",
      }}
    >
      <Form.Select
        style={{
          border:
            "1px solid " +
            StatusColors[currentStatus == "1" ? statues[0] : statues[1]],
          color: StatusColors[currentStatus == "1" ? statues[0] : statues[1]],
        }}
        onChange={(e) => {
          handleLoader(true);
          change_order_status(
            slug,
            lang,
            token,
            orderId,
            e.target.value,
            handleOrderStatus,
            handleLoader,
            handleStatusChangeErr
          );
        }}
      >
        <option selected={true} value={currentStatus == "1" ? statues[0] : ""}>
          {currentStatus == "1"
            ? _(`panel.${statues[0]}`)
            : _(`panel.${statues[1]}`)}
        </option>
        {statues.map((status, index) => {
          if (status !== (currentStatus == "1" ? statues[0] : statues[1])) {
            return (
              <option key={index} selected={false} value={!currentStatus}>
                {_(`panel.${status}`)}
              </option>
            );
          }
        })}
      </Form.Select>
      {statusChangeErr ? <p>{_("panel.statusChangeErr")}</p> : ""}
    </div>
  );
};

export default TableSelectStatus;
