import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import AxiosController from "../../authentication/AxiosController";
import ProductSearchItem from "./ProductSearchItem";

const SearchComponent = ({cart, handleCart, mi_id}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [query, handleQuery] = useState("");
  const [searchResults, handleSearchResults] = useState([]);
  const Navigate = useNavigate();
  const product_search = (query, user_id) => {
    AxiosController.get(`api/${slug}/${lang}/serach/get-products-bar-fulfillment/${user_id}/${query}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleSearchResults(res.data);
      })
      .catch((e) => {
        
      });
  };
  useEffect(() => {
    handleSearchResults([])
  }, [Navigate])
  return (
    <div className="w-100 position-relative">
      <InputGroup className="flex-grow-1 px-2 py-1  d-none d-md-flex">
        <FormControl placeholder={_('panel.search')} aria-label="Search" onInput={e=> {
            handleQuery(e.target.value);
            if (e.target.value != '')       {
                product_search(e.target.value, mi_id)
            }else {
                handleSearchResults([]);
            }
        }}/>
      </InputGroup>
      {searchResults.length ? (
        <ProductSearchItem list={searchResults} handleCart={handleCart} cart={cart} handleSearchResults={handleSearchResults}/>
      ) : ""}
    </div>
  );
};

export default SearchComponent;
