import "./style.css";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { Alert, Button, Modal } from "react-bootstrap";
import Loader from "../../../loader/Loader";
import ProductsStockTableRow from "./ProductsStockTableRow";
import AxiosController from "../../../authentication/AxiosController";
import SearchComponent from "./SearchComponent";

const get_warehouses = (slug, lang, token, handleWarehouses) => {
  AxiosController.get(`api/${slug}/${lang}/get-warehouses/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleWarehouses(res.data);
    })
    .catch((e) => {});
};
const get_suppliers = (slug, lang, token, handleWarehouses) => {
  AxiosController.get(`api/${slug}/${lang}/get-suppliers/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleWarehouses(res.data);
    })
    .catch((e) => {});
};

const ProductsStockTable = ({
  ProductsStockData = [],
  get_products_stock_handler,
}) => {
  const { slug, lang } = useParams();

  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [show, setShow] = useState(false);

  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [loading, handleLoader] = useState(false);
  const [loading2, handleLoader2] = useState(false);
  const [search, setSearch] = useState(false);

  const [sku, setSku] = useState("");
  const [skuErr, setSkuErr] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [supplierIdErr, setSupplierIdErr] = useState("");
  const [warehouseId, setWarehouseId] = useState("");
  const [warehouseIdErr, setWarehouseIdErr] = useState("");
  const [quantity, setQuantity] = useState();
  const [quantity2, setQuantity2] = useState(0);
  const [quantityErr, setQuantityErr] = useState();
  const [price, setPrice] = useState();
  const [priceErr, setPriceErr] = useState();
  const [status, setStatus] = useState("");
  const [statusErr, setStatusErr] = useState("");
  const [isCashErr, setIsCashErr] = useState();
  const [isCash, setIsCash] = useState();
  const [stockLogId, setStockLogId] = useState("");

  const [suppliers, setSuppliers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [success, setSuccess] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    setSku("");
    setSupplierId("");
    setWarehouseId("");
    setQuantity("");
    setPrice("");
    setStatus("");
    setIsCash("");
    setStockLogId("");
    setShow(false);
    setSuccess("");
  };
  const handleShow = (
    log_id,
    sku,
    quantity,
    price,
    supplier,
    warehouse,
    status,
    edit = false
  ) => {
    if (edit) {
      handleLoader(true);
      setStockLogId(log_id);
      setSku(sku);
      setQuantity(quantity);
      setPrice(price);
      setSupplierId(supplier);
      setWarehouseId(warehouse);
      setStatus(status);
      setQuantity2(0);
      get_warehouses(slug, lang, token, setWarehouses);
      get_suppliers(slug, lang, token, setSuppliers);
      handleLoader(false);
      setShow(true);
      setDisabled(false);
    } else {
      setQuantity2(0);
      handleLoader(true);
      get_warehouses(slug, lang, token, setWarehouses);
      get_suppliers(slug, lang, token, setSuppliers);
      handleLoader(false);
      setShow(true);
      setDisabled(true);
    }
  };

  const createStockLog = () => {
    handleLoader2(true);
    let stockLogReq = AxiosController.post(
      stockLogId
        ? `api/${slug}/${lang}/update-or-sell-or-damaged/`
        : `api/${slug}/${lang}/new-product-variant-stock/`,
      {
        sku: sku,
        supplier_id: supplierId,
        warehouse_id: warehouseId,
        quantity: quantity,
        left: quantity - quantity2,
        price: price,
        status: stockLogId ? isCash : "new",
        is_cash: status,
        stock_log_id: stockLogId ? stockLogId : "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    stockLogReq
      .then((res) => {
        setSku("");
        setSupplierId("");
        setWarehouseId("");
        setQuantity("");
        setPrice("");
        setStatus("");
        setIsCash("");
        setSuccess(_("panel.addedStockLog"));
        handleLoader2(false);
        handleClose();
        get_products_stock_handler();
      })
      .catch((e) => {
        setSkuErr(
          e.response.data.errors.sku ? e.response.data.errors.sku[0] : ""
        );
        setSupplierIdErr(
          e.response.data.errors.supplier_id
            ? e.response.data.errors.supplier_id[0]
            : ""
        );
        setWarehouseIdErr(
          e.response.data.errors.warehouse_id
            ? e.response.data.errors.warehouse_id[0]
            : ""
        );
        setQuantityErr(
          e.response.data.errors.quantity
            ? e.response.data.errors.quantity[0]
            : ""
        );
        setPriceErr(
          e.response.data.errors.price ? e.response.data.errors.price[0] : ""
        );
        setIsCashErr(
          e.response.data.errors.is_cash
            ? e.response.data.errors.is_cash[0]
            : ""
        );
        handleLoader2(false);
      });
  };

  return (
    <div
      className="position-relative tables-container d-flex flex-column gap-3"
      style={{ minHeight: "100px", width: "100%" }}
    >
      <Button
        className="fs-5 px-5 w-25 align-self-start"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          setSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <SearchComponent /> : ""}

      <Button
        variant="primary"
        onClick={handleShow}
        className="px-5 align-self-start"
        style={{ background: "#59C6C9", color: "white", border: "none" }}
      >
        {_("panel.add")}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Loader active={loading2} />
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">
            {_("panel.addwarehouse")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
          {label_input("SKU", sku, setSku)}
          {skuErr ? <Alert variant="danger">{skuErr}</Alert> : ""}
          {label_input(_("panel.quantity"), quantity, setQuantity, !disabled)}
          {quantityErr ? <Alert variant="danger">{quantityErr}</Alert> : ""}
          {label_input(
            _("panel.damagedOrSellQ"),
            quantity2,
            setQuantity2,
            disabled
          )}
          {label_input(_("panel.price"), price, setPrice)}
          {priceErr ? <Alert variant="danger">{priceErr}</Alert> : ""}
          {stockLogId ? (
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.qStatus")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setIsCash(e.target.value);
                }}
                value={isCash}
              >
                <option value={""}></option>
                <option value={"damaged"}>{_("panel.damaged")}</option>
                <option value={"sell"}>{_("panel.sell")}</option>
              </select>
              {supplierIdErr ? (
                <Alert variant="danger">{supplierIdErr}</Alert>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              {_("home.suppliername")}
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setSupplierId(e.target.value);
              }}
              value={supplierId}
            >
              <option value={0}></option>
              {suppliers.map((supplier) => {
                return <option value={supplier.id}>{supplier.name}</option>;
              })}
            </select>
            {supplierIdErr ? (
              <Alert variant="danger">{supplierIdErr}</Alert>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              {_("panel.warehouse")}
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setWarehouseId(e.target.value);
              }}
              value={warehouseId}
            >
              <option value={0}></option>
              {warehouses.map((warehouse) => {
                return <option value={warehouse.id}>{warehouse.title}</option>;
              })}
            </select>
            {warehouseIdErr ? (
              <Alert variant="danger">{warehouseIdErr}</Alert>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              {_("panel.payment")}
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value={0}></option>
              <option value={1}>{_("panel.cash")}</option>
              <option value={-1}>{_("panel.postponed")}</option>
            </select>
            {statusErr ? <Alert variant="danger">{statusErr}</Alert> : ""}
          </div>
          {isCashErr ? <Alert variant="danger">{isCashErr}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button
            variant="primary"
            onClick={() => {
              createStockLog();
            }}
          >
            {_("panel.save")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.variantsStockHistory")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {ProductsStockData ? (
            <ProductsStockTableRow
              payments_list={ProductsStockData}
              handleLoader={handleLoader}
              handleShow={handleShow}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
const label_input = (
  label,
  newWarehouseName,
  handleNewWarehouseName,
  disabled = false
) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        disabled={disabled}
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
      />
    </div>
  );
};
export default ProductsStockTable;
