import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import StoreTableLabels from "./StoreTableLabels";
import IconsGen from "../../icons_generator/IconsGen";
import Loader from "../../loader/Loader";
import AxiosController from "../../authentication/AxiosController";

const MyStoresTableRow = ({ stores_list, fetchStores }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");

  const [loading, handleLoader] = useState(false);
  const [deleteErr, setDeleteErr] = useState(false);
  const delete_store = (id) => {
    handleLoader(true);
    AxiosController.delete(`api/${slug}/${lang}/store/delete/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        fetchStores();  handleLoader(false);
      })
      .catch((e) => {
        setDeleteErr(e.response.data.error ? e.response.data.error.store : '');
        handleLoader(false);
      });
  }

  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <Loader active={loading}/>
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <StoreTableLabels />
        </thead>
        <tbody>
          {stores_list.map((store) => {
            const dateSegments = store["created_at_formatted"].split(" ");

            return (
              <tr className="">
                <td scope="row">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td>
                  {store["name"]} <br /> ID#{store["id"]}{" "}
                </td>
                <td><a className="fs-5" style={{color:"rgb(243, 168, 55)"}} href={'https://'+store["subdomain"]+".tejarty.store"} target="_blank">{_('panel.visit')}</a></td>

                <td onDoubleClick={()=> {
                  delete_store(store['id']);
                }}>{<IconsGen icon_name={"delete"} />} <br/> {deleteErr ? deleteErr : ''}</td>
                <NavLink to={`/${slug}/${lang}/panel/store/${store['id']}`}>
                  <td>{<IconsGen icon_name={"enter"} />}</td>
                </NavLink>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MyStoresTableRow;
