import React from "react";
import { NavLink, useParams } from "react-router-dom";

const ProductSearchItem = ({ list, cart, handleCart, handleSearchResults }) => {
  const { slug, lang } = useParams();

  return (
    <div
      className="search-results position-absolute w-100 d-none flex-column gap-2 d-md-flex rounded-3"
      style={{
        left: 0,
        top: "100%",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
        zIndex: "1000",
        maxHeight: "325px",
        overflow: "scroll",
      }}
    >
      {list.map((product, index) => {
        return (
          <div
            className="productItem px-2 py-3 d-flex gap-3 align-items-center cursor-pointer"
            style={{
              borderBottom:
                index != list.length - 1
                  ? "1px solid rgba(239, 237, 237, 1)"
                  : "",
              cursor: "copy",
            }}
            onClick={() => {
              handleSearchResults([]);
              let already = false;
              cart.forEach((element) => {
                already = element.variant_id == product.variant_id;
              });
              if (!already) {
                handleCart([
                  ...cart,
                  {
                    id: "None" + index,
                    product_id: product.id,
                    variant_id: product.variant_id,
                    total_base_commissions: product.price,
                    total_commissions: product.price,
                    thumbnail: product.thumbnail,
                    quantity: 1,
                    status: "Pending",
                  },
                ]);
                already = false;
              }
            }}
          >
            <div className="thumbnail">
              <img
                src={product.thumbnail}
                width={"70px"}
                className="rounded-3"
              />
            </div>
            <div className="info">
              <p style={{ color: "rgb(89, 198, 201)" }}>{product.title}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductSearchItem;
