import React, { useEffect, useState } from "react";
import "./VariantsSelector.css";
import { useTranslation } from "react-i18next";

const VariantsSelector = ({
  variants,
  selectedVariant,
  setSelectedVariant,
}) => {
  const [selectedColor, setSelectedColor] = useState(selectedVariant.color);
  const [selectedSize, setSelectedSize] = useState(selectedVariant.size);
  const [_] = useTranslation("global");
  useEffect(() => {}, [selectedVariant]);
  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex gap-2">
        {variants.length && variants[0].color != '' ? (<p className="color">{_("panel.color")}</p>) : ''}

        {Array.from(
          new Set(
            variants.map((variant) =>
              variant.color && variant.color != "" ? variant.color : null
            )
          )
        ).map((variant) => {
          return variant ? (
            <>
              <div
                className="color-box"
                style={{
                  background: variant,
                  border:
                    selectedVariant.color == variant
                      ? "3.2px solid rgb(89, 198, 201)"
                      : "3.2px solid rgba(239, 237, 237, 1)",
                }}
                onClick={() => {
                    setSelectedVariant(variants.filter((v) => {
                        return (v.color == variant);
                    })[0]);
                    setSelectedColor(variant)
                }}
              >

                {variant}
              </div>
            </>
          ) : (
            ""
          );
        })}
      </div>
      <div className="d-flex gap-2">
        {variants.length && variants[0].size != '' ? (<p className="color">{_("panel.size")}</p>) : ''}

        {Array.from(
          new Set(
            variants.map((variant) =>
              variant.size && variant.size != "" && variant.color == selectedColor ? variant.size : null
            )
          )
        ).map((variant) => {
          return variant ? (
            <>
              <div
                className="color-box"
                style={{
                  border:
                    selectedVariant.size == variant
                      ? "3.2px solid rgb(89, 198, 201)"
                      : "3.2px solid rgba(239, 237, 237, 1)",
                }}
                onClick={() => {
                    setSelectedVariant(variants.filter((v) => {
                        return (v.size == variant && v.color == selectedColor);
                    })[0]);
                    setSelectedSize(variant)
                }}
              >
                {variant}
              </div>
            </>
          ) : (
            ""
          );
        })}
      </div>
    </div>
  );
};

export default VariantsSelector;
