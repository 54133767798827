import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import AxiosController from "../authentication/AxiosController";
import { notifyError } from "../toastify/toastify";

const SuppliersAddAndUpdate = ({show,setShow,id,update,get_warehouses,data,}) => {  
  
  const { slug, lang, updateId } = useParams();
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [handling, setHandling] = useState(0);
  const [_] = useTranslation("global");
  const [bank, setBank] = useState("");
  const [banks, setBanks] = useState([]);
  const [labels, setLabels] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState("");

  const [label, setLabel] = useState("");
  const [notes, setNotes] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");

 
  useEffect(() => {
    if (data) {
      setHandling(data?.amount);
      setNotes(data?.notes);
      setBank(data?.account_id);
      const formattedDate = formatDateString(data.received_at_formatted);
      setDateOfBirth(formattedDate);      
      setLabel(data?.expense_label_id);
    }
  }, [data]);
  const formatDateString = (dateString) => {
    const [day, month, year] = dateString.split(' ')[0].split('\\');
    return `${year}-${month}-${day}`;
  };
  const createOrUpdateWarehouse = () => {
    handleLoader(true);
    const url = update
      ? `api/${slug}/${lang}/expenses/update/${id}`
      : `api/${slug}/${lang}/expenses/create`;
    const method = update ? "put" : "post";

    AxiosController[method](
      url,
      {
        expense_label: label,
        amount: handling,
        notes: notes,
        spent_at: dateOfBirth,
        affected_user_id :employee ,
        account_id: bank,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => { 
        get_warehouses();  
        handleClose(); 
      })
      .catch((e) => { 
        if (e.response && e.response.data && typeof e.response.data.error === 'object') { 
            Object.entries(e.response.data.error).forEach(([key, value]) => {
              if (Array.isArray(value)) {
                value.forEach((err) => {
                  notifyError(`${key}: ${err}`);
                });
              } else {
                notifyError(`${key}: ${value}`);
              }
            });
            
          } 
        handleLoader(false);
      })
  };
  const get_labels = () => {
    AxiosController.get(`api/${slug}/${lang}/expenses/labels`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setLabels(res.data);
        handleLoader(false);
      })
      .catch((e) => {   handleLoader(false);});
  };
  const get_employees = () => {
    AxiosController.get(`api/${slug}/${lang}/search/get-user/1?role=employee&is_active=1&all=1`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setEmployees(res.data.users);
        handleLoader(false);
      })
      .catch((e) => {   handleLoader(false);});
  };
  const get_shipping_costs = () => {
    AxiosController.get(`api/${slug}/${lang}/accounts/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setBanks(res.data);
        handleLoader(false);
      })
      .catch((e) => {   handleLoader(false);});
  };
  const handleClose = () => {
    setShow(false);
    setNotes("");
    setLabel([]);
    setDateOfBirth();
    setHandling(''); 
    setBank([]);  
  }; 
  useEffect(() => {
    get_labels();
    if(label == 4){ get_employees() }
    get_shipping_costs(); 
  }, [label]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="flex-grow-1">
          {update ? _("panel.update") : _("panel.add")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label htmlFor="bankSelect" className="form-label">
            {_("panel.thebank")}
          </label>
          <select
            className="form-select"
            id="bankSelect"
            onChange={(e) => setBank(e.target.value)}
            value={bank}
          >
            <option value={0}></option>
            {banks.map((bank) => {
              return <option value={bank.id}>{bank.name}</option>;
            })}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="labelSelect" className="form-label">
            {_("panel.label")}
          </label>
          <select
            className="form-select"
            id="labelSelect"
            onChange={(e) => setLabel(e.target.value)}
            value={label} >

            <option value={0}></option>
            {labels.map((label) => {
              return <option value={label.id}>{label.title}</option>;
            })}
          </select>
        </div>
      {label == 4 && !update && <div className="mb-3">
          <label htmlFor="employee" className="form-label">
            {_("panel.employee")}
          </label>
          <select
            className="form-select"
            id="employee"
            onChange={(e) => setEmployee(e.target.value)}
            value={employee}
          >
            <option value={0}></option>
            {employees && employees.length > 0 && employees.map((ele) => {
              return <option value={ele?.id}>{ele?.first_name} {ele?.last_name}</option>;
            })}
          </select>
        </div>}   
        {labelInput(_("panel.paymentamount"), handling, setHandling)}
        {labelInput(_("panel.notes"), notes, setNotes)}
        <div className="mb-3">
          <label htmlFor="receiptDate" className="form-label">
            {_("panel.spentDate")}
          </label> 
            <input type="date"    
            onChange={(e) => setDateOfBirth(e.target.value)}
            value={dateOfBirth} className="form-control col-4" />
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button variant="primary" onClick={createOrUpdateWarehouse}>
          {_("panel.save")}
        </Button>
        <Button variant="danger" onClick={handleClose}>
          {_("panel.cancel")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SuppliersAddAndUpdate;

const labelInput = (label, value, onChange) => (
  <div className="mb-3">
    <label className="form-label">{label}</label>
    <input
      type="text"
      className="form-control py-2"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);
