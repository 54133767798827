import "./style.css";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Pagination, Form } from "react-bootstrap";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import OrderTableRow from "./OrderTableRow";
import SearchComponent from "./SearchComponent";
import ExcelExport from "./excel components/ExcelExport";
import ProtectedSidebarRoute from "../../../authentication/ProtectedSidebarRoute";
import EmployeeTypes from "../../../authentication/EmployeeTypes";
import ConfUsers from "./ConfUsers";
import Roles from "../../../authentication/Roles";
import CourierAssign from "./CourierAssign";
import AllStatus from "../../../authentication/AllStatus";
import ConfirmModal from "../../../modals/ConfirmModal";
import ViewModal from "../../../modals/ViewModal";
import Manifest from "./manifest/Manifest";
import LinkedManifest from "./manifest/LinkedManifest";
import LinkModal from "../../../modals/LinkModal";
import { Helmet } from "react-helmet";

const fetchOrders = (
  slug,
  lang,
  token,
  handleOrdersData,
  page,
  handleLoader,
  order_number,
  customer_phone,
  product_code,
  sku,
  start_date,
  end_date,
  status,
  marketer_id,
  confirmation_user,
  govs,
  couriers,
  courier_track,
  tracks,
  show_all = false,
  customer_phone_order,
  start_date2,
  end_date2,
  postponed,
  reference_order,
  setDisplayedOrders,
  per_page
) => {
  AxiosController.get(`api/${slug}/${lang}/get-sellings-orders/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      order_number,
      customer_phone,
      product_code,
      sku,
      start_date,
      end_date,
      status,
      marketer_id,
      confirmation_user,
      govs,
      couriers,
      courier_track,
      tracks,
      show_all: show_all ? 1 : 0,
      start_date2,
      end_date2,
      postponed,
      reference_order,
      per_page,
    },
  })
    .then((res) => {
      handleOrdersData(res.data);
      setDisplayedOrders(res.data.orders.data);
      handleLoader(false);
    })
    .catch((e) => {
      handleLoader(false);
    });
};
const Orders = ({ customer_phone_order = "" }) => {
  const { slug, lang } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  const changeParam = (param, value) => {
    setPerPage(value);
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };
  const [confirmationUsers, setConfirmationUsers] = useState([]);
  const [setCouriers, setSetCouriers] = useState(false);
  const [unAssign, setUnAssign] = useState(false);
  const [Couriers, handleCouriers] = useState([]);

  const fetchConfs = () => {
    AxiosController.get(`api/${slug}/${lang}/call-center/get-emp`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleLoader(false);
        setConfirmationUsers(res.data);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const [ordersData, handleOrdersData] = useState();
  const [page, handlePage] = useState(urlParams.get("page") || 1);
  const [loading, handleLoader] = useState(false);
  const [search, handleSearch] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [unupdatedOrders, setUnupdatedOrders] = useState([]);
  const [orders_products, setOrdersProducts] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [linkViewModal, setLinkViewModal] = useState(false);
  const [unAssignText, setUnAssignText] = useState(false);
  const [courier_id, setCourierId] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [setStatus, setSetStatus] = useState(false);
  const [manifest, setManifest] = useState(false); 
  const [settle, setSettle] = useState(false);
  const [showConfirmModalStatus, setShowConfirmModalStatus] = useState(false);
  const [linkMessages, setLinkMessages] = useState([]);
  const [linkManifest, setLinkManifest] = useState([]);
  const [displayedOrders, setDisplayedOrders] = useState([]);
  const [perPage, setPerPage] = useState("");

  const fetchingOrdersHandler = useCallback(
    (show_all = false) => {
      handleLoader(true);
      const nextPage = parseInt(urlParams.get("page"), 10) || 1;
      const order_number = urlParams.get("order_number") || "";
      const customer_phone = urlParams.get("customer_phone") || "";
      const product_code = urlParams.get("product_code") || "";
      const sku = urlParams.get("sku") || "";
      const start_date = urlParams.get("start_date") || "";
      const end_date = urlParams.get("end_date") || "";
      const couriers = urlParams.get("couriers") || "";
      const start_date2 = urlParams.get("start_date2") || "";
      const end_date2 = urlParams.get("end_date2") || "";
      const status = urlParams.get("status") || "";
      const marketer_id = urlParams.get("marketer_id") || "";
      const confirmation_user = urlParams.get("confirmation_user") || "";
      const govs = urlParams.get("govs") || "";
      const courier_track = urlParams.get("courier_track") || "";
      const tracks = urlParams.get("tracks") || "";
      const postponed = urlParams.get("postponed") || "";
      const reference_order = urlParams.get("reference_order") || "";
      const per_page = urlParams.get("per_page") || "";
      handlePage(nextPage);
      
      // setSelectedOrders([]);

      fetchOrders(
        slug,
        lang,
        token,
        handleOrdersData,
        nextPage,
        handleLoader,
        order_number,
        customer_phone,
        product_code,
        sku,
        start_date,
        end_date,
        status,
        marketer_id,
        confirmation_user,
        govs,
        couriers,
        courier_track,
        tracks,
        show_all ?? false,
        customer_phone_order,
        start_date2,
        end_date2,
        postponed,
        reference_order,
        setDisplayedOrders,
        per_page
      );
    },
    [customer_phone_order, lang, slug, token, urlParams]
  );
                  

  const handleStatusChange = (status) => {
    setUpdatedStatus(status);
    setShowConfirmModalStatus(true);
  };
  const changeStatusBulk = useCallback(() => {
    handleLoader(true);
    AxiosController.post(
      `api/${slug}/${lang}/orders/change-orders-bulk`,
      {
        orders_id: selectedOrders.map((order) => {
          return order.id;
        }),
        status: updatedStatus,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false);
        setSetStatus(false);
        fetchingOrdersHandler();
       

      })
      .catch((e) => {
        handleLoader(false);
      });
  }, [fetchingOrdersHandler, lang, selectedOrders, slug, token]);

  const fetchCouriers = () => {
    AxiosController.get(`api/${slug}/${lang}/couriers/get-all-couriers/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleLoader(false);
        handleCouriers(res.data);
        setSetCouriers(true); 
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  const linkOrdersCourier = useCallback(() => {
    handleLoader(true);
    AxiosController.post(
      `api/${slug}/${lang}/orders/change-courier-bulk/`,
      {
        order_ids: selectedOrders.map((order) => {
          return order.id;
        }),
        courier_id,
        status: "Shipped",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false);
        setLinkMessages(res?.data);
        setLinkManifest(res?.data?.manifest);
        setUnupdatedOrders(res?.data?.unupdated_orders);
        setLinkViewModal(true);
        setSetCouriers(false);
        fetchingOrdersHandler();
        setSelectedOrders([]);
      })
      .catch((e) => {
        handleLoader(false);
      });
  }, [courier_id, fetchingOrdersHandler, lang, selectedOrders, slug, token]);

  
  const unAssignCourier = useCallback(() => {
    handleLoader(true);
    AxiosController.put(
      `api/${slug}/${lang}/orders/unattach-courier/`,
      {
        orders_id: selectedOrders.map((order) => {
          return order.id;
        }),
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false);
        setUnAssignText(true);
        setUnupdatedOrders(res?.data?.unupdated_orders); 
        setSetCouriers(false);
        fetchingOrdersHandler();
        setShowViewModal(true);
      })
      .catch((e) => {
        handleLoader(false);
      });
  }, [courier_id, fetchingOrdersHandler, lang, selectedOrders, slug, token]);
  
  const handleShowModal = () => {
    setShowConfirmModal(true);
  };

  useEffect(() => {
    handleLoader(true);
    fetchingOrdersHandler();
    fetchConfs();
  }, [token, slug, lang, location.search]);
   return (
    <>
      <div
        className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
        style={{ width: "100%" }}
      > <Helmet>
      <title>{_("panel.orders")}</title> 
   </Helmet>
        <Loader active={loading} />
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.orders")}
          </h3>
          <Button
            className="fs-5 px-5 w-25"
            style={{
              backgroundColor: "rgb(89, 198, 201)",
              border: "none",
              borderRadius: "7px",
            }}
            onClick={() => {
              handleSearch(!search);
            }}
          >
            {_("panel.search")}
          </Button>
        </div>

        {search && <SearchComponent ConfUsers={confirmationUsers} />  }

        <ProtectedSidebarRoute
          empType={employee_type}
          empTypes={[
            EmployeeTypes["OPERATION_EMPLOYEE"],
            EmployeeTypes["FINANCIALS_EMPLOYEE"],
          ]}
          roles={[Roles["EMPLOYEE"]]}
          component={ConfUsers}
          role={role}
          selectedOrders={selectedOrders}
          confirmationUsers={confirmationUsers}
          fetchingOrdersHandler={fetchingOrdersHandler}
        />
        <ProtectedSidebarRoute
          role={role}
          roles={[Roles["ADMIN"]]}
          component={ConfUsers}
          selectedOrders={selectedOrders}
          confirmationUsers={confirmationUsers}
          fetchingOrdersHandler={fetchingOrdersHandler}
        />
        <div
          className="tables-container d-flex flex-column px-2"
          style={{ position: "relative", flexGrow: 3 }}
        >
          <div
            className={`header d-flex flex-column flex-lg-row ${
              lang === "en" ? "flex-row" : "flex-row"
            } gap-3 align-items-center justify-content-between `}
          >
            <ProtectedSidebarRoute
              role={role}
              roles={[Roles["ADMIN"]]}
              component={CourierAssign}
              fetchCouriers={fetchCouriers}
              setSetStatus={setSetStatus}
              setUnAssign={setUnAssign}
              setManifest={setManifest}
              setSettle={setSettle}
              selectedOrders={selectedOrders}
            />

            <ProtectedSidebarRoute
              role={role}
              roles={[Roles["EMPLOYEE"]]}
              empTypes={[
                EmployeeTypes["CONFIRMATION_EMPLOYEE"],
                EmployeeTypes["OPERATION_EMPLOYEE"],
              ]}
              empType={employee_type}
              component={CourierAssign}
              fetchCouriers={fetchCouriers}
              setSetStatus={setSetStatus}
              setUnAssign={setUnAssign}
              setManifest={setManifest}
              setSettle={setSettle}
              selectedOrders={selectedOrders}
            />
         <ProtectedSidebarRoute
            role={role}
            roles={[Roles["ADMIN"]]}
            component={Manifest}
            selectedOrders={selectedOrders} 
          />
           <ProtectedSidebarRoute
            empTypes={[EmployeeTypes["OPERATION_EMPLOYEE"]]}
            roles={[Roles["EMPLOYEE"]]}
            component={Manifest}
            role={role} 
            selectedOrders={selectedOrders}/>
            <ExcelExport
              selectedOrders={selectedOrders}
              orders_products={orders_products}
              fetchingOrdersHandler={fetchingOrdersHandler}
            />
            
          </div>
          {showConfirmModal && (
            <ConfirmModal
              showModal={showConfirmModal}
              setShowModal={setShowConfirmModal}
              action={linkOrdersCourier}
              modalBody="linkOrdersCourierModalBody"
              modalHeader="linkOrdersCourierModalHeader"
              confirmButton="confirm"
            />
          )}
          {unAssign && (
            <ConfirmModal
              showModal={unAssign}
              setShowModal={setUnAssign}
              action={unAssignCourier}
              modalBody="unlinkOrdersCourierModalBody"
              modalHeader="unlinkOrdersCourierModalHeader"
              confirmButton="confirm"
            />
          )}
          {showViewModal && (
            <ViewModal
              showModal={showViewModal}
              setShowViewModal={setShowViewModal}
              data={unupdatedOrders}
              setUnupdatedOrders={setUnupdatedOrders}
              modalHeader={
                unAssignText
                  ? "unassignOrdersModalHeader"
                  : "unupdatedOrdersModalHeader"
              }
            />
          )}
          {linkViewModal && (
            <LinkModal
              setLinkMessages={setLinkMessages}
              linkMessages={linkMessages}
              showModal={linkViewModal}
              setShowViewModal={setLinkViewModal}
              modalHeader={"shippingCompaniesLink"}
            />
          )}

          {setCouriers &&
            <Form.Select
              onChange={(e) => {
                handleShowModal();
                setCourierId(e.target.value);
              }}
              // value={confUser}

              className="mt-2"
            >
              <option selected={true} disabled>{_("panel.courierAssign")}</option>
              {Couriers.map((courier) => {
                return (
                  <option selected={false} value={courier["id"]}>
                    {courier["name"]}
                  </option>
                );
              })}
            </Form.Select> }
          {setStatus && (
            <>
                
              <Form.Select
                onChange={(e) => {
                  handleStatusChange(e.target.value);
                }}
                // value={confUser}
                className="mt-2"
              >
                <option selected={true}></option>
                {AllStatus.map((courier) => {
                  return (
                    <option selected={false} value={courier}>
                      {_(`panel.${courier}`)}
                    </option>
                  );
                })}
              </Form.Select>
              <ConfirmModal
                showModal={showConfirmModalStatus}
                setShowModal={setShowConfirmModalStatus}
                action={changeStatusBulk}
                modalBody={`${updatedStatus}`}
                modalHeader="changeStatusBody"
                confirmButton="confirm"
              />
            </>
          )}
          
          <div className="row d-flex justify-content-end">
              
            <div className="mt-4 col-lg-3 col-md-4 mb-2">
              <Form.Group
                className="flex-grow-1"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Select
                  onChange={(e) => changeParam("per_page", e.target.value)}
                  value={perPage}
                >
                  <option value="" disabled>
                    {_("panel.numOfRows")}
                  </option>
                  <option value="15" key="15">
                    15
                  </option>
                  <option value="30" key="30">
                    30
                  </option>
                  <option value="45" key="45">
                    45
                  </option>
                  <option value="100" key="100">
                    100
                  </option>
                  <option value="500" key="500">
                    500
                  </option>
                  <option value="1000" key="1000">
                    1000
                  </option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <div className="footer d-flex flex-column flex-grow-1">
            {ordersData ? (
              <OrderTableRow
                alt_name={ordersData["alt_name"]}
                orders_list={ordersData["orders"]["data"]}
                handleLoader={handleLoader}
                setSelectedOrders={setSelectedOrders}
                displayedOrders={displayedOrders}
                selectedOrders={selectedOrders}
                allOrders={Object.assign({}, ordersData)}
                orders_products={orders_products}
                setOrdersProducts={setOrdersProducts}
              />
            ) : (
              ""
            )}
          </div>
          {ordersData &&
            <Pagination
              className="m-auto flex-wrap"
              dir={lang === "ar" ? "ltr" : ""}
            >
              <Pagination.First onClick={() => changeParam("page", 1)} />
              <Pagination.Prev
                onClick={() =>
                  page - 1 > 0 ? changeParam("page", page - 1) : 1
                }
              />
              {ordersData["orders"]["links"].map((element, index, arr) => {
                if (index == 0 || arr.length - 1 == index) {
                  return "";
                } else {
                  return (
                    <Pagination.Item
                      active={element["active"] ? true : false}
                      onClick={(e) =>
                        element["label"] != "..."
                          ? changeParam("page", parseInt(element["label"]))
                          : ""
                      }
                    >
                      {element["label"]}
                    </Pagination.Item>
                  );
                }
              })}
              <Pagination.Next onClick={() => changeParam("page", page + 1)} />
              <Pagination.Last
                onClick={() =>
                  changeParam("page", ordersData["orders"]["last_page"])
                }
              />
            </Pagination>
        }
        </div>
      </div>
      {/* {manifest &&
        <>
          <ProtectedSidebarRoute
            role={role}
            roles={[Roles["ADMIN"]]}
            component={Manifest}
            selectedOrders={selectedOrders} 
          />
          <ProtectedSidebarRoute
            empTypes={[EmployeeTypes["OPERATION_EMPLOYEE"]]}
            roles={[Roles["EMPLOYEE"]]}
            component={Manifest}
            role={role} 
            selectedOrders={selectedOrders}
          />
        </> } */}
      
       {linkManifest.length > 0 && <> <ProtectedSidebarRoute
            role={role}
            roles={[Roles["ADMIN"]]}
            component={LinkedManifest} 
            linkManifest={linkManifest}
          />
          <ProtectedSidebarRoute
            empTypes={[EmployeeTypes["OPERATION_EMPLOYEE"]]}
            roles={[Roles["EMPLOYEE"]]}
            component={LinkedManifest}
            role={role}
            linkManifest={linkManifest} 
          />
        </> 
       }   
    </>
  );
};

export default Orders;
