import "./products table/style.css";
import React, { useEffect, useState } from "react";
import {   useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
import AxiosController from "../../authentication/AxiosController";
import Loader from "../../loader/Loader";
import { Button, Pagination } from "react-bootstrap";
import PaymentRequestsTableRow from "./products table/PaymentRequestsTableRow";
import SearchComponent from "./products table/SearchComponent";
import { Helmet } from "react-helmet";

const fetchPaymentRequests = (
  slug,
  lang,
  token,
  handleOrdesData,
  page,
  handleLoader,
  code,
  user_id,
  start_date,
  end_date,
  status,
  name,
  sysCommission,
  sellerCommission,
  sku,
  category_id
) => {
  AxiosController.get(`api/${slug}/${lang}/latest-products/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      code,
      user_id,
      start_date,
      end_date,
      status,
      name,
      sysCommission,
      sellerCommission,
      sku,
      category_id
    },
  })
    .then((res) => {
      handleOrdesData(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};
const ProductsTable = () => {
  const { slug, lang } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };

  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [paymentRequests, handlePaymentRequests] = useState();
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);
  const [search, handleSearch] = useState(false);
  const fetchPaymentRequestsHandler = () => {
    handleLoader(true);
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    handlePage(nextPage);
    const code = urlParams.get("code") || "";
    const user_id = urlParams.get("user_id") || "";
    const start_date = urlParams.get("start_date") || "";
    const end_date = urlParams.get("end_date") || "";
    const status = urlParams.get("status") || "";
    const name = urlParams.get("name") || "";
    const sysCommission = urlParams.get("sysCommission") || "";
    const sellerCommission = urlParams.get("sellerCommission") || "";
    const sku = urlParams.get("sku") || "";
    const category_id = urlParams.get("category_id") || "";
    fetchPaymentRequests(
      slug,
      lang,
      token,
      handlePaymentRequests,
      nextPage,
      handleLoader,
      code,
      user_id,
      start_date,
      end_date,
      status,
      name,
      sysCommission,
      sellerCommission,
      sku,
      category_id
    );
  };
  useEffect(() => {
    fetchPaymentRequestsHandler();
  }, [token, slug, lang, location.search, handleLoader]);
  return (
    <div className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative">
         <Helmet>
   <title>{_("panel.allProducts")}</title> 
</Helmet>    <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <Button
          className="fs-5 px-5 w-25"
          style={{
            backgroundColor: "rgb(89, 198, 201)",
            border: "none",
            borderRadius: "7px",
          }}
          onClick={() => {
            handleSearch(!search);
          }}
        >
          {_("panel.search")}
        </Button>
        {search ? <SearchComponent /> : ""}
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.allProducts")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {paymentRequests ? (
            <PaymentRequestsTableRow
              alt_name={paymentRequests["alt_name"]}
              payments_list={paymentRequests["payment_requests"]}
              handleLoader={handleLoader}
              fetchPaymentRequestsHandler={fetchPaymentRequestsHandler}
            />
          ) : (
            ""
          )}
        </div>
        {paymentRequests ? (
          <Pagination className="m-auto flex-wrap">
            <Pagination.First onClick={() => changeParam("page", 1)} />
            <Pagination.Prev
              onClick={() => (page - 1 > 0 ? changeParam("page", page - 1) : 1)}
            />
            {paymentRequests["payment_requests"]["links"].map(
              (element, index, arr) => {
                if (index == 0 || arr.length - 1 == index) {
                  return "";
                } else {
                  return (
                    <Pagination.Item
                      active={element["active"] ? true : false}
                      onClick={(e) =>
                        element["label"] != "..."
                          ? changeParam("page", parseInt(element["label"]))
                          : ""
                      }
                    >
                      {element["label"]}
                    </Pagination.Item>
                  );
                }
              }
            )}
            <Pagination.Next onClick={() => changeParam("page", page + 1)} />
            <Pagination.Last
              onClick={() =>
                changeParam(
                  "page",
                  paymentRequests["payment_requests"]["last_page"]
                )
              }
            />
          </Pagination>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProductsTable;
