import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import { Pagination } from "react-bootstrap";
import ProductsStockTable from "./ProductsStockTable";
import Loader from "../../../loader/Loader";
import ProductStockDetails from "./ProductStockDetails"; 
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const get_products_stock = (
  slug,
  lang,
  token,
  handleProductsStock,
  page,
  handleLoader,
  productCode,
  sku,
  supplier,
  warehouse,
  startDate,
  endDate,
  status,
  is_cash
) => {
  AxiosController.get(
    `api/${slug}/${lang}/get-variants-stocks-history/${page}`,
    {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        product_code: productCode,
        sku,
        supplier,
        warehouse,
        start_date: startDate,
        end_date: endDate,
        status,
        is_cash,
      },
    }
  )
    .then((res) => {
      handleProductsStock(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const VariantsHistory = () => {
  const { slug, lang } = useParams();

  const token = localStorage.getItem("token");
  const [productsStock, handleProductsStock] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [showProduct, handleShowProduct] = useState(false);
  const [selectedProduct, handleSelectedProduct] = useState(0);
  const [page, handlePage] = useState(1);
  const location = useLocation();
    const [_] = useTranslation("global");

  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };
  const get_products_stock_handler = () => {
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    const productCode = urlParams.get("product_code") || "";
    const sku = urlParams.get("sku") || "";
    const supplier = urlParams.get("supplier") || "";
    const warehouse = urlParams.get("warehouse") || "";
    const startDate = urlParams.get("start_date") || "";
    const endDate = urlParams.get("end_date") || "";
    const status = urlParams.get("status") || "";
    const is_cash = urlParams.get("is_cash") || "";
    handlePage(nextPage);
    get_products_stock(
      slug,
      lang,
      token,
      handleProductsStock,
      page,
      handleLoader,
      productCode,
      sku,
      supplier,
      warehouse,
      startDate,
      endDate,
      status,
      is_cash
    );
  };
  useEffect(() => {
    handleLoader(true);
    get_products_stock_handler();
  }, [slug, lang, page, location.search]);

  return (
    <div
      className={`table-responsive tables-container my-3 d-flex flex-column position-relative ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >       
     <Helmet>
   <title>{_("panel.variantsStockHistory")}</title> 
</Helmet>
      <ProductStockDetails
        productId={selectedProduct}
        showComp={showProduct}
        handleShowComp={handleShowProduct}
      />
      <Loader active={loading} />
      <ProductsStockTable
        ProductsStockData={productsStock.data}
        showProduct={handleShowProduct}
        productSelector={handleSelectedProduct}
        get_products_stock_handler={get_products_stock_handler}
      />

      {productsStock["links"] ? (
        <Pagination
          className="m-auto flex-wrap"
          dir={lang === "ar" ? "ltr" : "rtl"}
        >
          <Pagination.First onClick={() => changeParam("page", 1)} />
          <Pagination.Prev
            onClick={() => (page - 1 > 0 ? changeParam("page", page - 1) : 1)}
          />
          {productsStock["links"].map((element, index, arr) => {
            if (index == 0 || arr.length - 1 == index) {
              return "";
            } else {
              return (
                <Pagination.Item
                  active={element["active"] ? true : false}
                  onClick={(e) =>
                    element["label"] != "..."
                      ? changeParam("page", parseInt(element["label"]))
                      : ""
                  }
                >
                  {element["label"]}
                </Pagination.Item>
              );
            }
          })}
          <Pagination.Next onClick={() => changeParam("page", page + 1)} />
          <Pagination.Last
            onClick={() => changeParam("page", productsStock["last_page"])}
          />
        </Pagination>
      ) : (
        ""
      )}
    </div>
  );
};

export default VariantsHistory;
