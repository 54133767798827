import React from "react";
import { useTranslation } from "react-i18next";

const PlaceCartItem = ({ title, thumbnail, quantity, altName, price }) => {
  const [_] = useTranslation("global");
  return (
    <div className="sku_info d-flex flex-sm-row gap-2 align-items-center">
      <img
        src={thumbnail}
        width="30px"
        style={{ flexGrow: 1, borderRadius:'10px' }}
        alt=""
      />
      <div className="sku_info_text flex-grow-1">
        <p style={{ color: "rgba(23, 43, 77, 1)" }}>{title}</p>
        <p>عدد {quantity}</p>
      </div>
      <div
        className="sku_info_text flex-grow-1"
        style={{ color: "rgba(89, 198, 201, 1)" }}
      >
        <p>{_('home.price')}</p>
        <p>
          {price} {altName}
        </p>
      </div>
    </div>
  );
};

export default PlaceCartItem;
