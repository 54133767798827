import React, { useEffect, useState } from "react";
import { Card, Pagination } from "react-bootstrap";
import { Link, Navigate, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen";
import AxiosController from "../../../authentication/AxiosController";
import { useTranslation } from "react-i18next";
import Loader from "../../../loader/Loader";
import { Helmet } from "react-helmet";
function trimStart(text, prefix) {
  if (text.startsWith(prefix)) {
    return text.substring(prefix.length).trimStart();
  }
  return text;
}

const get_control_panel_latest_products = (
  slug,
  lang,
  token,
  handleProdutsData,
  handleLoader,
  page,
  handleAltName,
  dates = {}
) => {
  AxiosController.get(`api/${slug}/${lang}/get-favorites/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleProdutsData(res.data[0]);
      handleAltName(res.data[1]);
      handleLoader(false);
    })
    .catch((e) => {});
};



const Favorites = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [productsData, handleProdutsData] = useState({});
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);
  const [altName, handleAltName] = useState("");
  const [_] = useTranslation("global");
  useEffect(() => {
    handleLoader(true);
    get_control_panel_latest_products(
      slug,
      lang,
      token,
      handleProdutsData,
      handleLoader,
      page,
      handleAltName
    );
  }, [slug, lang, token, handleProdutsData, handleLoader, page, handleAltName]);


  const addToCart = (prodcut_id) => {
    AxiosController.post(
      `api/${slug}/${lang}/add-to-cart/${prodcut_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        document.getElementById("ecomboCart").click();
      })
      .catch((e) => {
        Navigate(`/${slug}/${lang}/product/${prodcut_id}`);
      });
  };
  const addToFav = (prodcut_id) => {
    AxiosController.post(
      `api/${slug}/${lang}/add-to-fav/${prodcut_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {})
      .catch((e) => {
        Navigate(`/${slug}/${lang}/product/${prodcut_id}`);
      });
  };

  return (
    <div className="d-flex flex-column gap-4 position-relative w-100">
        <Loader active={loading} />
        <Helmet>
   <title>{_("panel.fav")}</title> 
</Helmet>
      <div className="d-flex flex-wrap ">
        {productsData.data
          ? productsData.data.map((product) => {
              return (
                <div className="p-2 col-6 col-md-4 col-lg-3">
                  <Card
                    className="pt-3 px-2 mr-2"
                    style={{
                      flexGrow: 1,
                      paddingRight: "100px",
                      minHeight: "100%",
                      minWidth:'150px'
                    }}
                    dir="rtl"
                  >
                    <Link to={`/${slug}/${lang}/product/${product.id}`}>
                      <Card.Img
                        src={product.thumbnail}
                        className="card-img-top px-1"
                        style={{ borderRadius: "10px" }}
                      />
                    </Link>
                    <Card.Body className="px-1">
                      <Card.Title className="d-flex flex-column flex-sm-column flex-md-row-reverse gap-2 justify-content-between align-items-center">
                        <div className="rating d-flex align-items-center justify-content-between flex-row-reverse">
                          <IconsGen icon_name="star" />
                          <p style={{ margin: 0 }}>4.5</p>
                        </div>
                        <p
                          className="title"
                          style={{ margin: 0, fontSize: "1.2rem" }}
                        >
                          {product.title.slice(0, 12) + "..."}
                        </p>
                      </Card.Title>

                    </Card.Body>
                    <Card.Body
                      className={`px-1 py-1 d-flex ${
                        lang === "en" ? "flex-row-reverse" : ""
                      } justify-content-between gap-5`}
                    >
                      <p>{_("home.price")}</p>
                      <p>
                        {product.price} {altName.split(" ")[0]}
                      </p>
                    </Card.Body>
                    <Card.Body
                      className={`px-1 py-1 pb-3 d-flex flex-column align-items-center flex-sm-column ${
                        lang === "en" ? "flex-md-row-reverse" : "flex-md-row"
                      } justify-content-between gap-2`}
                    >
                      <span
                        className="d-inline-block"
                        onClick={() => addToCart(product.id)}
                        id={product.id}
                      >
                        <button style={buttonStyles} className="addtocart">
                          {_("home.addtocart")}
                        </button>
                      </span>
                      <div
                        className={` ${
                          product.is_fav === "true" ? true : false
                        }`}
                        onClick={(e) => {
                          addToFav(product.id);
                          e.target.parentNode.classList.toggle("active");
                        }}
                      >
                        <IconsGen
                          icon_name={"heartCart"}
                          active={product.is_fav === "true" ? true : false}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            })
          : ""}
      </div>
      {productsData.data ? (
                <Pagination className="m-auto flex-wrap" dir="ltr">
                  <Pagination.First onClick={() => handlePage(1)}/>
                  <Pagination.Prev
                    onClick={() => (page - 1 > 0 ? handlePage(page - 1) : 1)}
                  />
                  {productsData.links.map((element, index, arr) => {
                      if (index == 0 || (arr.length -1) == index) {
                          return '';
                      }else {
      
                          return <Pagination.Item active={(element['active']) ? true : false} onClick={(e) => (element['label'] != '...') ? handlePage(parseInt(element['label'])) : ''}>{element['label']}</Pagination.Item>;
                      }
                  })}
                  <Pagination.Next onClick={() => handlePage(page + 1)} />
                  <Pagination.Last  onClick={() => handlePage(productsData['last_page'])}/>
                </Pagination>
              ) : (
                ""
              )}
    </div>
  );
};

const buttonStyles = {
  border: "none",
  padding: ".5rem .76rem",
  borderRadius: "7px",
};

export default Favorites;
