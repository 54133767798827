


export default class BarChartTemplate {
  constructor(labels) {
    this.labels = labels.map(value => value['title']);
    this.datasets = [{
      label: "عدد القطع",
      data: labels.map((value) => value['count']),
      backgroundColor: 'rgb(243, 168, 55)',
    }];
  }
}