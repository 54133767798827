import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../authentication/AxiosController";
import { notifySuccess, notifyError } from "../../toastify/toastify";

const TableSelectPending = ({ userId, handleLoader, pending, levelId, userRole }) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [orderStatus, handleOrderStatus] = useState();
  const { t } = useTranslation("global");  


  const currentStatus = pending === 1 ? t("panel.levelPending") : t("panel.Active"); 
  const statuses = [t("panel.Active"), t("panel.levelPending")];

  const StatusColors = {
    "Active": "rgb(28, 152, 47)",
    "Pending": "rgb(255, 165, 0)"
  };

  const changePendingStatus = () => {
    AxiosController.put(
      `api/${slug}/${lang}/level/pending-user/${userId}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        notifySuccess(t("panel.updateSuccessMsg"));
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
        if (e.response && e.response.data && e.response.data.errors) {
          const errors = e.response.data.errors;
          for (const key in errors) {
            if (errors[key].length) {
              errors[key].forEach((error) => notifyError(`${key}: ${error}`));
            }
          }
        } else {
          notifyError("An unexpected error occurred");
        }
      });
  };

  useEffect(() => {
    handleOrderStatus(currentStatus);
  }, [currentStatus]);

  return (
    <div
      className=""
      style={{
        width: "fit-content",
        minWidth: "100px",
        margin: "0 10px",
      }}
    >
 
        <Form.Select
          style={{
            border:pending === 0 ? `1px solid ${StatusColors["Active"]}`:`1px solid ${StatusColors["Pending"]}`,
            color: pending === 0 ? StatusColors["Active"]:StatusColors["Pending"],
          }}
          onChange={(e) => {
            handleLoader(true);
            changePendingStatus();
          }}
          disabled={userRole !== "affiliate"}
        >
          <option selected={true} value={currentStatus}>
            {currentStatus}
          </option>
          {statuses.map((status, index) => {
            if (status !== currentStatus) {
              return (
                <option key={index} value={status}>
                  {status}
                </option>
              );
            }
          })}
        </Form.Select> 
    </div>
  );
};

export default TableSelectPending;
