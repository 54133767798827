export default [
    'اس_كيو المنتج (SKU)',
    'COD', 
    'الكمية (Quantity)',
    'اسم العميل (Customer Name)',
    'المحافظة (Province)',
    'العنوان (Address)',
    'رقم الهاتف (Phone Number)',
    'رقم الهاتف 2 (Phone Number 2)',
    'ملاحظات (Notes)',
    'ملاحظات الادارة (notes)' 
  ];