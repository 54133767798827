import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Pagination } from "react-bootstrap";
import AxiosController from "../../authentication/AxiosController";
import Loader from "../../loader/Loader";
import IconsGen from "../../icons_generator/IconsGen";
import MyStoresTableRow from "./MyStoresTableRow";
import MyLandpagesTableRow from "./MyLandpagesTableRow";
import { Helmet } from "react-helmet";

const fetchStores = (
  slug,
  lang,
  token,
  handleOrdesData,
  page,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/landpage/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleOrdesData(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};
const MyLandpages = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [storesData, handleStoresData] = useState();
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);

  useEffect(() => {
    handleLoader(true);
    fetchStores(slug, lang, token, handleStoresData, page, handleLoader);
  }, [token, slug, lang, handleStoresData, page, handleLoader]);
  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    > <Helmet>
    <title>{_("panel.myLandpages")}</title> 
 </Helmet>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.myLandpages")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
        {storesData ? 
        (
          <MyLandpagesTableRow stores_list={storesData['data']} handleLoader={handleLoader}  />

        )
        : (
          ""
        )}
        </div>
        {storesData ? (
          <Pagination className="m-auto flex-wrap">
            <Pagination.First onClick={() => handlePage(1)}/>
            <Pagination.Prev
              onClick={() => (page - 1 > 0 ? handlePage(page - 1) : 1)}
            />
            {storesData["links"].map((element, index, arr) => {
                if (index == 0 || (arr.length -1) == index) {
                    return '';
                }else {

                    return <Pagination.Item active={(element['active']) ? true : false} onClick={(e) => (element['label'] != '...') ? handlePage(parseInt(element['label'])) : ''}>{element['label']}</Pagination.Item>;
                }
            })}
            <Pagination.Next onClick={() => handlePage(page + 1)} />
            <Pagination.Last  onClick={() => handlePage(storesData['last_page'])}/>
          </Pagination>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MyLandpages;
