import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination } from "react-bootstrap"; 
import AxiosController from "../../../authentication/AxiosController";
import AboutShippingTable from "./AboutShippingTable"
import Loader from "../../../loader/Loader";
import { Helmet } from "react-helmet";

const AboutShipping = () => {
  const { slug, lang } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [loading, handleLoader] = useState(false);
  const [page, handlePage] = useState(parseInt(urlParams.get("page")) || 1);
  const [data, setData] = useState(null);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };

  const getAboutShipping = () => {
    handleLoader(true);
    AxiosController.get(
      `api/${slug}/${lang}/orders/courier/report/cod/?page=${page}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setData(res.data);
        handleLoader(false);
      })
      .catch(() => {
        handleLoader(false);
      });
  };

  useEffect(() => {
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    handlePage(nextPage);
    getAboutShipping();
  }, [slug, lang, location.search]);

  return (
    <div
      className="position-relative tables-container"
      style={{ minHeight: "100px", width: "100%" }}
    ><Helmet>
    <title>{_("panel.aboutShipping")}</title> 
 </Helmet>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.aboutShipping")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {data && data.data && <AboutShippingTable data={data.data} />}
        </div>
        {data && data.links && (
          <Pagination className="m-auto flex-wrap" dir="ltr">
            <Pagination.First onClick={() => changeParam("page", 1)} />
            <Pagination.Prev
              onClick={() => page > 1 && changeParam("page", page - 1)}
            />
            {data.links.map((element, index) => {
              if (index === 0 || index === data.links.length - 1) {
                return null;
              }
              return (
                <Pagination.Item
                  key={index}
                  active={element.active}
                  onClick={() =>
                    element.label !== "..." && changeParam("page", parseInt(element.label))
                  }
                >
                  {element.label}
                </Pagination.Item>
              );
            })}
            <Pagination.Next
              onClick={() => page < data.last_page && changeParam("page", page + 1)}
            />
            <Pagination.Last
              onClick={() => changeParam("page", data.last_page)}
            />
          </Pagination>
        )}
      </div>
    </div>
  );
};

export default AboutShipping;
