import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AxiosController from "../authentication/AxiosController";
import ProductSearchItem from "./ProductSearchItem";

const SearchComponent = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [query, handleQuery] = useState("");
  const [searchResults, handleSearchResults] = useState([]);
  const Navigate = useNavigate();
  const product_search = (query) => {
    AxiosController.get(
      `api/${slug}/${lang}/serach/get-products-bar/${query}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleSearchResults(res.data);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    handleSearchResults([]);
  }, [Navigate]); 
  return (
<>   {token &&    <div className=" position-relative col-xl-8  col-md-6 col-12"><InputGroup className=" px-2 py-1  d-md-flex w-100">
        <FormControl
          placeholder={_("panel.search")}
          aria-label="Search"
          onInput={(e) => {
            handleQuery(e.target.value);
            if (e.target.value != "") {
              product_search(e.target.value);
            } else {
              handleSearchResults([]);
            }
          }}
        />
      </InputGroup>      {searchResults.length > 0 && <ProductSearchItem list={searchResults} />}
    </div>}  
</>
  );
};

export default SearchComponent;
