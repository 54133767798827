import React from "react";
import { useParams } from "react-router-dom";
import MarketerSearchComponent from "./search components/UsersSearchComponent";
import Roles from "../../authentication/Roles";
import ProtectedSidebarRoute from "../../authentication/ProtectedSidebarRoute";
import EmployeeTypes from "../../authentication/EmployeeTypes";
const SearchComponent = () => {
  const { slug, lang } = useParams();

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  return (
    <div>
      <ProtectedSidebarRoute
        role={role}
        roles={[Roles["ADMIN"]]}
        component={MarketerSearchComponent}
      />
      <ProtectedSidebarRoute
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empTypes={[
          EmployeeTypes["FINANCIALS_EMPLOYEE"],
          EmployeeTypes["OPERATION_EMPLOYEE"],
          EmployeeTypes["PRODUCTS_EMPLOYEE"],
        ]}
        empType={employee_type}
        component={MarketerSearchComponent}
      />
    </div>
  );
};

export default SearchComponent;
