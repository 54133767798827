import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AxiosController from "../../../components/authentication/AxiosController";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/loader/Loader";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import { Editor } from "primereact/editor";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import BaseServerUrl from "../../authentication/BaseServerUrl";
import IconsGen from "../../icons_generator/IconsGen";
import { Helmet } from "react-helmet";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const fetchStores = (slug, lang, token, handleStores, handleLoader) => {
  AxiosController.get(`api/${slug}/${lang}/all-stores`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleStores(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const Landpage = () => {
  const { slug, lang } = useParams();

  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const pond = useRef(null);
  const pond2 = useRef(null);

  const [loading, handleLoader] = useState(false);

  const [stores, handleStores] = useState([]);
  const acceptedFileTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/jpg",
  ];
  const [landpageName, handleLandpageName] = useState("");
  const [landpageSku, handleLandpageSku] = useState("");
  // const [landpageImgs, handleLandpageImgs] = useState([]);
  let landpageImgs2 = [];
  const [landpageDescription, handleLandpageDescription] = useState("");
  const [landpageLogo, handleLandpageLogo] = useState("");
  const [landpageProductTitle, handleLandpageProductTitle] = useState("");
  const [landpageStore, handleLandpageStore] = useState(0);
  const [landpageShipping, handleLandpageShipping] = useState("");
  const instanceObject = {
    beforePrice: 0,
    afterPrice: 0,
    quantity: 1,
    beforePriceErr: "",
    afterPriceErr: "",
    quantityErr: "",
  };
  const [landpageOffers, handleLandpageOffers] = useState([instanceObject]);
  const [landpageOffersErr, handleLandpageOffersErr] = useState("");
  const [landpageHasShipping, handleLandpageHasShipping] = useState(1);
  const [landpageNameErr, handleLandpageNameErr] = useState("");
  const [landpageSkuErr, handleLandpageSkuErr] = useState("");
  const [addedImgs, handleAddedImgs] = useState(0);
  const [uploadedLandpage, handleUploadedLandpage] = useState("");
  const [landpageStoreErr, handleLandpageStoreErr] = useState("");

  const [success, handleSuccess] = useState("");

  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);

  const create_landpage = () => {
    handleLoader(true);
    let valid = true;
    if (!landpageStore) {
      handleLandpageStoreErr(_("panel.invalidStore"));
    }

    if (landpageOffers.length == 0) {
      valid = false;

      handleLandpageOffersErr(_("panel.landpageOffersErr"));
    } else {
      handleLandpageOffersErr("");
    }
    if (valid && landpageHasShipping) {
    } else {
    }

    if (valid) {
      AxiosController.post(
        `api/${slug}/${lang}/landpage/create`,
        {
          store_id: landpageStore,
          sku: landpageSku,
          custom_description: landpageDescription,
          show_info: 1,
          title: landpageName,
          offers: landpageOffers,
          landpage_product_title: landpageProductTitle,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {
          handleUploadedLandpage(res.data.landpage_id);
          handleLandpageName("");
          handleLandpageSku("");
          handleLandpageProductTitle("");
          landpageImgs2 = [];
          handleLandpageDescription("");
          handleLandpageLogo("");
          handleLandpageStore(0);
          handleLandpageDescription("");
          handleLandpageShipping("");
          handleLandpageOffers([instanceObject]);
          handleLandpageHasShipping(1);
          handleLandpageSkuErr("");
          handleLandpageNameErr("");
          handleLandpageStoreErr("");
          handleLandpageOffersErr("");
          handleLandpageOffers(() => {
            pond2.current.processFiles().then(() => {
              handleSuccess(_("panel.landCreated"));
              handleLoader(false);
            });
            return landpageOffers.map((offer, index) => {
              let offerInstance = offer;
              offerInstance.afterPriceErr = "";
              offerInstance.beforePriceErr = "";
              offerInstance.quantityErr = "";
              return offerInstance;
            });
          });
        })
        .catch((e) => {
          handleLoader(false);
          handleLandpageSkuErr(
            e.response.data.error.variant
              ? e.response.data.error.variant[0]
              : ""
          );
          handleLandpageNameErr(
            e.response.data.error.title ? e.response.data.error.title[0] : ""
          );
          handleLandpageStoreErr(
            e.response.data.error.store_id
              ? e.response.data.error.store_id[0]
              : ""
          );
          handleLandpageOffersErr(
            e.response.data.error.offers ? e.response.data.error.offers[0] : ""
          );
          handleLandpageOffers(() => {
            return landpageOffers.map((offer, index) => {
              let offerInstance = offer;
              if (e.response.data.error[`offers.${index}.afterPrice`]) {
                offerInstance.afterPriceErr =
                  e.response.data.error[`offers.${index}.afterPrice`][0] ?? "";
              }
              if (e.response.data.error[`offers.${index}.beforePrice`]) {
                offerInstance.beforePriceErr =
                  e.response.data.error[`offers.${index}.beforePrice`][0] ?? "";
              }
              if (e.response.data.error[`offers.${index}.quantity`]) {
                offerInstance.quantityErr =
                  e.response.data.error[`offers.${index}.quantity`][0] ?? "";
              }
              return offerInstance;
            });
          });
        });
    } else {
      handleLoader(false);
    }
  };
  useEffect(() => {
    fetchStores(slug, lang, token, handleStores, handleLoader);
  }, [slug, lang, token]);
  return (
    <Container style={{ position: "relative" }}>
      <Loader active={loading} />
      <Helmet>
   <title>{_("panel.createLandpage")}</title> 
</Helmet>
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div className="add-header" dir={lang === "en" ? "ltr" : "rtl"}>
          <h2 className="mb-3" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.createLandpage")}
          </h2>
        </div>
        <div
          className="d-flex gap-5 flex-column-reverse flex-md-row"
          dir={lang === "en" ? "ltr" : "rtl"}
        >
          <div
            className="order-right-container d-flex flex-column"
            style={{ flex: "7" }}
          >
            <div className="order-right-entry">
              <div className="add-body1 d-flex flex-column flex-md-row gap-4 mb-4">
                <div
                  className="right-side d-flex flex-column"
                  style={{ flex: "3" }}
                >
                  <div className="right-head">
                    <h3>{_("panel.landpageDetails")}</h3>
                    <p>{_("panel.verifylandpage")}</p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.landpageTitle")}
                    </label>
                    {landpageNameErr ? (
                      <Alert variant={"danger"}>{landpageNameErr}</Alert>
                    ) : (
                      ""
                    )}
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={landpageName}
                      onInput={(e) => {
                        handleLandpageName(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      SKU
                    </label>
                    {landpageSkuErr ? (
                      <Alert variant={"danger"}>{landpageSkuErr}</Alert>
                    ) : (
                      ""
                    )}
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={landpageSku}
                      onInput={(e) => {
                        handleLandpageSku(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.productTitleLandpage")}
                    </label>

                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={landpageProductTitle}
                      onInput={(e) => {
                        handleLandpageProductTitle(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.landpageProductImgs")}
                    </label>

                    <FilePond
                      acceptedFileTypes={acceptedFileTypes}
                      onprocessfiles={() => {
                        setTimeout(() => {
                          setFiles2([]);
                          handleLoader(false);
                        }, 200);
                      }}
                      ref={pond2}
                      files={files2}
                      onupdatefiles={setFiles2}
                      allowMultiple={true}
                      maxFiles={3}
                      instantUpload={false}
                      server={{
                        url:
                          BaseServerUrl +
                          `/api/${slug}/${lang}/landpage/upload-landpage-images/${uploadedLandpage}`,
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                        process: {},
                      }}
                      name="image"
                      labelIdle={`${_(
                        "panel.draglandpage"
                      )} <span class="filepond--label-action">${_(
                        "panel.browse"
                      )}</span>`}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.landpageDescription")}
                    </label>
                    {/* {storeErr ? (
                      <Alert variant={"danger"}>{storeErr}</Alert>
                    ) : (
                      ""
                    )} */}
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Editor
                        value={landpageDescription}
                        onTextChange={(e) =>
                          handleLandpageDescription(e.htmlValue)
                        }
                        style={{ height: "320px" }}
                        dir="ltr"
                      />
                    </Form.Group>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.storeName")}
                    </label>
                    <Form.Select
                      aria-label="Default select example"
                      value={landpageStore}
                      onChange={(e) => {
                        handleLandpageStore(e.target.value);
                      }}
                    >
                      <option value={0}></option>
                      {stores.length
                        ? stores.map((store) => {
                            return (
                              <option value={store.id}>
                                {store.name} - {store.subdomain}.tejarty.store
                              </option>
                            );
                          })
                        : ""}
                    </Form.Select>
                  </div>
                  {landpageStoreErr ? (
                    <Alert variant={"danger"}>{landpageStoreErr}</Alert>
                  ) : (
                    ""
                  )}
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label d-flex justify-content-between"
                    >
                      {_("panel.offers")}
                      <Button
                        style={{
                          backgroundColor: "rgb(243, 168, 55)",
                          color: "white",
                          border: "none",
                        }}
                        className="fs-5 px-3 py-1"
                        onClick={() => {
                          handleLandpageOffers([
                            ...landpageOffers,
                            instanceObject,
                          ]);
                        }}
                      >
                        {_("panel.newoffer")}
                      </Button>
                    </label>
                    {landpageOffersErr ? (
                      <Alert variant={"danger"}>{landpageOffersErr}</Alert>
                    ) : (
                      ""
                    )}
                    {landpageOffers.map((offer, index) => {
                      return (
                        <div className="offers d-flex gap-4 flex-md-row flex-column align-items-center">
                          <div className="flex-grow-1">
                            <Form.Label htmlFor="inputPassword5">
                              {_("panel.beforeDiscount")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="inputPassword5"
                              aria-describedby="passwordHelpBlock"
                              value={offer.beforePrice}
                              onInput={(e) => {
                                handleLandpageOffers(() => {
                                  return landpageOffers.map(
                                    (offer2, index2) => {
                                      if (index !== index2) {
                                        return offer2;
                                      }
                                      let offerInstance = offer2;
                                      offerInstance.beforePrice =
                                        e.target.value;
                                      return offerInstance;
                                    }
                                  );
                                });
                              }}
                            />
                            {offer.beforePriceErr ? (
                              <Alert variant={"danger"}>
                                {offer.beforePriceErr}
                              </Alert>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="flex-grow-1">
                            <Form.Label htmlFor="inputPassword5">
                              {_("panel.afterDiscount")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="inputPassword5"
                              aria-describedby="passwordHelpBlock"
                              value={offer.afterPrice}
                              onInput={(e) => {
                                handleLandpageOffers(() => {
                                  return landpageOffers.map(
                                    (offer2, index2) => {
                                      if (index !== index2) {
                                        return offer2;
                                      }
                                      let offerInstance = offer2;
                                      offerInstance.afterPrice = e.target.value;
                                      return offerInstance;
                                    }
                                  );
                                });
                              }}
                            />
                            {offer.afterPriceErr ? (
                              <Alert variant={"danger"}>
                                {offer.afterPriceErr}
                              </Alert>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="flex-grow-1">
                            <Form.Label htmlFor="inputPassword5">
                              {_("panel.quantity")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="inputPassword5"
                              aria-describedby="passwordHelpBlock"
                              value={offer.quantity}
                              onInput={(e) => {
                                handleLandpageOffers(() => {
                                  return landpageOffers.map(
                                    (offer2, index2) => {
                                      if (index !== index2) {
                                        return offer2;
                                      }
                                      let offerInstance = offer2;
                                      offerInstance.quantity = e.target.value;
                                      return offerInstance;
                                    }
                                  );
                                });
                              }}
                            />
                            {offer.quantityErr ? (
                              <Alert variant={"danger"}>
                                {offer.quantityErr}test
                              </Alert>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="flex-grow-1"
                            onClick={() => {
                              handleLandpageOffers(() => {
                                return landpageOffers.filter(
                                  (value, index2) => {
                                    return index != index2;
                                  }
                                );
                              });
                            }}
                          >
                            <IconsGen icon_name={"delete"} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div class="form-check pb-3">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={landpageHasShipping}
                      id="flexCheckChecked"
                      style={{ border: "1px solid rgb(243, 168, 55)" }}
                      onInput={(e) => {
                        handleLandpageHasShipping(!landpageHasShipping);
                        handleLandpageShipping(0);
                      }}
                    />
                    <label className="form-check-label" for="flexCheckChecked">
                      {_("panel.landpageHasShipping")}
                    </label>
                  </div>
                  {!landpageHasShipping ? (
                    <div className="mb-3 mt-2">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {_("panel.landpageShippingValue")}
                      </label>
                      {landpageSkuErr ? (
                        <Alert variant={"danger"}>{landpageSkuErr}</Alert>
                      ) : (
                        ""
                      )}
                      <input
                        type="number"
                        className="form-control py-2 px-3 egp"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={landpageShipping}
                        onInput={(e) => {
                          handleLandpageOffers(() => {
                            return landpageOffers.map((offer2, index2) => {
                              let offerInstance = offer2;
                              offerInstance.afterPrice -= landpageShipping;
                              offerInstance.afterPrice += !isNaN(
                                parseFloat(e.target.value)
                              )
                                ? parseFloat(e.target.value)
                                : 0;
                              return offerInstance;
                            });
                          });
                          handleLandpageShipping(e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="mb-3">
                    <Button
                      style={{
                        backgroundColor: "rgba(89, 198, 201, 1)",
                        color: "white",
                        border: "none",
                        width: "100%",
                      }}
                      className="fs-5"
                      onClick={() => {
                        create_landpage();
                      }}
                    >
                      {_("panel.createShop")}
                    </Button>
                  </div>
                  {success ? <Alert variant={"success"}>{success}</Alert> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Landpage;
