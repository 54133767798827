import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import TableLabels from "./TableLabels";
import TableSelectStatus from "./TableSelectStatus";
import IconsGen from "../../../icons_generator/IconsGen";
import AxiosController from "../../../authentication/AxiosController";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import "primereact/resources/themes/lara-light-cyan/theme.css";
const PaymentRequestsTableRow = ({
  payments_list,
  alt_name,
  handleLoader,
  fetchPaymentRequestsHandler,
}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");

  const delete_product = (product_id) => {
    AxiosController.put(
      `api/${slug}/${lang}/product/delete-product/`,
      { id: product_id },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        fetchPaymentRequestsHandler();
      })
      .catch((e) => {});
  };

  const accept = (product_id) => {
    delete_product(product_id);
    // toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
  };

  const reject = () => {
    // toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  };
  const confirm2 = (product_id) => {
    confirmDialog({
      message: _("panel.deleteSure"),
      header: _("panel.deleteProduct"),
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => {
        accept(product_id);
      },
      reject,
    });
  };

  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <>
        <ConfirmDialog style={{ direction: lang == "en" ? "ltr" : "rtl" }} />
      </>
      <table
        className="table table-borderless text-nowrap"
        htmlFor="flexCheckDefault"
      >
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.data.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");

            return (
              <tr className="">
                <td key={order.id} className="align-middle">
                  <div
                    className="form-check mt-3"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </td>
                <td scope="row" className="align-middle">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td className="align-middle">
                  <div className="d-flex justify-content-center flex-column align-items-center ">
                    <NavLink to={`/${slug}/${lang}/product/${order["id"]}`}>
                      <img
                        src={order["thumbnail"]}
                        className="rounded-3"
                        width={"80px"}
                        alt="لم يتم تحديد صورة"
                      />
                    </NavLink>
                    <br />
                    <div>
                      #{order["id"]}
                      <br />
                      {order["title"] ? order["title"].slice(0, 14) : ""}
                    </div>
                  </div>
                </td>
                <td className="align-middle">
                  {order["left"] ? order["left"] : 0}
                </td>
                {role == "admin" ? (
                  <td className="align-middle">
                    {order["sys_commision"] ? order["sys_commision"] : 0}
                  </td>
                ) : (
                  ""
                )}
                {role == "admin" ? (
                  <td className="align-middle">
                    {order["sys_commision"]
                      ? order["total_commissions"] - order["sys_commision"]
                      : 0}
                  </td>
                ) : (
                  ""
                )}
                <td className="align-middle">
                  {order["total_commissions"] && role != 'seller' ? order["total_commissions"] : order["total_commissions"] - order["sys_commision"]}
                </td>
                <td className="align-middle">   
                  <p className="mb-3 fw-bold text-info">         
                       { order["product_exclusive"] === 1 ? _("panel.exclusive") : "" }

</p>
                  <TableSelectStatus
                    currentStatus={order["is_active"]}
                    orderId={order.id}
                    handleLoader={handleLoader}
                    product_exclusive={order["product_exclusive"]}
                  />
                </td>
                <td className="align-middle">
                  #{order["user_id"]} <br />
                  {order["first_name"] ?? ""} {order["last_name"] ?? ""} <br />
                  {order["user_role"] ? _(`panel.${order["user_role"]}`) : ""}
                </td>
                <td
                  className="align-middle px-3"
                  onClick={() => confirm2(order["id"])}
                >
                  {<IconsGen icon_name={"delete"} />}
                </td>
                <td className="align-middle px-3">
                  <NavLink to={`/${slug}/${lang}/panel/product/${order["id"]}`}>
                    {<IconsGen icon_name={"enter"} />}
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentRequestsTableRow;
