import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
import logo from "../../../../imgs/Logo_Ecombo 1.png";
import AxiosController from "../../../authentication/AxiosController";
import { Button } from "react-bootstrap";
import { downloadPDF } from "./print";
import { FaFilePdf } from "react-icons/fa6";

const Manifest = () => {
  const { slug, lang } = useParams(); 
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global"); 
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");

  const get_one_manifest = () => {
    setLoading(true);
    AxiosController.get(`/api/${slug}/${lang}/manifest/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
        setLoading(false);
        setData(res.data || []);
    })
    .catch(() => {
        setLoading(false);
        setData([]);
    });
}; 
    
  return (
    <>
      <div
        className={`tables-container   d-flex flex-column position-relative  ${
          lang === "en" ? "flex-row" : ""  } `}
        style={{marginTop:"100px"}}

      >
        <div className="d-flex gap-3">
            <input
                type="text"
                className="form-control mb-4"
                placeholder={_("panel.Manifest")}
                style={{ border: "1px solid  rgba(89, 198, 201, 1)"  }}
                onChange={(e) => setId(e.target.value)}
            />
            <Button
                className="fs-5 px-5  text-nowrap mb-4" 
                style={{ border: "1px solid  ", background:"rgba(89, 198, 201, 1)" }}
                onClick={get_one_manifest}
            >       {_("panel.Manifest")}
 
            </Button>

            
            </div>
                
        <div className="d-flex justify-content-end mt-4">
        <Button
                className="fs-5 px-5  text-nowrap mb-4"
                style={{ border: "1px solid  #172B4D", color: "#172B4D",  background:"transparent" }}
                onClick={()=>downloadPDF(data ,_("panel.total") , _("panel.orderContent") , _("panel.courierNumber") , _("panel.totalorders2"))}
                >              <FaFilePdf  className="fs-3 px-1 text-danger"/>    {_("panel.downloadpdf")}
 
            </Button>
 
        </div>

        <div className="row fw-bold" ref={tableRef}>
          <h1 className="text-center ecombo-secondary"> Manifest </h1>
          <div  className="d-flex justify-content-end "  >
            <img
              src={logo}
              alt="logo"
              className="m-4"
              style={{ height: "65px", width: "200px" }}
            />
          </div>
          <hr />
          <table className="table table-borderless" htmlFor="flexCheckDefault">
            <thead>
              <tr>
                <th>#</th>
                <th>{_("panel.total")}</th>

                <th>{_("panel.orderContent")}</th>
                <th>{_("panel.courierNumber")}</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((ele , index ) => (
                  <tr key={ele["Ref No"]}>
                        <td scope="row" className="align-middle">
                      {index +1}
                    </td>
                    <td scope="row" className="align-middle">
                      {ele["COD"]}
                    </td>
                    <td className="align-middle">
                      <span className="fw-bold">#{ele["Ref No"]}</span> <br />
                      <span>
                        {ele["Content"]&& ele["Content"]?.split("\n").map((line, index) => (
                          <p key={index}>
                            {line}
                            <br />
                          </p>
                        ))}
                      </span>
                    </td>
                    <td className="align-middle">{ele["Courier Track"]}</td>
                  </tr>
                ))}
            </tbody>
      
            <tfoot >
              <tr style={{borderTop :"3px solid black"}}>
              <td> </td>
                <td> </td>
                <td  >  {_("panel.totalorders2")}   </td>
                <td   >
              {data.length}
                </td>
               
              </tr>
  
             
            </tfoot>
          </table> 
        </div>
      </div>
    </>
  );
};

export default Manifest;
