import React from "react";
import useAuth from "./AuthValidator";

import { Navigate } from "react-router-dom";

function ProtectedRoute({ component: Component, role, roles, ...rest }) {
  const { isAuthenticated, hasAccess } = useAuth(role, roles);

  return isAuthenticated() && hasAccess() ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/" replace={true} />
  );
}
export default ProtectedRoute;
