import React, { useState } from "react";
import "../Style.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

import PreviewStats from "../preview_stats/PreviewStats";
import SellerStats from "../../statistics/StatsComp/EarningsComponents/SellerStats";
import { useTranslation } from "react-i18next";
import ChartsStats from "../preview_stats/ChartsStats";
const ControlPanelOperation = () => {
  const [_] = useTranslation("global");
  return (
    <div>
      <div className="stats-container d-flex flex-column gap-2">
        <PreviewStats />
        <SellerStats />
      </div>
    </div>
  );
};

export default ControlPanelOperation;
