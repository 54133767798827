import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import IconsGen from "../../icons_generator/IconsGen";
import { notifyError, notifySuccess } from "../../toastify/toastify";
import AxiosController from "../../authentication/AxiosController";
import { useEffect } from "react";

const CartStat = ({
  cart,
  shippingCost,
  confirmation_price,
  fulfillment_price,
  cancellation_price,
  orderId,
  total_price,
}) => {
  const [confirmationPriceValue, setConfirmationPriceValue] = useState();
  const [shippingCostValue, setShippingCostValue] = useState();
  const [fulfillmentPriceValue, setFulfillmentPriceValue] = useState();
  const [cancellationPriceValue, setCancellationPriceValue] = useState();

  const { t: _ } = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setShippingCostValue(shippingCost);
    setFulfillmentPriceValue(fulfillment_price);
    setConfirmationPriceValue(confirmation_price);
    setCancellationPriceValue(cancellation_price);
  }, [shippingCost, fulfillment_price, cancellation_price, fulfillment_price]);
  const totalNumber = cart.length;
  const totalPrice = total_price;
  const totalQ =
    cart.length > 0 &&
    cart.reduce((prev, current) => prev + current.quantity, 0);
  const id = orderId;

  const updateFulfillment = () => {
    setLoading(true);

    AxiosController.post(
      `api/${slug}/${lang}/fulfillments/order/data/edit/${id}`,
      {
        total_price: totalPrice,
        confirmation_price: confirmationPriceValue,
        fulfillment_price: fulfillmentPriceValue,
        cancellation_price: cancellationPriceValue,
        shipping_cost: shippingCostValue,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        notifySuccess(_("panel.updateSuccessMsg"));
        setLoading(false);
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          typeof e.response.data.error === "object"
        ) {
          Object.entries(e.response.data.error).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((err) => {
                notifyError(`${key}: ${err}`);
              });
            } else {
              notifyError(`${key}: ${value}`);
            }
          });
        }
        setLoading(false);
      });
  }; 
  return (
    <div
      className="left-side d-flex flex-column text-center py-4 px-4 gap-2"
      style={{ height: "100%", flexGrow: 1, width: "100%" }}
    >
      <div className="top py-3">
        <h3>{_("home.OrderDetails")}</h3>
      </div>

      <div
        className="left-body py-2 d-flex flex-column gap-3"
        style={{ minWidth: "100%" }}
        dir={lang === "en" ? "ltr" : "rtl"}
      >
        <div className="pricing-info d-flex flex-column">
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("home.productsNumber")}
            </p>
            <input
              className="form-control w-50"
              value={totalNumber}
              readOnly
              disabled
            />
          </div>
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("home.quantity")}
            </p>
            <input
              className="form-control w-50"
              value={totalQ}
              readOnly
              disabled
            />
          </div>
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("home.shippingcost")}
            </p>
            <input
              readOnly={role !== "admin" && employee_type!== "operation employee"}
              disabled={role !== "admin" && employee_type!== "operation employee"}
              className="form-control w-50"
              type="number"
              value={shippingCostValue}
              onChange={(e) => setShippingCostValue(Number(e.target.value))}
            />
          </div>
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("panel.confirmation_price")}
            </p>
            <input
              readOnly={role !== "admin" && employee_type!== "operation employee"}
              disabled={role !== "admin" && employee_type!== "operation employee"}
              className="form-control w-50"
              type="number"
              value={confirmationPriceValue}
              onChange={(e) =>
                setConfirmationPriceValue(Number(e.target.value))
              }
            />
          </div>
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("panel.fulfillment_price")}
            </p>
            <input
              readOnly={role !== "admin" && employee_type!== "operation employee"}
              disabled={role !== "admin" && employee_type!== "operation employee"}
              className="form-control w-50"
              type="number"
              value={fulfillmentPriceValue}
              onChange={(e) => setFulfillmentPriceValue(Number(e.target.value))}
            />
          </div>
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("panel.cancellation_price")}
            </p>
            <input
              readOnly={role !== "admin" && employee_type!== "operation employee"}
              disabled={role !== "admin" && employee_type!== "operation employee"}
              className="form-control w-50"
              type="number"
              value={cancellationPriceValue}
              onChange={(e) =>
                setCancellationPriceValue(Number(e.target.value))
              }
            />
          </div>
          <div>
            {role === "admin" || employee_type === "operation employee" ? (
              <Button
                className="fs-5 px-5 w-100 mt-4 mb-4 align-self-start"
                style={{
                  backgroundColor: "rgb(89, 198, 201)",
                  border: "none",
                  borderRadius: "7px",
                }}
                onClick={updateFulfillment}
                disabled={loading}
              >
                {_("panel.update")}
              </Button>
            ) : (
              ""
            )}
          </div>
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("home.COD")} ({_("panel.diff")})
            </p>
            <p>
              {totalPrice} (
              {totalPrice -
                (shippingCostValue +
                  confirmationPriceValue +
                  fulfillmentPriceValue +
                  cancellationPriceValue)}
              )
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartStat;
