import React, { useEffect, useRef, useState } from "react";
import AxiosController from "../../../authentication/AxiosController";
import { useParams } from "react-router-dom";
import ProductImgsSlider from "./Components/ProductImgsSlider";
import { FilePond, registerPlugin } from "react-filepond";

import "filepond/dist/filepond.min.css";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";

import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useTranslation } from "react-i18next";
import BaseServerUrl from "../../../authentication/BaseServerUrl";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Banners = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [banners, setBanners] = useState([]);
  const pond = useRef(null);
  const [_] = useTranslation("global");

  const [files, setFiles] = useState([]);
  const get_banners = () => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/slider/get-sliders`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setBanners(res.data);
        handleLoader(false);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    get_banners();
  }, [slug, lang]);
  return (
    <div>
          <Helmet>
   <title>{_("panel.banners")}</title> 
</Helmet>
      <FilePond
        files={files}
        ref={pond}
        onprocessfiles={() => {
          setTimeout(() => {
            setFiles([]);
            get_banners();
            handleLoader(false);
          }, 200);
        }}
        onupdatefiles={setFiles}
        allowMultiple={true}
        maxFiles={6}
        server={{
          url: BaseServerUrl + `/api/${slug}/${lang}/slider/add-slider`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          process: {
            onload: (e) => {},
          },
        }}
        instantUpload={false}
        name="slide"
        labelIdle={`${_(
          "panel.draglandpage"
        )} <span class="filepond--label-action">${_("panel.browse")}</span>`}
      />
      <div className="mb-3">
        <Button
          style={{
            backgroundColor: "rgba(89, 198, 201, 1)",
            color: "white",
            border: "none",
            width: "100%",
          }}
          className="fs-5"
          onClick={() => {
            handleLoader(true);
            pond.current.processFiles().then(() => {
            });
          }}
        >
          {_("panel.save")}
        </Button>
      </div>
      <ProductImgsSlider productImgs={banners} fetch={get_banners} />
    </div>
  );
};

export default Banners;
