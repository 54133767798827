import React, { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SupportTicketBox from "./SupportTicketBox";
import Loader from "../../loader/Loader";
import { useParams } from "react-router-dom";
import AxiosController from "../../authentication/AxiosController";
const add_ticket = (
  slug,
  lang,
  token,
  order_id,
  newTicket,
  ticketAdd,
  handleSuccess,
  handleLoader,
  get_order_handler,
  is_private
) => {
  handleLoader(true);
  AxiosController.post(
    `api/${slug}/${lang}/add-ticket-order/${order_id}`,
    { ticket: newTicket,
      is_private
     },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      handleSuccess(ticketAdd);
      get_order_handler();
      handleLoader(false);
    })
    .catch((e) => {});
};

const SupportTickets = ({
  tickets_list,
  order_id,
  get_order_handler,
  canEdit,
}) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const tickets = useState(tickets_list ?? []);
  const [_] = useTranslation("global");
  const [addTicket, handleAddTicket] = useState(false);
  const [loading, handleLoader] = useState(false);
  const [newTicket, handleNewTicket] = useState("");
  const [success, handleSuccess] = useState("");
  const [is_private, setPrivate] = useState(0);
  useEffect(() => {}, [tickets_list]);
  return (
    <div className="d-flex flex-column gap-2 position-relative">
      <Loader active={loading} />
      {role == "employee" && canEdit ? (
        <Button
          className="fs-5 px-5 w-25"
          style={{
            backgroundColor: "rgb(89, 198, 201)",
            border: "none",
            borderRadius: "7px",
          }}
          onClick={() => {
            handleAddTicket(!addTicket);
            handleNewTicket("");
          }}
        >
          {_("panel.add")}
        </Button>
      ) : (
        ""
      )}
      <h3>{_("panel.notes")}</h3>
      {success ? <Alert variant={"success"}>{success}</Alert> : ""}
      {addTicket ? (
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Control
            as="textarea"
            rows={3}
            onInput={(e) => handleNewTicket(e.target.value)}
          />
        </Form.Group>
      ) : (
        ""
      )}
      {newTicket.length > 0 ? (
        <div className="row">
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            className="fs-4"
            label={_("panel.private")}
            value={is_private}
            onInput={() => setPrivate(v => !v)}
          />
          <Button
            className="fs-5 px-5 w-25 align-self-end m-0 col-8"
            style={{
              backgroundColor: "rgb(89, 198, 201)",
              border: "none",
              borderRadius: "7px",
            }}
            onClick={() => {
              add_ticket(
                slug,
                lang,
                token,
                order_id,
                newTicket,
                _("panel.ticketAdd"),
                handleSuccess,
                handleLoader,
                get_order_handler,
                is_private
              );
              handleAddTicket(!addTicket);
            }}
          >
            {_("panel.save")}
          </Button>
          
        </div>
      ) : (
        ""
      )}
      {tickets_list.map((ticket) => {
        return (
          <SupportTicketBox
            content={ticket.ticket}
            addedBy={ticket.first_name + " " + ticket.last_name}
            user_id={ticket.user_id}
            date={ticket.created_at_formatted}
            photo={ticket.photo}
          />
        );
      })}
    </div>
  );
};

export default SupportTickets;
