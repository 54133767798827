import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../imgs/Logo_Ecombo 1.png";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import IconsGen from "../icons_generator/IconsGen";

const Footer = () => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  return (
    <footer  style={{marginTop:"100px"}} dir={lang === "en" ? "rtl" : "ltr"}>
      <div className="phone_footer d-flex d-md-none flex-row gap-2">
        <NavLink
          to={`/${slug}/${lang}/panel/profile`}
          className={
            "box-phone  d-flex flex-column flex-grow-1 justify-content-center align-items-center p-2 text-decoration-none"
          }
        >
          <IconsGen icon_name={"profile_phone"} />
          <p className="m-0">{_("panel.profile")}</p>
        </NavLink>
        <NavLink
          to={`/${slug}/${lang}/panel/products`}
          className={
            "box-phone d-flex flex-column  flex-grow-1 justify-content-center align-items-center p-2 text-decoration-none"
          }
        >
          <IconsGen icon_name={"home_products"} />

          <p className="m-0">{_("panel.products")}</p>
        </NavLink>
        <NavLink
          to={`/${slug}/${lang}/panel/sellings`}
          className={
            "box-phone d-flex flex-column  flex-grow-1 justify-content-center align-items-center p- text-decoration-none"
          }
        >
          <IconsGen icon_name={"ordersPhone"} />

          <p className="m-0">{_("panel.orders")}</p>
        </NavLink>
        <NavLink
          to={`/${slug}/${lang}`}
          className={
            "box-phone d-flex flex-column  flex-grow-1 justify-content-center align-items-center p-2 text-decoration-none"
          }
        >
          <IconsGen icon_name={"home"} />

          <p className="m-0">{_("panel.main")}</p>
        </NavLink>
      </div>
      <Container>
        <Row className="d-md-flex d-none flex-md-row-reverse py-5">
          <Col
            md={4}
            className="d-flex flex-column gap-3"
            style={{ maxWidth: "33rem" }}
            dir={lang == "en" ? "ltr" : "rtl"}
          >
            <img src={logo} width="220px" alt="" />

            <p style={{ color: "white" }}>{_("home.footer")}</p>
            <div className="social_icons d-flex gap-3">
              <a href="https://www.facebook.com/Ecombo.ae/" target="_blank">
                <IconsGen icon_name={"facebook"} />
              </a>
              <a href="https://www.instagram.com/ecombo.ae/" target="_blank">
                <IconsGen icon_name={"instagram"} />
              </a>
            </div>
          </Col>
          <Col
            md={4}
            className="section d-flex flex-column align-items-center justify-content-center gap-3"
          >
            <span>
              <a href={`/${lang}/auth/register/marketer`}>
                {_("home.registersupplier")}
              </a>
            </span>
            <span>
              <a href={`/${lang}/auth/register/seller`}>
                {_("home.registermarketer")}
              </a>
            </span>
            <span>
              <a href={`/${slug}/${lang}/contact-us`}>{_("home.contactus")}</a>
            </span>
            {/* <span>
              <a href="/#">{_("home.terms")}</a>
            </span> */}
          </Col>
          <Col
            md={4}
            className="section d-flex flex-column align-items-center justify-content-center gap-3"
          >
            <span>
              <a href="/ae/ar">{_("home.main")}</a>
            </span>
            <span>
              <a href="/ae/ar">{_("home.cats")}</a>
            </span>
            <span>
              <a href="/ae/ar/panel">{_("home.panel")}</a>
            </span>
            {/* <span>
              <a href="/ae/ar/panel/my-shop">{_("home.store")}</a>
            </span> */}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
