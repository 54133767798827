import React from "react";
import ProtectedSidebarRoute from "../../authentication/ProtectedSidebarRoute";
import EmployeeTypes from "../../authentication/EmployeeTypes";
import Roles from "../../authentication/Roles";
import { useParams } from "react-router-dom";
import ControlPanelMarketer from "./ControlPanel";
import ControlPanelCallCenter from "./call_center/ControlPanelCallCenter";
import ControlPanelOperation from "./operation/ControlPanelOperation";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
const ControlPanelPipe = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  const direction = lang === "en" ? "rtl" : "ltr";

  return (
    <div>
      <Helmet>
    <title>{_("home.panel")}</title> 
 </Helmet>
      <ProtectedSidebarRoute
        component={ControlPanelMarketer}
        role={role}
        roles={[Roles["AFFILIATE"]]}
        direction={direction}
      />
      {/* <ProtectedSidebarRoute
        component={ControlPanelMarketer}
        role={role}
        roles={[Roles["SELLER"]]}
        direction={direction}
      /> */}
      <ProtectedSidebarRoute
        component={ControlPanelMarketer}
        role={role}
        roles={[Roles["SELLER"], Roles['FULFILLMENT']]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={ControlPanelCallCenter}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[EmployeeTypes["CONFIRMATION_EMPLOYEE"]]}
        direction={direction}
      />
      <ProtectedSidebarRoute
        component={ControlPanelOperation}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[EmployeeTypes["OPERATION_EMPLOYEE"]]}
        direction={direction}
      />
    </div>
  );
};

export default ControlPanelPipe;
