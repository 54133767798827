import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import StoreTableLabels from "./StoreTableLabels";
import IconsGen from "../../icons_generator/IconsGen";
import LandpagesTableLabels from "./LandpagesTableLabels";

const MyLandpagesTableRow = ({ stores_list, handleLoader }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");

  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <LandpagesTableLabels />
        </thead>
        <tbody>
          {stores_list.map((store) => {
            const dateSegments = store["created_at_formatted"].split(" ");
            return (
              <tr className="">
                <td scope="row">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td>
                  {store["title"]} <br /> ID#{store["id"]}{" "}
                </td>
                <td>
                  {store["subdomain"]} <br /> ID#{store["store_id"]}{" "}
                </td>
                <td>
                  <a
                    className="fs-5"
                    style={{ color: "rgb(243, 168, 55)" }}
                    href={
                      "https://" +
                      store["subdomain"] +
                      ".tejarty.store/" +
                      store["id"]
                    }
                    target="_blank"
                  >
                    {_("panel.visit")}
                  </a>
                </td>

                <td>{<IconsGen icon_name={"delete"} />}</td>
                <NavLink to={`/${slug}/${lang}/panel/landpage/${store['id']}`}>
                  <td>{<IconsGen icon_name={"enter"} />}</td>
                </NavLink>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default MyLandpagesTableRow;
