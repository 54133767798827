import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";

import * as XLSX from "xlsx";
import { useNavigate, useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import ordersSheetColsFulfillment from "./ordersSheetColsFulfillment";
import { notification } from "antd";

const get_shippingcosts = (slug, lang, token, shippingcostsHandler) => {
  AxiosController.get(`api/${slug}/${lang}/get-shippingcost/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      shippingcostsHandler(res.data);
    })
    .catch((e) => {});
};
const validateBulkOrders = (slug, lang, token, sheetOrders, setSheetOrdersErr, navigate, setSheetOrders) => {

  
  AxiosController.post(
    `api/${slug}/${lang}/orders/place/multiple`,
    sheetOrders,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      
      setSheetOrdersErr({});
      setSheetOrders(sheetOrders);

    })
    .catch((e) => {
      setSheetOrdersErr(e.response.data.error);
    });
};

const UploadExcelFulfillment = ({ handleLoader, setSheetOrdersErr, setSheetOrders }) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [_] = useTranslation("global");
  const fileInputRef = useRef(null);
  const [shippingcosts, shippingcostsHandler] = useState([]);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement, status, success = true) => {
    if (success) {
      api.success({
        style: { direction: lang == "ar" ? "rtl" : "ltr" },
        description: `${status}!`,
        placement,
      });
    } else {
      api.error({
        style: { direction: lang == "ar" ? "rtl" : "ltr" },
        description: `${status}!`,
        placement,
      });
    }
  };
  const handleFileUpload = (event) => {
    handleLoader(true);
    const file = event.target.files[0];
    const reader = new FileReader();
    const shippingcostsNames = shippingcosts.map((v) => v.arabic_name);
    let localOrdersSheet = [];
    let phoneNumbers = {};
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const columns = json[0];
      const isValid = ordersSheetColsFulfillment.every(
        (col, index) => col === columns[index]
      );
      if (isValid) {
        for (let index = 1; index < json.length; index++) {
          if (json[index].every((v) => {
            return v == '';
          })) break;
          const element = json[index];
          let nameIndex = shippingcostsNames.indexOf(element[4])
          if (nameIndex === -1) {
            openNotification(
              lang == "ar" ? "topRight" : "topLeft",
              `${_("panel.govError")} ${index + 1}`,
              false
            );
            handleLoader(false);
            break;
          }
          if (!phoneNumbers.hasOwnProperty(element[6])) {
            const orderObj = {
              customer_name: element[3], 
              COD: element[1], 
              gov_id: shippingcosts[nameIndex].id,
              phone_1: element[6],
              phone_2: element[7],
              address: element[5],
              public_notes: element[8],
              private_notes: element[9],
              variants: [
                {
                  price:  0 ,
                  quantity: element[2],
                  sku: element[0],
                },
              ],
            };
            phoneNumbers[element[6]] = localOrdersSheet.length;
            localOrdersSheet = [...localOrdersSheet, orderObj];
          }else {
            localOrdersSheet[phoneNumbers[element[6]]].variants.push({
              price: 0,
              quantity: element[2],
              sku: element[0],
            });
          }

        }
        validateBulkOrders(slug, lang, token, localOrdersSheet, setSheetOrdersErr, navigate, setSheetOrders);
        handleLoader(false);
        localOrdersSheet = [];
      } else {
        handleLoader(false);
        openNotification(
          lang == "ar" ? "topRight" : "topLeft",
          _("panel.uploadRightExcel"),
          false
        );
      }
    };

    reader.readAsArrayBuffer(file);
  };
  useEffect(() => {
    get_shippingcosts(slug, lang, token, shippingcostsHandler);
  }, []);
  return (
    <>
      {contextHolder}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <Button
        style={{
          backgroundColor: "rgb(243, 168, 55)",
          color: "white",
          border: "none",
          width: "100%",
        }}
        className="fs-5 d-flex justify-content-center align-items-center px-2 py-2 gap-2"
        onClick={handleButtonClick}
      >
        <IconsGen icon_name={"uploadExcelTaager"} />
        <p>{_("panel.uploadExcel")}</p>
      </Button>
    </>
  );
};

export default UploadExcelFulfillment;
