import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconsGen from "../../icons_generator/IconsGen";
import AxiosController from "../../authentication/AxiosController";
import Loader from "../../loader/Loader";
import { Button, Pagination } from "react-bootstrap";
import PaymentRequestsTableRow from "./PaymentRequestsTableRow";
import SearchComponent from "./SearchComponent";
import EmployeeAdd from "../../modals/EmployeeAdd";
import { Helmet } from "react-helmet";

const fetchPaymentRequests = (
  slug,
  lang,
  token,
  handleOrdesData,
  page,
  handleLoader,
  name,
  code,
  email,
  role,
  start_date,
  end_date,
  is_active
) => {
  AxiosController.get(`api/${slug}/${lang}/search/get-user/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
    params:{
      name,
      code,
      email,
      role,
      start_date,
      end_date,
      is_active
    }
  })
    .then((res) => {
    
      handleOrdesData(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const Users = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const Navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [levels, setLevels] = useState([]);
  const fetchLevels = () => {
    handleLoader(true);

    AxiosController.get(`api/${slug}/${lang}/level/get-levels`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setLevels(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };

  const token = localStorage.getItem("token");
  const [users, handleUsers] = useState();
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);
  const [search, handleSearch] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    fetchLevels()
    handleLoader(true);
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    const name = urlParams.get("name") || "";
    const code = urlParams.get("code") || "";
    const email = urlParams.get("email") || "";
    const role = urlParams.get("role") || "";
    const start_date = urlParams.get("start_date") || "";
    const end_date = urlParams.get("end_date") || "";
    const is_active = urlParams.get("is_active") || "";
    handlePage(nextPage);

    fetchPaymentRequests(
      slug,
      lang,
      token,
      handleUsers,
      nextPage,
      handleLoader,
      name,
      code,
      email,
      role,
      start_date,
      end_date,
      is_active
    );
  }, [token, slug, lang, location.search, handleLoader]);
  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    >        <Helmet>
    <title>{_("panel.users")}</title> 
 </Helmet>
      <Loader active={loading} />
      <Button
        className="fs-5 px-5 w-25"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      <Button
        className="fs-5 px-5 w-25"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
         onClick={()=>setShow(true)} 
      >
        {_("panel.addEmployee")}
      </Button> 
      <EmployeeAdd 
  show={show} 
  setShow={setShow} 
  fetchPaymentRequests={() => fetchPaymentRequests(
    slug,
    lang,
    token,
    handleUsers,
    1, 
    handleLoader,
    urlParams.get("name") || "",
    urlParams.get("code") || "",
    urlParams.get("email") || "",
    urlParams.get("role") || "",
    urlParams.get("start_date") || "",
    urlParams.get("end_date") || "",
    urlParams.get("is_active") || ""
  )}
/>

      {search ? <SearchComponent /> : ""}

      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.users")}
          </h3>
          
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {users ? (
            <PaymentRequestsTableRow
              alt_name={users["alt_name"]}
              payments_list={users["users"]}
              handleLoader={handleLoader}
              levels={levels}
            />
          ) : (
            ""
          )}
        </div>
        {users ? (
          <Pagination className="m-auto flex-wrap">
            <Pagination.First onClick={() => changeParam("page", 1)} />
            <Pagination.Prev
              onClick={() => (page - 1 > 0 ? changeParam("page", page - 1) : 1)}
            />
            {users["users"]["links"].map((element, index, arr) => {
              if (index == 0 || arr.length - 1 == index) {
                return "";
              } else {
                return (
                  <Pagination.Item
                    active={element["active"] ? true : false}
                    onClick={(e) =>
                      element["label"] != "..."
                        ? changeParam("page", parseInt(element["label"]))
                        : ""
                    }
                  >
                    {element["label"]}
                  </Pagination.Item>
                );
              }
            })}
            <Pagination.Next onClick={() => changeParam("page", page + 1)} />
            <Pagination.Last
              onClick={() =>
                changeParam("page", users["users"]["last_page"])
              }
            />
          </Pagination>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Users;
