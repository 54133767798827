import React, { useEffect, useState } from "react";
import AxiosController from "../../../authentication/AxiosController";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Pagination } from "react-bootstrap";
import Loader from "../../../loader/Loader";
import IconsGen from "../../../icons_generator/IconsGen";
import ProductsStockTableRow from "./ProductsStockTableRow";
import { useTranslation } from "react-i18next";
import SearchComponent from "./SearchComponent";
import * as XLSX from "xlsx";
import { Helmet } from "react-helmet";
const get_products_stock = (slug,lang,token,handleProductsStock,page,handleLoader,productCode,sku,supplier,warehouse,startDate,endDate,sellerId,handleProductsStockLog) => {
  AxiosController.get(`api/${slug}/${lang}/get-products-stocks/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      product_code: productCode,
      sku,
      supplier,
      warehouse,
      start_date: startDate,
      end_date: endDate,
      seller_id:sellerId
    },
  })
    .then((res) => {
      handleProductsStock(res.data[0]);
      handleProductsStockLog(res.data[1]);
      handleLoader(false);
    })
    .catch((e) => {    handleLoader(false);});
};

const ProductsStock = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [_] = useTranslation("global");
  const [productsStock, handleProductsStock] = useState([]);
  const [productsStockLog, handleProductsStockLog] = useState([]);
  const [productsStockLabel, handleProductsStockLabel] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [search, setSearch] = useState(false);
  const [page, handlePage] = useState(1);
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };  
  const get_stock_labels = (product_code, sku,supplier, warehouse, start_date , end_date , sellerId) => {
    handleLoader(true);

    AxiosController.get(`api/${slug}/${lang}/warehouse/products-overview`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {product_code,sku,supplier,warehouse,start_date ,end_date ,seller_id: sellerId},})
      .then((res) => {
        handleLoader(false);
        handleProductsStockLabel(res?.data);
      })
      .catch((e) => {        handleLoader(false);
      });
  };

  const get_products_stock2 = (all = false) => {
    const productCode = urlParams.get("product_code") || "";
    const sku = urlParams.get("sku") || "";
    const supplier = urlParams.get("supplier") || "";
    const warehouse = urlParams.get("warehouse") || "";
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/get-variants-stocks/${page}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {all: all ? 1 : 0,product_code: productCode,sku,supplier,warehouse,},
    })
      .then((res) => {
        if (!all) {
          handleLoader(false);
        } else {
          let worksheet = XLSX.utils.json_to_sheet(
            res.data.map((order) => {
              const excelObj = {
                "كود المنتج": order["product_code"],
                SKU: order["sku"],
                "عنوان المنتج": order["product_title"],
                المتبقي: order["left"],
                "متوسط السعر":
                  parseFloat(order["price"]) && role == "admin"
                    ? parseFloat(order["price"]).toFixed(2)
                    : 0,
                "ربحية التاجر":
                    parseFloat(order["commission"])? parseFloat(order["commission"]).toFixed(2):0,
                "اسم التاجر": `${order["seller_first_name"]} ${order["seller_last_name"]}`,
                "مع شركات الشحن": order["with_courier"],
              };
              return excelObj;
            })
          );
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          XLSX.writeFile(workbook,  ("Products-Stock.xlsx") );
          handleLoader(false);
        }
      })
      .catch((e) => {    handleLoader(false);});
  };

  useEffect(() => {
    handleLoader(true);
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    const productCode = urlParams.get("product_code") || "";
    const sku = urlParams.get("sku") || "";
    const supplier = urlParams.get("supplier") || "";
    const startDate = urlParams.get("start_date") || "";
    const endDate = urlParams.get("end_date") || "";
    const sellerId = urlParams.get("seller_id") || "";
    handlePage(nextPage);
    const warehouse = urlParams.get("warehouse") || "";
    get_stock_labels (productCode, sku,supplier, warehouse, startDate , endDate , sellerId ) ;
    get_products_stock(slug,lang,token,handleProductsStock,nextPage,handleLoader,productCode,sku,supplier,warehouse,startDate,endDate,sellerId,handleProductsStockLog, );
  }, [slug, lang, location.search , handleLoader , handleProductsStockLabel]);
 
  return (
    <div
      className={`table-responsive tables-container my-3 d-flex flex-column position-relative ${
        lang === "en" ? "flex-row" : "flex-row-reversed"} align-items-center`}
    >         <Helmet>
    <title>{_("panel.productsStock")}</title> 
 </Helmet>
      <Button
        className="fs-5 px-5 w-25 align-self-start"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          setSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <SearchComponent /> : ""}
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2 w-100"
        style={{ position: "relative", flexGrow: 3 }}>

        <div
          className={`header d-flex flex-column  flex-md-row ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}>

          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.productsStock")}
          </h3>
          <button
            className="btn"
            style={{ border: "1px solid #172B4D", color: "#172B4D" }}
            onClick={() => get_products_stock2(true)}
          >
            <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
          </button>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {productsStock.data &&
            <ProductsStockTableRow
              payments_list={productsStock.data}
              productsStockLog={productsStockLog}
              handleLoader={handleLoader}
              labels={productsStockLabel}
            /> }
        </div>
      </div>
      {productsStock["links"] &&
        <Pagination
          className="m-auto flex-wrap"
          dir={lang === "ar" ? "ltr" : "rtl"}
        >
          <Pagination.First onClick={() => changeParam("page", 1)} />
          <Pagination.Prev
            onClick={() => (page - 1 > 0 ? changeParam("page", page - 1) : 1)}
          />
          {productsStock["links"].map((element, index, arr) => {
            if (index == 0 || arr.length - 1 == index) {
              return "";
            } else {
              return (
                <Pagination.Item
                  active={element["active"] ? true : false}
                  onClick={(e) =>
                    element["label"] != "..."
                      ? changeParam("page", parseInt(element["label"]))
                      : ""
                  }
                >
                  {element["label"]}
                </Pagination.Item>
              );
            }
          })}
          <Pagination.Next onClick={() => changeParam("page", page + 1)} />
          <Pagination.Last
            onClick={() => changeParam("page", productsStock["last_page"])}
          />
        </Pagination>
    }
    </div>
  );
};

export default ProductsStock;
