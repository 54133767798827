import React, { useEffect, useRef, useState } from "react";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { currancyConvertor } from "../../../../unit/currancyConvertor";
import { Helmet } from "react-helmet";

const FulfilllmentRepost = () => {
  const { slug, lang } = useParams();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [data, setData] = useState({});
 
  const get_fulfillments = () => {
    AxiosController.get(`api/${slug}/${lang}/fulfillments/reports`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setData(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };
  useEffect(() => {
    get_fulfillments();
  }, []);

  return (
    <div className="flex-grow-1 stats-component">
        <Helmet>
   <title>{_("panel.fulfillmentReport")}</title> 
</Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 style={{ color: "rgba(23, 43, 77, 1)" }}>
            {_("panel.fulfillmentReport")}
          </h3>
   
        </div>
        <div
          className="boxes-container d-flex flex-wrap gap-2"
          dir={box_head_dir}
        >
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"dollarSign"} />
              <p className="flex-grow-1"  >
                {_("panel.total_confirmation_cost")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {data["total_confirmation_price"] || 0} 
              </p>
            </div>
          </div>
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"bluecheck"} />
              <p className="flex-grow-1"  >
                {_("panel.cancellation_cost")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {data["total_cancellation_price"] || 0} 
              </p>
            </div>
          </div>
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"bluetrans"} />

              <p className="flex-grow-1"  >
                {_("panel.handling_cost")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {data["total_fulfillment_price"] || 0} 
              </p>
            </div>
          </div>
          {/* <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"orangdash"} />

              <p className="flex-grow-1"  >
                {_("panel.order_value")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {data["total_expected_money"] || 0}
              </p>
            </div>
          </div> */}
                <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"orangdash"} />

              <p className="flex-grow-1"  >
                {_("home.shippingcost")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {data["total_shipping_cost"] || 0}
              </p>
            </div>
          </div>
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"orangdash"} />

              <p className="flex-grow-1"  >
                {_("panel.total_order_value_after_discount")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {data["total_total_price"] || 0}
              </p>
            </div>
          </div>
          <div
            className="report-box col-5 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify"
            style={{ background: "none" }}
          ></div>
          <div
            className="report-box col-5 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify"
            style={{ background: "none" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default FulfilllmentRepost;
