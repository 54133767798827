import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import StatisticsSearchComponent from "./search components/StatisticsSearchComponent";
import { Button, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";

const MarketerOverview = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [allData, handleAllData] = useState([]);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [search, handleSearch] = useState(false);
  const [marketerId, setMarketerId] = useState("");
  const [courierId, setCourierId] = useState("");
  const [productId, setProductId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const role = localStorage.getItem("role");


  useEffect(() => {
    setMarketerId(urlParams.get("marketer_id") || "");
    setCourierId(urlParams.get("courier_id") || "");
    setProductId(urlParams.get("product_id") || "");
    setStartDate(urlParams.get("start_date") || "");
    setEndDate(urlParams.get("end_date") || "");
  }, [urlParams]);

  const get_products_overview = () => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/reports/products-overview`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
        marketer_id: marketerId,
        courier_id: courierId,
        product_id: productId,
      },
    })
      .then((res) => {
        handleLoader(false);

        handleAllData(res?.data?.products);
      })
      .catch(() => {
        handleLoader(false);
      });
  };

  useEffect(() => {
    get_products_overview();
  }, [marketerId, courierId, productId, startDate, endDate]);

  const totalQuantity = allData.reduce((acc, item) => acc + Number(item.total_quantity),0);
  const totalShippedCount =   allData.reduce((acc, item) => acc + item.shipped_count,0);
  const totalDeliveredCashDeliveryCount =   allData.reduce((acc, item) => acc + Number(item.delivered_cash_delivery_count),0);
  const totalSysCommission =   allData.reduce((acc, item) => acc + item.total_sys_commission,0);
  const totalSellerCommission =   allData.reduce((acc, item) => acc + item.total_seller_commission,0);
  const totalMarketerCommission =   allData.reduce((acc, item) => acc + item.total_marketer_commission,0 );


  

  return (
    <div className="flex-grow-1 stats-component"> <Helmet>
    <title>{_("panel.productsOverview")}</title> 
 </Helmet>
      <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <StatisticsSearchComponent type={"product"} /> : ""}

      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />

        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 className="fs-2 mb-4" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.productsOverview")}
          </h3>
        </div>
        <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
          <div
            className="col-12"
            style={{ position: "relative", overflowX: "auto", height: "100%" }}
          >
            <Table>
              <thead>
                <tr className="text-center">
                  <th scope="col" style={{ fontSize: "16px" }}>
                    {_("panel.productTitle")}
                  </th>
                  <th scope="col" style={{ fontSize: "16px" }}>
                    <span className="fw-bold">
                        
                      ({totalQuantity.toLocaleString()})
                    </span>
                    <br />
                    {_("panel.totalorders")}
                  </th>
                  <th scope="col" style={{ fontSize: "16px" }}>
                      
                    <span className="fw-bold">
                        
                      ({totalShippedCount.toLocaleString()})
                    </span>
                    <br />
                    {_("panel.shipped_orders")}
                  </th>
                  <th scope="col" style={{ fontSize: "16px" }}>
                    <span className="fw-bold">
                      ({totalDeliveredCashDeliveryCount?.toLocaleString()})
                    </span>
                    <br />
                    {_("panel.totalSoldOrders")}  
                  </th>
           {role === "admin" && <>    <th scope="col" style={{ fontSize: "16px" }}>
                    <span className="fw-bold">
                        
                      ({totalSysCommission?.toLocaleString()})
                    </span>  
                    <br />
                    {_("panel.sys_commission")}
                  </th>
                  <th scope="col" style={{ fontSize: "16px" }}>
                    <span className="fw-bold">
                        
                      ({totalSellerCommission?.toLocaleString()})
                    </span>  
                    <br />
                    {_("panel.seller_commission")}
                  </th>
                  <th scope="col" style={{ fontSize: "16px" }}>
                    <span className="fw-bold">
                        
                      ({totalMarketerCommission?.toLocaleString()})
                    </span>  
                    <br />
                    {_("panel.marketer_commission")}
                  </th>
           </>} 
              
                </tr>
              </thead>
              <tbody>
                {allData.length > 0 &&
                  allData.map((item, index) => (
                    <tr className="text-center" key={item?.id}>
                      <td style={{ fontSize: "16px" }}>
                        <span className="fw-bold mb-2">#{item?.id}</span>
                        <br />
                        {item?.title}
                      </td>

                      <td style={{ fontSize: "16px" }}>
                        {Number(item?.total_quantity).toLocaleString()}
                      </td>

                      <td style={{ fontSize: "16px" }}>
                        {item?.shipped_count?.toLocaleString()}(%{(item?.shipped_count/item?.total_quantity *100).toLocaleString()})
                      </td>
                      <td style={{ fontSize: "16px" }}>
                        {item?.delivered_cash_delivery_count} (%{( item?.delivered_cash_delivery_count /item?.shipped_count *100).toLocaleString()})
                      </td>
                
               {role ==="admin" && <>   <td style={{ fontSize: "16px" }}>
                        {item?.total_sys_commission?.toLocaleString()}
                      </td>
                      <td style={{ fontSize: "16px" }}>
                        {item?.total_seller_commission?.toLocaleString()}
                      </td>
                      <td style={{ fontSize: "16px" }}>
                        {item?.total_marketer_commission?.toLocaleString()}
                      </td></>}    
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketerOverview;
