import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

const CurrencySelector = ({ userCurrencies }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const location = useLocation();

  return (
<Dropdown className="d-flex  col-xl-2 col-md-3 col-12 p-1">
{userCurrencies.map((currency) => {
        if (currency.slug === slug) {
          return (
            <Dropdown.Toggle
              style={{
                backgroundColor: "transparent",
                color: "rgba(243, 168, 55, 1)",
                border: "1px solid rgba(243, 168, 55, 1)",
                width: "100%",
              }}
              id="dropdown-basic"
            >                                                            
              {_("navbar.shipto")} 
              <img
                style={currencyFlagStyles}
                src={currency.image}
                alt={currency.country}
              />
              {currency.country}
            </Dropdown.Toggle>
          );
        } else {
          return null;
        }
      })}
      <Dropdown.Menu>
        {userCurrencies.map((currency, index) => {
          if (currency.slug !== slug) {
            return (
              <Dropdown.Item
                key={index}
                style={{
                  textAlign: lang === "en" ? "left" : "right",
                  textDecoration: "none",
                  color: "#172B4D",
                  padding: "0px",
                }}
                href={`/${currency.slug}/${lang}${location.pathname.substring(
                  location.pathname.indexOf(lang) + lang.length
                )}`}
                className="d-flex gap-2 align-items-center p-2"
              >
                <img
                  style={currencyFlagStyles}
                  src={currency.image}
                  alt={currency.country}
                />
                {currency.country}
              </Dropdown.Item>
            );
          } else {
            return null;
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
const currencyFlagStyles = {
  width: "24px",
  borderRadius: "3px",
  height: "18px",
};

export default CurrencySelector;
