import React from "react";
import useAuth from "./AuthValidator";


function ProtectedSidebarRoute({ component: Component, role, roles, empTypes, empType, ...rest }) {
  const { isAuthenticated, hasAccess, hasAccess2 } = useAuth(role, roles, empTypes, empType);

  return isAuthenticated() && hasAccess() && hasAccess2() ? (
    <Component {...rest} />
  ) : (
    <></>
  );
}
export default ProtectedSidebarRoute;
