import React, { useState } from "react";
import "./Style.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";

import PreviewStats from "./preview_stats/PreviewStats";
import { useTranslation } from "react-i18next";
import ChartsStats from "./preview_stats/ChartsStats";

const ControlPanelMarketer = () => {
  const [_] = useTranslation("global");
  return (
    <div>
      <div className="stats-container d-flex flex-column gap-2 w-100">
        <PreviewStats />

        <ChartsStats />
      </div>
    </div>
  );
};

export default ControlPanelMarketer;
