import React, { useEffect, useState } from "react";
import "./print.css";
import logo from "../../../../imgs/Logo_Ecombo 1.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";

const paymentArabicEng = {
  محفظة: "wallet",
  "حواله بنكية": "bank_transfer",
  "باي بال": "paypal",
  "تيك توك": "tiktok",
  "فيزا ايكومبو": "ecombo",
  USDT: "USDT",
  "": "",
  وايز: "wise",
};
const paymentRequestTypes = {
  wallet: {
    accountNumber: true,
    email: false,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "محفظة",
  },
  bank_transfer: {
    accountNumber: true,
    email: false,
    bank_name: true,
    bank_country: true,
    bank_account_currency: true,
    bank_account_holder_name: true,
    IBAN: true,
    arabic: "حواله بنكية",
  },
  paypal: {
    accountNumber: false,
    email: true,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "باي بال",
  },
  // tiktok: {
  //   accountNumber: false,
  //   email: false,
  //   bank_name: false,
  //   bank_country: false,
  //   bank_account_currency: false,
  //   bank_account_holder_name: false,
  //   IBAN: false,
  //   arabic: "تيك توك",
  // },
  // ecombo: {
  //   accountNumber: false,
  //   email: false,
  //   bank_name: false,
  //   bank_country: false,
  //   bank_account_currency: false,
  //   bank_account_holder_name: false,
  //   IBAN: false,
  //   arabic: "فيزا ايكومبو",
  // },
  USDT: {
    accountNumber: true,
    email: false,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "USDT",
  },
  // wise: {
  //   accountNumber: false,
  //   email: true,
  //   bank_name: false,
  //   bank_country: false,
  //   bank_account_currency: false,
  //   bank_account_holder_name: false,
  //   IBAN: false,
  //   arabic: "وايز",
  // },
  "": {},
};

const Invoice = () => {
  const { slug, lang, id } = useParams();

  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [payment_request, setPayment_request] = useState({});
  const get_payment_request = () => {
    AxiosController.get(`api/${slug}/${lang}/get-payment-request/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setPayment_request(res.data);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    get_payment_request();
  }, [slug, lang, token]);
  return (
    <div className="print-only pt-3">
      <div className="invoice-header d-flex justify-content-between align-items-center">
        <div>
          <img src={logo} width={"260px"} className="avatar" alt="Logo" />
        </div>
        <div className="company-details">
          <h2 style={{ color: "rgb(89, 198, 201)" }}>ECOMBO PORTAL L.L.C</h2>
          <h4 style={{ color: "rgb(243, 168, 55)" }}>
            إيكومبو للتعامل الإلكتروني ش.ذ.م.م
          </h4>
        </div>
      </div>

      <div className="invoice-title">
        <h3>{_("panel.invoice")}</h3>
      </div>

      <div className="row">
        <div className="col-md-6">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>
                  <strong>{_("panel.Company Name")}:</strong>
                </td>
                <td>ECOMBO PORTAL L.L.C</td>
              </tr>
              <tr>
                <td>
                  <strong>{_("panel.Company IRN")}:</strong>
                </td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>
                  <strong>{_("panel.Address")}:</strong>
                </td>
                <td>Dubai, United Arab Emirates</td>
              </tr>
              <tr>
                <td>
                  <strong>{_("panel.Email")}:</strong>
                </td>
                <td>info@ecombo.ae</td>
              </tr>
              <tr>
                <td>
                  <strong>{_("panel.Telephone")}:</strong>
                </td>
                <td>+971505067725</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-6">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <td>
                  <strong>{_("panel.Invoice No")} #:</strong>
                </td>
                <td>{id}</td>
              </tr>
              <tr>
                <td>
                  <strong>{_("panel.Invoice Date")}:</strong>
                </td>
                <td>
                  {() => {
                    // Get the current date
                    let currentDate = new Date();

                    // Get the month, day, and year
                    let month = currentDate.getMonth() + 1; // Months are zero-based in JavaScript
                    let day = currentDate.getDate();
                    let year = currentDate.getFullYear();

                    // Format the date as MM/DD/YYYY
                    let formattedDate =
                      (month < 10 ? "0" : "") +
                      month +
                      "/" +
                      (day < 10 ? "0" : "") +
                      day +
                      "/" +
                      year;
                    return formattedDate;
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered">
            <tbody>
              {Object.keys(payment_request).map((key) => {
                const blockedFields = [
                  "created_at",
                  "updated_at",
                  "currency_id",
                  "amount",
                  "user_id",
                  "status",
                  "id",
                  "m_id",
                  "m_first_name",
                  "m_last_name",
                  "alt_name",
                  "name",
                  "changed_by",
                  "image",
                ];
                if (payment_request[key] && !blockedFields.includes(key)) {
                  return (
                    <tr>
                      <td>{_(`panel.${key}`)}</td>
                      <td>{payment_request[key]}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th>{_("panel.Description")}</th>
            <th>
              {_("panel.Amount")} ({payment_request.name})
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {_("panel.payment_request")} <br />
              {_("panel.client")} {payment_request.m_first_name}{" "}
              {payment_request.m_last_name} #{payment_request.m_id}
            </td>
            <td>{Number(payment_request.amount).toLocaleString()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Invoice;
