import React, { useEffect } from "react";

const AutomaticLogout = ({ children }) => {
  useEffect(() => {
    const lastActivity = localStorage.getItem("loginTime");
    const currentTime = new Date().getTime();
    if (lastActivity && currentTime - parseInt(lastActivity, 10) > 43200000) {
      localStorage.clear();
    }
  }, []);

  return <>{children}</>;
};

export default AutomaticLogout;
