import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import UploadExcelFulfillment from "./UploadExcelFulfillment";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import IconsGen from "../../../icons_generator/IconsGen";
import ordersSheetCols from "../../../globals/ordersSheetCols";
import PaymentRequestsTableRow from "./PaymentRequestsTableRow";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};
const OrdersBulkFulfillment = () => {
    const { slug, lang } = useParams();
    const Navigate = useNavigate();
    const token = localStorage.getItem("token");
  
  const [_] = useTranslation("global");
  const [sheetOrdersErrs, setSheetOrdersErr] = useState({});
  const [show, setShow] = useState(true);
  const [sheetOrders, setSheetOrders] = useState([]);
  const [loading, handleLoader] = useState(false);

  const uploadOrders = () => {
    sheetOrders.map((order) => { 
      AxiosController.post(`api/${slug}/${lang}/place-order`, order, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {})
        .catch((e) => {});
    });
    Navigate(`/${slug}/${lang}/success-order`);
  };

  return (
    <div className="position-relative">
         <Helmet>
   <title>{_("panel.ordersBulk")}</title> 
</Helmet>
      <Loader active={loading} />
      {Object.keys(sheetOrdersErrs).map((err) => {
        return sheetOrdersErrs[err].map((errI) => {
          return (
            <Alert variant="danger" onClose={() => setShow(false)}>
              <Alert.Heading>
                {err.replace(/^(\d+).*\.(\w+)$/, (g, index, field) => {
                  return `${field} ${_("panel.inRow")} ${parseInt(index) + 2}`;
                })}
              </Alert.Heading>
              <p>{errI}</p>
            </Alert>
          );
        });
      })}
      <div className="mb-3 d-flex gap-2">
        <a
          style={{
            backgroundColor: "#172B4D",
            color: "white",
            border: "none",
            width: "100%",
          }}
          className="fs-5 d-flex justify-content-center align-items-center px-2 py-2 gap-2 text-decoration-none rounded-3"
          // onClick={handleDownload}
          href={slug === 'ae' ? '/OrdersBulk_uae.xlsx' : '/OrdersBulk_sa.xlsx'}
          download
        >
          <IconsGen icon_name={"downloadExcelTaager"} />
          <p>{_("panel.downloadExcel")}</p>
        </a>
        <UploadExcelFulfillment
          handleLoader={() => {}}
          setSheetOrdersErr={setSheetOrdersErr}
          setSheetOrders={setSheetOrders}
        />
      </div>
      <div className="footer d-flex flex-column flex-grow-1">
        {sheetOrders.length ? (
          <>
            <PaymentRequestsTableRow
              payments_list={sheetOrders}
              handleLoader={handleLoader}
              setSheetOrders={setSheetOrders}
            />
            <span className="m-auto">
              <Button
                className="ecombo-btn fs-5 px-4 py-2"
                onClick={() => {
                  handleLoader(true);
                  uploadOrders();
                  handleLoader(false);

                }}
              >
                {_("panel.upload")}
              </Button>
            </span>
          </>
        ) : (
          ""
        )}
        
      </div>
    </div>
  );
};

const handleDownload = () => {
  // Define the column headers
  const columns = ordersSheetCols;

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet([columns]);

  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Convert the workbook to a binary string
  const workbookBinary = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  });

  // Create a Blob object from the binary string
  const blob = new Blob([s2ab(workbookBinary)], {
    type: "application/octet-stream",
  });

  // Create a link element
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "template.xlsx";

  // Append the link to the document and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
};

export default OrdersBulkFulfillment;
