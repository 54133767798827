import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const CartStat = ({ cart, shippingCost }) => {
  const [_] = useTranslation("global");
  const { lang } = useParams();
  const role = localStorage.getItem("role");
  const totalNumber = cart.length;
  const totalMarketerPrice = cart.length
    ? cart.reduce((prev, current) => {
        return prev + current.total_commissions * current.quantity;
      }, 0)
    : 0;
    const totalSellerCommission = cart.length
    ? cart.reduce((prev, current) => {
        return prev + current.seller_commission  ;
      }, 0)
    : 0;
  const totalCommission = cart.length
    ? cart.reduce((prev, current) => {
        return (
          prev +
          current.total_commissions * current.quantity -
          current.total_base_commissions * current.quantity
        );
      }, 0)
    : 0;
  const totalPrice = cart.length
    ? cart.reduce((prev, current) => {
        return prev + current.total_base_commissions * current.quantity;
      }, 0)
    : 0;
  const sysCommission = cart.length
    ? cart.reduce((prev, current) => {
        return prev + current.sys_commission * current.quantity;
      }, 0)
    : 0;
  const sellerCommission = cart.length
    ? cart.reduce((prev, current) => {
        return prev + current.seller_commission * current.quantity;
      }, 0)
    : 0;
  const altName = cart.length > 0 ? cart[0].alt_name : "";
  return (
    <div
      className="left-side d-flex flex-column align-items-center text-center py-4 px-4 gap-2"
      style={{ height: "100%", flexGrow: 1, width: "100%" }}
    >
      <div className="top py-3">
        <h3>{_("home.OrderDetails")}</h3>
      </div>
      <div
        className="left-body py-2 d-flex flex-column gap-3"
        style={{ minWidth: "100%" }}
        dir={lang === "en" ? "ltr" : "rtl"}
      >
        <div className="pricing-info d-flex flex-column">
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("home.productsNumber")}
            </p>
            <p>{totalNumber}</p>
          </div>
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("home.shippingcost")}
            </p>
            <p>{shippingCost} </p>
          </div>
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("home.tax")}
            </p>
            <p>0</p>
          </div>
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("home.commission")}
            </p>
            <p>{totalCommission} </p>
          </div>

          {role == "admin" ? (
            <>
              <div className="price-row d-flex justify-content-between p-3">
                <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
                  {_("panel.sysCommission")}
                </p>
                <p>{sysCommission} </p>
              </div>
              <div className="price-row d-flex justify-content-between p-3">
                <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
                  {_("panel.sellerCommission")}
                </p>
                <p>{sellerCommission} </p>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
              {_("home.price")}
            </p>
            <p>{totalPrice} </p>
          </div>
          <div className="price-row d-flex justify-content-between p-3">
            <p className="fs-5" style={{ color: "rgba(23, 43, 77, 1)" }}>
            {role =="seller" ?  _("home.sellerCommission"):  _("home.totalprice") }    
          
            </p>
            <p>
            {role =="seller" ?totalSellerCommission:totalMarketerPrice + shippingCost}   {altName}  
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartStat;
