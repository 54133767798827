import React, { useEffect, useState } from "react";
import "./style.css";
import IconsGen from "../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import Loader from "../../loader/Loader";
import { useParams } from "react-router-dom";
import AxiosController from "../../authentication/AxiosController";
import BlueRow from "./WalletComp/BlueRow";
import OrangeRow from "./WalletComp/OrangeRow";
import PaymentRequestTableRow from "./WalletComp/PaymentRequestTableRow";
import PaymentRequests from "./WalletComp/PaymentRequests";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";

const control_panel_stats_wallet = (
  slug,
  lang,
  token,
  handleStatsWallet,
  handleAltName,
  handleLoader,
  dates = {}
) => {
  AxiosController.get(`api/${slug}/${lang}/get-control-panel-stats-wallet/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      start: dates["start"] ? dates["start"] : "",
      end: dates["end"] ? dates["end"] : "",
    },
  })
    .then((res) => {
      handleStatsWallet(res.data);
      handleAltName(res.data.alt_name);
      handleLoader(false);
    })
    .catch((e) => {});
};
const paymentArabicEng = {
  محفظة: "wallet",
  "حواله بنكية": "bank_transfer",
  "باي بال": "paypal",
  "تيك توك": "tiktok",
  "فيزا ايكومبو": "ecombo",
  USDT: "USDT",
  "": "",
  وايز: "wise",
};
const paymentRequestTypes = {
  wallet: {
    accountNumber: true,
    email: false,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "محفظة",
  },
  bank_transfer: {
    accountNumber: true,
    email: false,
    bank_name: true,
    bank_country: true,
    bank_account_currency: true,
    bank_account_holder_name: true,
    IBAN: true,
    arabic: "حواله بنكية",
  },
  paypal: {
    accountNumber: false,
    email: true,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "باي بال",
  },
  // tiktok: {
  //   accountNumber: false,
  //   email: false,
  //   bank_name: false,
  //   bank_country: false,
  //   bank_account_currency: false,
  //   bank_account_holder_name: false,
  //   IBAN: false,
  //   arabic: "تيك توك",
  // },
  // ecombo: {
  //   accountNumber: false,
  //   email: false,
  //   bank_name: false,
  //   bank_country: false,
  //   bank_account_currency: false,
  //   bank_account_holder_name: false,
  //   IBAN: false,
  //   arabic: "فيزا ايكومبو",
  // },
  USDT: {
    accountNumber: true,
    email: false,
    bank_name: false,
    bank_country: false,
    bank_account_currency: false,
    bank_account_holder_name: false,
    IBAN: false,
    arabic: "USDT",
  },
  // wise: {
  //   accountNumber: false,
  //   email: true,
  //   bank_name: false,
  //   bank_country: false,
  //   bank_account_currency: false,
  //   bank_account_holder_name: false,
  //   IBAN: false,
  //   arabic: "وايز",
  // },
  "": {},
};

const Wallet = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const [alt_name, handleAltName] = useState("");
  const [statsWallet, handleStatsWallet] = useState({});
  const [loading, handleLoader] = useState(true);
  const [loading2, handleLoader2] = useState(false);

  // Form data
  const [amount, handleAmount] = useState(0);
  const [amountErr, handleAmountErr] = useState(0);
  const [paymentType, handlePaymentType] = useState("");
  const [paymentTypeErr, handlePaymentTypeErr] = useState("");
  const [accountNumber, handleAccountNumber] = useState("");
  const [email, handleEmail] = useState("");
  const [bank_name, handleBank_name] = useState("");
  const [bank_country, handleBank_country] = useState("");
  const [bank_account_currency, handleBank_account_currency] = useState("");
  const [bank_account_holder_name, handleBank_account_holder_name] =
    useState("");
  const [IBAN, handleIBAN] = useState("");
  const [notes, handleNotes] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [success, handleSuccess] = useState("");

  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  useEffect(() => {
    control_panel_stats_wallet(
      slug,
      lang,
      token,
      handleStatsWallet,
      handleAltName,
      handleLoader
    );
  }, [slug, lang, token, handleStatsWallet, handleAltName, handleLoader]);
  const new_payment_request = () => {
    handleLoader2(true);
    AxiosController.post(
      `api/${slug}/${lang}/add-payment-request/`,
      {
        amount: amount,
        payment_method: paymentType,
        bank_name: bank_name,
        email: email,
        bank_country: bank_country,
        bank_account_currency: bank_account_currency,
        bank_account_holder_name: bank_account_holder_name,
        IBAN: IBAN,
        account_number: accountNumber,
        notes: notes,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleSuccess(_("panel.success_payReq"));
        handleLoader2(false);
        handleAmount("");
        handlePaymentType("");
        handleAccountNumber("");
        handleEmail("");
        handleBank_name("");
        handleBank_country("");
        handleBank_account_currency("");
        handleBank_account_holder_name("");
      })
      .catch((e) => {
        handleLoader2(false);
        handlePaymentTypeErr(
          e.response.data.errors.payment_method
            ? e.response.data.errors.payment_method[0]
            : ""
        );
        handleAmountErr(
          e.response.data.errors.amount ? e.response.data.errors.amount[0] : ""
        );
      });
  };

  return (
    <div className="wallet-container container my-3 d-flex flex-column gap-2 position-relative">
              <Helmet>
   <title>{_("panel.wallet")}</title> 
</Helmet>
      <Loader active={loading} />
      <BlueRow
        values={[
          statsWallet["total_expected_money"]
            ? statsWallet["total_expected_money"][0]["stats"] || 0
            : 0,
          statsWallet["total_confirmed_money"]
            ? statsWallet["total_confirmed_money"][0]["stats"] || 0
            : 0,
          statsWallet["balance"] ? statsWallet["balance"]["total"] || 0 : 0,
        ]}
        slug={alt_name}
        image={statsWallet["image"]}
        titles={[
          _("panel.expectedearnings"),
          _("panel.confirmedearnings"),
          _("panel.withdrawableearnings"),
        ]}
      />
      <OrangeRow
        values={[
          statsWallet["total_withdrawn_money"]
            ? statsWallet["total_withdrawn_money"][0]["stats"] || 0
            : 0,
          statsWallet["total_earnings"]
            ? statsWallet["total_earnings"] || 0
            : 0,
          statsWallet["total_pending_reqs"]
            ? statsWallet["total_pending_reqs"][0]["stats"] || 0
            : 0,
        ]}
        slug={alt_name}
        image={statsWallet["image"]}
      />
      {/* <BlueRow
        values={[0, 0, 0]}
        slug={alt_name}
        image={statsWallet["image"]}
        titles={[
          _("panel.confirmedtaxes"),
          _("panel.cashdeliverytaxes"),
          _("panel.totaltaxs"),
        ]}
      /> */}
      {role != "fulfillment" ? (
        <>
          <div className="withdrawal">
            <button className="btn mt-3 py-2" onClick={handleShow}>
              {_("panel.withdrawearnings")}
            </button>
          </div>

          <Modal show={show} onHide={handleClose}>
            <Loader active={loading2} />
            <Modal.Header closeButton className="border-0" dir={box_head_dir}>
              <Modal.Title className="flex-grow-1">
                {_("panel.withdrawearnings")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body dir={box_head_dir}>
              {amountErr ? <Alert variant={"danger"}>{amountErr}</Alert> : ""}

              {label_input(_("panel.amount"), amount, handleAmount)}
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.paymentMethod")}
              </label>
              {paymentTypeErr ? (
                <Alert variant={"danger"}>{paymentTypeErr}</Alert>
              ) : (
                ""
              )}

              <Form.Select
                onChange={(e) => {
                  handlePaymentType(e.target.value);
                }}
                className="mb-3"
              >
                <option selected={true}></option>
                {Object.keys(paymentRequestTypes).map((type) => {
                  if (type == "") {
                  } else {
                    return (
                      <option value={paymentRequestTypes[type]["arabic"]}>
                        {_(`panel.${type}`)}
                      </option>
                    );
                  }
                })}
              </Form.Select>
              {paymentRequestTypes[paymentArabicEng[paymentType]][
                "accountNumber"
              ]
                ? label_input(
                    _("panel.accountNumber"),
                    accountNumber,
                    handleAccountNumber
                  )
                : ""}
              {paymentRequestTypes[paymentArabicEng[paymentType]]["email"]
                ? label_input(_("panel.email"), email, handleEmail)
                : ""}
              {paymentRequestTypes[paymentArabicEng[paymentType]]["bank_name"]
                ? label_input(_("panel.bank_name"), bank_name, handleBank_name)
                : ""}
              {paymentRequestTypes[paymentArabicEng[paymentType]][
                "bank_country"
              ]
                ? label_input(
                    _("panel.bank_country"),
                    bank_country,
                    handleBank_country
                  )
                : ""}
              {paymentRequestTypes[paymentArabicEng[paymentType]][
                "bank_account_currency"
              ]
                ? label_input(
                    _("panel.bank_account_currency"),
                    bank_account_currency,
                    handleBank_account_currency
                  )
                : ""}
              {paymentRequestTypes[paymentArabicEng[paymentType]][
                "bank_account_holder_name"
              ]
                ? label_input(
                    _("panel.bank_account_holder_name"),
                    bank_account_holder_name,
                    handleBank_account_holder_name
                  )
                : ""}
              {paymentRequestTypes[paymentArabicEng[paymentType]]["IBAN"]
                ? label_input("IBAN", IBAN, handleIBAN)
                : ""}
              {label_textarea(_("panel.notes"), notes, handleNotes)}
              {success ? <Alert variant={"success"}>{success}</Alert> : ""}

              {/* {newSupplierNameErr ? <Alert variant="danger">{newSupplierNameErr}</Alert> : ''} */}
            </Modal.Body>
            <Modal.Footer className="border-0" dir={box_head_dir}>
              <Button
                variant="primary"
                onClick={() => {
                  new_payment_request();
                }}
              >
                {_("panel.save")}
              </Button>
            </Modal.Footer>
          </Modal>
          <PaymentRequests />
        </>
      ) : (
        ""
      )}
    </div>
  );
};
const label_input = (label, newWarehouseName, handleNewWarehouseName) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
      />
    </div>
  );
};
const label_textarea = (label, Data, handleData) => {
  return (
    <div className="mb-3 d-flex flex-column">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <textarea
        className="form-control"
        rows={3}
        value={Data}
        onInput={(e) => {
          handleData(e.target.value);
        }}
      ></textarea>
    </div>
  );
};
export default Wallet;
