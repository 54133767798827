import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import TableLabels from "./TableLabels";
import IconsGen from "../../../icons_generator/IconsGen";
const WarehousesTableRow = ({ payments_list, alt_name, handleLoader }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless " htmlFor="flexCheckDefault">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");

            return (
              <tr className="">
                <td key={order.id}>
                  <div
                    className="form-check mt-3 d-flex justify-content-center"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </td>
                <td scope="row">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td>#{order["id"]}</td>
                <td>{order["title"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WarehousesTableRow;
