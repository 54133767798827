import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import AxiosController from "../authentication/AxiosController";
import Loader from "../loader/Loader";
import { notifyError, notifySuccess } from "../toastify/toastify";

const InternalTransfer = ({ show, setShow , get_warehouses}) => {
  const { slug, lang } = useParams();
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [allAccounts, setAllAccounts] = useState(false);
  const [banks, setBanks] = useState([]);
  const [sourceAccount, setSourceAccount] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [amount, setAmount] = useState("");
  const [amountInSourceCurrency, setAmountInSourceCurrency] = useState("");

  const get_shipping_costs = () => {
    AxiosController.get(
      `api/${slug}/${lang}/accounts?all_currencies=${allAccounts}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setLoading(false);
        setBanks(res.data); 
      })
      .catch((e) => { setLoading(false);});
  };
  const createWarehouse = () => {
    setLoading(true);
    AxiosController.post(
      `api/${slug}/${lang}/accounts/internal-transfer`,
      {
        from: from,
        to: to,
        amount: amount,
        amount_in_source_currency:amountInSourceCurrency,
        source_account:sourceAccount
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setShow(false);
        setLoading(false);
        notifySuccess(_("panel.internal_transfer_successful"));
get_warehouses()
      })
      .catch((e) => {
        setLoading(false);  
        if (e.response && e.response.data && typeof e.response.data.error === 'object') { 
          Object.entries(e.response.data.error).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((err) => {
                notifyError(`${key}: ${err}`);
              });
            } else {
              notifyError(`${key}: ${value}`);
            }
          });
          
        } 
       
      });
  };

  const handleClose = () => {
    setShow(false);
    setAmount('')
    setAmountInSourceCurrency('')
    setFrom('')
    setTo('')
    setSourceAccount('')
  };
  useEffect(() => {
    get_shipping_costs();
  }, [allAccounts]);
  return (
    <>
      <Loader active={loading} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">
            {_("panel.addInternalTransfers")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
          <div className="row">
            <div className="mb-2">
              <label className="form-check-label " htmlFor="flexRadioDefault1">
                {_("panel.showAllAccounts")}
              </label>
              <div className="form-check mb-2">
                <input
                  onChange={() => setAllAccounts(true)}
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  {_("panel.yes")}
                </label>
              </div>
              <div className="form-check  mb-2">
                <input
                  onChange={() => setAllAccounts(false)}
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  defaultChecked
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  {_("panel.no")}
                </label>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.sourceAccount")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setSourceAccount(e.target.value);
                }}
                value={sourceAccount}
              >
                <option value={0}></option>
                <option value={"seller"}>{_("panel.seller")}</option>
                <option value={"affiliate"}>{_("panel.affiliate")}</option>
                <option value={"system"}>{_("panel.system")}</option>
                <option value={"fulfillment"}>{_("panel.fulfillment")}</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.bankFrom")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setFrom(e.target.value);
                }}
                value={from}
              >
                <option value={0}></option>
                {banks.map((bank) => {
                  return <option value={bank.id}>{bank.name}</option>;
                })}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.bankTo")}
              </label>
              <select
                className="form-select"
                aria-label="Default select example"
                onChange={(e) => {
                  setTo(e.target.value);
                }}
                value={to}
              >
                <option value={0}></option>
                {banks.map((bank) => {
                  return <option value={bank.id}>{bank.name}</option>;
                })}
              </select>
            </div>
       
            <div >
              <label className="mb-2" htmlFor="amountInSourceCurrency">
                {_("panel.money_in_source")}
              </label>
              <input
                type="number"
                className="form-control"
                id="amountInSourceCurrency"
                onChange={(e) => {
                  setAmountInSourceCurrency(e.target.value);
                }}
                value={amountInSourceCurrency}
              />
            </div>     <div >
              <label className="mb-2" htmlFor="amount">
                {_("panel.paymentamount")} ( {_("panel.receiving_account")} )
              </label>
              <input
                type="number"
                className="form-control"
                id="amount"
                onChange={(e) => {
                  setAmount(e.target.value); 
                }}
                value={amount}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button variant="secondary" onClick={handleClose}>
            {_("panel.cancel")}
          </Button>
          <Button variant="primary" onClick={createWarehouse}>
            {_("panel.save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InternalTransfer;
