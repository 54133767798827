import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Offers = ({ slug, lang }) => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  return (
    <li>
      {role == "affiliate" ? (
        <NavLink to={`/${slug}/${lang}/panel/products/all-offers`}>
          <p className="d-inline ">{_("panel.offers2")}</p>
        </NavLink>
      ) : (
        <NavLink to={`/${slug}/${lang}/panel/products/offers`}>
          <p className="d-inline ">{_("panel.offers2")}</p>
        </NavLink>
      )}
    </li>
  );
};

export default Offers;
