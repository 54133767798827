import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen";

const Shipping = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const role = localStorage.getItem("role");
  const [isActive, setIsActive] = useState(false);

  const employee_type = localStorage.getItem("employee_type");
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);
  
  const handleClick = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };
  
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/panel/shipping/")) {
      setIsSubNavVisible(true);
    }else{
      setIsSubNavVisible(false);

    }
  }, [location.pathname]);

  return (
    <div className="links-container d-flex flex-column">
 <div className="d-flex flex-column">
 
      <div
        className={`feature_link ${lang === "en" ? "feature_link_en" : ""} d-flex flex-row-reverse align-items-center gap-2 py-3 
        ${isSubNavVisible ? 'active-link' : ''}`}
        dir={direction}
        // to={`/${slug}/${lang}/panel/shipping`}
        onClick={handleClick}
      >
        <IconsGen icon_name={"shoppingCart"} />
        <p >{_("panel.shipping")}</p>
      </div>
  
      <ul className={`sub-nav flex-column gap-2 px-5 pt-3 ${isSubNavVisible ? 'd-flex' : 'd-none'}`}>
      <li>
            <NavLink to={`/${slug}/${lang}/panel/shipping/couriers`}>
              <p className="d-inline">{_("panel.couriers")}</p>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${slug}/${lang}/panel/shipping/prices`}>
              <p className="d-inline">{_("panel.shippingCost")}</p>
            </NavLink>
          </li>
          <li>
            <NavLink to={`/${slug}/${lang}/panel/shipping/fulfillment`}>
              <p className="d-inline">{_("panel.fulfillmentpricing")}</p>
            </NavLink>
          </li>
        </ul>
    
    </div>
    </div>
  );
};

export default Shipping;
