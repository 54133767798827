import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import IconsGen from "../../icons_generator/IconsGen";
import TableLabels from "./TableLabels";
import TableSelectStatus from "./TableSelectStatus";
const PaymentRequestsTableRow = ({ payments_list, alt_name, handleLoader }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table
        className="table table-borderless text-nowrap"
        htmlFor="flexCheckDefault"
      >
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.data.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");

            return (
              <tr className="">
                <td key={order.id}>
                  <div
                    className="form-check mt-3"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </td>

                
                <td scope="row">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                  <br />
                  {order["ch_first_name"] ?? ""} {order["ch_last_name"] ?? ""}
                </td>
                <td>
                  #{order["id"]} <br />#{order["user_id"]}{" "}
                  {order["first_name"] ?? ""} {order["last_name"] ?? ""} <br />
                  {order["balance"]} {alt_name}
                  <br />
                  {order["user_role"] ? _(`panel.${order["user_role"]}`) : ""}
                </td>
                <td>
                  <b>
                    {order["amount"]
                      ? Number(order["amount"]).toLocaleString()
                      : 0}{" "}
                    {alt_name} <br /> {order["payment_method"]} <br />
                    {order["bank_name"] ?? ""}
                  </b>{" "}
                </td>
                <td className="">
                  <TableSelectStatus
                    currentStatus={order["status"]}
                    orderId={order.id}
                    handleLoader={handleLoader}
                  />
                </td>

                <td className="px-3">
                  <NavLink
                    to={`/${slug}/${lang}/panel/transaction/${order["id"]}`}
                  >
                    {<IconsGen icon_name={"enter"} />}
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentRequestsTableRow;
