import { Bar } from 'react-chartjs-2';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import AxiosController from '../authentication/AxiosController';
import { Button } from 'react-bootstrap';
import StatisticsSearchComponent from '../panel/statistics/admin/search components/StatisticsSearchComponent';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement // Registering ArcElement to avoid the "arc" not registered error
);
const BarChartSellers = () => {
  const { slug, lang } = useParams();
  const location = useLocation();
  const { t: _ } = useTranslation("global");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [sellers, setSellers] = useState([]);
  const urlParams = new URLSearchParams(location.search);
  const [search, setSearch] = useState(false);
  const [seller, setSeller] = useState("");

  useEffect(() => {
    setSeller(urlParams.get("seller_id") || "");
  }, [urlParams]);

  useEffect(() => {
    get_sellers_overview();
  }, [seller]);  

  const get_sellers_overview = () => {
    setLoading(true);
    AxiosController.get(`api/${slug}/${lang}/reports/sellers-overview`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        seller_id: seller,
      },
    })
      .then((res) => { 
        setSellers(res?.data?.sellers || []);  
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error("Error fetching data:", e);
      });
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          setSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search && <StatisticsSearchComponent type={'seller'} />} 
      <div className='row'>
        {seller && sellers.length == 1 ? (
          sellers.map((seller) => (
            <div className="col-lg-6 gy-4" key={seller.seller_id}>
              <h4 className='text-center'>
                Name: {seller.seller_first_name} {seller.seller_last_name} <br/> <br/> (ID: {seller.seller_id})
              </h4>
              <Bar
                data={{
                  labels: [_("panel.sys_commission"), _("panel.seller_commission"), _("panel.marketer_commission")],
                  datasets: [
                    {
                      label: 'الارباح',
                      data: [
                        seller?.sys_commission  ,
                        seller?.seller_commission  ,
                         seller?.marketer_commission  ,
                      ],
                      backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(75, 192, 192, 0.2)'],
                      borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(75, 192, 192, 1)'],
                      borderWidth: 1,
                    },
                  ],
                }}
                options={options}
              />
              <p>{_("panel.sys_commission") } : {seller?.sys_commission.toLocaleString() }</p>
              <p>{  _("panel.seller_commission") } : {seller?.seller_commission.toLocaleString()}</p>
              <p>{  _("panel.marketer_commission")} :{seller?.marketer_commission.toLocaleString() }</p>
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
};

export default BarChartSellers;
