import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import StatusGenerator from "../../../../globals/StatusGenerator";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AllStatus from "../../../../authentication/AllStatus";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "./style.css";
import AxiosController from "../../../../authentication/AxiosController";
const AdminSearchComponent = ({ ConfUsers }) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const location = useLocation();
  const Navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

  const [selectedCities, setSelectedCities] = useState([]);
  const cities = [];
  const [governments, setGovs] = useState([]);
  const [selectedGovs, setSelectedGovs] = useState([]);
  const govs = [];
  const cours = [];
  const [couriers, setCouriers] = useState([]);
  const [selectedCouriers, setSelectedCouriers] = useState([]);
  AllStatus.forEach((status) => {
    cities.push({ name: _(`panel.${status}`), Code: status });
  });
  governments.forEach((gov) => {
    govs.push({ name: gov.arabic_name, Code: gov.id });
  });
  couriers.forEach((gov) => {
    cours.push({ name: gov.name, Code: gov.id });
  });

  const [orderNumber, handleOrderNumber] = useState("");
  const [orderCourierNumber, handleOrderCourierNumber] = useState("");
  const [customerNumber, handleCustomerNumber] = useState("");
  const [productCode, handleProductCode] = useState("");
  const [sku, handleSku] = useState("");
  const [marketerId, handleMarketerId] = useState("");
  const [confirmationId, handleConfirmationId] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [startDate2, handleStartDate2] = useState("");
  const [endDate, handleEndDate] = useState("");
  const [endDate2, handleEndDate2] = useState("");
  const [refOrder, setRefOrder] = useState("");
  const [perPage, setPerPage] = useState("");

  const fetchCouriers = () => {
    AxiosController.get(`api/${slug}/${lang}/couriers/get-all-couriers/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCouriers(res.data);
      })
      .catch((e) => {});
  };
  const fetchGovs = () => {
    AxiosController.get(`api/${slug}/${lang}/get-shippingcost`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setGovs(res.data);
      })
      .catch((e) => {});
  };
  const changeParam = () => {
    urlParams.set("order_number", orderNumber);
    urlParams.set("customer_phone", customerNumber);
    urlParams.set("product_code", productCode);
    urlParams.set("sku", sku);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    urlParams.set("start_date2", startDate2);
    urlParams.set("end_date2", endDate2);
    urlParams.set("per_page", perPage);
    urlParams.set("page", 1);
    urlParams.set(
      "status",
      selectedCities.map((city) => city.Code)
    );
    urlParams.set(
      "govs",
      selectedGovs.map((gov) => gov.Code)
    );
    urlParams.set(
      "couriers",
      selectedCouriers.map((courier) => courier.Code)
    );
    urlParams.set("marketer_id", marketerId);
    urlParams.set("confirmation_user", confirmationId);
    urlParams.set("courier_track", orderCourierNumber);
    urlParams.set("reference_order", refOrder);

    Navigate(`?${urlParams.toString()}`);
  };

  const resetHandler = () => {
    handleOrderNumber("");
    handleOrderCourierNumber("");
    handleCustomerNumber("");
    handleProductCode("");
    handleSku("");
    handleConfirmationId("");
    setPerPage("")
    handleStartDate("");
    handleEndDate("");
    handleStartDate2("");
    handleEndDate2("");
    setSelectedCities([]);
    setSelectedCouriers([]);
    setSelectedGovs([]);
    handleMarketerId("");
    setRefOrder("");

    Navigate("");
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(startDate);
    handleEndDate(endDate);
  };

  const handleApply2 = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate2(startDate);
    handleEndDate2(endDate);
  };
  useEffect(() => {
    fetchCouriers();
    fetchGovs();
  }, [ConfUsers]);

  return (
    <form
      className="w-100 d-flex flex-column gap-1"
      onSubmit={(e) => {
        e.preventDefault();
        // handleConfirmationId(e.target.value);
        changeParam();
      }}
    >
      <div className="inputs-row d-flex  flex-wrap gap-2 mb-3 flex-lg-nowrap flex-column  flex-md-row">
      <Col  >
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              type="text"
              placeholder={_("panel.refOrder")}
              onChange={(e) => {
                setRefOrder(e.target.value);
              }}
              value={refOrder}
            />
          </Form.Group>
        </Col>
        <Col >
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              type="text"
              placeholder={_("panel.orderNumber")}
              onChange={(e) => {
                handleOrderNumber(e.target.value);
              }}
              value={orderNumber}
            />
          </Form.Group>
        </Col>
        <Col >
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              type="text"
              placeholder={
                _("home.phonenumber") + " " + _("panel.customerInfo")
              }
              onChange={(e) => {
                handleCustomerNumber(e.target.value);
              }}
              value={customerNumber}
            />
          </Form.Group>
        </Col>
        <Col >
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              type="text"
              placeholder={_("panel.productcode")}
              onChange={(e) => {
                handleProductCode(e.target.value);
              }}
              value={productCode}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={3}>
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              type="text"
              placeholder="SKU"
              onChange={(e) => {
                handleSku(e.target.value);
              }}
              value={sku}
            />
          </Form.Group>   
        </Col>
      </div>
      <div className="inputs-row d-flex  flex-wrap gap-2 mb-3 flex-lg-nowrap flex-column  flex-md-row">
        <Col>
          <Form.Group className=" flex-grow-1  ">
            <MultiSelect
              value={selectedCities}
              onChange={(e) => {
                setSelectedCities(e.value);
              }}
              options={cities}
              optionLabel="name"
              placeholder={_("panel.status")}
              maxSelectedLabels={3}
              className="w-100 align-items-center px-3 py-1 "
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className=" flex-grow-1 h-100">
            <div className="d-flex justify-content-center h-100">
              <MultiSelect
                value={selectedCouriers}
                onChange={(e) => {
                  setSelectedCouriers(e.value);
                }}
                options={cours}
                optionLabel="name"
                placeholder={_("panel.Courier")}
                maxSelectedLabels={3}
                className="w-100 align-items-center px-3 py-1"
              />
            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className=" flex-grow-1 h-100">
            <div className="d-flex justify-content-center h-100">
              <MultiSelect
                value={selectedGovs}
                onChange={(e) => {
                  setSelectedGovs(e.value);
                }}
                options={govs}
                optionLabel="name"
                placeholder={_("home.province")}
                maxSelectedLabels={3}
                className="w-100 align-items-center px-3 py-1"
              />
            </div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1 "
          >
            <Form.Select
              onChange={(e) => {
                handleConfirmationId(e.target.value);
              }}
              value={confirmationId}
            >
              <option value={""} disabled>
                {_("panel.callcenter")}
              </option>
              {ConfUsers
                ? ConfUsers.map((user, index) => {
                    return (
                      <option selected={false} value={user.id} key={index}>
                        {user.first_name + " " + user.last_name}
                      </option>
                    );
                  })
                : ""}
            </Form.Select>
          </Form.Group>
        </Col>   <Col   >
 

          <Form.Group
            className=" flex-grow-1  "
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              type="text"
              placeholder={_("panel.themarketer")}
              onChange={(e) => {
                handleMarketerId(e.target.value);
              }}
              value={marketerId}
            />
          </Form.Group>
        </Col>

      </div>
      <div className="inputs-row d-flex w-100 flex-wrap flex-lg-nowrap gap-2 mb-3 flex-column  flex-md-row  ">
 

     
        <Col>
        

          <Form.Group
            className=" flex-grow-1 "
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              type="text"
              placeholder={_("panel.orderCourierTrack")}
              onChange={(e) => {
                handleOrderCourierNumber(e.target.value);
              }}
              value={orderCourierNumber}
            />
          </Form.Group>
        </Col>
        <Col  className="d-flex  gap-1 ">
        <label  className='mt-2'>    {_("panel.dateAdd")}  </label>
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <DateRangePicker onApply={handleApply}>
              <input type="text" className="form-control" />
            </DateRangePicker>
          </Form.Group>
        </Col>
        <Col className="d-flex gap-1  ">
        <label className='mt-2'> {_("panel.dateState")}  </label>

          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <DateRangePicker onApply={handleApply2}>
              <input type="text" className="form-control" />
            </DateRangePicker>
          </Form.Group>
        </Col>
      </div>

      <div className="action-inputs d-flex justify-content-between">
        <input
          className=" px-5 align-self-end py-2"
          style={{
            backgroundColor: "rgb(243, 168, 55)",
            border: "none",
            borderRadius: "7px",
            color: "white",
            textAlign: "center",
          }}
          onClick={() => {
            changeParam();
          }}
          type="submit"
          value={_("panel.search")}
        />
        <Button
          style={{ backgroundColor: "rgb(23, 43, 77)" }}
          variant="dark"
          className="d-inline"
          onClick={resetHandler}
        >
          {_("panel.reset")}
        </Button>
      </div>
    </form>
  );
};

export default AdminSearchComponent;
