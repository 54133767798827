import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useLocation, useNavigate } from "react-router-dom";
const SearchComponent = () => {
  const [_] = useTranslation("global");
  const location = useLocation();
  const Navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

  const [code, handleCode] = useState("");
  const [userId, handleUserId] = useState("");
  const [status, handleStatus] = useState("");
  const [type, setType] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");

  const [startUpdatedAt, handleStartUpdatedAt] = useState("");
  const [endUpdatedAt, handleEndUpdatedAt] = useState("");

  const changeParam = () => {
    urlParams.set("code", code);
    urlParams.set("user_id", userId);
    urlParams.set("start_date", startDate);
    urlParams.set("start_Updated_at", startUpdatedAt);
    urlParams.set("end_updated_at", endUpdatedAt);
    urlParams.set("end_date", endDate);
    urlParams.set("status", status);
    urlParams.set("type", type);
    Navigate(`?${urlParams.toString()}`);
  };
  const resetHandler = () => {
    handleCode("");
    handleUserId("");
    handleStatus("");
    handleStartDate("");
    handleStartUpdatedAt("");
    handleEndUpdatedAt("");
    handleEndDate("");
    Navigate("");
    setType("")
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(startDate);
    handleEndDate(endDate);
  };
  const handleUpdatedAt = (event, picker) => {
    const startUpdatedAt = picker.startDate.format("YYYY-MM-DD");
    const endUpdatedAt = picker.endDate.format("YYYY-MM-DD");
    handleStartUpdatedAt(startUpdatedAt);
    handleEndUpdatedAt(endUpdatedAt);
  };
  const paymentArabicEng = [
    "محفظة",
    "حواله بنكية",
    "باي بال",
    "تيك توك",
    "فيزا ايكومبو",
    "USDT",
    "وايز",
  ];
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        changeParam();
      }}
    >
      <div className="w-100 d-flex flex-column gap-1 my-3">
        <div className="inputs-row d-flex w-100 flex-nowrap  flex-md-row flex-column gap-2">
          <Col  >
            <Form.Group className="mb-3 " controlId="exampleForm.ControlInput1">
              <Form.Control
                type="text"
                placeholder={_("panel.code")}
                onChange={(e) => {
                  handleCode(e.target.value);
                }}
                value={code}
              />
            </Form.Group>
          </Col>
          <Col  >
            <Form.Group
              className="mb-3 flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={_("panel.code2")}
                onChange={(e) => {
                  handleUserId(e.target.value);
                }}
                value={userId}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={2}>
            <Form.Group className="mb-3 flex-grow-1">
              <Form.Select
                onChange={(e) => {
                  handleStatus(e.target.value);
                }}
                value={status}
              >
                <option value={""} disabled>
                  حالة الطلب
                </option>
                <option value={"Pending"}>{_("panel.Pending")}</option>
                <option value={"Approved"}>{_("panel.Approved")}</option>
                <option value={"Rejected"}>{_("panel.Rejected")}</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col  >
            <Form.Group className="mb-3 flex-grow-1">
              <Form.Select
                onChange={(e) => {
                  setType(e.target.value);
                }}
                value={type}
              >
                <option value={""} disabled>
                  نوع المحفظة
                </option>
                {paymentArabicEng.map((type) => {
                  return (
                    <option value={type}>{type}</option>
                  );
                })}

              </Form.Select>
            </Form.Group>
          </Col>
          
        </div>
        <div className="inputs-row d-flex w-100 flex-nowrap  flex-md-row flex-column gap-2">
 
          
          <Col  >
          <label  className='mb-1'>    {_("panel.dateAdd")}  </label>
            <Form.Group
              className="mb-3 flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <DateRangePicker onApply={handleApply}>
                <input type="text" className="form-control" />
              </DateRangePicker>
            </Form.Group>
          </Col>
          
            
          <Col  >
          <label  className='mb-1'>    {_("panel.dateState")}  </label>
            <Form.Group
              className="mb-3 flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <DateRangePicker onApply={handleUpdatedAt}>
                <input type="text" className="form-control" />
              </DateRangePicker>
            </Form.Group>
          </Col>
        </div>
        {/* <div className="inputs-row d-flex w-100 gap-2">
        </div> */}
        <div className="action-inputs">
          <input
            className=" px-5 align-self-end py-2"
            style={{
              backgroundColor: "rgb(243, 168, 55)",
              border: "none",
              borderRadius: "7px",
              color: "white",
              textAlign: "center",
            }}
            onClick={() => {
              changeParam();
            }}
            type="submit"
            value={_("panel.search")}
          />
          <Button
            style={{ backgroundColor: "rgb(23, 43, 77)" }}
            variant="dark"
            className="d-inline"
            onClick={resetHandler}
          >
            {_("panel.reset")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SearchComponent;
