import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
const LangSelector = ({ slug, lang }) => {
  const newLang = lang === "ar" ? "en" : "ar";
  const location = useLocation();
  let newLangUrl;
  if (slug) {
    newLangUrl = `/${slug}/${newLang}${location.pathname.substring(
      location.pathname.indexOf(lang) + lang.length
    )}`;
  } else {
    newLangUrl = `/${newLang}${location.pathname.substring(
      location.pathname.indexOf(lang) + lang.length
    )}`;
  }
  return (
    <Dropdown style={dropdownStyles}>
      <Dropdown.Toggle
        variant="secondary"
        id="dropdown-basic"
        style={dropdownBtn}
      >
        {lang === "ar" ? "عربي" : "English"}
      </Dropdown.Toggle>

      <Dropdown.Menu
        aria-labelledby="dropdown-basic"
        style={{ maxWidth: "0 !important" }}
      >
        <Dropdown.Item>
          <Link style={dropdownBtn} to={newLangUrl}>
            {lang === "ar" ? "English" : "عربي"}
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
const dropdownStyles = {
  padding: "10px",
  background: "transparent",
};
const dropdownBtn = {
  backgroundColor: "transparent",
  color: "black",
  border: "none",
  textDecoration: "none",
};

export default LangSelector;
