import * as XLSX from "xlsx";

const exportToExcel = (data, title) => {
  // Filter the data
  const filteredData = data.filter(
    (item) =>
item.mi_role === "fulfillment"
  );

  // Map the filtered data to the required format
  const formattedData = filteredData.map((item) => {
    const shipping_cost = item.shipping_cost; // Assuming shipping cost is 0 as it's not provided
    const total_fees =
      shipping_cost +
      item.confirmation_price +
      item.fulfillment_price +
      item.cancellation_price;
    const earning = item.total_price - total_fees;

    return {
      Order: item.track,
      "Reference Order": item.reference_order,
      Status: item.status,
      COD: item.total_price,
      "Shipping cost": shipping_cost,
      Confirmation: item.confirmation_price,
      Fulfillment: item.fulfillment_price,
      Cancellation: item.cancellation_price,
      "Total fees": total_fees,
      Earning: earning,
      Notes: "",
    };
  });

  // Calculate the sum of the specified columns
  const sumRow = {
    Order: "Total",
    "Reference Order": "",
    Status: "",
    COD: formattedData.reduce((sum, item) => sum + item.COD, 0),
    "Shipping cost": formattedData.reduce(
      (sum, item) => sum + item["Shipping cost"],
      0
    ),
    Confirmation: formattedData.reduce(
      (sum, item) => sum + item.Confirmation,
      0
    ),
    Fulfillment: formattedData.reduce((sum, item) => sum + item.Fulfillment, 0),
    Cancellation: formattedData.reduce(
      (sum, item) => sum + item.Cancellation,
      0
    ),
    "Total fees": formattedData.reduce(
      (sum, item) => sum + item["Total fees"],
      0
    ),
    Earning: formattedData.reduce((sum, item) => sum + item.Earning, 0),
    Notes: "",
  };

  // Insert the sum row at the beginning
  formattedData.unshift(sumRow);

  // Create a new workbook and worksheet
  const ws = XLSX.utils.json_to_sheet(formattedData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Export the workbook
  XLSX.writeFile(wb, title+".xlsx");
};
export default exportToExcel;
