import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import TableLabels from "./TableLabels";
import IconsGen from "../../../icons_generator/IconsGen";
const WarehousesTableRow = ({ payments_list, alt_name, handleLoader }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless " htmlFor="flexCheckDefault">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order) => {

            return (
              <tr className="">
                <td>{order["name"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WarehousesTableRow;
