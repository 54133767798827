import React from 'react'

const SupportTicketBox = ({content, date, addedBy, user_id, photo}) => {
  return (
    <div className='d-flex flex-column gap-3 p-3 ticket-box' 
        style={{border:'1.3px solid #EFEDED', borderRadius:'7px'}}
    >
      <p className='m-0'>{content}</p>
      <p className='m-0' style={{color:'#A5A5A5'}}>{date}</p>
      <span style={{color: '#59C6C9'}} className='d-flex align-items-center gap-3'>
        <img width={'50px'} src={photo} className='rounded-circle' />
        <p className='m-0'>{addedBy} #{user_id}</p>
      </span>
    </div>
  )
}

export default SupportTicketBox
