import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";

function NewSettlement({
  showModal,
  setShowModal,
  action,
  modalHeader,
  confirmButton,
  title,
  setTitle,
}) {
  const [_] = useTranslation("global");
  const handleClose = () => {
    setShowModal(false);
  };
  const handleAction = () => {
    action();
    handleClose();
  };

  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{_(`panel.${modalHeader}`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {label_input(_("panel.settleName"), title, setTitle)}
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "red" }} onClick={handleClose}>
            {_(`panel.close`)}
          </Button>
          <Button
            variant="primary"
            onClick={title.length ? handleAction : () => {}}
          >
            {_(`panel.${confirmButton}`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
const label_input = (label, newWarehouseName, handleNewWarehouseName) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
      />
    </div>
  );
};

export default NewSettlement;
