import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import VariantsStock from "../product-variant-stock/VariantsStock";

const get_product_details = (
  slug,
  lang,
  token,
  handleProductData,
  productId,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/full-product-details/${productId}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleProductData(res.data);
      handleLoader(false);
    })
    .catch((e) => {
    });
};

const get_product_details_stock = (
  slug,
  lang,
  token,
  handleProductsStock,
  page,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/get-products-stocks/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleProductsStock(res.data);
      handleLoader(false);
    })
    .catch((e) => {
    });
};

const ProductStockDetails = ({ productId, showComp, handleShowComp }) => {
  const { slug, lang } = useParams();

  const token = localStorage.getItem("token");
  const [show, setShow] = useState(showComp);
  const [productData, handleProductData] = useState({});
  const handleClose = () => {
    setShow(false);
    handleShowComp(false);
  };
  const handleShow = () => setShow(showComp);
  const [loading, handleLoader] = useState(false);

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  useEffect(() => {
    if (productId && showComp) {
        handleLoader(true);
      get_product_details(
        slug,
        lang,
        token,
        handleProductData,
        productId,
        handleLoader
      );
    }
    if (showComp) {
      handleShow();
    }
  }, [productId, showComp]);
  return (
    <div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Loader  active={loading}/>
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">
            {_("panel.productStockDetails")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
            {constant_labels(_('panel.productTitle'), productData[0] ? productData[0]['title'] : '')}
            {constant_labels(_('panel.seller'), productData[0] ? productData[0]['first_name'] + ' ' + productData[0]['last_name'] : '')}
            {constant_labels(_('panel.category'), productData[0] ? productData[0]['arabic_name'] : '')}
            {constant_labels(_('panel.varieties'), productData[1] ? productData[1] : [''], true)}
            <VariantsStock productId={productId} />
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button variant="primary" onClick={() => {handleClose()}}>
            {_("panel.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const constant_labels = (label, value, is_array = false) => {
    return (
      <div className="info-group d-flex flex-md-row flex-column gap-2">
        <div className="info-group d-flex gap-2 align-items-center">
          <span className="user-key">
            <p>{label} : </p>
          </span>
          <span className="user-value">
            {(is_array) ? (
                value.map((sku) => {
                    return (<p className="m-0">{sku.sku} : {sku.price}</p>);
                })
            ) : (
            <p>{value}</p>

            )}
          </span>
        </div>
      </div>
    );
  };
export default ProductStockDetails;
