import React from "react";
import { useTranslation } from "react-i18next";

const TableLabels = () => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const roles_labels = {
    affiliate: [
      _("panel.date"),
      _("panel.code"),
    ],
  };

  return (
    <tr>
      <th>
        <div
          className="form-check mt-3 d-flex justify-content-center align-items-center"
          style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
        >
          <input
            className="form-check-input"
            style={{
              border: "1px solid rgba(243, 168, 55, 1)",
              transform: "translate(-0%, -50%)",
            }}
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
        </div>
      </th>
      {roles_labels[role].map((label) => {
        return <th>{label}</th>;
      })}
      <th></th>
    </tr>
  );
};

export default TableLabels;
