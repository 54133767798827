import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../authentication/AxiosController";

const change_order_status = (
  slug,
  lang,
  token,
  orderId,
  status,
  currentStatusHandler,
  handleLoader,
  handleStatusChangeErr
) => {
  AxiosController.put(
    `api/${slug}/${lang}/change-request-status/${orderId}`,
    { status },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      currentStatusHandler(status);
      handleLoader(false);
    })
    .catch((e) => {
      handleStatusChangeErr(true);
      handleLoader(false);
    });
};

const TableSelectStatus = ({ currentStatus, orderId, handleLoader, disabled, handleStatus }) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [orderStatus, handleOrderStatus] = useState("");

  const [_] = useTranslation("global");
  const statues = ["Pending", "Approved", "Rejected"];
  const StatusColors = {
    Approved: "rgb(116, 217, 144)",
    Rejected: "rgb(252, 131, 131)",
    Pending: "#98B6B5",
  };
 
  useEffect(() => {
    handleOrderStatus(currentStatus);
  }, [currentStatus]);
  return (
    <div style={{ minWidth: "130px" }}>
      <Form.Select
                        disabled={currentStatus=="Approved" ||currentStatus== "Rejected"}
                        style={{
          border: "1px solid " + StatusColors[orderStatus],
          color: "rgba(23, 43, 77, 1)",
          background:StatusColors[orderStatus]
        }}
        onChange={(e) => {
          handleOrderStatus(e.target.value);
          handleStatus(e.target.value);
        }}
      >
        <option selected={true} value={currentStatus}>
          {_(`panel.${orderStatus}`)}
        </option>
        {statues.map((status) => {
          if (status !== currentStatus && disabled) {
            return (
              <option selected={false} value={status}>
                {_(`panel.${status}`)}
              </option>
            );
          }
        })}
      </Form.Select>
    </div>
  );
};

export default TableSelectStatus;
