import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../authentication/AxiosController";
import { useParams } from "react-router-dom";
const ConfUsers = ({
  selectedOrders,
  confirmationUsers,
  fetchingOrdersHandler,
}) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [confUser, setConfUser] = useState("");

  const changeConfUser = (confUser) => {
    AxiosController.put(
      `api/${slug}/${lang}/orders/change-confirmation`,
      {
        confirmation_user: confUser,
        orders_id: selectedOrders.map((order) => {
          return order.id;
        }),
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setConfUser("");
        fetchingOrdersHandler();
      })
      .catch((e) => {});
  };

  return (
    <div className="inputs-row d-flex w-100 gap-2 align-items-center ">
      <Form.Group
        className="mb-3 flex-grow-1 d-flex gap-2 flex-column flex-md-row"
        controlId="exampleForm.ControlInput1"
      >
        <Form.Select
          onChange={(e) => {
            setConfUser(e.target.value);
          }}
          value={confUser}
          className=""
        >
          <option selected={true} value={0}>
            {_("panel.callcenter")}
          </option>
          {confirmationUsers.map((user) => {
            if (user.available_for_work) {

              return (
                <option selected={false} value={user["id"]}>
                  {user["first_name"] + " " + user["last_name"]} #{user["id"]}
                </option>
              );
            }
          })}
        </Form.Select>
        <Button
          className=" px-5 align-self-center h-100 w-100"
          style={{
            backgroundColor: "rgb(89, 198, 201)",
            border: "none",
            borderRadius: "7px",
            fontSize: "15px",
          }}
          onClick={() => {
            changeConfUser(confUser);
          }}
        >
          {_("panel.setEmployee")}
        </Button>
        <Button
          className=" px-5 align-self-center h-100 w-100"
          style={{
            backgroundColor: "rgb(89, 198, 201)",
            border: "none",
            borderRadius: "7px",
            fontSize: "15px",
          }}
          onClick={() => {
            setConfUser("");
            changeConfUser("");
          }}
        >
          {_("panel.resetEmployee")}
        </Button>
      </Form.Group>
    </div>
  );
};

export default ConfUsers;
