import axios from "axios";
import BaseServerUrl from "./BaseServerUrl";

const AxiosController = axios.create({
  baseURL: BaseServerUrl,
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  headers: {
    Accept: "application/json",
  },
  withCredentials: true
});
export default AxiosController;
