import React from "react";
import { useTranslation } from "react-i18next";

const LandpagesTableLabels = () => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const roles_labels = {
    affiliate: [
      _("panel.date"),
      _("panel.landpageTitle"),
      _("panel.store"),
      _("panel.landpageLink"),
      _("panel.cancel2"),
    ],
  };

  return (
    <tr>
      {roles_labels[role].map((label) => {
        return <th>{label}</th>;
      })}
    </tr>
  );
};

export default LandpagesTableLabels;
