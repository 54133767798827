import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import IconsGen from "../../icons_generator/IconsGen";
import TableLabels from "./TableLabels";
import TableSelectStatus from "./TableSelectStatus";
import TableSelectLevel from "./TableSelectLevel";
import TableSelectStatus2 from "./TableSelectStatus2";
import TableSelectPending from "./TableSelectPending";
const colors = {
  seller: "rgba(89, 198, 201, 1)",
  affiliate: "rgb(243, 168, 55)",
  employee: "rgba(23, 43, 77, 1)",
};
const PaymentRequestsTableRow = ({ payments_list, alt_name, handleLoader ,levels  }) => {

  
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.data.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");
            return (
              <tr className="">
                <td key={order.id}>
                  <div
                    className="form-check mt-3"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </td>
                <td scope="row" className="align-middle">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td className="align-middle">{order["id"]}</td>
                <td className="align-middle">
                  {order["first_name"] ?? ""} {order["last_name"] ?? ""} <br />
                  {order["email"] ?? ""}
                </td>
                <td className="align-middle">
                  <img
                    className="rounded-circle"
                    height={"75px"}
                    src={order["photo"]}
                    alt=""
                  />
                </td>
                <td
                  style={{ color: colors[order["role"]] }}
                  className="align-middle"
                >
                  {_(`panel.${order["role"]}`) ?? ""} <br />
                  {_(`panel.${order["employee_type"]}`) ?? ""} 
                </td>
                {role == "admin" &&  
                  <td className="align-middle">
                    <TableSelectStatus
                      currentStatus={order["is_active"]}
                      orderId={order.id}
                      handleLoader={handleLoader}
                    />
                  </td>  }
                <td className="align-middle">
                  <TableSelectStatus2
                    currentStatus={order["available_for_work"]}
                    orderId={order.id}
                    handleLoader={handleLoader}
                  />
                </td>
                {role == "admin" && 
                <>      <td className="align-middle">
                    <TableSelectLevel
                      currentLevel={order.level}
                      userId={order.id} 
                      levelId={order.level_id}
                      handleLoader={handleLoader}
                      userRole={order.role}
                      levels={levels}
                    />
                  </td>
                  <td className="align-middle">
                    <TableSelectPending 
                      userId={order.id} 
                      levelId={order.level_id}
                      handleLoader={handleLoader}
                      userRole={order.role}
                      pending={order.pending}

                    />
                  </td>
                  </>
               }
                {role !== "seller" ? (
                  <NavLink
                    to={`/${slug}/${lang}/panel/user/${order["id"]}`}
                    style={{}}
                  >
                    <td className="d-flex justify-content-center align-items-center px-1">
                      {<IconsGen icon_name={"enter"} />}
                    </td>
                  </NavLink>
                ) : (
                  ""
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentRequestsTableRow;
