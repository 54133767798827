import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import TableLabels from "./TableLabels";
import IconsGen from "../../../../icons_generator/IconsGen";
import AxiosController from "../../../../authentication/AxiosController";

import * as XLSX from "xlsx";


const exportToExcel = (data, name) => {



  // Map the filtered data to the required format
  const formattedData = data.map((item) => {
    const shipping_cost = item.shipping_cost; // Assuming shipping cost is 0 as it's not provided
    const total_fees =
      shipping_cost +
      item.confirmation_price +
      item.fulfillment_price +
      item.cancellation_price;
    const earning = item.total_price - total_fees;

    return {
      Order: item.track,
      "Reference Order": item.reference_order,
      Status: item.status,
      COD: item.total_price,
      "Shipping cost": shipping_cost,
      Confirmation: item.confirmation_price,
      Fulfillment: item.fulfillment_price,
      Cancellation: item.cancellation_price,
      "Total fees": total_fees,
      Earning: earning,
      Notes: "",
    };
  });

  // Calculate the sum of the specified columns
  const sumRow = {
    Order: "Total",
    "Reference Order": "",
    Status: "",
    COD: formattedData.reduce((sum, item) => sum + item.COD, 0),
    "Shipping cost": formattedData.reduce(
      (sum, item) => sum + item["Shipping cost"],
      0
    ),
    Confirmation: formattedData.reduce(
      (sum, item) => sum + item.Confirmation,
      0
    ),
    Fulfillment: formattedData.reduce((sum, item) => sum + item.Fulfillment, 0),
    Cancellation: formattedData.reduce(
      (sum, item) => sum + item.Cancellation,
      0
    ),
    "Total fees": formattedData.reduce(
      (sum, item) => sum + item["Total fees"],
      0
    ),
    Earning: formattedData.reduce((sum, item) => sum + item.Earning, 0),
    Notes: "",
  };

  // Insert the sum row at the beginning
  formattedData.unshift(sumRow);

  // Create a new workbook and worksheet
  const ws = XLSX.utils.json_to_sheet(formattedData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Export the workbook
  XLSX.writeFile(wb, name+".xlsx");
};

const SuppliersTableRow = ({ payments_list, settingModal, handleLoader }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem('token');
  const ordersToExport = (tracks, name) => {

    AxiosController.get(`api/${slug}/${lang}/get-sellings-orders/1`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        tracks,
        show_all:  1
      },
    })
      .then((res) => {
        exportToExcel(res.data.orders.data, name);
      })
      .catch((e) => {});

  }
  const getSettlement = (id, name) => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/settlements/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => { 
        const tracks = res.data.orders.map((order) => {
          return order.track;
        });
        ordersToExport(tracks.join(','), name);
      })
      .catch((e) => {});
    handleLoader(false)
  };
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");
            return (
              <tr className="">
                <td scope="row" className="align-middle">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td className="align-middle">
                  #{order["id"]}
                  <br />
                  {order.user?.first_name} {order.user?.last_name}
                </td>
                <td className="align-middle">{order["name"]}</td>
                <td
                  className="align-middle"
                  onClick={() => {
                    getSettlement(order['id'], order["name"]);
                  }}
                >
                  <IconsGen icon_name={"downloadExcelTaager2"} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SuppliersTableRow;
