import { jsPDF } from "jspdf";
import { format } from "date-fns";
import logo from "../../../../../imgs/Logo_Ecombo 1.png";
import "jspdf-autotable";
import { font } from "../../../../../fonts/font";

// Function to add custom font
var callAddFont = function () {
  this.addFileToVFS("Amiri-Regular-normal.ttf", font);
  this.addFont("Amiri-Regular-normal.ttf", "Amiri-Regular", "normal");
};

jsPDF.API.events.push(["addFonts", callAddFont]);

export const downloadPDF = (selected, total, orderContent, courierNumber, totalorders) => {
  const doc = new jsPDF("p", "mm", "a4");

  // Set the font
  doc.setFont("Amiri-Regular");

  // Add logo to the PDF
  doc.addImage(logo, "PNG", 10, 10, 50, 15); // Adjust position and size as needed

  doc.setFontSize(16);

  // Get the current date and format it
  const currentDate = format(new Date(), "dd/MM/yyyy");

  // Center the main title and set it to bold
  const pageWidth = doc.internal.pageSize.getWidth();
  doc.setFont(undefined, "bold"); // Set the font weight to bold
  doc.text("Manifest", pageWidth / 2, 20, { align: "center" }); // Center the text

  // Add the date near the title
  doc.setFontSize(12);
  doc.setFont(undefined, "normal"); // Reset to normal font weight
  doc.text(`Date: ${currentDate}`, pageWidth / 2, 30, { align: "center" }); // Center the date below the title

  // Define columns and selected for the table
  const columns = [
    { title: courierNumber, dataKey: "courierTrack" },
    { title: total, dataKey: "cod" },
    { title: orderContent, dataKey: "content" },
    { title: "#", dataKey: "index" },
  ];

  const rows = selected?.map((ele, index) => ({
    index: index + 1,
    cod: ele["COD"],
    content: `#${ele["Ref No"]}\n${ele["Content"] ? ele["Content"].replace(/\n/g, "\n\n") : ""}`,
    courierTrack: ele["Courier Track"],
  }));

  // Render the table and add page numbers
  let finalY = 0; // Variable to store the final Y position after the table
  doc.autoTable({
    columns: columns,
    body: rows,
    headStyles: { font: "Amiri-Regular", halign: "center" },
    bodyStyles: { font: "Amiri-Regular", halign: "center" },
    startY: 40,
    margin: { horizontal: 10 },
    theme: "striped",
    didDrawPage: (selected) => {
      // Capture the Y position after the table is drawn
      finalY = selected.cursor.y;

      // Add page number at the bottom center
      const pageCount = doc.internal.getNumberOfPages();
      doc.setFontSize(10);
      doc.text(`Page ${pageCount}`, pageWidth / 2, doc.internal.pageSize.getHeight() - 10, { align: "center" });
    },
  });

  // Check if there is enough space to add the footer content
  const pageHeight = doc.internal.pageSize.getHeight();
  const footerHeight = 20; // Estimated height needed for footer content

  // Only add footer content if there is enough space, otherwise add it on the last page
  if (finalY + footerHeight < pageHeight) {
    addFooterContent(doc, pageWidth, finalY + 10, selected, totalorders); // Pass selected and totalorders to the function
  } else {
    // Add footer to the bottom of the last page
    doc.addPage();
    addFooterContent(doc, pageWidth, 20, selected, totalorders); // Pass selected and totalorders to the function
  }

  // Save the PDF
  doc.save("manifest.pdf");
};

// Function to draw the footer content
const addFooterContent = (doc, pageWidth, startY, selected, totalorders) => {
  doc.setFontSize(12);

  // Draw the first row (Date)
  doc.text("Date:", 10, startY);
  doc.text("______________________________________", 30, startY);
  doc.text("Date:", pageWidth / 2 + 10, startY);
  doc.text("_________________________________", pageWidth / 2 + 30, startY);

  // Draw the second row (Signature)
  const signatureY = startY + 10; // Adjust spacing between rows
  doc.text("Signature:", 10, signatureY);
  doc.text("______________________________________", 30, signatureY);
  doc.text("Signature:", pageWidth / 2 + 10, signatureY);
  doc.text("_________________________________", pageWidth / 2 + 30, signatureY);

  // Optional: Add total orders text centered at the bottom
  doc.setFontSize(10);
// Correct way to add centered text with alignment options and coordinates
doc.text(
  `Total Number of Orders : ${selected.length}`,     // Text for the first line
  pageWidth / 2,                        // X-coordinate for centering the text
  doc.internal.pageSize.height - 15,    // Y-coordinate for the first line
  { align: "center" }                   // Center alignment
);

// Second line: Display the page number
doc.text(
  `Page ${doc.internal.getNumberOfPages()}`,  // Display the current page number
  pageWidth / 2,                              // X-coordinate for centering the text
  doc.internal.pageSize.height - 10,          // Y-coordinate for the second line
  { align: "center" }                         // Center alignment
);
};
