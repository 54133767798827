import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement 
);

const BarChartEarning = ({ data }) => {
  const [_] = useTranslation("global");

  const labels = [_("panel.total_expenses"), _("panel.sys_commission"), _("panel.shipping_cost_difference") , _("panel.revenue_from_previous_months")];
  const values = [
    data?.expenses?.total_expenses,
    data?.sys_commission?.sys_commission,
    data?.sys_commission_diff_shipping?.shipping_cost_diff,
    data?.old_sys_commission?.sys_commission,
  ];

  const barData = {
    labels: labels,
    datasets: [
      {        

        data: values,
        backgroundColor: [
          "rgb(89, 198, 201 ,0.8)",
          "rgb(243, 168, 55 ,0.8)",
          "rgb(113, 1, 255  ,0.8)"
        ],
        borderWidth: 0
      }
    ]
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: _("الارباح"),  
        font: {
          size: 20
        }
      },
      legend: {
        display: false  
      }
    },
    maintainAspectRatio: false,  
  };

  return (
    <div style={{ width: '70%' }}>
      <Bar data={barData} options={options} height={360} width={360} />
    </div>
  );
};

export default BarChartEarning;
