import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TableLabels from "./TableLabels";

const colors = {
  seller: "rgba(89, 198, 201, 1)",
  affiliate: "rgb(243, 168, 55)",
  employee: "rgba(23, 43, 77, 1)",
};
const PaymentRequestsTableRow = ({ payments_list, alt_name, handleLoader }) => {

  const {  lang } = useParams();
  const [_] = useTranslation("global");
  let totalPriceSysLoop = 0,
    totalPriceCourLoop = 0;
  const [totalPriceSys, setTotalPriceSys] = useState(0);
  const [totalPriceCour, setTotalPriceCour] = useState(0);
  useEffect(() => {
    payments_list.forEach((order, index) => {
      totalPriceSysLoop += parseInt(order["total_quantity"]);
    });
    payments_list.forEach((order, index) => {
      totalPriceCourLoop += parseInt(order["order_number"]);
    });
    setTotalPriceSys(totalPriceSysLoop);
    setTotalPriceCour(totalPriceCourLoop);
  }, [payments_list]);
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless " htmlFor="flexCheckDefault">
        <thead>
          <TableLabels
            stats={[payments_list.length, totalPriceCour, totalPriceSys  ]}
          />
        </thead>
        <tbody>
          {payments_list.map((order) => {
            return (
              <tr className="">
                <td key={order.id}>
                  <div
                    className="form-check mt-3"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </td>
                <td className={`align-middle danger `}>
                    <img
                    className="rounded-3"
                    src={order["thumbnail"]}
                    width={"55px"}
                  /> 
                  <br />  {order["sku"]} <br /> #{order["id"]} <br/>  {order["title"] ? order["title"].slice(0, 14) : ""}
                </td> 
                <td className={`align-middle `}>{order["order_number"]}</td>
                <td className={`align-middle `}>{order["total_quantity"]}</td>
                <td className={`align-middle `}><span className="fw-bold">{order["seller_id"]}</span><br/>{order["first_name"] } { order["last_name"]}</td>
                <td className={`align-middle `}>{order["color"] || "__"}</td>
                <td className={`align-middle `}>{order["size"] || "__"}</td>

              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentRequestsTableRow;
