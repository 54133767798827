import React, { useEffect } from "react";
import "../../components/registerations/styles/style.css";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import Login from "../../components/registerations/Login";
import RegisterMarketer from "../../components/registerations/RegisterMarketer";
import i18next from "i18next";
import RightSide from "../../components/registerations/RightSide";
import RightSideMarketer from "../../components/registerations/RightSideMarketer";
import RightSideSeller from "../../components/registerations/RightSideSeller";
import RegisterSeller from "../../components/registerations/RegisterSeller";
import RightSideVerify from "../../components/registerations/RightSideVerify";
import Verify from "../../components/registerations/Verify";
import ForgotPassword from "../../components/registerations/ForgotPassword";
import NewPassword from "../../components/registerations/NewPassword";
import Choice from "../../components/registerations/Choice";
import ChangePasswordVerify from "../../components/registerations/ChangePasswordVerify";
import RightSideChangePasswordVerify from "../../components/registerations/RightSideChangePasswordVerify";

const Registration = () => {
  const { lang } = useParams();
  i18next.changeLanguage(lang);

  const Navigate = useNavigate();
  useEffect(() => {

  }, [Navigate])
  return (
    <div 
      className="d-flex flex-row-reverse vh-for-high"
      dir={lang === "en" ? "rtl" : "ltr"}
    >
      <Routes>
        <Route path={`login`} element={<Login />} />
        <Route path="register/choice" element={<Choice />} />
        <Route path="register/marketer" element={<RegisterMarketer />} />
        <Route path="register/seller" element={<RegisterSeller />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="password/reset" element={<NewPassword />} />
        <Route path="verify/:email" element={<Verify />} />
        <Route path="verify/:email" element={<Verify />} />
        <Route path="changepasswordverify" element={<ChangePasswordVerify />} />
      </Routes>

      <Routes>
        <Route path={`login`} element={<RightSide />} />
        <Route path="register/marketer" element={<RightSideMarketer />} />
        <Route path="register/seller" element={<RightSideSeller />} />
        <Route path="forgot-password" element={<RightSide />} />
        <Route path="password/reset" element={<RightSide />} />
        <Route path="verify/:email" element={<RightSideVerify />} />
        <Route path="verify/:email" element={<RightSideVerify />} />
        <Route path="changepasswordverify" element={<RightSideChangePasswordVerify />} />

      </Routes>
      
    </div>
  );
};

export default Registration;
