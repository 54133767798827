import React, { useEffect, useState } from "react";
import logo from "../../imgs/Logo_Ecombo 1.png";
import { Form, } from "react-bootstrap";
import {  useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSelector from "../languages/LangSelector";


import AxiosController from "../authentication/AxiosController";
import { Helmet } from "react-helmet";

const Verify = () => {
  const { lang, email } = useParams();
  const [message, setMessage] = useState("");
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  const send_email_verify = () => {
    AxiosController.post(`api/${lang}/auth/register/email/verify`, {
      email,
    })
      .then((res) => {})
      .catch((e) => {
        setMessage(e.response.data.message);
      });
  };
  useEffect(() => {}, [email]);
  return (
    <div
      className="   inputs-data d-flex align-items-center px-5 justify-content-center flex-grow-1 position-relative verify"
      style={{ backgroundColor: "white" }}
      dir={box_head_dir}
    ><Helmet>
    <title>  {_("registeration.verifyEmail")}</title> 
 </Helmet>
      <div className="data-entry-container" style={{ width: "100%" }}>
        <div className="d-flex">
          <img src={logo} width="230px" alt="" />
          <LangSelector lang={lang} />
        </div>
        <Form
          className="d-flex flex-column gap-2 text-center align-items-center"
          style={{ direction: box_head_dir }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <h3 style={{ color: "#59C6C9" }}>{_("registeration.verifyEmail")}</h3>
          <p className="fs-5" style={{ color: "#F3A837" }}>
            {email}
          </p>
          <p className="fs-5 w-50" style={{ color: "#A5A5A5" }}>
            {_("registeration.goto")}
          </p>
          <button
            onClick={() => {
              send_email_verify();
            }}
            className="btn"
            style={{
              color: "rgb(243, 168, 55)",
              border: "1px solid rgb(243, 168, 55)",
            }}
          >
            {_("panel.resendEmail")}
          </button>
          <p className="fs-5 w-50">{message ? message : ""}</p>
        </Form>
      </div>
    </div>
  );
};

export default Verify;
