import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import TableLabels from "./TableLabels";
import TableSelectStatus from "./TableSelectStatus";
import IconsGen from "../../../icons_generator/IconsGen";
import SuppliersAddAndUpdate from "../../../modals/SuppliersAddAndUpdate";
import AxiosController from "../../../authentication/AxiosController";
const SuppliersTableRow = ({ payments_list = [] , get_warehouses }) => {
  const { slug, lang } = useParams();
  const [show, setShow] = useState(false);
  const [id, setid] = useState(null);
  const [data, setData] = useState(null);
  const [userData, handleUserData] = useState({});
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      AxiosController.get(`api/${lang}/auth/userdata`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          handleUserData(res.data);
        })
        .catch((e) => {});
    }
  }, []);


  const [_] = useTranslation("global");
  return (
    <>
       <SuppliersAddAndUpdate 
       show={show}
       setShow={setShow}
       update={true}
       id={id}
       data={data}
       get_warehouses={get_warehouses}
     
       />
      <div
        className={`table-responsive my-3 d-flex ${
          lang === "en" ? "flex-row" : "flex-row-reversed"
        } align-items-center`}
      >
        <table className="table table-borderless" htmlFor="flexCheckDefault">
          <thead>
            <TableLabels />
          </thead>
          <tbody>
            {payments_list && payments_list.map((order) => {
              const dateSegments = order["created_at_formatted"].split(" ");
              const dateSegmentsReceived =
                order["received_at_formatted"].split(" ");
              return (
                <tr className="">
                  <td scope="row" className="align-middle">
                    {dateSegments[0]}
                  </td>
                  <td scope="row" className="align-middle">
                    {dateSegments[1]} {dateSegments[2]}
                  </td>
                  <td className="align-middle">{dateSegmentsReceived[0]}</td>

                  <td className="align-middle">{order["name"]}</td>
                  <td className="align-middle">
                    {order["amount"].toLocaleString()}
                  </td>
                  <td className="align-middle">{order["title"]}</td>
                  <td className="align-middle">{order["notes"]}</td>
                {userData.id ===2 && <td   onClick ={()=>{
        setShow(true);
        setid(order["id"]);
        setData(order);

       }}>{<IconsGen icon_name={"enter"} />}</td>}  
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SuppliersTableRow;
