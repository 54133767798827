import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { changePasswordValidationSchema } from "../../utils/validation";
import AxiosController from "../authentication/AxiosController";
import { useParams } from "react-router-dom";
import IconsGen from "../icons_generator/IconsGen";

const ChangePasswordModel = ({ show, setShow }) => {
  const handleClose = () => {
    setShow(false);
    formikPassword.resetForm();  
  };
    const [_] = useTranslation("global");
  const token = localStorage.getItem("token");          
  const { slug, lang } = useParams();
  const [initialPassword, setInitialPassword] = useState({
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  const formikPassword = useFormik({
    initialValues: initialPassword,
    validationSchema: changePasswordValidationSchema,
    onSubmit: handlePasswordSubmit,
  });

  function handlePasswordSubmit(values) { 
    AxiosController.put(
      `api/${slug}/${lang}/user/change-password`,
      values,  // pass values directly
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => { 
        <IconsGen icon_name={"panel.passwordChangeSuccess"} />

        handleClose();
      })       
      .catch((err) => { 
      });
  }

  return (
    <div>
        <Modal show={show} onHide={handleClose}>
      <Form onSubmit={formikPassword.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>{_("panel.changepassword")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicOldPassword">
              <Form.Label>{_("panel.oldPassword")}</Form.Label>
              <Form.Control
                type="password"
                name="old_password"
                value={formikPassword.values.old_password}
                onChange={formikPassword.handleChange}
                onBlur={formikPassword.handleBlur}
                placeholder={_("panel.oldPassword")}
              />
              {formikPassword.touched.old_password && formikPassword.errors.old_password ? (
                      <div className="alert alert-danger">
                        {formikPassword.errors.old_password}
                      </div>
                    ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicNewPassword">
              <Form.Label>{_("panel.newPassword")}</Form.Label>
              <Form.Control
                type="password"
                name="new_password"
                value={formikPassword.values.new_password}
                onChange={formikPassword.handleChange}
                onBlur={formikPassword.handleBlur}
                placeholder={_("panel.newPassword")}
              />
                  {formikPassword.touched.new_password && formikPassword.errors.new_password ? (
                      <div className="alert alert-danger">
                        {formikPassword.errors.new_password}
                      </div>
                    ) : null}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
              <Form.Label>{_("panel.confirmPassword")}</Form.Label>
              <Form.Control
                type="password"
                name="new_password_confirmation"
                value={formikPassword.values.new_password_confirmation}
                onChange={formikPassword.handleChange}
                onBlur={formikPassword.handleBlur}
                placeholder={_("panel.confirmPassword")}
              />
         {formikPassword.touched.new_password_confirmation && formikPassword.errors.new_password_confirmation ? (
                      <div className="alert alert-danger">
                        {formikPassword.errors.new_password_confirmation}
                      </div>
                    ) : null}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button variant="secondary" onClick={handleClose}>
              {_("panel.cancel")}
            </Button>
            <Button variant="primary" type="submit"  >
              {_("panel.changepassword")}               
            </Button>
          </Modal.Footer>
      </Form>
        </Modal>
    </div>
  );
};

export default ChangePasswordModel;
