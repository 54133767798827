import React from "react";

import StatusColors from "../../globals/StatusColors";

const StatusLogsBox = ({ content, date, addedBy, photo, user_id }) => {
  const role = localStorage.getItem("role");
  return (
    <div
      className="d-flex flex-column gap-3 p-3 ticket-box"
      style={{ borderRight: "1.3px dashed #A5A5A5", borderRadius: "7px" }}
    >
      <p className="m-0" style={{ color: StatusColors(photo) }}>
        {content}
      </p>
      <p className="m-0" style={{ color: "#A5A5A5" }}>
        {date} 
      </p>
      {role != "seller" && role != "affiliate" ? (
        <span
          style={{ color: "#59C6C9" }}
          className="d-flex align-items-center gap-3"
        >
          <p className="m-0">
            {addedBy} #{user_id}
          </p>
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default StatusLogsBox;
