import React, { useState } from "react";
import { Button, Col, Form, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import StatusGenerator from "../../../../globals/StatusGenerator";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AllStatus from "../../../../authentication/AllStatus";
import { useLocation, useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";

const SellerSearchComponent = () => {
  const [_] = useTranslation("global");
  const location = useLocation();
  const Navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);
  const [selectedCities, setSelectedCities] = useState([]);
  const cities = [];
  AllStatus.forEach((status) => {
    cities.push({ name: _(`panel.${status}`), Code: status });
  });
  const [orderNumber, handleOrderNumber] = useState("");
  const [customerNumber, handleCustomerNumber] = useState("");
  const [productCode, handleProductCode] = useState("");
  const [sku, handleSku] = useState("");
  const [perPage, setPerPage] = useState("");

  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");
  const resetHandler = () => {
    handleOrderNumber("");
    handleCustomerNumber("");
    handleProductCode("");
    setPerPage("")
    handleSku("");
    handleStartDate("");
    handleEndDate("");
    setSelectedCities("");
    Navigate("");
  };
  const changeParam = () => {
    urlParams.set("order_number", orderNumber);
    urlParams.set("customer_phone", customerNumber);
    urlParams.set("product_code", productCode);
    urlParams.set("sku", sku);
    urlParams.set("per_page", perPage);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    urlParams.set("page", 1);

    urlParams.set(
      "status",
      selectedCities.map((city) => city.Code)
    );
    Navigate(`?${urlParams.toString()}`);
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(startDate);
    handleEndDate(endDate);
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        changeParam();
      }}
    >
      <div className="w-100 d-flex flex-column gap-3">
        <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
          <Col>
            <Form.Group
              className=" flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={_("panel.orderNumber")}
                onChange={(e) => {
                  handleOrderNumber(e.target.value);
                }}
                value={orderNumber}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className=" flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={_("panel.productcode")}
                onChange={(e) => {
                  handleProductCode(e.target.value);
                }}
                value={productCode}
              />
            </Form.Group>
          </Col>
          <Col>

            <Form.Group
              className=" flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder="SKU"
                onChange={(e) => {
                  handleSku(e.target.value);
                }}
                value={sku}
              />
            </Form.Group>
          </Col> 
        </div>
        <div className="inputs-row d-flex w-100 gap-2 flex-column  flex-md-row">
          <Col>
            <Form.Group className=" flex-grow-1 h-100">
              <div className="d-flex justify-content-center h-100">
                <MultiSelect
                  value={selectedCities}
                  onChange={(e) => {
                    setSelectedCities(e.value);
                  }}
                  options={cities}
                  optionLabel="name"
                  placeholder={_("panel.status")}
                  maxSelectedLabels={3}
                  className="w-100 align-items-center px-2 p-2 mb-1"
                />
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className=" flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <DateRangePicker onApply={handleApply}>
                <input type="text" className="form-control p-2" />
              </DateRangePicker>
            </Form.Group>
          </Col>
        </div>

        <div className="action-inputs">
          <input
            className=" px-5 align-self-end py-2"
            style={{
              backgroundColor: "rgb(243, 168, 55)",
              border: "none",
              borderRadius: "7px",
              color: "white",
              textAlign: "center",
            }}
            onClick={() => {
              changeParam();
            }}
            type="submit"
            value={_("panel.search")}
          />
          <Button
            style={{ backgroundColor: "rgb(23, 43, 77)" }}
            variant="dark"
            className="d-inline"
            onClick={resetHandler}
          >
            {_("panel.reset")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SellerSearchComponent;