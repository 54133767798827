import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen";
import CounterComponent from "./CounterComponent";
import AxiosController from "../../../authentication/AxiosController";
  
const PaymentReq = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [number, setNumber] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/panel/transactions/")) {
      setIsSubNavVisible(true);
    }else{
      setIsSubNavVisible(false);

    }
  }, [location.pathname]);
  const fetchConfs = () => {
    AxiosController.get(`api/${slug}/${lang}/payment-requests/counter`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setNumber(res.data[1].count);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    // fetchConfs();
  }, []);
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);

  const handleClick = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };
  return (
    <div className="links-container d-flex flex-column w-100">
      
      <div
        className={`feature_link w-100  justify-content-between ${
          lang === "en" ? "feature_link_en" : ""
        } d-flex flex-row-reverse align-items-center gap-2 py-3 ${isSubNavVisible ? 'active-link' : ''}`}
        dir={direction}
        onClick={handleClick}
        // to={`/${slug}/${lang}/panel/transactions`}
      >
        <span className="d-flex flex-row-reverse gap-2">
          <IconsGen icon_name={"Money313"} />
          <p>{_("panel.Finances")}</p>
        </span>
        {/* <CounterComponent theNumber={number}/> */}
      </div>
      <ul className={`${isSubNavVisible ? 'd-flex' : 'd-none'}  sub-nav  flex-column gap-2 px-5 pt-3`}>
        <li>
          <NavLink to={`/${slug}/${lang}/panel/transactions/payment-requests`}>
            <p className="d-inline ">{_("panel.payReq")}</p>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/${slug}/${lang}/panel/transactions/expenses`}>
            <p className="d-inline ">{_("panel.exports")}</p>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/${slug}/${lang}/panel/transactions/income`}>
            <p className="d-inline ">{_("panel.imports")}</p>
          </NavLink>
        </li>
        <li>
          <NavLink to={`/${slug}/${lang}/panel/transactions/balances`}>
            <p className="d-inline ">{_("panel.balances")}</p>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default PaymentReq;
