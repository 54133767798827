import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Modal, Pagination } from "react-bootstrap";
import SuppliersTableRow from "./SuppliersTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader"; 
import InternalTransfer from "../../../modals/InternalTransfer.jsx";
import SuppliersAddAndUpdate from "../../../modals/SuppliersAddAndUpdate";
import SearchComponent from "./SearchComponent";

const get_warehouses = (
  slug,
  lang,
  token,
  handleWarehouses,
  courierId,
  page,
  handleLoader,
  expense_label,
  start,
  end,
  account_id,
  spent_at_start,
  spent_at_end,
  affected_user_id
) => {
  AxiosController.get(`api/${slug}/${lang}/expenses/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: { expense_label, start, end ,account_id , spent_at_start, spent_at_end ,affected_user_id},
  })
    .then((res) => {
      handleWarehouses(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const get_shipping_costs = (
  slug,
  lang,
  token,
  shippingcostsHandler,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/accounts/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      shippingcostsHandler(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const Income = () => {
  const { slug, lang, courierId } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [suppliers, handleSuppliers] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [cost, setCost] = useState("");
  const [show, setShow] = useState(false);
  const [showInternalTransfer, setShowInternalTransfer] = useState(false);
  const [banks, setBanks] = useState([]);
  const [page, handlePage] = useState(urlParams.get("page") || 1);
  const [search, setSearch] = useState(false);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };

  useEffect(() => {
    handleLoader(true);
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;

    const expense_label = urlParams.get("expense_label") || "";
    const account_id = urlParams.get("account_id") || "";
    const start = urlParams.get("start") || "";
    const end = urlParams.get("end") || "";
    const spent_at_start = urlParams.get("spent_at_start") || "";
    const spent_at_end = urlParams.get("spent_at_end") || "";
    const affected_user_id = urlParams.get("affected_user_id") || "";
    handlePage(nextPage);
    get_shipping_costs(slug, lang, token, setBanks, handleLoader);
    get_warehouses(
      slug,
      lang,
      token,
      handleSuppliers,
      courierId,
      nextPage,
      handleLoader,
      expense_label,
      start,
      end,
      account_id,
      spent_at_start,
      spent_at_end,
      affected_user_id
    );
  }, [slug, lang, location.search]);

  return (
    <>
      <SuppliersAddAndUpdate
        show={show}
        setShow={setShow}
        update={false}
        get_warehouses={() =>
          get_warehouses(
            slug,
            lang,
            token,
            handleSuppliers,
            courierId,
            1,
            handleLoader
          )
        }      />
      <div
        className="position-relative tables-container pt-2"
        style={{ minHeight: "100px", width: "100%" }}
      >
        <div className="d-flex  row mb-4">
          <div className="col-6">
            <Button
              className=" w-100"
              variant="primary"
              onClick={() => {
                setShow(true);
              }}
              style={{ background: "#59C6C9", color: "white", border: "none" }}
            >
              {_("panel.add")}
            </Button>
          </div>
          <div className="col-6">
            <Button
              variant="primary"
              className=" w-100"
              onClick={() => {
                setShowInternalTransfer(true);
              }}
              style={{ background: "#59C6C9", color: "white", border: "none" }}
            >
              {_("panel.addInternalTransfers")}
            </Button>
          </div>
        </div>

        <Button
          className="fs-5 px-5 w-25 align-self-stat mt-4 "
          style={{
            backgroundColor: "rgb(89, 198, 201)",
            border: "none",
            borderRadius: "7px",
          }}
          onClick={() => {
            setSearch(!search);
          }}
        >
          {_("panel.search")}
        </Button>
        {search ? <SearchComponent /> : ""}
        <InternalTransfer
          show={showInternalTransfer}
          setShow={setShowInternalTransfer}
          get_warehouses={() =>
            get_warehouses(
              slug,
              lang,
              token,
              handleSuppliers,
              courierId,
              1,
              handleLoader
            )
          }  
        />

        <Loader active={loading} />
        <div
          className="tables-container d-flex flex-column px-2"
          style={{ position: "relative", flexGrow: 3 }}
        >
          <div
            className={`header d-flex ${
              lang === "en" ? "flex-row" : "flex-row"
            } gap-3 align-items-center justify-content-between`}
          >
            <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
              {_("panel.exports")}
            </h3>
          </div>
          <div className="footer d-flex flex-column flex-grow-1">
            {suppliers ? (
              <SuppliersTableRow
                payments_list={suppliers.data}
                get_warehouses={() =>
                  get_warehouses(
                    slug,
                    lang,
                    token,
                    handleSuppliers,
                    courierId,
                    1,
                    handleLoader
                  )
                }
              />
            ) : (
              ""
            )}
          </div>
          {suppliers.data ? (
            <Pagination
              className="m-auto flex-wrap"
              dir={lang === "ar" ? "ltr" : ""}
            >
              <Pagination.First onClick={() => changeParam("page", 1)} />
              <Pagination.Prev
                onClick={() =>
                  page - 1 > 0 ? changeParam("page", page - 1) : 1
                }
              />
              {suppliers["links"].map((element, index, arr) => {
                if (index == 0 || arr.length - 1 == index) {
                  return "";
                } else {
                  return (
                    <Pagination.Item
                      active={element["active"] ? true : false}
                      onClick={(e) =>
                        element["label"] != "..."
                          ? changeParam("page", parseInt(element["label"]))
                          : ""
                      }
                    >
                      {element["label"]}
                    </Pagination.Item>
                  );
                }
              })}
              <Pagination.Next onClick={() => changeParam("page", page + 1)} />
              <Pagination.Last
                onClick={() => changeParam("page", suppliers["last_page"])}
              />
            </Pagination>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
const label_input = (label, newWarehouseName, handleNewWarehouseName) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
      />
    </div>
  );
};
export default Income;
