
import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { useTranslation } from "react-i18next";
import './style.css'
const VariantsProps = ({Variants, setVariants, stdObj, setStdObj}) => {
    useEffect(() => {

    }, []);
    const [ingredients, setIngredients] = useState([]);
    const [_] = useTranslation('global');
    const onIngredientsChange = (e) => {
        let _ingredients = [...ingredients];

        if (e.checked) {
            _ingredients.push(e.value);
            stdObj[e.value] = '';
            setStdObj(stdObj);
            setVariants(() => {
                return Variants.map((item) => {
                    item[e.value] = '';
                    return item;
                });
            })
        }else {
            _ingredients.splice(_ingredients.indexOf(e.value), 1);
            delete stdObj[e.value];
            setStdObj(stdObj);
            setVariants(() => {
                return Variants.map((item) => {
                    delete item[e.value]
                    return item;
                });
            })
        }
            

        setIngredients(_ingredients);
    }

    return (
        <div className="card d-flex flex-row flex-wrap justify-content-center gap-3 border-0">
            <div className="flex align-items-center gap-1">
                <Checkbox inputId="ingredient1" name="pizza" value="color" onChange={onIngredientsChange} checked={ingredients.includes('color')} />
                <label htmlFor="ingredient1" className="ml-2">{_('panel.color')}</label>
            </div>
            <div className="flex align-items-center gap-1">
                <Checkbox inputId="ingredient2" name="pizza" value="size" onChange={onIngredientsChange} checked={ingredients.includes('size')} />
                <label htmlFor="ingredient2" className="ml-2">{_('panel.size')}</label>
            </div>

        </div>
    )
}

export default VariantsProps
