import React, { useEffect, useState } from "react";
import { Container, Button, Alert } from "react-bootstrap";
import "./Cart.css";
import AxiosController from "../../components/authentication/AxiosController";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CartItem from "../../components/cart/CartItem";
import CartStat from "../../components/cart/CartStat";
import UploadExcel from "../../components/cart/UploadExcel";
import Loader from "../../components/loader/Loader";
import IconsGen from "../../components/icons_generator/IconsGen";
import * as XLSX from "xlsx";
import ordersSheetCols from "../../components/globals/ordersSheetCols";
import { Helmet } from "react-helmet";

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

const Cart = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const ecombo_affiliate = localStorage.getItem("ecombo_affiliate");
  const [cart, handleCart] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [_] = useTranslation("global");
  const [sheetOrdersErrs, setSheetOrdersErr] = useState({});
  const [show, setShow] = useState(true);
  const Navigate = useNavigate();
  if (token) {
  } else {
    Navigate(`/ar/auth/login`);
  }
  const get_cart = () => {
    AxiosController.get(`api/${slug}/${lang}/get-cart`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleCart(res.data);
        handleLoader(false);
      })
      .catch((e) => {});
  }
  useEffect(() => {
    handleLoader(true);
    get_cart();
  }, [slug, lang, token]);

  return (
    <Container
      className="add-product-container my-3 px-4 "
      style={{ position: "relative" }}
    > <Helmet>
    <title>   {_("home.cartPage")}</title> 
 </Helmet>
      <Loader active={loading} />
      {Object.keys(sheetOrdersErrs).map((err) => {
        return sheetOrdersErrs[err].map((errI) => {
          return (
            <Alert variant="danger" onClose={() => setShow(false)}>
              <Alert.Heading>
                {err.replace(/^(\d+).*\.(\w+)$/, (g, index, field) => {
                  return `${field} ${_('panel.inRow')} ${parseInt(index) + 2}`;
                })}
              </Alert.Heading>
              <p>
                {errI}
              </p>
            </Alert>
          );
        });
      })}
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div className="add-header" dir={lang === "en" ? "ltr" : "rtl"}>
          <h2 className="mb-3" style={{ color: "rgba(243, 168, 55, 1)" }}>
            {_("home.cartPage")}
            <span style={{ color: "rgba(165, 165, 165, 1)" }}>
              ({cart.length} {_("home.product")})
            </span>
          </h2>
        </div>
        <div
          className={`d-flex gap-2 flex-column-reverse ${
            lang === "en" ? "flex-md-row-reverse" : "flex-md-row"
          }`}
        >
          <div
            className="order-right-container d-flex flex-column gap-3"
            style={{ flexGrow: 3 }}
          >
            {cart.length
              ? cart.map((item) => {
                  return (
                    <CartItem
                      quantity={item.quantity}
                      originalPrice={item.v_price}
                      marketerPrice={item.price}
                      altName={item.alt_name}
                      thumbnail={item.thumbnail}
                      offer={item.offer_id}
                      cartId={item.id}
                      productId={item.p_id}
                      cart={cart}
                      handleCart={handleCart}
                      handleLoader={handleLoader}
                      get_cart={get_cart}
                    />
                  );
                })
              : ""}
          </div>
          <div
            className="h-100  d-flex flex-column gap-2 cart-stat-width"
            style={{ flexGrow: "3" }}
          >
            <CartStat cart={cart} />
            <div className="mb-3">
              <Button
                style={{
                  backgroundColor:
                    cart.length > 0 ? "rgba(89, 198, 201, 1)" : "#ddd",
                  color: "white",
                  border: "none",
                  width: "100%",
                }}
                className="fs-5"
                onClick={() =>
                  cart.length > 0
                    ? Navigate(`/${slug}/${lang}/place-order`)
                    : ""
                }
              >
                {_("home.continueordering")}
              </Button>
            </div>


          </div>
        </div>
      </div>
    </Container>
  );
};

const handleDownload = () => {
  // Define the column headers
  const columns = ordersSheetCols;

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet([columns]);

  // Create a new workbook and add the worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Convert the workbook to a binary string
  const workbookBinary = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  });

  // Create a Blob object from the binary string
  const blob = new Blob([s2ab(workbookBinary)], {
    type: "application/octet-stream",
  });

  // Create a link element
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "template.xlsx";

  // Append the link to the document and trigger the download
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
};

export default Cart;
