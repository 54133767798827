import * as Yup from "yup";

export const changePasswordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required("Old Password is required"),
  new_password: Yup.string()
    .required("New Password is required")
    .notOneOf(
      [Yup.ref("old_password")],
      "New Password must be different from Old Password"
    )
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/,
      "The input must be at least 6 characters long, contain at least one number, one uppercase letter, one lowercase letter, and one special character."
    ),
  new_password_confirmation: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("new_password")], "Passwords do not match"),
});


export const createEmployee = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email address").required("Email is required"),
  birthdate: Yup.date().required("Birth date is required"),
  sex: Yup.string().required("Gender is required"), 
  employee_type: Yup.string().required("Employee Type is required"),
  password: Yup.string()
    .required("Password is required"),
  password_confirmation: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});