import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import TableLabels from "./TableLabels";
import TableSelectStatus from "./TableSelectStatus";
import IconsGen from "../../../../icons_generator/IconsGen";
const SuppliersTableRow = ({
  payments_list,
}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order) => {
            const dateSegments = order["created_at_formatted"].split(" ");
            return (
              <tr className="">
                <td scope="row" className="align-middle">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td className="align-middle">{order["title"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SuppliersTableRow;
