import React, { useEffect, useState } from "react";
import AxiosController from "../../authentication/AxiosController";
import Loader from "../../loader/Loader";
import ProtectedSidebarRoute from "../../authentication/ProtectedSidebarRoute";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { NavLink, useParams } from "react-router-dom";
import TableSelectStatus from "../sellings/orders/TableSelectStatus";

const PrevOrder = ({ phone_1, phone_2, track }) => {
  const { slug, lang } = useParams();
  const [prevOrders, setPrevOrders] = useState([]);
  const [_] = useTranslation('global');
  const [loading, setLoading] = useState();
  const token = localStorage.getItem('token');
  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  const get_customer_prevOrders = () => {
    AxiosController.post(`api/${slug}/${lang}/customer/orders`, 
    {
        phone:phone_1,
        current_track : track
    }
    ,{
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setPrevOrders(res.data);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    get_customer_prevOrders()
  }, [phone_1, track]);

  return (
    <div className="position-relative" style={{ width: "100%" }}>
      <Loader active={loading} />
      <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
        <h4 style={{ color: "rgba(23, 43, 77, 1)" }}>
          {_("panel.prevOrders")}
        </h4>
      </div>
      <Table responsive dir={box_head_dir} className="reports-table">
        <thead>
          <tr>
            <th className="small">{_("panel.orderNumber")}</th>
            <th className="small">{_("panel.status")}</th>
          </tr>
        </thead>
        <tbody>
            {prevOrders.length ? (prevOrders.map((order) => {
                return (
                    <tr>
                        <td><NavLink target="_blank" style={{color:'rgb(243, 168, 55)'}} to={`/${slug}/${lang}/panel/order/${order['track']}`}>{order['track']}</NavLink></td>
                        <td><TableSelectStatus currentStatus={order['status']} canEdit={false}/></td>
                    </tr>
                );
            })) : _('panel.notfound2')}
        </tbody>
      </Table>
    </div>
  );
};

export default PrevOrder;
