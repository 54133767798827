import React from "react";
import Products from "./Products";
import ProtectedSidebarRoute from "../../authentication/ProtectedSidebarRoute";
import { useParams } from "react-router-dom";
import Roles from "../../authentication/Roles";
import EmployeeTypes from "../../authentication/EmployeeTypes";
import ProductsTable from "./ProductsTable";

const ProductsPipe = () => {
  const { slug, lang } = useParams();

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");
  return (
    <div className="w-100">
      <ProtectedSidebarRoute
        component={Products}
        role={role}
        roles={[Roles["AFFILIATE"]]}
      />
      <ProtectedSidebarRoute
        component={Products}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[EmployeeTypes["CONFIRMATION_EMPLOYEE"]]}
      />
      <ProtectedSidebarRoute
        component={ProductsTable}
        role={role}
        roles={[Roles["SELLER"], Roles['FULFILLMENT']]}
      />
      <ProtectedSidebarRoute
        component={ProductsTable}
        role={role}
        roles={[Roles["EMPLOYEE"]]}
        empType={employee_type}
        empTypes={[
          EmployeeTypes["PRODUCTS_EMPLOYEE"],
          EmployeeTypes["OPERATION_EMPLOYEE"],
          EmployeeTypes["WAREHOUSE_EMPLOYEE"],
        ]}
      />
      <ProtectedSidebarRoute
        component={ProductsTable}
        role={role}
        roles={[Roles["ADMIN"]]}

      />
    </div>
  );
};

export default ProductsPipe;
