import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import TableLabels from "./TableLabels";
import IconsGen from "../../../icons_generator/IconsGen";
const colors = {
  seller: "rgba(89, 198, 201, 1)",
  affiliate: "rgb(243, 168, 55)",
  employee: "rgba(23, 43, 77, 1)",
};
const PaymentRequestsTableRow = ({
  payments_list,
  alt_name,
  handleLoader,
  excelData,
}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");

  useEffect(() => {}, [payments_list]);
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table
        className="table table-borderless text-nowrap"
        htmlFor="flexCheckDefault"
      >
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {payments_list.map((order, index) => {
            const dateSegments = order["created_at_formatted"].split(" ");

            return (
              <tr className="">
                <td className="d-flex justify-content-center">
                  <input
                    className="form-check-input align-middle"
                    style={{
                      border: "1px solid rgba(243, 168, 55, 1)",
                      transform: "translate(-0%, -50%)",
                    }}
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                </td>
                <td scope="row">
                  {dateSegments[0]} <br /> {dateSegments[1]} {dateSegments[2]}
                </td>
                <td style={{ width: "fit-content" }}>
                  <div className="d-flex justify-content-center align-content-center gap-2">
                    <div
                      className=""
                      style={{
                        height: "69px",
                        objectFit: "cover",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={order["thumbnail"]}
                        width={"69px"}
                        className="rounded"
                        alt=""
                      />
                    </div>

                    <div>
                    <span className="fw-bold">  {order["product_code"]}</span> #  <br/>
                      {order["sku"]}<br/>
                      {order["product_title"].substring(0, 13) + "..."} <br />
                    </div>
                  </div>
                </td>
                <td>{order["total"]}</td>
                <td>{order["left"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentRequestsTableRow;
