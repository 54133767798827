import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChartExpenses = ({ labels, expenses }) => {
  const matchedData = labels.map(label => {
    const expense = expenses.find(exp => exp.expense_label_id === label.id);
    return expense ? expense.total_expenses : 0;
  });

  const barData = {
    labels: labels.map(label => label.title),
    datasets: [
      {
        label: "النافقات",
        data: matchedData,
        backgroundColor: 'rgb(243, 168, 55)',
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Expenses Chart',
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          text: 'Labels'
        }
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: 'Expenses'
        }
      }
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <Bar data={barData} options={options} />
    </div>
  );
};

export default BarChartExpenses;
