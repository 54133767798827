export const currancyConvertor = (number) => {
  let wow = number;
  if (number === null || number === undefined) {
    wow = 0;
  }

  let numStr = wow.toString();
  let [integerPart, decimalPart] = numStr.split(".");

  let formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  let formattedDecimal =
    decimalPart && decimalPart !== "00" ? `.${decimalPart}` : "";

  let formattedNumber = formattedInteger + formattedDecimal;

  return formattedNumber;
};
