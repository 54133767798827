import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import IconsGen from "../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "react-bootstrap";
import "./style.css";
import Loader from "../../loader/Loader";
import AxiosController from "../../authentication/AxiosController";
import CustomerData from "./CustomerData";
import Cart from "./Cart";
import SupportTickets from "./SupportTickets";
import StatusLogs from "./StatusLogs";
import UpdateLog from "./UpdateLog";
import Orders from "../sellings/orders/Orders";
import PrevOrder from "./PrevOrder";

const get_order_data = (
  slug,
  lang,
  token,
  order_id,
  orderCustomerDataHandler,
  orderDetailsDataHandler,
  orderStatusLogHandler, 
  orderSupportTicketsHandler,
  handleLoader,
  altNameHandler
) => {
  AxiosController.get(`api/${slug}/${lang}/get-order/${order_id}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      orderCustomerDataHandler(res?.data?.order);
      orderDetailsDataHandler(res.data.order_details);
      orderStatusLogHandler(res.data.order_status_log);
      orderSupportTicketsHandler(res.data.order_support_tickets);
      altNameHandler(res?.data?.alt_name?.split(" ")[0]);
      handleLoader(false);
    })
    .catch((e) => {});
};

const OrderFulfillment = () => {
  const { orderId, slug, lang } = useParams();
  const token = localStorage.getItem("token");

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [orderCustomerData, orderCustomerDataHandler] = useState({});
  const [orderDetailsData, orderDetailsDataHandler] = useState({});
  const [orderStatusLog, orderStatusLogHandler] = useState([]);
  const [orderSupportTickets, orderSupportTicketsHandler] = useState([]);
  const [alt_name, altNameHandler] = useState({});
  const [loading, handleLoader] = useState(false);
  const role = localStorage.getItem("role");
  const get_order_handler = () => {
    handleLoader(true);

    get_order_data(
      slug,
      lang,
      token,
      orderId,
      orderCustomerDataHandler,
      orderDetailsDataHandler,
      orderStatusLogHandler,
      orderSupportTicketsHandler,
      handleLoader,
      altNameHandler
    );
  };
  useEffect(() => {
    get_order_handler();
  }, [orderId]);

  return (
    <div
      className="position-relative order-container rounded-3"
      style={{ width: "100%" }}
    >

      <Loader active={loading} />
      {role !=="seller" && <>      <div
        className="ticket py-2 px-2"
        style={{ position: "absolute", left: lang == "en" ? "90%" : "5%" }}
      >
        <p className="pt-1">
          {_(
            `panel.${orderCustomerData[0] ? orderCustomerData[0].status : ""}`
          )}
        </p>
      </div>      <CustomerData
        CustomerData={orderCustomerData[0]}
        orders_handler={get_order_handler}
      />
      <hr /></>}

      <Cart
        orderCart={orderDetailsData}
        alt_name={alt_name}
        order_id={orderCustomerData[0] ? orderCustomerData[0].id : ""}
        canEdit={orderCustomerData[0] ? orderCustomerData[0]["canEdit"] : ""}
        confirmation_price={orderCustomerData[0] ? orderCustomerData[0]["confirmation_price"] : ""}
        fulfillment_price={orderCustomerData[0] ? orderCustomerData[0]["fulfillment_price"] : ""}
        cancellation_price={orderCustomerData[0] ? orderCustomerData[0]["cancellation_price"] : ""}
        mi_id={orderCustomerData[0] ? orderCustomerData[0]["marketer_id"] : ""}
        shippingCost={
          orderCustomerData[0] ? orderCustomerData[0].shipping_cost : 0
        }
        total_price={orderCustomerData[0] ? orderCustomerData[0]["total_price"] : 0}
      />
      {role == "admin" || role == "employee" ? (
        <>
          <hr />
          <PrevOrder
            phone_1={
              orderCustomerData[0] ? orderCustomerData[0]["phone_1"] : ""
            }
            phone_2={
              orderCustomerData[0] ? orderCustomerData[0]["phone_2"] : ""
            }
            track={orderCustomerData[0] ? orderCustomerData[0]["track"] : ""}
          />
        </>
      ) : (
        ""
      )}
      <hr />
      <SupportTickets
        tickets_list={orderSupportTickets}
        order_id={orderCustomerData[0] ? orderCustomerData[0].id : ""}
        canEdit={orderCustomerData[0] ? orderCustomerData[0]["canEdit"] : ""}
        get_order_handler={get_order_handler} 
      />
      <hr />
      <StatusLogs status_logs_list={orderStatusLog}  get_order_handler={get_order_handler} />
      <UpdateLog status_update={orderCustomerData[0] && orderCustomerData[0]["updated_at"]} />
    </div>
  );
};

export default OrderFulfillment;
