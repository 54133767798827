import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";


import { Pagination } from "react-bootstrap";
import PaymentRequestsTableRow from "./PaymentRequestsTableRow";
import AxiosController from "../../../authentication/AxiosController";
import IconsGen from "../../../icons_generator/IconsGen";
import Loader from "../../../loader/Loader";

const fetchPaymentRequests = (
    slug,
    lang,
    token,
    handleOrdesData,
    page,
    handleLoader
  ) => {
    AxiosController.get(`api/${slug}/${lang}/get-payment-requests/${page}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleOrdesData(res.data);
        handleLoader(false);
      })
      .catch((e) => {});
  };


  const PaymentRequests = () => {
    const { slug, lang } = useParams();
    const [_] = useTranslation("global");
    const token = localStorage.getItem("token");
    const [paymentRequests, handlePaymentRequests] = useState();
    const [page, handlePage] = useState(1);
    const [loading, handleLoader] = useState(false);
  
    useEffect(() => {
      handleLoader(true);
      fetchPaymentRequests(slug, lang, token, handlePaymentRequests, page, handleLoader);
    }, [token, slug, lang, page, handleLoader]);
    return (
      <div
        className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
        style={{ width: "100%" }}
      >
        <Loader active={loading} />
        <div
          className="tables-container d-flex flex-column px-2"
          style={{ position: "relative", flexGrow: 3 }}
        >
          <div
            className={`header d-flex ${
              lang === "en" ? "flex-row" : "flex-row"
            } gap-3 align-items-center justify-content-between`}
          >
            <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
              {_("panel.payReq")}
            </h3>
          </div>
          <div className="footer d-flex flex-column flex-grow-1">
          {paymentRequests ? 
          (
            <PaymentRequestsTableRow alt_name={paymentRequests['alt_name']} payments_list={paymentRequests['payment_requests']} handleLoader={handleLoader}  />
  
          )
          : (
            ""
          )}
          </div>
          {paymentRequests ? (
            <Pagination className="m-auto flex-wrap" >
              <Pagination.First onClick={() => handlePage(1)}/>
              <Pagination.Prev
                onClick={() => (page - 1 > 0 ? handlePage(page - 1) : 1)}
              />
              {paymentRequests['payment_requests']["links"].map((element, index, arr) => {
                  if (index == 0 || (arr.length -1) == index) {
                      return '';
                  }else {
  
                      return <Pagination.Item  active={(element['active']) ? true : false} onClick={(e) => (element['label'] != '...') ? handlePage(parseInt(element['label'])) : ''}>{element['label']}</Pagination.Item>;
                  }
              })}
              <Pagination.Next onClick={() => page + 1 <= paymentRequests['payment_requests']['last_page'] ? handlePage(page + 1) : 1} />
              <Pagination.Last  onClick={() => handlePage(paymentRequests['payment_requests']['last_page'])}/>
            </Pagination>
          ) : (
            ""
          )}
        </div>
      </div>
    );
}

export default PaymentRequests
