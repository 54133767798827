import React, { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import {  useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../imgs/Logo_Ecombo 1.png"; 
import LangSelector from "../languages/LangSelector";


import AxiosController from "../authentication/AxiosController";
import { Helmet } from "react-helmet";

const ChangePasswordVerify = () => {
  const { lang, email } = useParams();
  const [message, setMessage] = useState("");
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const navigate =useNavigate()

  // const send_email_verify = () => {
  //   AxiosController.post(`api/${lang}/auth/register/email/verify`, {
  //     email,
  //   })
  //     .then((res) => {})
  //     .catch((e) => {
  //       setMessage(e.response.data.message);
  //     });
  // };
  useEffect(() => {}, [email]);
  return (
    <div
      className="   inputs-data d-flex align-items-center px-5 justify-content-center flex-grow-1 position-relative verify"
      style={{ backgroundColor: "white" }}
      dir={box_head_dir}
    > <Helmet>
    <title>  {_("panel.donotHaveEmail")}</title> 
 </Helmet>
      <div className="data-entry-container" style={{ width: "100%" }}>
        <div className="d-flex">
          <img src={logo} width="230px" alt="" />
          <LangSelector lang={lang} />
        </div>
        <div
          className="d-flex flex-column gap-2 text-center align-items-center"
          style={{ direction: box_head_dir }}
          
        >
      <p>             {_("panel.donotHaveEmail")}
      </p>
      
          <button
            onClick={() => {
              navigate(`/${lang}/auth/login`)
            }}
            className="btn w-100"
            style={{
              color: "rgb(243, 168, 55)",
              border: "1px solid rgb(243, 168, 55)",
            }}
          >
            {_("panel.login")}
          </button>
          <button
            onClick={() => {
              navigate(`/${lang}/auth/register/choice`)
            }}
            className="btn w-100"
            style={{
              color: "rgb(243, 168, 55)",
              border: "1px solid rgb(243, 168, 55)",
            }}
          >
            {_("registeration.createone")}
          </button> 
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordVerify;
