const StatusGenerator = (role, employee_type) => {
  switch (role) {
    case "affiliate":
      return ["Canceled"];
    case "admin":
      return [
        "Canceled",
        "Returned",
        "Cash Delivery",
        "Delivered",
        "Pending Cancel",
        "Shipped",
        "Pending Return",
        "Waiting",
        "Ready For Shipping",
        "Call Attempt",
        "Confirmed",
        "Pending",
        'Not Confirmed',
        'Settled'

      ];
    case "employee":
      if (employee_type == "confirmation employee") {
        return [
          "Pending Cancel",
          "Shipped",
          "Pending Return",
          "Waiting",
          "Ready For Shipping",
          "Call Attempt",
          "Confirmed",
          "Pending",
          'Not Confirmed'

        ];
      } else if (employee_type == "operation employee") {
        return [
          "Canceled",
          "Returned",
          "Cash Delivery",
          "Delivered",
          "Pending Cancel",
          "Shipped",
          "Pending Return",
          "Waiting",
          "Ready For Shipping",
          "Call Attempt",
          "Confirmed",
          "Pending",
          'Not Confirmed',
          'Settled'

        ];
      }
      if (employee_type == "financials employee") {
        return ["Cash Delivery", "Pending"];
      }
    default:
      return [];
  }
};

export default StatusGenerator;
