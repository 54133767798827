import React from "react";
import { NavLink, useParams } from "react-router-dom";

const ProductSearchItem = ({ list, cart, handleCart }) => {
  const { slug, lang } = useParams();

  return (
    <div
      className="search-results position-absolute w-100  flex-column gap-2 d-md-flex rounded-3"
      style={{
        left: 0,
        top: "100%",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
        zIndex: "1000",
        maxHeight: "325px",
        overflow: "scroll",
      }}
    >
      {list.map((product, index) => {
        return (
          <NavLink
            to={`/${slug}/${lang}/product/${product.id}`}
            style={{ textDecoration: "none" }}
          >
            <div
              className="productItem px-2 py-3 d-flex gap-3 align-items-center"
              style={{
                borderBottom:
                  index != list.length - 1
                    ? "1px solid rgba(239, 237, 237, 1)"
                    : "",
              }}
            >
              <div className="thumbnail">
                <img
                  src={product.thumbnail}
                  width={"70px"}
                  className="rounded-3"
                />
              </div>
              <div className="info">
                <p style={{ color: "rgb(89, 198, 201)" }}>{product.title}</p>
              </div>
            </div>
          </NavLink>
        );
      })}
    </div>
  );
};

export default ProductSearchItem;
