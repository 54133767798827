import { memo, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next"; 
import { useLocation, useNavigate, useParams } from "react-router-dom";

function CouriersCODModal({
  viewModal,
  setViewModal,  
  wrong,
  success,     
data
}) {  
  const [_] = useTranslation("global"); 
  const Navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    setViewModal(false);
    setViewModal(false); 
  }; 
  const { slug, lang } = useParams();

  const urlParams = new URLSearchParams(location.search);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`/${slug}/${lang}/panel/sellings/orders?${urlParams.toString()}`);
  };
  return (
    <>
      <Modal show={viewModal} onHide={handleClose}>
        <Modal.Header closeButton> 
        </Modal.Header>
        <Modal.Body>
        <div className="mt-4 mb-4">
       
 
  <div className="mb-4">
    <table className="table">
      <thead>
        <tr className="text-center">
          <th scope="col">{_("panel.openOrders")}</th>
          <th scope="col">{_("panel.closedOrders")}</th> 
        </tr>
      </thead>
      <tbody>
      {success?.length > 0 && success?.map((msg, index) => (
        <tr key={index}>
          <td>{msg || "_"}</td> 
        </tr>
      ))}
      
           {wrong?.length > 0 && wrong?.map((msg, index) => (
          <tr key={index}>
            <td>{msg}</td> 
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

          
        </Modal.Body>
        <Modal.Footer>  <Button  onClick={() =>
          changeParam(
            "tracks",
            data.map((track) => track.track)
          )
        }style={{ backgroundColor: "var(--primary-color)" }} 
      >
            {_(`panel.showDetailed`)}
          </Button>
          <Button style={{ backgroundColor: "red" }} onClick={handleClose}>
            {_(`panel.close`)}
          </Button>
        
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CouriersCODModal;
