import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import DateRangePicker from "react-bootstrap-daterangepicker";
import IconsGen from "../../../icons_generator/IconsGen";
import Loader from "../../../loader/Loader";
import { Helmet } from "react-helmet";
import StatisticsSearchComponent from "./search components/StatisticsSearchComponent";
import { notifyError } from "../../../toastify/toastify";

const AllOrders = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const [search, handleSearch] = useState(false); 
  const [marketerId, setMarketerId] = useState("");
  const [courierId, setCourierId] = useState("");
  const [sku, setSku] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [_] = useTranslation("global");
  const [allStats, setAllStats] = useState({}); 

  const get_all_orders = () => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/reports/orders-overview/`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate, 
        marketer_id: marketerId,
        courier_id: courierId,
        sku: sku
      },
    })
      .then((res) => {
        setAllStats(res.data);
        handleLoader(false);
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.error) {
          const errors = e.response.data.error;
          Object.entries(errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((err) => notifyError(`${key}: ${err}`));
            } else {
              notifyError(`${key}: ${value}`);
            }
          });
        } else {
          notifyError("An unexpected error occurred.");
        }
        handleLoader(false);
      });
  };
  useEffect(() => { 
    setMarketerId(urlParams.get("marketer_id") || "");
    setCourierId(urlParams.get("courier_id") || "");
    setSku(urlParams.get("sku") || "");
    setStartDate(urlParams.get("start_date") || "");
    setEndDate(urlParams.get("end_date") || "");
 
  }, [urlParams]);

  const DeliveryPercentage = () => {
    if (allStats.orders && allStats.orders_grouped_status) {
      const totalDelivered = allStats.orders.total_delivered;
      const totalGroupedStatus = allStats.orders_grouped_status.reduce(
        (sum, stat) => sum + stat.total,
        0
      );

      if (totalGroupedStatus === 0) {
        return "0%"; // Avoid division by zero
      }

      const percentage = (totalDelivered / totalGroupedStatus) * 100;
      return `${percentage?.toLocaleString()}%`;
    }

    return "0%"; // Handle the case when data is not available
  };

 
  useEffect(() => {
    get_all_orders();
  }, [   startDate, endDate , marketerId , courierId , sku]);
  return (
    <div className="position-relative">
      <Helmet>
        <title>{_("panel.orders")}</title>
      </Helmet>
      <Button
        className="fs-5 px-5 w-25 mb-4"
        style={{
          backgroundColor: "rgb(89, 198, 201)",
          border: "none",
          borderRadius: "7px",
        }}
        onClick={() => {
          handleSearch(!search);
        }}
      >
        {_("panel.search")}
      </Button>
      {search ? <StatisticsSearchComponent type={"all_orders"} /> : ""}
  
      <Loader active={loading} />
      <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
        <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
          {_("panel.orders")}
        </h3>
        
      </div>
      <div className="d-flex flex-wrap gap-3">
        <div
          className="report-box col-12  col-xxl-2   col-lg-3  d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 rounded-3"
          style={{ backgroundColor: "#EBFEFF" }}
        >
          <div className="top d-flex align-items-center gap-2">
            <IconsGen icon_name={"dollarSign"} />
            <p className="flex-grow-1">{_("panel.اجمالي المبيعات بالشحن")}</p>
          </div>
          <div className="middle fs-2">
            <p>{allStats.orders?.total_price?.toLocaleString()||   '0'}</p>
          </div>
        </div>
        <div
          className="report-box col-12  col-xxl-2   col-lg-3  d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify rounded-3"
          style={{ backgroundColor: "#EBFEFF" }}
        >
          <div className="top d-flex align-items-center gap-2">
            <IconsGen icon_name={"dollarSign"} />

            <p className="flex-grow-1">{_("panel.اجمالي المبيعات بدون شحن")}</p>
          </div>
          <div className="middle fs-2">
            <p>
              {allStats.orders?.net_price
                ? allStats.orders.net_price?.toLocaleString()
                : "0"}
            </p>
          </div>
        </div>
        <div
          className="report-box col-12  col-xxl-2   col-lg-3   d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify rounded-3"
          style={{ backgroundColor: "#EBFEFF" }}
        >
          <div className="top d-flex align-items-center gap-2">
            <IconsGen icon_name={"dollarSign"} />

            <p className="flex-grow-1">{_("panel.totalorders")}</p>
          </div>
          <div className="middle fs-2">
            <p>
              {" "}
              {allStats.orders_grouped_status &&
                allStats.orders_grouped_status
                  .reduce((sum, stat) => sum + stat.total, 0)
                  ?.toLocaleString()||   '0'}
            </p>
          </div>
        </div>
        <div
          className="report-box col-12  col-xxl-2   col-lg-3  d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 rounded-3"
          style={{ backgroundColor: "#EBFEFF" }}
        >
          <div className="top d-flex align-items-center gap-2">
            <IconsGen icon_name={"dollarSign"} />
            <p className="flex-grow-1">{_("panel.إجمالي الطلبات")}</p>
          </div>
          <div className="middle fs-2">
            <p>{allStats.orders?.total_delivered?.toLocaleString()||   '0'}</p>
            <p> {DeliveryPercentage()} </p>
          </div>
        </div>
        <div
          className="report-box col-12 col-xxl-2   col-lg-3  d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify rounded-3"
          style={{ backgroundColor: "#EBFEFF" }}
        >
          <div className="top d-flex align-items-center gap-2">
            <IconsGen icon_name={"dollarSign"} />

            <p className="flex-grow-1">{_("panel.إجمالي القطع الموصله")}</p>
          </div>
          <div className="middle fs-2">
            <p>
              {Number(
                allStats.order_details?.delivered_quantity
              )?.toLocaleString()||   '0'}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap gap-3 mt-3 mb-4">
        <div
          className="report-box col-12  col-xxl-2   col-lg-4  d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify rounded-3"
          style={{ backgroundColor: "#FFD99F" }}
        >
          <div className="top d-flex align-items-center gap-2">
            <IconsGen icon_name={"orangdash"} />

            <p className="flex-grow-1">{_("panel.الربحيه من الشحن")}</p>
          </div>
          <div className="middle fs-2">
            <p>
              {allStats.orders?.profit
                ? allStats.orders.profit?.toLocaleString()
                : '0'}
            </p>
          </div>
        </div>
        <div
          className="report-box col-12  col-xxl-2   col-lg-4  d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 rounded-3"
          style={{ backgroundColor: "#FFD99F" }}
        >
          <div className="top d-flex align-items-center gap-2">
            <IconsGen icon_name={"orangdash"} />
            <p className="flex-grow-1">{_("panel.إجمالي ربحيه الشركه")}</p>
          </div>
          <div className="middle fs-2">
            <p>{allStats.order_details?.sys_commission?.toLocaleString() ||   '0'}</p>
          </div>
        </div>
        <div
          className="report-box col-12 col-xxl-2   col-lg-4  d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify rounded-3"
          style={{ backgroundColor: "#FFD99F" }}
        >
          <div className="top d-flex align-items-center gap-2">
            <IconsGen icon_name={"orangdash"} />

            <p className="flex-grow-1">{_("panel.اجمالي ربحيه التجار")}</p>
          </div>
          <div className="middle fs-2">
            <p>{allStats.order_details?.seller_commission?.toLocaleString()||   '0'}</p>
          </div>
        </div>
        <div
          className="report-box col-12 col-xxl-2   col-lg-4  d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 rounded-3"
          style={{ backgroundColor: "#FFD99F" }}
        >
          <div className="top d-flex align-items-center gap-2">
            <IconsGen icon_name={"orangdash"} />
            <p className="flex-grow-1">{_("panel.اجمالي ربحيه المسوقين")}</p>
          </div>
          <div className="middle fs-2">
            <p>
              {allStats.order_details?.marketer_commission?.toLocaleString()||   '0'}
            </p>
          </div>
        </div>
      </div>

      <Table
        responsive
        dir={box_head_dir}
        className="reports-table text-nowrap text-center"
      >
        <thead>
          <tr>
            <th>{_("panel.status")}</th>
            <th>{_("panel.totalorders")}</th>
            <th>{_("panel.Amount")}</th>
          </tr>
        </thead>
        <tbody>
          {allStats.orders_grouped_status &&
            allStats.orders_grouped_status.map((stat) => {
              return (
                <tr key={stat.status}>
                  <td>{_(`panel.${stat.status}`)||   '0'}</td>
                  <td>{stat.total?.toLocaleString()||   '0'}</td>
                  <td>{stat.net_price?.toLocaleString()||   '0'}</td>
                </tr>
              );
            })}

          {allStats.orders_grouped_status && (
            <tr className="fw-bold " style={{ borderTop: "2px solid black" }}>
              <td>{_("panel.total")}</td>
              <td className="fw-bold ">
                {allStats.orders_grouped_status
                  .reduce((sum, stat) => sum + stat.total, 0)
                  ?.toLocaleString()||   '0'}
              </td>
              <td className="fw-bold ">
                {allStats.orders_grouped_status
                  .reduce((sum, stat) => sum + stat.net_price, 0)
                  ?.toLocaleString()||   '0'}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default AllOrders;
