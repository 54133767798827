import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen";
import TableLabels from "./TableLabels";
import TableSelectStatus from "./TableSelectStatus";
import AxiosController from "../../../authentication/AxiosController";
const OrderTableRow = ({
  orders_list,
  alt_name,
  handleLoader,
  setSelectedOrders,
  selectedOrders,
  allOrders,
  orders_products,
  setOrdersProducts,
  displayedOrders,
}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  useEffect(() => {
    setOrdersProducts(() => []);
    orders_list.map(async (item, index) => {
      AxiosController.get(
        `api/${slug}/${lang}/get-products-orders/${item.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {
          setOrdersProducts((prevOrdersProducts) => {
            return { ...prevOrdersProducts, [item.id]: res.data.products };
          });
        })
        .catch((e) => {});
    });
  }, [orders_list]);
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels
            allOrders={allOrders}
            setSelectedOrders={setSelectedOrders}
            displayedOrders={displayedOrders}
            selectedOrders={selectedOrders}
          />
        </thead>
        <tbody>
          {orders_list.map((order, index) => {
            const dateSegments = order["created_at_formatted"].split(" ");

            return (
              <tr className="" key={index}>
                <td className="align-middle">
                  <div
                    className="form-check mt-3"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      checked={selectedOrders.some(
                        (item) => item.id === order.id
                      )}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setSelectedOrders(
                            selectedOrders.filter(
                              (item) => item.id !== order.id
                            )
                          );
                        } else {
                          setSelectedOrders([...selectedOrders, order]);
                        }
                      }}
                      id={`checkbox-${order.id}`}
                    />
                  </div>
                </td>
                <td className=" ">
                  <p>{dateSegments[0]}</p>
                  <p>
                    {dateSegments[2]}
                    {dateSegments[1]}
                  </p>
                  <p>
                    {order["courier_name"] ?? ""}{" "}
                    {order["courier_id"] ? `#${order["courier_id"]}` : ""}
                  </p>
                </td>
                <td className="align-middle">
                  {order["track"]} <br />{" "}
                  {order["settlement"] ? (
                    <>
                      {order["settlement"]["settlement"]["name"]} #{order["settlement"]["settlement"]["id"]} <br />
                    </>
                  ) : (
                    ""
                  )}{" "}

                  {order["conf_first_name"]
                    ? order["conf_first_name"] + " " + order["conf_last_name"]
                    : ""}{" "}
                  <br /> {order["conf_id"] ? "#" + order["conf_id"] : ""}
                </td>
                <td
                  style={{
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    verticalAlign: "middel",
                  }}
                >
                  {orders_products[order.id] ? (
                    orders_products[order.id].map((item) => {
                      return (
                        <p
                          style={{
                            fontSize: "14px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            verticalAlign: "middel",
                            width: "100px",
                            padding: "0 10px",
                          }}
                        >
                          {item["product"] ? item["product"].title : ""}
                        </p>
                      );
                    })
                  ) : (
                    <td>{_("panel.loading")}</td>
                  )}
                </td>
                {order["customer_name"] ? (
                  <td className="align-middle">
                    {order["customer_name"]} <br /> {order["phone_1"]}
                  </td>
                ) : (
                  ""
                )}

                {order["mi_id"] ? (
                  <td className="align-middle">
                    {order["mi_first_name"] + " " + order["mi_last_name"]}{" "}
                    {order["mi_role"] ? (
                      <>
                        <br />

                        <span
                          className={`m-0 p-0 fw-bolder ${
                            order["mi_role"] == "affiliate"
                              ? "ecombo-primary"
                              : "ecombo-secondary"
                          }`}
                        >
                          {_(`panel.${order["mi_role"]}`)}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                    <br /> #{order["mi_id"]}
                  </td>
                ) : (
                  ""
                )}
                {order["total_price"] ? (
                  <td className="align-middle">
                    {order["total_price"] ?? 0}
                    <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                      {alt_name === "درهم امارتي" ? "AED" : "KSA "}
                    </span>
                  </td>
                ) : (
                  <td>
                    0
                    <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                      {alt_name === "درهم امارتي" ? "AED" : "KSA "}
                    </span>
                  </td>
                )}

                {role != "fulfillment" ? (
                  <td className="align-middle">
                    {order["commissions"] ?? 0}
                    <span style={{ fontWeight: "bold", marginLeft: "5px" }}>
                      {alt_name === "درهم امارتي" ? "AED" : "KSA "}
                    </span>
                  </td>
                ) : (
                  ""
                )}
                <td className="align-middle">
                  <TableSelectStatus
                    currentStatus={order["status"]}
                    orderId={order.id}
                    handleLoader={handleLoader}
                  />
                  <p style={{ textAlign: "right", fontSize: "15px" }}>
                    {_(`panel.${order["label"]}`)}
                  </p>
                </td>

                <td style={{ verticalAlign: "middle" }}>
                  <NavLink
                    to={`${
                      role == "fulfillment" || order["mi_role"] == "fulfillment"
                        ? `/${slug}/${lang}/panel/order/fulfillment/${order["track"]}`
                        : `/${slug}/${lang}/panel/order/${order["track"]}`
                    }`}
                  >
                    {<IconsGen icon_name={"enter"} />}
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTableRow;
