import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, Button, Form, Modal, Pagination } from "react-bootstrap";
import SuppliersTableRow from "./SuppliersTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import { Helmet } from "react-helmet";
const get_warehouses = (
  slug,
  lang,
  token,
  handleWarehouses,
  page,
  handleLoader
) => {
  AxiosController.get(
    `api/${slug}/${lang}/exclusive/get-all-exclusive/${page}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      handleWarehouses(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};
const createWarehouse = (
  slug,
  lang,
  token,
  name,
  type,
  handleLoader,
  handleWarehouses,
  setShow,
  handleNewCourierNameErr,
  handleCourierTypeErr
) => {
  handleLoader(true);
  AxiosController.post(
    `api/${slug}/${lang}/exclusive/add-exclusive`,
    { marketer_id: type, product_id: name },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      get_warehouses(slug, lang, token, handleWarehouses, 1, handleLoader);
      setShow(false);
    })
    .catch((e) => {
      handleNewCourierNameErr(
        e.response.data.errors ? e.response.data.errors.name[0] : ""
      );
      handleCourierTypeErr(
        e.response.data.errors ? e.response.data.errors.type[0] : ""
      );
      handleLoader(false);
    });
};

const Exclusive = () => {
  const { slug, lang } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [suppliers, handleSuppliers] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [marketerCode, setMarketerCode] = useState("");
  const [newCourierNameErr, handleNewCourierNameErr] = useState("");
  const [productCode, setProductCode] = useState("");
  const [courierTypeErr, handleCourierTypeErr] = useState("");
  const [page, handlePage] = useState(urlParams.get("page") || 1);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };
  useEffect(() => {
    handleLoader(true);
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    handlePage(nextPage);

    get_warehouses(slug, lang, token, handleSuppliers, nextPage, handleLoader);
  }, [slug, lang, location.search]);



  return (
    <div
      className="position-relative tables-container"
      style={{ minHeight: "100px", width: "100%" }}
    >          <Helmet>
    <title>{_("panel.ExclusiveProduct")}</title> 
 </Helmet>
      <Button
        variant="primary"
        onClick={handleShow}
        style={{ background: "#59C6C9", color: "white", border: "none" }}
      >
        {_("panel.add")}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">
            {_("panel.addwarehouse")} 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
          {label_input(_("panel.product"), productCode, setProductCode)}
          {label_input(_("panel.themarketer"), marketerCode, setMarketerCode)}
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button
            variant="primary"
            onClick={() => {
              createWarehouse(
                slug,
                lang,
                token,
                productCode,
                marketerCode,
                handleLoader,
                handleSuppliers,
                setShow,
                handleNewCourierNameErr,
                handleCourierTypeErr
              );
            }}
          >
            {_("panel.save")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.ExclusiveProduct")}
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {suppliers.data ? (
            <SuppliersTableRow
              payments_list={suppliers.data}
              handleLoader={handleLoader}
              get_warehouses={()=>get_warehouses(slug, lang, token, handleSuppliers, 1, handleLoader)}
            />
          ) : (
            ""
          )}
          {suppliers.data ? (
            <Pagination
              className="m-auto flex-wrap"
              dir={lang === "ar" ? "ltr" : ""}
            >
              <Pagination.First onClick={() => changeParam("page", 1)} />
              <Pagination.Prev
                onClick={() =>
                  page - 1 > 0 ? changeParam("page", page - 1) : 1
                }
              />
              {suppliers["links"].map((element, index, arr) => {
                if (index == 0 || arr.length - 1 == index) {
                  return "";
                } else {
                  return (
                    <Pagination.Item
                      active={element["active"] ? true : false}
                      onClick={(e) =>
                        element["label"] != "..."
                          ? changeParam("page", parseInt(element["label"]))
                          : ""
                      }
                    >
                      {element["label"]}
                    </Pagination.Item>
                  );
                }
              })}
              <Pagination.Next onClick={() => changeParam("page", page + 1)} />
              <Pagination.Last
                onClick={() => changeParam("page", suppliers["last_page"])}
              />
            </Pagination>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
const label_input = (label, newWarehouseName, handleNewWarehouseName) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
      />
    </div>
  );
};
export default Exclusive;
