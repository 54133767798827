import React from "react";

const Loader = ({ active }) => {
  return (
    <div
      class={`loader position-absolute ${active ? "" : "d-none"}`}
      style={containerStyle}
    >
      <svg
        version="1.1"
        id="e_00000162353554832608871800000013929443245397790398_"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1080 1080"
        style={svgStyle}
        width="150px"
      >
        <g id="e">
          <path
            style={circleStyle}
            d="M758.839,425.451l-324.09,220.174c24.093,25.402,52.042,39.632,83.828,42.697
                c31.789,3.071,63.448-6.108,94.986-27.531c37.693-25.608,61.136-60.79,70.327-105.571L796,537.391
                c-0.77,44.452-11.038,83.924-30.812,118.418c-19.772,34.494-49.983,65.555-90.634,93.17
                c-63.069,42.847-127.95,57.852-194.644,45.004c-66.696-12.834-120.378-49.192-161.049-109.057
                c-41.674-61.345-56.506-125.71-44.498-193.103c12.012-67.383,47.819-121.333,107.443-161.837
                c63.314-43.013,128.572-57.724,195.768-44.15c67.197,13.584,123.39,53.63,168.579,120.148L758.839,425.451z M603.977,414.537
                c-21.891-17.891-47.421-27.2-76.582-27.912c-29.16-0.712-56.693,7.731-82.606,25.336c-28.132,19.109-47.452,43.765-57.963,73.942
                c-6.632,18.91-7.169,44.662-1.619,77.254L603.977,414.537z"
          />
        </g>
      </svg>
    </div>
  );
};
const containerStyle = {
  width: "100%",
  height: "100%",
  zIndex: 10000,
  backgroundColor: "rgba(255, 255, 255, 0.4)",
  display: "flex",
  justifyContent: "center",
  overflow:'hidden'
};
const svgStyle = {
  enableBackground: "new 0 0 1080 1080",
};
const circleStyle = {
  fill: "#f0a637",
};
export default Loader;
