import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const NavigateToRoot = () => {
    const Navigate = useNavigate();
    useEffect(() => {
        Navigate(`/ae/ar`);
    });
  return (
    <div>
      
    </div>
  )
}

export default NavigateToRoot
