import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next"; 
import DateRangePicker from "react-bootstrap-daterangepicker";
import AllStatus from "../../../authentication/AllStatus";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import AxiosController from "../../../authentication/AxiosController";

const SearchComponent = () => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const location = useLocation();
  const Navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);  
   const cours = [];
  const [couriers, setCouriers] = useState([]);
  const [selectedCouriers, setSelectedCouriers] = useState([]);
 
  couriers.forEach((gov) => {
    cours.push({ name: gov.name, Code: gov.id });
  });

 
  const [startDate, handleStartDate] = useState(""); 
  const [endDate, handleEndDate] = useState(""); 
  const [perPage, setPerPage] = useState("");
  const [userId, setUserId] = useState("");

  const fetchCouriers = () => {
    AxiosController.get(`api/${slug}/${lang}/couriers/get-all-couriers/`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => { 
        
        setCouriers(res.data);
      })
      .catch((e) => {});
  };
 
  const changeParam = () => { 
    const courierIds = selectedCouriers.map(courier => courier.Code).join(",");
    urlParams.set("courier_id",courierIds); 
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate); 
    urlParams.set("per_page", perPage);
    urlParams.set("page", 1);
    urlParams.set("user_id", userId); 
    Navigate(`?${urlParams.toString()}`);
  };


  const resetHandler = () => { 
    setUserId("");  
    setPerPage("")
    handleStartDate("");
    handleEndDate(""); 
    setSelectedCouriers([]);
    Navigate("");
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(startDate);
    handleEndDate(endDate);
  };
 
  useEffect(() => {
    fetchCouriers(); 
  }, []);

  return (
    <form
      className="w-100 d-flex flex-column gap-1"
      onSubmit={(e) => {
        e.preventDefault(); 
        changeParam();
      }}
    >
 
 
      <div className="inputs-row d-flex w-100 flex-wrap flex-lg-nowrap gap-2 mb-3 flex-column  flex-md-row  ">
 

     
        <Col>
        

          <Form.Group
            className=" flex-grow-1 "
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              type="text"
              placeholder={_("panel.user")}
              onChange={(e) => {
                setUserId(e.target.value);
              }}
              value={userId}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className=" flex-grow-1 h-100">
            <div className="d-flex justify-content-center h-100">
              <MultiSelect
                value={selectedCouriers}
                onChange={(e) => {
                  setSelectedCouriers(e.target.value);
                }}
                options={cours}
                optionLabel="name"
                placeholder={_("panel.Courier")}
                maxSelectedLabels={3}
                className="w-100 align-items-center px-3 py-1"
              />
            </div>
          </Form.Group>
        </Col>
        <Col> 
          <Form.Group
            className=" flex-grow-1"
            controlId="exampleForm.ControlInput1"
          >
            <DateRangePicker onApply={handleApply}>
              <input type="text" className="form-control" />
            </DateRangePicker>
          </Form.Group>
        </Col>
       
      </div>
      
      <div className="action-inputs d-flex justify-content-between">
        <input
          className=" px-5 align-self-end py-2"
          style={{
            backgroundColor: "rgb(243, 168, 55)",
            border: "none",
            borderRadius: "7px",
            color: "white",
            textAlign: "center",
          }}
          onClick={() => {
            changeParam();
          }}
          type="submit"
          value={_("panel.search")}
        />
        <Button
          style={{ backgroundColor: "rgb(23, 43, 77)" }}
          variant="dark"
          className="d-inline"
          onClick={resetHandler}
        >
          {_("panel.reset")}
        </Button>
      </div>
    </form>
  );
};

export default SearchComponent;
