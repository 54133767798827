const StatusColors = (status) => {
  const status_color = {
    Canceled: "#FC8383",
    Returned: "#F24949",
    "Cash Delivery": "#59C6C9",
    "Pending Cancel": "#FC8383",
    Shipped: "#F3A837",
    "Pending Return": "#E12616",
    // moave

    "Ready For Shipping": "#172B4F",
    Confirmed: "#6188D1",
    //green
    Delivered: "#35C03C",
    Settled: "#35C03C",
    //gray
    "Call Attempt": "#557777",
    Waiting: "#709998",
    Pending: "#98B6B5",
    "Not Confirmed" : "#FC8383",
    
  };
  return status_color[status];
};

export default StatusColors;
