import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, Button, Form, Modal, Pagination } from "react-bootstrap";
import SuppliersTableRow from "./SuppliersTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import { Helmet } from "react-helmet";
const get_warehouses = (
  slug,
  lang,
  token,
  handleWarehouses,
  userId,
  page,
  handleLoader
) => {
  AxiosController.get(
    `api/${slug}/${lang}/fulfillments/govs/${userId}/${page}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      handleWarehouses(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};
const get_warehouses2 = (
  slug,
  lang,
  token,
  handleWarehouses,
  userId,
  handleLoader
) => {
  AxiosController.get(
    `api/${slug}/${lang}/couriers/get-courier/${userId}/`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      handleWarehouses(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const get_shippingcosts = (
  slug,
  lang,
  token,
  shippingcostsHandler,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/get-shippingcost/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      shippingcostsHandler(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const createWarehouse = (
  slug,
  lang,
  token,
  userId,
  govId,
  cost,
  handling,
  extra_handling,
  handleLoader,
  setShow,
  handleSuppliers
) => {
  handleLoader(true);
  AxiosController.post(
    `api/${slug}/${lang}/fulfillments/gov/new/`,
    { user_id: userId, gov_id: govId, price:cost },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      get_warehouses(
        slug,
        lang,
        token,
        handleSuppliers,
        userId,
        1,
        handleLoader
      );
      setShow(false);
    })
    .catch((e) => {
      // handleNewCourierNameErr(
      //   e.response.data.errors ? e.response.data.errors.name[0] : ""
      // );
      // handleCourierTypeErr(
      //   e.response.data.errors ? e.response.data.errors.type[0] : ""
      // );
      handleLoader(false);
    });
};

const ShippingSellerFulfillment = () => {
  const { slug, lang, userId } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [suppliers, handleSuppliers] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [cost, setCost] = useState(0);
  const [handling, setHandling] = useState(0);
  const [extraHandling, setExtraHandling] = useState(0);
  const [show, setShow] = useState(false);
  const [shippingCosts, setShippingCosts] = useState([]);
  const [ courier , setCourier] = useState({});
  const [govId, setGovId] = useState();
  const [page, handlePage] = useState(urlParams.get("page") || 1);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };
  useEffect(() => {
    handleLoader(true);
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    handlePage(nextPage);
    get_shippingcosts(slug, lang, token, setShippingCosts, handleLoader);
    get_warehouses(
      slug,
      lang,
      token,
      handleSuppliers,
      userId,
      nextPage,
      handleLoader
    );
    get_warehouses2(
      slug,
      lang,
      token,
      setCourier,
      userId,
      handleLoader
    );
  }, [slug, lang, location.search]);

  return (
    <div
      className="position-relative tables-container"
      style={{ minHeight: "100px", width: "100%" }}
    > <Helmet>
    <title>{_("panel.fulfillmentShippingPricing")}</title> 
</Helmet>
      <Button
        variant="primary"
        onClick={() => {
          setCost(0);
          setHandling(0);
          setExtraHandling(0);
          setGovId(0);
          handleShow(true);
        }}
        style={{ background: "#59C6C9", color: "white", border: "none" }}
      >
        {_("panel.add")}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">{_("panel.add")}</Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
          <label htmlFor="exampleInputEmail1" className="form-label">
            {_("home.province")}
          </label>
          <Form.Group className="mb-3 flex-grow-1">
            <Form.Select
              onChange={(e) => {
                setGovId(e.target.value);
              }}
              value={govId}
            >
              <option selected={true}></option>
              {shippingCosts.map((gov) => {
                return (
                  <option value={gov.id}>
                    {gov.arabic_name} - {gov.cost}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          {label_input(_("panel.cost"), cost, setCost)}
          {/* {newCourierNameErr ? (
            <Alert variant="danger">{newCourierNameErr}</Alert>
          ) : (
            ""
          )} */}
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button
            variant="primary"
            onClick={() => {
              createWarehouse(
                slug,
                lang,
                token,
                userId,
                govId,
                cost,
                handling,
                extraHandling,
                handleLoader,
                setShow,
                handleSuppliers
              );
            }}
          >
            {_("panel.save")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.fulfillmentShippingPricing")}{" "}
            <span style={{ color: "rgb(243, 168, 55)" }}>
              {courier?.name}
            </span>
          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {suppliers.data ? (
            <SuppliersTableRow
              payments_list={suppliers.data}
              handleLoader={handleLoader}
              setShow={setShow}
              setCost={setCost}
              setGovId={setGovId}
              setHandling={setHandling}
              setExtraHandling={setExtraHandling}
            />
          ) : (
            ""
          )}
          {suppliers.data ? (
            <Pagination
              className="m-auto flex-wrap"
              dir={lang === "ar" ? "ltr" : ""}
            >
              <Pagination.First onClick={() => changeParam("page", 1)} />
              <Pagination.Prev
                onClick={() =>
                  page - 1 > 0 ? changeParam("page", page - 1) : 1
                }
              />
              {suppliers["links"].map((element, index, arr) => {
                if (index == 0 || arr.length - 1 == index) {
                  return "";
                } else {
                  return (
                    <Pagination.Item
                      active={element["active"] ? true : false}
                      onClick={(e) =>
                        element["label"] != "..."
                          ? changeParam("page", parseInt(element["label"]))
                          : ""
                      }
                    >
                      {element["label"]}
                    </Pagination.Item>
                  );
                }
              })}
              <Pagination.Next onClick={() => changeParam("page", page + 1)} />
              <Pagination.Last
                onClick={() => changeParam("page", suppliers["last_page"])}
              />
            </Pagination>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
const label_input = (label, newWarehouseName, handleNewWarehouseName) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
      />
    </div>
  );
};
export default ShippingSellerFulfillment;
