import "./Home.css";
import { Card, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { notification, Space } from "antd";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconsGen from "../../components/icons_generator/IconsGen";
import AxiosController from "../../components/authentication/AxiosController";
import ProductsSlider from "../../components/home/ProductsSlider";

export const Offers = () => {
  const [altName, handleAltName] = useState("");
  const [mostSellingRow, handleMostSellingRow] = useState([]);
  const [_] = useTranslation("global");
  const Navigate = useNavigate();
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [api] = notification.useNotification();

  const openNotification = (placement, status) => {
    api.success({
      style: { direction: lang == "ar" ? "rtl" : "ltr" },
      description: `${status}!`,
      placement,
    });
  };
  const addToCart = (prodcut_id) => {
    AxiosController.post(
      `apiv2/${slug}/${lang}/offers/add-to-cart/${prodcut_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        document.getElementById("ecomboCart").click();
      })
      .catch((e) => {
        Navigate(`/${slug}/${lang}/product/${prodcut_id}`);
      });
  };

  useEffect(() => {
    AxiosController.get(`apiv2/${slug}/${lang}/offers/marketer?home=1`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleMostSellingRow(res?.data);
      })
      .catch((e) => {});
  }, [slug, lang, token]);

  return (
    <>
      {" "}
      {mostSellingRow.length ? (
        <>
          <Row className="d-lg-flex d-none" dir={lang === "en" ? "rtl" : ""}>
            <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
              <h2
                style={{
                  color: "rgba(23, 43, 77, 1)",
                  marginTop: "50px",
                  marginBottom: "50px",
                }}
                className="fs-3"
              >
                {_("panel.offers2")}
              </h2>
              <Link
                style={{
                  color: "rgba(89, 198, 201, 1)",
                  border: "1px solid rgba(89, 198, 201, 1)",
                  background: "transparent",
                  textDecoration: "none",
                }}
                className="p-2 rounded-3 cat-link"
                to={`/${slug}/${lang}/panel/products/all-offers`}
              >
                {_("home.seemore")}
              </Link>
            </div>{" "}
            <div className="col-lg-9">
              <div className="container">
                {" "}
                <div className="row">
                  {mostSellingRow.map((product) => (
                    <div className="col-lg-4" key={product.id}>
                      {true && (
                        <div className="p-2 d-flex">
                          <Card
                            className="pt-3 px-2 mr-2"
                            style={{
                              flexGrow: 1,
                              paddingRight: "100px",
                              minHeight: "100%",
                            }}
                            dir="rtl"
                          >
                            <NavLink
                              to={`/${slug}/${lang}/offer/${product.id}`}
                            >
                              <Card.Img
                                src={product.thumbnail}
                                className="card-img-top px-1"
                                style={{
                                  borderRadius: "10px",
                                  height: "200px",
                                }}
                              />
                            </NavLink>

                            <Card.Body className="px-1 pt-2 pb-0">
                              <Card.Title className="d-flex flex-column flex-sm-column flex-lg-row-reverse gap-2 justify-content-between align-items-center">
                                <div className="rating d-flex align-items-center justify-content-between flex-row-reverse">
                                  <IconsGen icon_name="star" />
                                  <p style={{ margin: 0 }}>4.5</p>
                                </div>
                                <p
                                  className="title"
                                  style={{ margin: 0, fontSize: "1.2rem" }}
                                >
                                  {product?.title}
                                </p>
                              </Card.Title>
                            </Card.Body>

                            <Card.Body className="py-2 m-0">
                              <span>{_("panel.products")}</span>
                              {product.products.map((prd) => {
                                return (
                                  <p className="d-block">
                                    {prd?.product?.title?.slice(0, 8)}...
                                  </p>
                                );
                              })}
                            </Card.Body>

                            <Card.Body
                              className={`px-1 py-1 pb-3 d-flex flex-column align-items-center flex-sm-column ${
                                lang === "en"
                                  ? "flex-lg-row-reverse"
                                  : "flex-lg-row"
                              } justify-content-between gap-2`}
                            >
                              <span
                                className="d-inline-block"
                                onClick={() => addToCart(product.id)}
                                id={product.id}
                              >
                                <button
                                  style={{
                                    border: "none",
                                    padding: ".5rem .76rem",
                                    borderRadius: "7px",
                                  }}
                                  className="addtocart"
                                >
                                  {_("home.addtocart")}
                                </button>
                              </span>
                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </div>
                  ))}{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <img
                className="w-100 rounded shadow"
                style={{ height: "100%" }}
                src={"https://i.postimg.cc/909Y0cxq/3.png"}
                alt="new arrival"
              />
            </div>
          </Row>
          <Row className="d-lg-none d-block " dir={lang === "en" ? "rtl" : ""}>
            <Col className="d-flex flex-row-reverse justify-content-between align-items-center">
              <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
                <h2
                  style={{
                    color: "rgba(23, 43, 77, 1)",
                    marginTop: "50px",
                    marginBottom: "50px",
                  }}
                  className="fs-3"
                >
                  {_("home.mostSelling")}
                </h2>
              </div>
            </Col>
            <img
              className="w-100 rounded "
              style={{ height: "500px" }}
              src={"https://i.postimg.cc/VkSrmFmY/most-Selling.png"}
              alt="new arrival"
            />
            <ProductsSlider row={mostSellingRow} />
          </Row>
        </>
      ) : (
        ""
      )}
    </>
  );
};
