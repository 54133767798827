import { memo, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next"; 

function LinkModal({
  showModal,
  setShowViewModal,
  modalHeader, 
  setLinkMessages,
  linkMessages
}) { 
  const [show, setShow] = useState(showModal);
  const [_] = useTranslation("global"); 
  const handleClose = () => {
    setShowViewModal(false);
    setShow(false);
    setLinkMessages([]); 
  }; 
  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{_(`panel.${modalHeader}`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="mt-4 mb-4">
          <h5 className="mt-3 mb-3  text-center bg-secondary bg-gradient text-white pt-3 pb-3 rounded ">{_("panel.Courier")} : {linkMessages?.courier_name}</h5>
  <div className="mb-4">
    <table className="table">
      <thead>
        <tr className="text-center">
          <th scope="col">__ </th>
          <th scope="col">__ </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{_("panel.link_success")}</td>
          <td className="text-center">{linkMessages?.total_success}</td>
        </tr>
        <tr>
          <td>{_("panel.link_failed")}</td>
          <td className="text-center">{linkMessages?.total_failed}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div className="mb-4">
    <table className="table">
      <thead>
        <tr className="text-center">
          <th scope="col">{_("panel.orderTrack")}</th>
          <th scope="col">{_("panel.orderCourierTrack")}</th>
          <th scope="col">{_("panel.status")}</th>
        </tr>
      </thead>
      <tbody>
        {linkMessages?.result && linkMessages?.result.map((msg, index) => (
          <tr key={index}>
            <td>{msg.track}</td>
            <td>{msg.courier_track}</td>
            <td>{msg.message}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

          
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "red" }} onClick={handleClose}>
            {_(`panel.close`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LinkModal;
