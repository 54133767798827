import React, { useEffect, useState } from "react";

import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
import { notifyError } from "../../../toastify/toastify";

const change_order_status = (
  slug,
  lang,
  token,
  orderId,
  status,
  currentStatusHandler,
  handleLoader,
  handleStatusChangeErr
) => {
  AxiosController.put(
    `api/${slug}/${lang}/product/switch-activation-product/${orderId}`,
    { status },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      currentStatusHandler(status);
      handleLoader(false);
    })
    .catch((e) => {
      handleStatusChangeErr(true);
      handleLoader(false);
    });
};

const TableSelectStatus = ({ currentStatus, orderId, handleLoader , product_exclusive }) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");

  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  const [orderStatus, handleOrderStatus] = useState("");
  const [statusChangeErr, handleStatusChangeErr] = useState(false);

  const [_] = useTranslation("global");
  const statues = ["Inactive", "Active"];
  const StatusColors = {
    Active: "#7EF5A0",
    Inactive: "#FF0000",
    Pending: "#98B6B5",
  };

  useEffect(() => {
    handleOrderStatus(currentStatus);
    handleStatusChangeErr(false);
  }, [currentStatus]);
  return (
    <div className="mx-3">
      <Form.Select
        style={{
          border: "1px solid " + StatusColors[statues[orderStatus]],
          color: StatusColors[statues[orderStatus]],
          minWidth: "100px",
        }}
        onChange={(e) => {
          if(product_exclusive===1&&currentStatus==0){
            notifyError("لا يمكن تغير الحالة ")

          }else{
              handleLoader(true);
          change_order_status(
            slug,
            lang,
            token,
            orderId,
            e.target.value,
            handleOrderStatus,
            handleLoader,
            handleStatusChangeErr
          );
          }
        
        }}
      >
        <option selected={true} value={currentStatus}>
          {_(`panel.${statues[orderStatus]}`)}
        </option>
        {statues.map((status) => {
          if (
            status !== statues[currentStatus] &&
            ["admin", "employee"].includes(role)
          ) {
            return (
              <option selected={false} value={statues.indexOf(status)}>
                {_(`panel.${status}`)}
              </option>
            );
          }
        })}
      </Form.Select>
      {statusChangeErr ? <p>{_("panel.statusChangeErr")}</p> : ""}
    </div>
  );
};

export default TableSelectStatus;
