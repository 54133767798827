import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AxiosController from '../../components/authentication/AxiosController';
import network from '../../imgs/network.png';

const EmailVerify = () => {
    const [message, setMessage] = useState(""); // State to hold the message
    const Navigate = useNavigate();
    const location = useLocation();
    const { lang } = useParams();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            AxiosController.get(`api/${lang}/auth/register/email/verify?token=${token}`)
                .then((response) => {
                    setMessage("Email verified successfully. Redirecting to login page.");
                    setTimeout(() => {
                        Navigate(`/${lang}/auth/login`);
                    }, 2000);
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        setMessage("Invalid token provided. Redirecting to Register page.");
                        setTimeout(() => {
                            Navigate(`/${lang}/auth/register/marketer`);
                        }, 2000);
                    } else {
                        setMessage("An error occurred while verifying email.");
                        setTimeout(() => {
                            Navigate(`/${lang}/auth/register`);
                        }, 2000);
                    }
                });
        }
        else {
            setMessage("No token provided. Redirecting to Register page.");
            setTimeout(() => {
                Navigate(`/${lang}/auth/register/marketer`);
            }, 2000);
        }
    }, [lang, Navigate, location.search]);

    return (
        <div className="d-flex flex-row-reverse vh-for-high" dir={lang === "en" ? "ltr" : "rtl"}>
            <div className="cover-left d-flex flex-column gap-2 align-items-end px-5 d-none d-md-flex" style={{ flexGrow: 3, color: 'white' }}>
                <img src={network} width="400px" alt="" style={{ position: 'absolute', left: 0, bottom: 0 }} />
                {message && (
                    <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <p className={`alert ${message.includes("verified") ? "alert-success" : "alert-danger"}`}
                           style={{ fontSize: '30px'}}>
                            {message}
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}

export default EmailVerify;
