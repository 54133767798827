import React from 'react'
import ProtectedSidebarRoute from "../../../authentication/ProtectedSidebarRoute"; 
import Roles from '../../../authentication/Roles';
import MarketerSearchComponent from './search components/MarketerSearchComponent';
import SellerSearchComponent from './search components/SellerSearchComponent';
import EmployeeTypes from '../../../../components/authentication/EmployeeTypes';
import CallCenterSearchComponent from './search components/CallCenterSearchComponent';
import AdminSearchComponent from './search components/AdminSearchComponent';
const SearchComponent = ({ConfUsers}) => { 
    const role = localStorage.getItem("role");
    const employee_type = localStorage.getItem("employee_type");
  
  return (
    <div>
      <ProtectedSidebarRoute role={role} roles={[Roles['AFFILIATE'], Roles['FULFILLMENT']]} component={MarketerSearchComponent}  />
      <ProtectedSidebarRoute role={role} roles={[Roles['SELLER']]} component={SellerSearchComponent}  />
      <ProtectedSidebarRoute role={role} roles={[Roles['EMPLOYEE']]} empTypes={[EmployeeTypes['CONFIRMATION_EMPLOYEE']]} empType={employee_type} component={CallCenterSearchComponent}  />
      <ProtectedSidebarRoute role={role} roles={[Roles['EMPLOYEE']]} empTypes={[EmployeeTypes['FINANCIALS_EMPLOYEE'], EmployeeTypes['OPERATION_EMPLOYEE']]} empType={employee_type} component={AdminSearchComponent}  ConfUsers={ConfUsers} />
      <ProtectedSidebarRoute role={role} roles={[Roles['ADMIN']]}  component={AdminSearchComponent} ConfUsers={ConfUsers}  />
    </div>
  )
}

export default SearchComponent
