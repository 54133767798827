import React from 'react' 
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const Favorites = ({slug, lang}) => {
  const [_] = useTranslation("global");
  return (
    <li>
          
      <NavLink to={`/${slug}/${lang}/panel/products/all-favorites`}>
        <p className="d-inline ">{_("panel.fav")}</p>
      </NavLink>
    </li>
  );
};

export default Favorites;
