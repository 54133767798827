import { useNavigate } from "react-router-dom";

function useAuth(role, roles, empTypes = [], empType = '') {
  role = role ?  role.toUpperCase() : '';
  roles = roles.map((role) => {
    return role.toUpperCase();
  })
  empType = empType ? empType.toUpperCase() : '';
  empTypes = empTypes.map((empType) => {
    return empType.toUpperCase();
  })
  const navigate = useNavigate();

  const isAuthenticated = () => {
    return true;
  };

  const hasAccess = () => {
    return roles.includes(role);
  };
  const hasAccess2 = () => {
    return empType ? empTypes.includes(empType) : true;
  };

  const requireAuth = () => {
    if (!isAuthenticated()) {
      // Redirect to login page
      navigate('/ar/auth/login');
    } else if (role && !hasAccess()) {
      // Redirect to unauthorized page or show a message
      navigate('/');
    }
  };

  return { isAuthenticated, hasAccess, hasAccess2, requireAuth };
}
export default useAuth;
