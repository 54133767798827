import React from 'react' 
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const Colors = ({slug, lang}) => {
    const [_] = useTranslation('global');
  return (
    <li>
      
         
    <NavLink to={`/${slug}/${lang}/panel/products/colors`}>
      <p className="d-inline ">{_("panel.colors")}</p>
    </NavLink>
  </li>
  )
}

export default Colors
