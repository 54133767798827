import React, { useEffect, useState } from "react";
import IconsGen from "../../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../../loader/Loader";
import AxiosController from "../../../../authentication/AxiosController";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Chart } from "primereact/chart";
import { Helmet } from "react-helmet";

const control_panel_stats_wallet = (
  slug,
  lang,
  token,
  handleStatsWallet,
  handleLoader,
  setChartData,
  setChartOptions,
  _
) => {
  handleLoader(true);
  AxiosController.get(`api/${slug}/${lang}/get-orders-stats-last-30-days/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      const dates = res.data.map(item => item.date);
      const counts = res.data.map(item => item.count);

      const data = {
        labels: dates,
        datasets: [
          {
            label: _('panel.orders'),
            data: counts,
            backgroundColor: '#59c6c9',
            borderColor: '#59c6c9',
            borderWidth: 1,
            borderRadius: 25
          },
        ],
      };

      const options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };

      setChartData(data);
      setChartOptions(options);
      handleLoader(false);
    })
    .catch((e) => { handleLoader(false);});
};

const Last30Orders = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    control_panel_stats_wallet(
      slug,
      lang,
      token,
      null,
      handleLoader,
      setChartData,
      setChartOptions,
      _
    );
  }, [slug, lang, token]);

  return (
    <div className="flex-grow-1 stats-component">
          <Helmet>
   <title>{_("panel.orders")}</title> 
</Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 style={{ color: "rgba(23, 43, 77, 1)" }}>{_("panel.orders")}</h3>
        </div>
        <div
          className="boxes-container d-flex flex-wrap gap-2 w-100"
          dir={box_head_dir}
        >
          <Chart
            type="bar"
            data={chartData}
            style={{width: "100%"}}
            options={chartOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default Last30Orders;
