import React, { useEffect, useState } from "react";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Helmet } from "react-helmet";

const get_control_panel_stats_preview = (
  slug,
  lang,
  token,
  handleStatsWalletPrev,
  handleLoader,
  handleAltName,
  dates = {}
) => {
  AxiosController.get(`api/${slug}/${lang}/get-control-panel-stats-preview/`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      start: dates["start"] ? dates["start"] : "",
      end: dates["end"] ? dates["end"] : "",
    },
  })
    .then((res) => {
      handleStatsWalletPrev(res.data);
      handleLoader(false);
      handleAltName(res.data.alt_name.split(" ")[0]);
    })
    .catch((e) => {});
};

const OrdersStats = () => {
  const { slug, lang } = useParams();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(true);
  const [statsWallet, handleStatsWalletPrev] = useState({});
  const [alt_name, handleAltName] = useState("");

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleLoader(true);
    get_control_panel_stats_preview(
      slug,
      lang,
      token,
      handleStatsWalletPrev,
      handleLoader,
      handleAltName,
      { start: startDate, end: endDate }
    );
  };

  useEffect(() => {
    get_control_panel_stats_preview(
      slug,
      lang,
      token,
      handleStatsWalletPrev,
      handleLoader,
      handleAltName
    );
  }, [slug, lang, token, handleStatsWalletPrev, handleLoader]);

  return (
    <div className="flex-grow-1 stats-component">
          <Helmet>
   <title>{_("panel.ordersStatus")}</title> 
</Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
          <h3 style={{ color: "rgba(23, 43, 77, 1)" }}>
            {_("panel.ordersStatus")}
          </h3>
          <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
            <DateRangePicker onApply={handleApply}>
              <input type="text" className="form-control" />
            </DateRangePicker>
          </div>
        </div>
        <div
          className="boxes-container d-flex flex-wrap gap-2"
          // style={{ direction: "rtl" }}
          dir={box_head_dir}
        >
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"dollarSign"} />
              <p className="flex-grow-1" style={{}}>
                {_("panel.totalorders")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_marketer_orders"]
                  ? statsWallet["total_marketer_orders"][0]["stats"] || 0
                  : 0}{" "}
              </p>
            </div>
            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"bluewaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"bluecheck"} />
              <p className="flex-grow-1" style={{}}>
                {_("panel.deliveredorder")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_marketer_orders_delivered"]
                  ? statsWallet["total_marketer_orders_delivered"][0][
                      "stats"
                    ] || 0
                  : 0}{" "}
              </p>
            </div>
            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"bluewaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"bluetrans"} />

              <p className="flex-grow-1" style={{}}>
                {_("panel.shippedorders")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_marketer_orders_shipped"]
                  ? statsWallet["total_marketer_orders_shipped"][0]["stats"] ||
                    0
                  : 0}{" "}
              </p>
            </div>
            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"bluewaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify">
            <div className="top d-flex align-items-center gap-2">
              <IconsGen icon_name={"orangdash"} />

              <p className="flex-grow-1" style={{}}>
                {_("panel.pendingorders")}
              </p>
            </div>
            <div className="middle fs-2">
              <p>
                {statsWallet["total_marketer_orders_prepared"]
                  ? statsWallet["total_marketer_orders_prepared"][0]["stats"] ||
                    0
                  : 0}{" "}
              </p>
            </div>
            {/* <div className="footer d-flex gap-2 flex-row-reverse align-items-center">
              <IconsGen icon_name={"yellowwaves"} />

              <p>+5%</p>
            </div> */}
          </div>
          <div
            className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify"
            style={{ background: "none" }}
          ></div>
          <div
            className="report-box col-12 col-lg-3 d-flex flex-column gap-2 flex-grow-1 align-items-center py-3 orangify"
            style={{ background: "none" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default OrdersStats;
