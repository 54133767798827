import React from "react";
import StatusLogsBox from "./StatusLogsBox";
import { useTranslation } from "react-i18next"; 
import dateFormat, { masks } from "dateformat";
const UpdateLog = ({ status_update }) => {
  const [_] = useTranslation("global");
   const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type"); 
  const formattedDate = dateFormat( status_update , "dddd, mmmm dS, yyyy, h:MM TT");


  return (
    <div className="d-flex flex-column gap-3 mt-4">

      
     
      {role == "admin" || employee_type == "operation emploee "  || employee_type == "confirmation employee" ? (     <><h3>{_('panel.updateLog')}</h3> 
      <div
      className="d-flex flex-column gap-3 p-3 ticket-box"
      style={{ borderRight: "1.3px dashed #A5A5A5", borderRadius: "7px" }}
    >
         <p className="m-0" style={{ color: "#A5A5A5" }}>
         {/* {status_update} */}
         {formattedDate}
      </p>    </div>
      </> 
      ) : (
        ""
      )}

    </div>
  );
};

export default UpdateLog;
