import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../../authentication/AxiosController";
import IconsGen from "../../icons_generator/IconsGen";
import Loader from "../../loader/Loader";

// /delete-order-detail/{order_id}/{order_detail}
const delete_order_detail = (
  slug,
  lang,
  token,
  order_id,
  order_detail_id,
  handleLoader
) => {
  handleLoader(true);
  AxiosController.delete(
    `api/${slug}/${lang}/delete-order-detail/${order_id}/${order_detail_id}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      handleLoader(false);
    })
    .catch((e) => {});
};
const CartItem = ({
  quantity,
  originalPrice,
  marketerPrice,
  altName,
  thumbnail,
  status,
  handleCart,
  cart,
  cartId,
  orderId,
  productId,
  sku,
  productTitle,
  sellerPrice,
  sysPrice,
  canEdit
}) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const role = localStorage.getItem("role");

  return (
    <div
      className={`box d-flex position-relative flex-column ${
        lang === "en" ? "flex-lg-row-reverse" : "flex-lg-row"
      } justify-content-between p-4 align-items-center`}
      style={{
        border: "1px solid rgba(239, 237, 237, 1)",
        borderRadius: "7px",
      }}
    >
      <Loader active={loading} /> 
      <div className="d-flex flex-column align-items-center">
        
        <img
          src={thumbnail}
          style={{ borderRadius: "7px" }}
          width="120px"
          alt=""
        />
        <p className="m-0">
          {productTitle ? productTitle.slice(0, 10) : ""}...
        </p>
        <p className="m-0">
          <NavLink
            style={{ color: "rgb(243, 168, 55)" }}
            to={`/${slug}/${lang}/product/${productId}`}
          >
            #{productId}
          </NavLink>
        </p>{" "}
        <br />
        <p className="m-0">{sku}</p>
      </div>
      <div className="price-info-cart d-flex flex-column align-items-center gap-2">
        <p style={{ color: "rgba(89, 198, 201, 1)" }}>{_("home.quantity")}</p>
        <div className="q-div d-flex align-items-center gap-2">
          {status == "Pending" && canEdit ? (
            <div
              onClick={(e) => {
                handleCart(() => {
                  return cart.map((item) => {
                    let itemInstance = item;
                    if (item.id == cartId) {
                      itemInstance.quantity += 1;
                    }
                    return itemInstance;
                  });
                });
              }}
            >
              <IconsGen icon_name={"addCircle"} />
            </div>
          ) : (
            ""
          )}

          <p>{quantity}</p>

          {status == "Pending" && canEdit ? (
            <div
              onClick={() => {
                handleCart(() => {
                  return cart.map((item) => {
                    let itemInstance = item;
                    if (item.id == cartId && itemInstance.quantity > 1) {
                      itemInstance.quantity -= 1;
                    }
                    return itemInstance;
                  });
                });
              }}
            >
              <IconsGen icon_name={"minusCircle"} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="price-info-cart d-flex flex-column align-items-center gap-2 align-self-start">
        {status == "Pending" && canEdit ? (
          <div
            onDoubleClick={() => {
              handleCart(() => {
                delete_order_detail(
                  slug,
                  lang,
                  token,
                  orderId,
                  cartId,
                  handleLoader
                );
                return cart.filter((item) => {
                  return item.id != cartId;
                });
              });
            }}
          >
            <IconsGen icon_name={"closeCart"} />
          </div>
        ) : (
          ""
        )}
      </div> 
 

    </div>
  );
};

export default CartItem;
