import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TableLabels = ({
  allOrders,
  displayedOrders,  
  setSelectedOrders,
  selectedOrders,
}) => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const [isAllSelected, setIsAllSelected] = useState(false);  

  const roles_labels = {
    affiliate: [
      _("panel.date"),
      _("panel.orderNumber"),
      _("panel.orderContent"),
      _("panel.customerInfo"),
      _("panel.total"),
      _("panel.commission"),
      _("panel.status"),
    ],
    fulfillment: [
      _("panel.date"),
      _("panel.orderNumber"),
      _("panel.orderContent"),
      _("panel.customerInfo"),
      _("home.COD"),
      _("panel.status"),
    ],
    seller: [
      _("panel.date"),
      _("panel.orderNumber"),
      _("panel.orderContent"),
      _("panel.commission"),
      _("panel.status"),
    ],
    employee: [
      _("panel.date"),
      _("panel.orderNumber"),
      _("panel.orderContent"),
      _("panel.customerInfo"),
      _("panel.user"),
      _("panel.total"),
      _("panel.commission"),
      _("panel.status"),
    ],
    admin: [
      _("panel.date"),
      _("panel.orderNumber"),
      _("panel.orderContent"),
      _("panel.customerInfo"),
      _("panel.user"),
      _("panel.total"),
      _("panel.commission"),
      _("panel.status"),
    ],
  };

  useEffect(() => {
     
    const allDisplayedSelected = displayedOrders.every((order) =>
      selectedOrders.some((selected) => selected.id === order.id)
    );
    setIsAllSelected(allDisplayedSelected);
  }, [displayedOrders, selectedOrders]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedOrders((prevSelected) => [
        ...prevSelected,
        ...displayedOrders.filter(
          (order) => !prevSelected.some((selected) => selected.id === order.id)
        ),
      ]);
    } else {
      setSelectedOrders((prevSelected) =>
        prevSelected.filter(
          (order) => !displayedOrders.some((displayed) => displayed.id === order.id)
        )
      );
    }
  };

  return (
    <tr>
      <th>
        <div
          className="form-check mt-3"
          style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
        >
          ({selectedOrders.length})
          <input
            className="form-check-input"
            style={{
              border: "1px solid rgba(243, 168, 55, 1)",
              transform: "translate(-0%, -50%)",
            }}
            type="checkbox"
            checked={isAllSelected}
            id="flexCheckDefault"
            onChange={handleSelectAll}
          />
        </div>
      </th>
      {roles_labels[role].map((label, index) => (
        <th key={index}>{label}</th>
      ))}
    </tr>
  );
};

export default TableLabels;
