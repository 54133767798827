import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconsGen from "../../icons_generator/IconsGen";
import AxiosController from "../../authentication/AxiosController";
import Loader from "../../loader/Loader";
import { Button, Pagination } from "react-bootstrap";
import PaymentRequestsTableRow from "./PaymentRequestsTableRow";
import SearchComponent from "./SearchComponent";
import { Tabs } from "@mantine/core";
import MarketersStats from "./stats components/MarketersStats";
import SellerStats from "./stats components/SellerStats";
import * as XLSX from "xlsx";
import { Helmet } from "react-helmet";

const fetchPaymentRequests = (
  slug,
  lang,
  token,
  handleOrdesData,
  page,
  handleLoader,
  code,
  user_id,
  start_date,
  end_date,
  status,
  type,start_Updated_at,end_updated_at
) => {
  AxiosController.get(`api/${slug}/${lang}/get-payment-requests/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      code,
      user_id,
      start_date,
      end_date,
      status,
      type,start_Updated_at,end_updated_at
    },
  })
    .then((res) => {
      handleOrdesData(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const PaymentRequests = () => {
  const { slug, lang } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };

  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [paymentRequests, handlePaymentRequests] = useState();
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);
  const [search, handleSearch] = useState(false);


  const usersHaveBalances = () => {
    AxiosController.get(`api/${slug}/${lang}/users/get-users-have-balance`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const worksheet = XLSX.utils.json_to_sheet(res.data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Users-Have-Balance.xlsx");
      })
      .catch((e) => {});
  };



  useEffect(() => {
    handleLoader(true); 
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    handlePage(nextPage);
    const code = urlParams.get("code") || "";
    const user_id = urlParams.get("user_id") || "";
    const start_date = urlParams.get("start_date") || "";
    const start_Updated_at = urlParams.get("start_Updated_at") || "";
    const end_updated_at = urlParams.get("end_updated_at") || "";
    const end_date = urlParams.get("end_date") || "";
    const status = urlParams.get("status") || "";
    const type = urlParams.get("type") || "";
    fetchPaymentRequests(
      slug,
      lang,
      token,
      handlePaymentRequests,
      nextPage,
      handleLoader,
      code,
      user_id,
      start_date,
      end_date,
      status,
      type,start_Updated_at,end_updated_at
    );
  }, [token, slug, lang, location.search, handleLoader]);
  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    > <Helmet>
    <title>{_("panel.payReq")}</title> 
 </Helmet>
      <Tabs defaultValue="gallery">
        <Tabs.List grow>
          <Tabs.Tab value="gallery" className="fs-5" color="rgb(89, 198, 201)">
            {_("panel.marketers")}
          </Tabs.Tab>
          <Tabs.Tab value="messages" className="fs-5" color="rgb(89, 198, 201)">
            {_("panel.sellers")}
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="gallery">
          <MarketersStats />
        </Tabs.Panel>

        <Tabs.Panel value="messages">
          <SellerStats />
        </Tabs.Panel>
      </Tabs>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <Button
          className="fs-5 px-5 w-25"
          style={{
            backgroundColor: "rgb(89, 198, 201)",
            border: "none",
            borderRadius: "7px",
          }}
          onClick={() => {
            handleSearch(!search);
          }}
        >
          {_("panel.search")}
        </Button>

        {search ? <SearchComponent /> : ""}
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.payReq")}
          </h3>
          <button
            className="btn"
            style={{ border: "1px solid #172B4D", color: "#172B4D" }}
            onClick={usersHaveBalances}
          >
            <IconsGen icon_name={"excel"} /> {_("panel.downloadExcel")}
          </button>
        </div>
        <div className="footer d-flex flex-column flex-grow-1 overflow-auto">
          {paymentRequests ? (
            <PaymentRequestsTableRow
              alt_name={paymentRequests["alt_name"]}
              payments_list={paymentRequests["payment_requests"]}
              handleLoader={handleLoader}
              />
          ) : (
            ""
          )}
        </div>
        {paymentRequests ? (
          <Pagination className="m-auto flex-wrap">
            <Pagination.First onClick={() => changeParam("page", 1)} />
            <Pagination.Prev
              onClick={() => (page - 1 > 0 ? changeParam("page", page - 1) : 1)}
            />
            {paymentRequests["payment_requests"]["links"].map(
              (element, index, arr) => {
                if (index == 0 || arr.length - 1 == index) {
                  return "";
                } else {
                  return (
                    <Pagination.Item
                      active={element["active"] ? true : false}
                      onClick={(e) =>
                        element["label"] != "..."
                          ? changeParam("page", parseInt(element["label"]))
                          : ""
                      }
                    >
                      {element["label"]}
                    </Pagination.Item>
                  );
                }
              }
            )}
            <Pagination.Next onClick={() => changeParam("page", page + 1)} />
            <Pagination.Last
              onClick={() =>
                changeParam(
                  "page",
                  paymentRequests["payment_requests"]["last_page"]
                )
              }
            />
          </Pagination>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PaymentRequests;
