import React, { useEffect, useState } from "react";
import StatusGenerator from "../../../globals/StatusGenerator";
import StatusColors from "../../../globals/StatusColors";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";

const change_order_status = (
  slug,
  lang,
  token,
  orderId,
  status,
  currentStatusHandler,
  handleLoader,
  handleStatusChangeErr,
  orders_handler
) => {
  AxiosController.put(
    `api/${slug}/${lang}/change-order-status/${orderId}`,
    { status },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      currentStatusHandler(status);
      handleLoader(false);
      if (orders_handler) orders_handler();
    })
    .catch((e) => {
      handleStatusChangeErr(true);
      handleLoader(false);
    });
};

const TableSelectStatus = ({ currentStatus, orderId, handleLoader, orders_handler = '', canEdit = true }) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");

  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  const [orderStatus, handleOrderStatus] = useState(currentStatus);
  const [statusChangeErr, handleStatusChangeErr] = useState(false);

  const [_] = useTranslation("global");
  const statues = StatusGenerator(role, employee_type);

  useEffect(() => {
    handleOrderStatus(currentStatus);
    handleStatusChangeErr(false);
  }, [currentStatus]);

  return (
    <div className="position-relative " style={{ padding: "0 10px" }}>
      <Form.Select
        style={{
          border: "2px solid " + StatusColors(orderStatus),
          backgroundColor: "white",
          width: "fit-content",
          fontWeight: "bold",
          color: StatusColors(orderStatus),

          paddingRight: "32px",
          margin: "auto",
        }}
        onChange={(e) => {
          handleLoader(true);
          change_order_status(
            slug,
            lang,
            token,
            orderId,
            e.target.value,
            handleOrderStatus,
            handleLoader,
            handleStatusChangeErr,
            orders_handler
          );
        }}
      >
        <option selected={true} value={orderStatus}>
          {_(`panel.${orderStatus}`)}
        </option>
        {canEdit ? statues.map((status) => {
          if (status !== orderStatus) {
            return (
              <option selected={false} value={status}>
                {_(`panel.${status}`)}
              </option>
            );
          }
        }) : ''}
      </Form.Select>
      {statusChangeErr ? (
        <p className="position-absolute left-">{_("panel.statusChangeErr")}</p>
      ) : (
        ""
      )}
    </div>
  );
};

export default TableSelectStatus;
