import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Alert, Button, Form, Modal, Pagination } from "react-bootstrap";
import SuppliersTableRow from "./SuppliersTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import SearchComponent from "./SearchComponent";

const get_warehouses = (
  slug,
  lang,
  token,
  handleWarehouses,
  courierId,
  page,
  handleLoader,
  expense_label,
  start,
  end,
  account_id,
  spent_at_start,
  spent_at_end
) => {
  AxiosController.get(`api/${slug}/${lang}/income/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
    params: { expense_label, start, end ,account_id , spent_at_start, spent_at_end },

  })
    .then((res) => {
      handleWarehouses(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const get_shippingcosts = (
  slug,
  lang,
  token,
  shippingcostsHandler,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/accounts/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      shippingcostsHandler(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};
const get_labels = (slug, lang, token, shippingcostsHandler, handleLoader) => {
  AxiosController.get(`api/${slug}/${lang}/income/labels`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      shippingcostsHandler(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const Income = () => {
  const { slug, lang, courierId } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const [_] = useTranslation("global");
  const [suppliers, handleSuppliers] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [cost, setCost] = useState("");
  const [handling, setHandling] = useState(0);
  const [extraHandling, setExtraHandling] = useState(0);
  const [show, setShow] = useState(false); 
  const [banks, setBanks] = useState([]);
  const [bank, setBank] = useState([]);
  const [labels, setLabels] = useState([]);
  const [label, setLabel] = useState([]);
  const [notes, setNotes] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [govId, setGovId] = useState();
  const [page, handlePage] = useState(urlParams.get("page") || 1);
  const [search, setSearch] = useState(false);

 
  const handleClose = () => setShow(false);
  const handleShow = () => {
    get_labels(slug, lang, token, setLabels, handleLoader);
    setShow(true);
  };
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    setDateOfBirth(startDate);
  };

  const handleCallback = (event, picker) => {
    const startDate = picker._d.toISOString().split("T")[0];
    setDateOfBirth(startDate);
  };

  const createWarehouse = () => {
    handleLoader(true);
    AxiosController.post(
      `api/${slug}/${lang}/income/create`,
      {
        income_label: label,
        amount: handling,
        notes: notes,
        received_at: dateOfBirth,
        account_id: bank,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        get_warehouses(
          slug,
          lang,
          token,
          handleSuppliers,
          courierId,
          1,
          handleLoader
        );
        setShow(false);
      })
      .catch((e) => {
        // handleNewCourierNameErr(
        //   e.response.data.errors ? e.response.data.errors.name[0] : ""
        // );
        // handleCourierTypeErr(
        //   e.response.data.errors ? e.response.data.errors.type[0] : ""
        // );
        handleLoader(false);
      });
  };

  useEffect(() => {
    handleLoader(true);
    const nextPage = parseInt(urlParams.get("page"), 10) || 1;
    const expense_label = urlParams.get("expense_label") || "";
    const account_id = urlParams.get("account_id") || "";
    const start = urlParams.get("start") || "";
    const end = urlParams.get("end") || "";
    const spent_at_start = urlParams.get("spent_at_start") || "";
    const spent_at_end = urlParams.get("spent_at_end") || "";
    handlePage(nextPage);
    get_shippingcosts(slug, lang, token, setBanks, handleLoader);
    get_warehouses(
      slug,
      lang,
      token,
      handleSuppliers,
      courierId,
      nextPage,
      handleLoader,
      
  expense_label,
  start,
  end,
  account_id,
  spent_at_start,
  spent_at_end
    );
  }, [slug, lang, location.search]);

  return (
    <div
      className="position-relative tables-container pt-2"
      style={{ minHeight: "100px", width: "100%" }}
    >
      <Button
      className="mb-4"
        variant="primary"
        onClick={() => {
          setCost("");
          setHandling(0);
          setExtraHandling(0);
          setGovId(0);
          handleShow(true);
        }}
        style={{ background: "#59C6C9", color: "white", border: "none" }}
      >
        {_("panel.add")}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0" dir={box_head_dir}>
          <Modal.Title className="flex-grow-1">{_("panel.add")}</Modal.Title>
        </Modal.Header>
        <Modal.Body dir={box_head_dir}>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              {_("panel.thebank")}
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setBank(e.target.value);
              }}
              value={bank}
            >
              <option value={0}></option>
              {banks.map((bank) => {
                return <option value={bank.id}>{bank.name}</option>;
              })}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              {_("panel.label")}
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              value={label}
            >
              <option value={0}></option>
              {labels.map((bank) => {
                return <option value={bank.id}>{bank.title}</option>;
              })}
            </select>
          </div>
          {label_input(_("panel.paymentamount"), handling, setHandling)}
          {label_input(_("panel.notes"), notes, setNotes)}
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              {_("panel.receiptDate")}
            </label>
            <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                startDate: new Date().toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                }),
                minYear: 1901,
                maxYear: parseInt(moment().format("YYYY"), 10),
              }}
              onApply={handleApply}
              onCallback={handleCallback}
            >
              <input type="text" className="form-control col-4" />
            </DateRangePicker>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0" dir={box_head_dir}>
          <Button
            variant="primary"
            onClick={() => {
              createWarehouse(
                slug,
                lang,
                token,
                courierId,
                govId,
                cost,
                handling,
                extraHandling,
                handleLoader,
                setShow,
                handleSuppliers
              );
            }}
          >
            {_("panel.save")}
          </Button>
        </Modal.Footer>
      </Modal>
               <div> <Button
          className="fs-5 px-5 w-25 align-self-stat mt-2 mb-2 "
          style={{
            backgroundColor: "rgb(89, 198, 201)",
            border: "none",
            borderRadius: "7px",
          }}
          onClick={() => {
            setSearch(!search);
          }}
        >
          {_("panel.search")}
        </Button>
        {search ? <SearchComponent /> : ""} 
                </div>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
   
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.imports")}

          </h3>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {suppliers ? (
            <SuppliersTableRow payments_list={suppliers.data} />
          ) : (
            ""
          )}
        </div>
        {suppliers.data ? (
          <Pagination
            className="m-auto flex-wrap"
            dir={lang === "ar" ? "ltr" : ""}
          >
            <Pagination.First onClick={() => changeParam("page", 1)} />
            <Pagination.Prev
              onClick={() => (page - 1 > 0 ? changeParam("page", page - 1) : 1)}
            />
            {suppliers["links"].map((element, index, arr) => {
              if (index == 0 || arr.length - 1 == index) {
                return "";
              } else {
                return (
                  <Pagination.Item
                    active={element["active"] ? true : false}
                    onClick={(e) =>
                      element["label"] != "..."
                        ? changeParam("page", parseInt(element["label"]))
                        : ""
                    }
                  >
                    {element["label"]}
                  </Pagination.Item>
                );
              }
            })}
            <Pagination.Next onClick={() => changeParam("page", page + 1)} />
            <Pagination.Last
              onClick={() => changeParam("page", suppliers["last_page"])}
            />
          </Pagination>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
const label_input = (label, newWarehouseName, handleNewWarehouseName) => {
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={newWarehouseName}
        onInput={(e) => handleNewWarehouseName(e.target.value)}
      />
    </div>
  );
};
export default Income;
