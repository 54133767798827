import "./styles.css";
import React, { useState, useRef, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import { FaCalendarAlt } from "react-icons/fa";
import { endOfYear } from "date-fns";

const DateRangePickerComponent = ({ state, setState, applyDateRange }) => {
  const { startDate, endDate } = state.selection;
  const maxDate = endOfYear(new Date());
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const pickerRef = useRef(null);  
 
  const togglePicker = () => setIsPickerOpen((prevState) => !prevState); 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsPickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const formattedStartDate = format(startDate, "yyyy-MM-dd");
  const formattedEndDate = format(endDate, "yyyy-MM-dd");

  const buttonText = isPickerOpen
    ? ` ${formattedStartDate} - ${formattedEndDate}`
    : ` ${formattedStartDate} - ${formattedEndDate}`;

  const handleApplyDateRange = () => {
    applyDateRange(startDate, endDate);
    togglePicker();
  };

  return (
    <div ref={pickerRef}>
      <button
        className="btn d-flex align-items-center fw-bold"
        onClick={togglePicker}
        style={{ border: "rgba(89, 198, 201, 1) 1px solid" }}
      >
        {buttonText}
        <FaCalendarAlt className="me-2" />
      </button>

      {isPickerOpen && (
        <div
          dir="ltr"
          className="mt-3 shadow border bg-light container-position"
        >
          <DateRangePicker
            onChange={(item) => setState({ ...state, ...item })}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={[state.selection]}
            maxDate={maxDate} // Limit the maximum selectable date
            minDate={new Date("2000-01-01")} // Set a reasonable minDate if needed
            direction="horizontal"
            ariaLabels={{
              dateInput: {
                selection: {
                  startDate: `Start Date: ${formattedStartDate}`,
                  endDate: `End Date: ${formattedEndDate}`,
                },
              },
              monthPicker: "month picker",
              yearPicker: "year picker",
              prevButton: "previous month button",
              nextButton: "next month button",
            }}
          />
          <div className="d-flex align justify-content-end px-2">
            <button
              className="btn text-white mt-3 mb-2"
              style={{ background: "rgba(89, 198, 201, 1)" }}
              onClick={handleApplyDateRange}
            >
              ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePickerComponent;
