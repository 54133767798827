import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PaymentRequestsTableRow from "./PaymentRequestsTableRow";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import IconsGen from "../../../icons_generator/IconsGen";
import * as XLSX from "xlsx";
import { Helmet } from "react-helmet";

const OrdersContent = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const fileInputRef = useRef(null);

  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`/${slug}/${lang}/panel/sellings/orders?${urlParams.toString()}`);
  };

  const verifyData = (data) => {
    AxiosController.post(
      `api/${slug}/${lang}/orders/orders-content`,
      { tracks: data },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
 
    )
      .then((res) => {
        setData(res.data);
        handleLoader(false);
      })
      .catch((e) => {        handleLoader(false);
      });
  };   

  const handleFile = (e) => {
    handleLoader(true);
    try {
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        setData2(
          parsedData.map((item) => {
            return item["tracks"];
          })
        );
        verifyData(
          parsedData.map((item) => {
            return item["tracks"];
          })
        );
      };
    } catch (e) {
      handleLoader(false);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    verifyData();
    handleLoader(false);
  }, [token, slug, lang, page, handleLoader]);
  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    >  <Helmet>
    <title>{_("panel.Cancels")}</title> 
 </Helmet>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.Cancels")}
          </h3>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFile}
          />
          <button
            className="btn"
            style={{ border: "none", color: "rgb(243, 168, 55)" }}
            onClick={() => changeParam("tracks", data2)}
          >
            {_("panel.showDetailed")}
          </button>
          <button
            className="btn"
            style={{ border: "1px solid #172B4D", color: "#172B4D" }}
            onClick={handleButtonClick}
          >
            <IconsGen icon_name={"excel"} /> {_("panel.uploadExcel")}
          </button>
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {data.length ? (
            <PaymentRequestsTableRow
              payments_list={data}
              handleLoader={handleLoader}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default OrdersContent;
