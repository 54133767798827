import React, { useEffect, useState } from "react";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import Sidebar from "../panel/sidebar/SidebarPhone";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const PhoneSidebar = () => {
  const Navigate = useNavigate();
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    setShow(0);
  }, [Navigate]);
  return (
    <div className="d-md-none d-block">
      <Navbar.Toggle
        aria-controls={`offcanvasNavbar-expand-false`}
        onClick={() => setShow(!show)}
      />
      {/* <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-false`}
            aria-labelledby={`offcanvasNavbarLabel-expand-false`}
            className={'d-block d-md-none'}
            placement="end"
            
          >
            <Offcanvas.Body>
              
            </Offcanvas.Body>
          </Navbar.Offcanvas> */}

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={lang === "en" ? "start" : "end"}
      >
        <Offcanvas.Header closeButton dir={lang === "ar" ? "rtl" : "ltr"}>
          <Offcanvas.Title style={{ fontSize: "1.4rem", color: "#172B4D" }}>
            {_("panel.menu")}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column gap-3">
          <Sidebar />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default PhoneSidebar;
