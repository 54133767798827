import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen"; 

const ControlPanel = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  return (
    <NavLink
      className={`feature_link ${
        lang === "en" ? "feature_link_en" : ""
      } d-flex flex-row-reverse align-items-center gap-2 py-3`}
      dir={direction}
      to={`/${slug}/${lang}/panel/control-panel`}
    >
 
      <IconsGen icon_name={"controlPanel"} />
      <p>{_("panel.controlPanel")}</p>
    </NavLink>
  );
};

export default ControlPanel;
