import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import IconsGen from "../icons_generator/IconsGen";
import { Galleria } from "primereact/galleria";
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className={"slick-arrow"} onClick={onClick} style={nextArrowStyles}>
      <IconsGen icon_name={"nextArrow"} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={"slick-arrow"} onClick={onClick} style={prevArrowStyles}>
      <IconsGen icon_name={"prevArrow"} />
    </div>
  );
}

const ProductSwipper = ({ productImgs }) => {
  const settings = {
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const [images, setImages] = useState(null);
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 3,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];

  useEffect(() => {
  }, []);

  const itemTemplate = (item) => {
    return item ? (<img src={item.img_url} style={{ width: "99%" }} className="rounded-3 mb-3" />) : '';
  };

  const thumbnailTemplate = (item) => {
    return item ? (<img src={item.img_url} style={{width:'100px'}} />) : '';
  };

  return (
    <div className="col-12 col-md-5">

      <Galleria
        value={productImgs}
        responsiveOptions={responsiveOptions}
        numVisible={5}
        style={{ maxWidth: "640px", 'position': 'sticky', 'top':'0' }}
        item={itemTemplate}
        thumbnail={thumbnailTemplate}
      />
    </div>
  );
};

const nextArrowStyles = {
  background: "rgb(89, 198, 201)",
  display: "block",
  position: "absolute",
  right: "-40px",
  top: "50%",
  transform: "translateY(-50%)",
  borderRadius: "100%",
};
const prevArrowStyles = {
  background: "rgb(89, 198, 201)",
  display: "block",
  position: "absolute",
  left: "-40px",
  top: "50%",
  transform: "translateY(-50%)",
  borderRadius: "100%",
};

export default ProductSwipper;
