import React, { useEffect, useState } from "react";
import IconsGen from "../../../icons_generator/IconsGen";
import { useTranslation } from "react-i18next";

import exportToExcel from "../../../globals/functions/ExportToExcel";
import NewSettlement from "./NewSettlement";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";
const createSettlement = (slug, lang, token, selectedOrders, title) => {
  AxiosController.post(
    `api/${slug}/${lang}/settlements/`,
    {
      name: title,
      orders: selectedOrders.map((order) =>
        order.mi_role === "fulfillment"
          ? order.id
          : null
      )
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {})
    .catch((e) => {});
};

const CourierAssign = ({
  fetchCouriers,
  setSetStatus,
  setUnAssign,
  setManifest,
  setSettle,
  selectedOrders,
}) => {
  const [_] = useTranslation("global");
  useEffect(() => {
    
  }, [selectedOrders]);
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [showModal, setShowModal] = useState();
  const [title, setTitle] = useState("");

  return (
    <div className="d-flex gap-2 align-items-center w-100 flex-column flex-lg-row">
      <NewSettlement
        modalHeader={"settle"}
        confirmButton={"save"}
        action={() => {
          createSettlement(slug, lang, token, selectedOrders, title);
          exportToExcel(selectedOrders, title);
     
        }}
        title={title}
        setTitle={setTitle}
        setShowModal={setShowModal}
        showModal={showModal}
      />
      <button
        className="btn px-4 w-100"
        style={{ background: "rgb(89, 198, 201)", color: "#fff" }}
        onClick={fetchCouriers}
      >
        {_("panel.courierAssign")}
      </button>
      <button
        className="btn px-4 w-100"
        style={{ background: "red", color: "#fff" }}
        onClick={() => setUnAssign(true)}
      >
        {_("panel.unassign")}
      </button>
      <button
        className="btn px-4 w-100"
        style={{ background: "#F3A837", color: "#fff" }}
        onClick={() => setSetStatus(true)}
      >
        {_("panel.chnageStatus")}
      </button>
      {/* <button
        className="btn px-4 w-100"
        style={{ background: "#F3A837", color: "#fff" }}
        onClick={() => setManifest((v) => !v)}
      >
        {_("panel.Manifest")}
      </button>
       */}
      <button
        className="btn px-4 w-100"
        style={{ background: "#F3A837", color: "#fff" }}
        onClick={() => {
          if (selectedOrders.length > 0) {
            setShowModal(true);
          }
        }}
      >
        {_("panel.settle")}
      </button>
    </div>
  );
};

export default CourierAssign;
