import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import BarChartExpenses from "../../../charts/BarChartExpenses";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import DateRangePickerComponent from "../../../date/DateRangePickerComponent";

const Expenses = () => {
  const { slug, lang } = useParams();

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const token = localStorage.getItem("token");
  const [loading, handleLoader] = useState(false);
  const [data, handleData] = useState({});
  const [labels, handleLabels] = useState([]);
  const [expenses, handleExpenses] = useState([]); 
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate:new Date(),                
      key: 'selection'
    }
  });  
  const applyDateRange = () => {
    const { startDate, endDate } = state.selection; 
    get_expenses(startDate, endDate);

  }; 
  const get_expenses = (startDate, endDate) => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/reports/system-expenses`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    })
      .then((res) => { 
        handleLabels(res.data.labels);
        handleExpenses(res.data.expenses);
        handleData(res?.data);
        handleLoader(false);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    get_expenses();
  }, [handleData]);

 
  return (
    <div className="flex-grow-1 stats-component">
         <Helmet>
   <title>{_("panel.expenses")}</title> 
</Helmet>
      <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative ">
        <Loader active={loading} />
        <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
        <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
        {_("panel.expenses")}
          </h3>
          <div className="interval d-flex flex-column gap-2 flex-md-row-reverse">
          <DateRangePickerComponent 
                state={state} 
                setState={setState}    
                applyDateRange={()=>applyDateRange()}
              />
          </div>
        </div>
        <div
          className="row w-100 d-flex align-items-center justify-content-center"
          dir={box_head_dir}
        >
          <div className="col-12 w-75  mb-4   gy-4 ">
            <BarChartExpenses labels={labels} expenses={expenses} />
          </div>
          <hr />
          <div className="col-12 gy-4 mt-4 ">
            <div className="container">
              <Table
        responsive 
        > 
                <thead>
                  <tr   className="text-center">
                    <th scope="col" style={{ fontSize: "16px" }}>
                      #
                    </th>

                    <th
                      scope="col"
                   
                      style={{ fontSize: "16px" }}
                    >
                      {" "}
                      {_("panel.label")}
                    </th>
                    <th
                      scope="col" 
                      style={{ fontSize: "16px" }}
                    >
                       
                      {_("panel.total_expenses")}
                    </th>
                  </tr>
                </thead>
                <tbody  >
                {labels.map((label, index) => {
  const expense = expenses.find(
    (exp) => exp.expense_label_id === label.id
  );
  const totalExpenses = expense ? expense.total_expenses : 0;

  return (
    <tr key={label.id} className="text-center">
      <th scope="row" style={{ fontSize: "16px" }}>
        {index + 1}
      </th>
      <td style={{ fontSize: "16px" }}>{label.title}</td>
      <td style={{ fontSize: "16px" }}>
        {totalExpenses?.toLocaleString()}
      </td>
    </tr>
  );
})}
      <tr className="fw-bold " style={{borderTop: "2px solid black"}}>
      <th scope="row" style={{ fontSize: "16px" }}>
    {/* Empty cell for the index column */}
  </th>
  <td  style={{ fontSize: "16px" }}>{_("panel.reportTotal")}</td>  <td style={{ fontSize: "16px" }}>
    {labels
      .reduce((sum, label) => {
        const expense = expenses.find(
          (exp) => exp.expense_label_id === label.id
        );
        return sum + (expense ? expense.total_expenses : 0);
      }, 0)
      .toLocaleString()}
  </td>
</tr>

 
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expenses;
