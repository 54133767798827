import React from "react";
import "./SuccessOrder.css";
import IconsGen from "../../components/icons_generator/IconsGen";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const SuccessOrder = () => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();

  return (
    <div className="container">
        <Helmet>
    <title>  {_("panel.successOrder")}</title> 
 </Helmet>
      <div className="success-container my-3 d-flex justify-content-center flex-column gap-3 align-items-center">
        <div className="success-header">
          <h1>{_("panel.successOrder")}</h1>
        </div>
        <div className="success-body d-flex flex-column gap-4">
          <div className="img d-flex justify-content-center">
            <IconsGen icon_name={"successOrder"} />
          </div>
          <div className="btns-group d-flex gap-2">
            <button className="btn col-5">
              <NavLink to={`/${slug}/${lang}/panel/sellings`} style={{color:'rgba(243, 168, 55, 1)'}}>
                {_('panel.backtoorders')}
              </NavLink>
            </button>
            <button className="btn col-5"><NavLink  to={`/${slug}/${lang}/panel/products`}>{_('panel.backtoproductss')}</NavLink></button>
          </div>
        </div>
        <button className="btn py-2"><NavLink  to={`/${slug}/${lang}`}>{_('panel.backtophome')}</NavLink></button>
      </div>
    </div>
  );
};

export default SuccessOrder;
