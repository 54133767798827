import React from 'react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Sellers = ({allData}) => {
    const {  lang } = useParams();
    console.log(allData);

    const [_] = useTranslation("global");
    const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const formatAmount = (amount) => {
    return parseFloat(amount).toLocaleString();
  };
  return (
    <div className="row" dir={box_head_dir} style={{ height: "1200px" }}>
    <div
      className="col-12"
      style={{ position: "relative", overflowX: "auto", height: "100%" }}
    >
   
   <Table
responsive
dir={box_head_dir}
className="reports-table text-nowrap text-center">
<thead>
<tr className="text-center">
<th>{_("home.fullname")}</th>
<th>{_("panel.approvedPaymentRequests")}</th>
<th>{_("panel.balance")}</th>
<th>{_("panel.confirmedMoney")}</th>
<th>{_("panel.expectedMoney")}</th>
</tr>
</thead>
<tbody>
{allData &&
allData
.sort((a, b) => Number(b.approved_payment_requests) - Number(a.approved_payment_requests))
.map((item, index) => (
    <tr key={index} className="text-center">
      <td>
        <span className="fw-bold">#{item.id}</span><br/>
        {item.first_name} {item.last_name}
      </td>
      <td>{formatAmount(item.approved_payment_requests)}</td>
      <td>{formatAmount(item.balance)}</td>
      <td>{formatAmount(item.confirmed_money)}</td>
      <td>{formatAmount(item.expected_money)}</td>
    </tr>
  ))}
</tbody>
</Table>

    </div>
  </div>
  )
}

export default Sellers
