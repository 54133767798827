/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import Loader from "../../loader/Loader";
import AxiosController from "../../authentication/AxiosController";
import TableSelectStatus from "../sellings/orders/TableSelectStatus";
import TableSelectLabels from "../sellings/orders/TableSelectLabels";
// import { lang } from "moment";
const get_shippingcosts = (
  slug,
  lang,
  token,
  shippingcostsHandler,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/get-shippingcost/`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      shippingcostsHandler(res.data);
      handleLoader(false);
    })
    .catch((e) => {});
};

const CustomerData = ({ CustomerData = {}, orders_handler = "" }) => {
  const { orderId, slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const [_] = useTranslation("global");
  const [loading, handleLoader] = useState(false);
  const [shippingcosts, shippingcostsHandler] = useState([]);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    handleLoader(true);
    get_shippingcosts(slug, lang, token, shippingcostsHandler, handleLoader);
    setShow(true);
  };
  const constant_labels = (label, value, id = "", link = false) => {
    return (
      <div className="info-group d-flex flex-md-row flex-column gap-2">
        <div className="info-group d-flex gap-2">
          <span className="user-key">
            <p>{label} : </p>
          </span>
          <span className="user-value">
            {link ? (
              <NavLink
                style={{ color: "rgb(243, 168, 55)" }}
                to={`/${slug}/${lang}/panel/user/${id}`}
              >
                {value}
              </NavLink>
            ) : (
              <p>{value}</p>
            )}
          </span>
        </div>
      </div>
    );
  };
  const [show, setShow] = useState(false);
  const box_head_dir = lang === "en" ? "ltr" : "rtl";
  const [CustomerNewData, CustomerNewDataHandler] = useState(CustomerData);
  const [shippingcost, shippingcostHandler] = useState(
    CustomerNewData["shipping_cost"]
  );
  useEffect(() => {
    CustomerNewDataHandler(CustomerData);
  }, [CustomerData]);

  const update_customerData = () => {
    AxiosController.put(
      `api/${slug}/${lang}/order-client-update/`,
      {
        id: CustomerNewData.id,
        customer_name: CustomerNewData.customer_name,
        phone_1: CustomerNewData.phone_1,
        phone_2: CustomerNewData.phone_2,
        address: CustomerNewData.address,
        gov_id: CustomerNewData.gov_id,
        public_notes: CustomerNewData.public_notes,
        private_notes: CustomerNewData.private_notes,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        shippingcostsHandler(res.data);
        handleLoader(false);
        setShow(false);
        orders_handler();
      })
      .catch((e) => {});
  };
  return (
    <div className="header py-4 position-relative">
      <div
        className={`upper align-items-center d-flex flex-row gap-3`}
        dir={box_head_dir}
      >
        <div className="user-info col-6 align-self-center">
          {constant_labels(
            _("home.fullname"),
            CustomerData["customer_name"] ? CustomerData["customer_name"] : ""
          )}
          {constant_labels(
            _("home.phonenumber"),
            CustomerData["phone_1"] ? CustomerData["phone_1"] : ""
          )}
          {constant_labels(
            _("home.phonenumber2"),
            CustomerData["phone_2"] ? CustomerData["phone_2"] : ""
          )}
          {constant_labels(
            _("home.province"),
            CustomerData["arabic_name"] ? CustomerData["arabic_name"] : ""
          )}
          {constant_labels(
            _("home.address"),
            CustomerData["address"] ? CustomerData["address"] : ""
          )}
          {constant_labels(
            _("panel.orderNumber"),
            CustomerData["track"] ? CustomerData["track"] : ""
          )}
          {constant_labels(
            _("home.notesforclient"),
            CustomerData["public_notes"] ? CustomerData["public_notes"] : ""
          )}
          {constant_labels(
            _("home.notesformangment"),
            CustomerData["private_notes"] ? CustomerData["private_notes"] : ""
          )}
          {constant_labels(
            _("panel.refOrder"),
            CustomerData["reference_order"] ? CustomerData["reference_order"] : ""
          )}
          {role == "admin" || role == "employee"
            ? constant_labels(
                _("panel.themarketer"),
                CustomerData["marketer_id"]
                  ? `#${CustomerData["marketer_id"]} ${CustomerData["marketer_first_name"]} ${CustomerData["marketer_last_name"]}`
                  : "",
                CustomerData["marketer_id"],
                true
              )
            : ""}
          {constant_labels(
            _("panel.date"),
            CustomerData["created_at_formatted"]
              ? CustomerData["created_at_formatted"]
              : ""
          )}
          {role == "admin" || role == "employee"
            ? constant_labels(
                _("panel.confirmationUser"),
                CustomerData["first_name"]
                  ? CustomerData["first_name"] + " " + CustomerData["last_name"]
                  : ""
              )
            : ""}
          {role == "admin" || role == "employee"
            ? constant_labels(
                _("panel.courierTrack"),
                CustomerData["courier_track"] || CustomerData["courier_name"]
                  ? `${CustomerData["courier_track"]} ${CustomerData["courier_name"]}`
                  : ""
              )
            : ""}
        </div>
        <div>
          <div className="d-flex flex-column gap-2 align-items-center justify-content-center">
            <div className="d-flex align-items-center gap-2 flex-column flex-md-row">
              {CustomerData["status"] === "Pending" &&
              CustomerData["canEdit"] ? (
                <Button
                  variant="primary"
                  className="my-3 rounded"
                  style={{
                    borderRadius: 0,
                    background: "rgb(243, 168, 55)",
                    color: "white",
                    border: "none",
                  }}
                  onClick={handleShow}
                >
                  {_("panel.editdetails")}
                </Button>
              ) : (
                ""
              )}
              <TableSelectStatus
                currentStatus={CustomerData["status"]}
                handleLoader={handleLoader}
                orderId={CustomerData["id"]}
                orders_handler={orders_handler}
                canEdit={CustomerData["canEdit"]}
              />
            </div>
            {role == "employee" || role == "admin" ? (
              <TableSelectLabels
                handleLoader={handleLoader}
                currentLabel={CustomerData["label"]}
                currentStatus={CustomerData["status"]}
                orderId={CustomerData["id"]}
              />
            ) : (
              ""
            )}

            <Modal show={show} onHide={handleClose} centered size="md">
              <Loader active={loading} />

              <Modal.Header
                closeButton
                className="justify-content-between border-0"
                dir={box_head_dir}
              >
                <Modal.Title className="flex-grow-1">
                  {_("panel.editdetails")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body dir={box_head_dir}>
                <div className="inputs-container">
                  {label_input(
                    _("home.fullname"),
                    CustomerNewData,
                    CustomerNewDataHandler,
                    "customer_name"
                  )}
                  {label_input(
                    _("home.phonenumber"),
                    CustomerNewData,
                    CustomerNewDataHandler,
                    "phone_1"
                  )}
                  {label_input(
                    _("home.phonenumber2"),
                    CustomerNewData,
                    CustomerNewDataHandler,
                    "phone_2"
                  )}
                  <div>
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.province")}
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) =>
                        CustomerNewDataHandler({
                          ...CustomerNewData,
                          gov_id: e.target.value,
                        })
                      }
                    >
                      <option></option>
                      {shippingcosts.map((gov) => {
                        return (
                          <option
                            value={gov.id}
                            selected={
                              gov.id === CustomerData["gov_id"] ? true : false
                            }
                          >
                            {gov.arabic_name} - {gov.cost}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {label_textarea(
                    _("home.address"),
                    CustomerNewData,
                    CustomerNewDataHandler,
                    "address",
                    CustomerData["address"]
                  )}
                  {label_textarea(
                    _("home.notesforclient"),
                    CustomerNewData,
                    CustomerNewDataHandler,
                    "public_notes"
                  )}
                  {label_textarea(
                    _("home.notesformangment"),
                    CustomerNewData,
                    CustomerNewDataHandler,
                    "private_notes"
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer className="d-flex py-4 border-0">
                <Button
                  variant="primary"
                  className="flex-grow-1 py-2"
                  onClick={() => {
                    update_customerData();
                  }}
                >
                  {_("panel.save")}
                </Button>
                <Button
                  variant="primary"
                  className="flex-grow-1 py-2"
                  onClick={handleClose}
                  style={{ background: "rgb(243, 168, 55)" }}
                >
                  {_("panel.cancel")}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};
const label_input = (label, CustomerNewData, CustomerNewDataHandler, prop) => {
  const handleChange = (e) => {
    CustomerNewDataHandler({ ...CustomerNewData, [prop]: e.target.value });
  };
  return (
    <div className="mb-3">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <input
        type="text"
        className="form-control py-2 egp"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        value={CustomerNewData[prop]}
        onChange={handleChange}
      />
    </div>
  );
};
const label_textarea = (
  label,
  CustomerNewData,
  CustomerNewDataHandler,
  prop
) => {
  const newCustomerData = { ...CustomerNewData };

  return (
    <div className="mb-3 d-flex flex-column">
      <label htmlFor="exampleInputEmail1" className="form-label">
        {label}
      </label>
      <textarea
        class="form-control"
        rows={3}
        onInput={(e) => {
          newCustomerData[prop] = e.target.value;
          CustomerNewDataHandler(newCustomerData);
        }}
      >
        {CustomerNewData[prop]}
      </textarea>
    </div>
  );
};

export default CustomerData;
