import React from "react";
import { useTranslation } from "react-i18next";

const TableLabels = () => {
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");

  const roles_labels = {
    employee: [
      _("panel.dateExpenses"),
      _("panel.time"),
      _("panel.spentDate"),
      _("panel.thebank"),
      _("panel.thecharge"),
      _("panel.label"),
      _("panel.notes"),
    ],
    admin: [
      _("panel.dateExpenses"),
      _("panel.time"),
      _("panel.spentDate"),
      _("panel.thebank"),
      _("panel.thecharge"),
      _("panel.label"),
      _("panel.notes"),


    ],
  };

  return (
    <tr>
      {roles_labels[role].map((label) => {
        return <th>{label}</th>;
      })}
    </tr>
  );
};

export default TableLabels;
