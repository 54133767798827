import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TableLabels from "./TableLabels";

const colors = {
  seller: "rgba(89, 198, 201, 1)",
  affiliate: "rgb(243, 168, 55)",
  employee: "rgba(23, 43, 77, 1)",
};

const PaymentRequestsTableRow = ({
  payments_list,
  alt_name,
  handleLoader,
  excelData,
}) => {
  
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const role = localStorage.getItem("role");
  let totalPriceSysLoop  = 0, totalPriceCourLoop = 0;
  const [totalPriceSys, setTotalPriceSys] = useState(0);
  const [totalPriceCour, setTotalPriceCour] = useState(0);
  const [formatted_data, setFormattedData] = useState(() => {
    return excelData.reduce((acc, { track, cost }) => {
      acc[track] = cost;
      return acc;               
    }, {});
  });
  const total_marketer_commission   =   payments_list.reduce((total , payment)=>{
    
    return  total + payment.marketer_commissions; 
     
  },0)
  const total_seller_commission   =   payments_list.reduce((total , payment)=>{
    
    return  total + payment.seller_commission; 
     
  },0)
  const total_sys_commission   =   payments_list.reduce((total , payment)=>{
    
    return  total + payment.sys_commission; 
     
  },0)
  useEffect(() => {
    // excelData.forEach((item) => {
    //   setFormattedData({...formatted_data, [item['track']]: item['cost']})
    // }); 
    payments_list.forEach((order, index) => {
      totalPriceSysLoop += Number(order["total_price"]) || 0;
      const priceForTrack = Number(formatted_data[order["track"]]) || 0; 
      totalPriceCourLoop += priceForTrack;
    });
    
    setTotalPriceSys(totalPriceSysLoop);
    setTotalPriceCour(totalPriceCourLoop);
  }, [excelData, payments_list , formatted_data]); 
 
  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels
            stats={[payments_list.length.toLocaleString(), totalPriceSys.toLocaleString(), Math.min(totalPriceCour).toFixed(3), total_marketer_commission, total_seller_commission, total_sys_commission]}
          />
        </thead>
        <tbody>
          {payments_list.map((order, index) => {
            const valid = formatted_data[order["track"]] == order["total_price"]  ? "" : "text-danger";     
            const delivered = order["delivered"]  == 1 ? "" : "text-white bg-danger";

            return (
              <tr className="">
                <td key={order.id}>
                  <div
                    className="form-check mt-3"
                    style={{ paddingLeft: "2.24rem", paddingRight: "7px" }}
                  >
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </td>
                <td className={`align-middle ${delivered}  ${valid} `}> {order["track"]}   </td>
                <td className={`align-middle  ${delivered}   ${valid} `}> {order["total_price"]} </td>
                <td className={`align-middle      ${delivered}  ${valid} `}>{formatted_data[order["track"]]}</td>
                {role ==="admin" &&
                <><td className={`align-middle  ${delivered}   ${valid} `}> {order["marketer_commissions"]} </td>
                <td className={`align-middle  ${delivered}   ${valid} `}> {order["seller_commission"]} </td>
                <td className={`align-middle  ${delivered}   ${valid} `}> {order["sys_commission"]} </td></>}
                
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentRequestsTableRow;
