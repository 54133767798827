import React, { useEffect } from "react";
import { Image } from "antd";
import IconsGen from "../../../icons_generator/IconsGen";
import { useParams } from "react-router-dom";
import AxiosController from "../../../authentication/AxiosController";

const delete_product_image = (slug, lang, token, product_image_id, handleLoader) => {
  handleLoader(true);
  AxiosController.put(
    `api/${slug}/${lang}/product-image/delete-image`,
    {
      id: product_image_id,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      handleLoader(false)
    })
    .catch((e) => {});
};
const set_product_thumb = (slug, lang, token, product_id, img_url, handleLoader, fetchProduct) => {
  handleLoader(true);
  AxiosController.put(
    `api/${slug}/${lang}/product-image/update-thumb/${product_id}`,
    {
      thumbnail: img_url,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      handleLoader(false)
      fetchProduct();
    })
    .catch((e) => {});
};
const ProductImgsSlider = ({ productImgs = [], productId, handleLoader , fetchProductImgs, productThumb, fetchProduct}) => {
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  useEffect(() => {

  }, [])
  return (
    <div style={containerStyles} className="d-flex flex-wrap gap-2">
      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) =>
            console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        {productImgs.map((img) => {
          return (
            <div className="position-relative antd-container rounded-3 p-1" style={productThumb == img.img_url ? {borderColor:'rgb(89, 198, 201)', borderWidth:'2px'} : {}} >
              <div
                className="trash position-absolute p-2 rounded-3"
                onClick={() => {
                  delete_product_image(slug, lang, token, img.id, handleLoader);
                  fetchProductImgs()
                }}
              >
                <IconsGen icon_name={"delete"} />
              </div>
              <Image width={200} src={img.img_url} />
              <div className="thumb position-absolute p-2 rounded-3" onClick={() => {
                set_product_thumb(slug, lang, token, productId, img.img_url, handleLoader, fetchProduct);
                fetchProductImgs()
              }}>
                <IconsGen icon_name={"gall"} />
              </div>
            </div>
          );
        })}
      </Image.PreviewGroup>
    </div>
  );
};
const containerStyles = {};
export default ProductImgsSlider;
