import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next"; 
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../loader/Loader";

const SearchComponent = () => {
  const { t: _ } = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

   const [sku, setSku] = useState(urlParams.get("sku") || "");
  const [productId, setProductId] = useState(urlParams.get("product_id") || "");
  const [productName, setProductName] = useState(urlParams.get("product_name") || "");
  const [seller, setSeller] = useState(urlParams.get("seller") || ""); 
  const [loading, setLoading] = useState(false);

  const changeParam = () => { 
    urlParams.set("sku", sku);
    urlParams.set("product_id", productId); 
    urlParams.set("product_name", productName); 
    urlParams.set("seller", seller); 
    navigate(`?${urlParams.toString()}`);
  };

  const resetHandler = () => {
     setSku("");
    setProductId("")
    setSeller("") 
    navigate("");
  };

 
  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, []);

  return (
    <div className="w-100 d-flex flex-column gap-1 mt-2 position-relative gap-3 my-3 pl-5">
      <Loader active={loading} />
      <div className="inputs-row d-flex flex-column flex-md-row gap-2">
        <Col>
          <Form.Group controlId="productName">
            <Form.Control
              type="text"
              placeholder={_("panel.productName")}
              onChange={(e) => setProductName(e.target.value)}
              value={productName}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="sku">
            <Form.Control
              type="text"
              placeholder="SKU"
              onChange={(e) => setSku(e.target.value)}
              value={sku}
            />
          </Form.Group>
        </Col>

      </div>
      <div className="inputs-row d-flex w-100 gap-2 flex-column flex-md-row">
      <Col>
          <Form.Group controlId="seller">
            <Form.Control
              type="text"
              placeholder={_("panel.seller")}              onChange={(e) => setSeller(e.target.value)}
              value={seller}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="productId-name">
            <Form.Control
              type="text"
              placeholder={_("home.productcode")}              onChange={(e) => setProductId(e.target.value)}
              value={productId}
            />
          </Form.Group>
        </Col>
      </div>
      <div className="action-inputs d-flex gap-2">
        <Button
          className="px-5 align-self-end py-2"
          style={{
            backgroundColor: "rgb(243, 168, 55)",
            border: "none",
            borderRadius: "7px",
            color: "white",
            textAlign: "center",
          }}
          onClick={changeParam}
        >
          {_("panel.search")}
        </Button>
        <Button
          style={{ backgroundColor: "rgb(23, 43, 77)" }}
          variant="dark"
          onClick={resetHandler}
        >
          {_("panel.reset")}
        </Button>
      </div>
    </div>
  );
};

export default SearchComponent;
