import React from "react";
import { useTranslation } from "react-i18next";

import IconsGen from "../../../icons_generator/IconsGen";
import { currancyConvertor } from "../../../../unit/currancyConvertor";

const BlueRow = ({ values, slug, image, titles = [] }) => {
  const [_] = useTranslation("global");
  return (
    <div className="gap-2  justify-content-center d-flex flex-wrap">
      {titles.map((title, index) => {
        return (
          <div className="money-card flex-grow-1 py-4 col-12 col-md-2">
            <div className="money-card-container d-flex flex-column align-items-center">
              <div className="title d-flex flex-column flex-lg-row-reverse align-items-center gap-2">
                <IconsGen icon_name={"bluemoney"} />
                <p>{title}</p>
              </div>
              <div className="money">
                <p>{currancyConvertor(values[index])}</p>
              </div>
              <div className="currency d-flex flex-column flex-md-row-reverse align-items-center gap-2">
                <img src={image} alt="" />
                <p>{slug}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BlueRow;
