import React from "react";
import Carousel from "react-bootstrap/Carousel";

const HomeCarousel = ({ imgs }) => {
  return (
    <Carousel fade className="mt-4">
      {imgs.map((element) => {
        return (
          <Carousel.Item>
            <img className="d-block w-100" src={element.image} alt="img" />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default HomeCarousel;
