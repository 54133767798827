import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useLocation, useNavigate } from "react-router-dom";
import AllStatus from "../../authentication/AllStatus";
import Roles from '../../../components/authentication/AllStatus'
const SearchComponent = () => {
  const [_] = useTranslation("global");
  const location = useLocation();
  const Navigate = useNavigate();

  const urlParams = new URLSearchParams(location.search);

  const [orderNumber, handleOrderNumber] = useState("");
  const [customerNumber, handleCustomerNumber] = useState("");
  const [productCode, handleProductCode] = useState("");
  const [sku, handleSku] = useState("");
  const [status, handleStatus] = useState("");
  const [startDate, handleStartDate] = useState("");
  const [endDate, handleEndDate] = useState("");

  const changeParam = () => {
    urlParams.set("name", orderNumber);
    urlParams.set("code", customerNumber);
    urlParams.set("email", productCode);
    urlParams.set("role", sku);
    urlParams.set("start_date", startDate);
    urlParams.set("end_date", endDate);
    urlParams.set("is_active", status);
    Navigate(`?${urlParams.toString()}`);
  };
  const resetHandler = () => {
    handleOrderNumber("");
    handleCustomerNumber("");
    handleProductCode("");
    handleSku("");
    handleStatus("");
    handleEndDate("");
    Navigate("");
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    const endDate = picker.endDate.format("YYYY-MM-DD");
    handleStartDate(startDate);
    handleEndDate(endDate);
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        changeParam();
      }}
    >
      <div className="w-100 d-flex flex-column gap-1">
        <div className="inputs-row d-flex w-100 gap-2 justify-content-between flex-column  flex-md-row">
          <Col>
            <Form.Group
              className="mb-3 flex-grow-1"
              controlId="exampleForm.ControlInput1"
            >
              <Form.Control
                type="text"
                placeholder={_("panel.code")}
                onChange={(e) => {
                  handleCustomerNumber(e.target.value);
                }}
                value={customerNumber}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="">
              <Form.Select
                onChange={(e) => {
                  handleStatus(e.target.value);
                }}
                value={status}
              >
                <option value={""} disabled>
                  الحالة
                </option>
                <option value={1}>{_("panel.Active")}</option>
                <option value={0}>{_("panel.Inactive")}</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="" controlId="exampleForm.ControlInput1">
              <DateRangePicker onApply={handleApply}>
                <input type="text" className="form-control" />
              </DateRangePicker>
            </Form.Group>
          </Col>
        </div>

        <div className="action-inputs mt-3">
          <input
            className=" px-5 align-self-end py-2"
            style={{
              backgroundColor: "rgb(243, 168, 55)",
              border: "none",
              borderRadius: "7px",
              color: "white",
              textAlign: "center",
            }}
            onClick={() => {
              changeParam();
            }}
            type="submit"
            value={_("panel.search")}
          />
          <Button
            style={{ backgroundColor: "rgb(23, 43, 77)" }}
            variant="dark"
            className="d-inline"
            onClick={resetHandler}
          >
            {_("panel.reset")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SearchComponent;
