export default [
  "Canceled",
  "Returned",
  "Cash Delivery",
  "Delivered",
  "Pending Cancel",
  "Shipped",
  "Pending Return",
  "Waiting",
  "Ready For Shipping",
  "Call Attempt",
  "Confirmed",
  "Pending",
  'Not Confirmed',
  'Settled'
];
